import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import userService from './../../../services/users/user.service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentPasteSearch from '@mui/icons-material/ContentPasteSearch';
import ReportServices from './../../../services/reportservices';
import Popover from '@mui/material/Popover';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionIcon from '@mui/icons-material/Description';
import './style.css';
import PrescriptionServices from '../../../services/prescription';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faPrint, faDownload } from '@fortawesome/free-solid-svg-icons';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportsFilesmanagement from '../shared/downloadandprint';
import AppointmentId from '../../../services/Appointment';
import Users from '../../../utils/users';
import { Helmet } from "react-helmet"
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Dropdown from 'react-bootstrap/Dropdown';
import SearchIcon from '@mui/icons-material/Search';



interface Doctor {
    id: string;
    FirstName: string;
    LastName: string;
}

interface Patient {
    id: string;
    PatientName: string;
    // patientID: string;
    PhoneNumber: string;
    DoctorID: string;
    EmailId: string;
    AppointmentDate: string;
    AppointmentTime: string;
    PaymentMode: string;
    Amount: string;
    PaymentStatus: string
    AppointmentID: string;

}

const AppoinementListHistory = () => {
    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

    // pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.REACT_APP_HOST}js/pdf.worker.min.mjs`;

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

    const location = useLocation();
    const initialState: Patient = {
        id: '',
        PatientName: '',
        // patientID: '',
        PhoneNumber: '',
        DoctorID: '',
        EmailId: '',
        AppointmentDate: '',
        AppointmentTime: '',
        PaymentMode: '',
        Amount: '',
        PaymentStatus: '',
        AppointmentID: ''

    };

    const navigate = useNavigate();
    const [data, setData] = useState<Patient>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [patientList, setPatientList] = useState<Patient[]>([]);
    const [doctorList, setDoctorList] = useState<Doctor[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('Modal');
    const [singleRegister, setSingleRegister] = useState<any>(null);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const [viewFiles, setViewFiles] = useState<any>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [kYCModalOpen, setIsKYCModalOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [fileTypeList, setFileTypeList] = useState([]);
    const [mail, setMail] = useState({ appID: '', paymentMode: '' })

    const { slug }: any = useParams();



    const getPatients = async () => {
        const fileTypes: any = await ReportServices().reportTypeList();
        setFileTypeList(fileTypes);
        let response: any
        // if (logUserObject?.Role === "Doctor") {
        if (logUserObject?.Role === "Doctor") {
            const apponitment = (location?.state?.patientID && location.state.patientID !== "NA") ? await userService().ListPatientAppointmentsBypatientID(location?.state?.patientID) : await userService().ListPatientAppointmentsByphoneNumber(location?.state?.PhoneNumber);
            response = apponitment?.filter((user: any) => user?.DoctorID === logUserObject?.userId)
        } else {
            if (location?.state?.appointmentDetails) {
                response = location?.state?.appointmentDetails;
            } else {
                response = (location?.state?.patientID && location.state.patientID !== "NA") ? await userService().ListPatientAppointmentsBypatientID(location?.state?.patientID) : await userService().ListPatientAppointmentsByphoneNumber(location?.state?.PhoneNumber);
            }
        }

        const sortedAppointments = response?.sort((a: any, b: any) => {
            const dateTimeA = new Date(`${a.AppointmentDate}T${a.AppointmentTime}`).getTime();
            const dateTimeB = new Date(`${b.AppointmentDate}T${b.AppointmentTime}`).getTime();

            return dateTimeA - dateTimeB; // Sort in ascending order
        });

        setPatientList(sortedAppointments);
    };

    // useEffect(() => {
    //     const getAppointment = async () => {
    //         const appDecId = slug && Users().decodeBase64(slug);
    //         const getAppInfo = appDecId && await userService().ListPatientAppointmentsById('', appDecId) as any;
    //         if (getAppInfo && getAppInfo.length > 0) {
    //             handleAppoinmentDelete(getAppInfo[0]?.id, getAppInfo[0]?.PaymentMode);
    //         }
    //     };

    //     getAppointment();
    // }, [slug]);


    useEffect(() => {
        const getAppointment = async () => {
            if (slug) {
                const appDecId = await Users().decodeBase64(slug)
                const getAppInfo = await userService().ListPatientAppointmentsById('', appDecId) as any;
                if (getAppInfo?.length > 0) {
                    // Set mail to true (this will trigger the second useEffect)
                    setMail({
                        appID: getAppInfo[0]?.id,
                        paymentMode: getAppInfo[0]?.PaymentMode
                    });
                }
            }
        };
        getAppointment();
    }, [slug]);

    // Second useEffect to handle appointment deletion when mail changes
    useEffect(() => {
        if (mail && mail?.appID) {
            handleAppoinmentDelete(mail?.appID, mail?.paymentMode);
        }
    }, [mail?.appID]);


    const handleStatusToggle = (rowData: any) => async (event: any) => {
        const updatedStatus = event?.target?.checked === true ? "1" : "0";
        console.log("updatedStatus", updatedStatus);
        const updatedRowData = { ...rowData, id: rowData?.id, PaymentStatus: updatedStatus };
        console.log("updatedRowData", updatedRowData);
        const update = await AppointmentId().updatePatientPayment(updatedRowData);
        console.log("updatepayment", update);

        setPatientList((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, PaymentStatus: updatedStatus } : row
            )
        );
    };


    const getDoctors = async () => {
        const response = await userService().ListDoctor('Doctor');
        setDoctorList(response);
    };

    useEffect(() => {
        getPatients();
        getDoctors();
    }, []);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
            (day % 10 === 2 && day !== 12) ? 'nd' :
                (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

        return `${day}${suffix} ${month} ${year}`;
    };

    const handleAppoinmentDelete = async (appID: string, PaymentMode: string) => {
        // console.log("appointemntID", appID);

        const confirmBox = window.confirm("Are you sure you want to cancel the appointment?");
        if (!confirmBox) {
            alert('Cancellation process aborted.');
            return; // Abort if the user doesn't confirm
        }
        // if (confirmBox) {
        try {
            if (PaymentMode === "online") {
                // List payments
                const paymentResp = await AppointmentId().ListPayments(appID);
                const paymentID = paymentResp[0]?.razorpay_payment_id;
                const payAmount = paymentResp[0]?.Amount;

                // const response = await fetch(`https://api.razorpay.com/v1/payments/${paymentID}/refund`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': `Basic ${btoa(`${process.env.REACT_APP_RAZORPAY_KEY}:${process.env.REACT_APP_RAZORPAY_SECRET_KEY}`)}`
                //     },
                //     body: JSON.stringify({
                //         amount: payAmount
                //     })
                // });

                // if (!response.ok) {
                //     const errorText = await response.text();
                //     throw new Error(`Refund request failed: ${response.status} - ${errorText}`);
                // }

                // const refundResult = await response.json();
                // console.log("Refund result:", refundResult);

                // if (refundResult?.status === 'authorized') {
                // alert('Refund initiated successfully!');
                const response = await AppointmentId().cancelAppointMent(appID);
                if (response && response?.data?.deletePatientAppointment) {

                    const currentDate = new Date();
                    const formattedDate = currentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(',', ''); // Removing comma from the formatted date
                    const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                    if (response?.data?.deletePatientAppointment?.EmailId) {
                        const SMSresponse: any = await Users().generateEmailSMS(
                            response?.data?.deletePatientAppointment?.EmailId,
                            `<html><body><div>Welcome to Trust Hospitals!<br /><br />Thank you for contacting us,your appointment  ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime}.<br/><br />Best Regards,<br />Trust Hospital Team</div></body></html>`,
                            'Trust Hospitals,Patient Appointment',
                            'Your appointment cancelled successfully'
                        );
                        const MobileSMSResponse = await Users().generateMobileMsgBulk("+91" + response?.data?.deletePatientAppointment?.PhoneNumber, `Thank you! Your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime} - CLUSTERIT`, "Your appointment created successfully")
                    }

                    // const MobileSMSResponse = await Users().generateMobileMsg(

                    //     "+91" + response?.data?.deletePatientAppointment?.PhoneNumber,
                    //     `Thank you, your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} on ${appDate} at ${response?.data?.deletePatientAppointment?.AppointmentTime}  has been cancelled successfully.`,
                    //     'Your appointment cancelled successfully'

                    // )
                    if (response && response?.data?.deletePatientAppointment) {
                        await alert('Appointment canceled.');
                        await getPatients();
                    }
                } else {
                    console.error("Error in CancleAppointment:", response);
                    alert('An error occurred while canceling the appointment. Please try again.');
                }
                // } else {
                //     alert('Refund failed. Please try again.');
                // }
            } else {
                const response = await AppointmentId().cancelAppointMent(appID);

                const appDate = formatDate(response?.data?.deletePatientAppointment?.AppointmentDate)
                const appEncId = response && await Users().encodeBase64(response?.data?.deletePatientAppointment?.AppointmentID)

                console.log({ response });

                if (response && response?.data?.deletePatientAppointment) {
                    const currentDate = new Date();
                    const formattedDate = currentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(',', ''); // Removing comma from the formatted date
                    const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                    if (response && response?.data?.deletePatientAppointment?.EmailId) {
                        try {
                            const SMSresponse: any = await Users().generateEmailSMS(
                                response?.data?.deletePatientAppointment?.EmailId,
                                `<html><body><div>Welcome to Trust Hospitals!<br /><br />Thank you for contacting us,your appointment  ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime}.<br/><br />Best Regards,<br />Trust Hospital Team</div></body></html>`,
                                'Trust Hospitals,Patient Appointment',
                                'Your appointment cancelled successfully'
                            );
                        } catch (err) {
                            console.error("Error sending Email:", err);
                        }
                    }
                    try {
                        const MobileSMSResponse = await Users().generateMobileMsgBulk("+91" + response?.data?.deletePatientAppointment?.PhoneNumber, `Thank you! Your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime} - CLUSTERIT`, "Your appointment created successfully");
                    } catch (err) {
                        console.error("Error sending SMS:", err);
                    }

                    if (response && response?.data?.deletePatientAppointment) {
                        alert('Appointment canceled.');
                        getPatients();
                    }
                } else {
                    console.error("Error in CancleAppointment:", response);
                    alert('An error occurred while canceling the appointment. Please try again.');
                }
            }
        } catch (error) {
            console.error('Error during cancellation:', error);
            alert('An error occurred while canceling the appointment.');
        }
        // } else {
        //     alert('Cancellation process aborted.');
        // }
    };

    const handleDelete = async (id: string) => {
        // const confirmBox = window.confirm("Are you sure you want to delete the selected patient?");
        // if (confirmBox) {
        await userService().Deletepatient(id);
        getPatients();
        // } else {
        //     alert('Deletion canceled.');
        // }
    };
    const handleReportEdit = async (event: any) => {
        navigate('/addReports', { state: { appointmentID: event?.id } });
    }
    const handleUpload = async (row: any) => {
        navigate("/addReports", { state: { appointmentId: row?.id, patientID: row?.patientID } });
    };
    const manageReports = async (event: any) => {
        const getReport = await ReportServices().reportsListBypatientID(event?.id);
        if (getReport?.length > 0) {
            handleReportEdit(event);
        } else {
            handleUpload(event);
        }
    }

    const getFileUrl = (fileName: any) => {
        const baseUrl = process.env.REACT_APP_S3_BUCKET_FETCH;
        const file = `${baseUrl}/images/${fileName}`;
        return file;
    };
    const handleReportDownload: any = async (doctor: any, type: any) => {
        const report = type === "reports"
            ? await ReportServices().reportsListBypatientID(doctor?.id)
            : await PrescriptionServices().prescriptionListBypatientID(doctor?.id);
        const downloadfile = report[0];
        const zip = new JSZip();

        const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
        const folderName = type === "reports" ? `reports${timestamp}` : `prescriptions${timestamp}`;
        const folder: any = zip.folder(folderName);
        if (downloadfile?.file?.length > 0) {
            try {
                for (const file of downloadfile?.file) {
                    // Handle file names and download
                    const fileNames = Array.isArray(file?.name) ? file?.name : [file?.name];
                    for (const fileName of fileNames) {
                        try {
                            const fileUrl = getFileUrl(fileName);
                            const response = await fetch(fileUrl);

                            if (!response?.ok) {
                                console.error(`Failed to fetch file: ${fileName}. Status: ${response.status}`);
                                continue;
                            }

                            const fileBlob = await response.blob();

                            if (!fileBlob.size) {
                                console.error(`Empty file received: ${fileName}`);
                                continue;
                            }

                            // Determine MIME type
                            let mimeType = fileBlob.type;
                            if (mimeType === "application/octet-stream") {
                                const extension = fileName.split('.').pop().toLowerCase();
                                switch (extension) {
                                    case 'jpg':
                                    case 'jpeg':
                                        mimeType = 'image/jpeg';
                                        break;
                                    case 'png':
                                        mimeType = 'image/png';
                                        break;
                                    default:
                                        mimeType = 'application/octet-stream';
                                }
                            }

                            // Create new Blob with the correct MIME type
                            const correctBlob = new Blob([fileBlob], { type: mimeType });
                            // Add file to zip
                            folder?.file(fileName, correctBlob, { binary: true });
                        } catch (fetchError) {
                            console.error(`Error processing file: ${fileName}`, fetchError);
                        }
                    }

                    // Handle description and generate PDF
                    if (file?.description) {
                        await ReportsFilesmanagement().imageCanvas(file?.description, folder, true, file?.fileType);
                    }
                }

                const content = await zip.generateAsync({ type: 'blob' });
                saveAs(content, `${folderName}.zip`);
            } catch (error) {
                console.error('Error while downloading files:', error);
            }
        } else {
            alert("you don't any files to download.")
        }
    };

    const handlePrescriptionEdit = async (event: any) => {
        navigate('/addprescription', { state: { appointmentID: event?.id } });
    }
    const handleUploadprescription = async (row: any) => {
        navigate("/addprescription", { state: { appointmentId: row?.id, patientID: row?.patientID, doctorid: row?.DoctorID } });
    };
    const managePrescriptions = async (event: any) => {
        const getPrescription = await PrescriptionServices().prescriptionListBypatientID(event?.id);
        if (getPrescription?.length > 0) {
            handlePrescriptionEdit(event);
            // handleUploadprescription(event);
        } else {
            handleUploadprescription(event);
        }
    }
    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts.map((part, index) => (regex.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const getDoctorNameById = (doctorId: string) => {
        const doctor = doctorList?.find(doc => doc?.id === doctorId);
        return doctor ? `${doctor?.FirstName} ${doctor?.LastName}` : 'Unknown Doctor';
    };

    const filteredData: any = patientList?.filter((row: Patient) =>
        Object.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData?.length / pageSize);
    const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };
    const handleSubmit = (setUpdate: boolean) => {
        navigate('/appointment', { state: { setUpdate: false } });
    }
    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

    const handleEdit = (row: Patient) => {
        navigate("/appointment", { state: { patientId: row.id } });
    }

    const openKYCModal = async (patient: any) => {
        const singleReg = await userService().getSingleAppointment(patient?.id);
        setSingleRegister(singleReg);
        // setModalHeading(`${singleReg.data.getUser.FirstName} ${singleReg.data.getUser.LastName}`);
        setIsKYCModalOpen(true);
    };

    const KYCcloseModal = () => {
        setIsKYCModalOpen(false);
        setModalHeading("");
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % viewFiles?.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + viewFiles?.length) % viewFiles?.length);
    };
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };
    const renderFile = (file: any) => {
        const { name, description, fileType, patientName, AppointmentID } = file;
        const formattedDate = ReportsFilesmanagement().formatDate(file?.date);
        const fileType1: any = fileTypeList?.find((type: any) => type.id === fileType);
        // console.log("file", file);

        // console.log("name", name);

        if (name) {
            const fileExtension = file?.name?.split('.').pop().toLowerCase();

            return (
                <div>
                    {fileExtension === 'pdf' ? (
                        //  return (
                        <>
                            <div style={{ float: "left" }}>
                                <strong>Patient Name: </strong>{patientName}<br />
                                <strong>Appointment ID: </strong>{AppointmentID}
                            </div>
                            <div className='center'>
                                <strong>ReportType: </strong> {fileType1?.name}<br />
                                <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
                            </div>
                            <div className='clrbth' >
                                {/* <Document file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${file}`}>
                             <Page pageNumber={1} />
                   
                             </Document> */}
                                < Document
                                    file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`
                                    }
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    className="pdf-viewer"
                                >
                                    {
                                        Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <Page key={`page_${index + 1}`} pageNumber={index + 1
                                                } />
                                            )
                                        )}
                                </Document>
                                {/* <div>{viewFiles[currentIndex]}</div> */}
                            </div>
                            < div className='clrbth' >
                                <button className="nav-button reportbtn" onClick={handlePrev} >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                                < button className="nav-button reportbtn" onClick={handleNext} >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </div>
                        </>

                    ) : (
                        <>
                            <div style={{ float: "left" }}>
                                <strong>Patient Name: </strong>{patientName}<br />
                                <strong>Appointment ID: </strong>{AppointmentID}
                            </div>
                            <div className='center'>
                                <strong>ReportType: </strong> {fileType1?.name}<br />
                                <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
                            </div>
                            <div className='clrbth' >
                                <img
                                    key={file}
                                    src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`
                                    }
                                    alt={file}
                                    className='img-viewer'
                                />
                            </div>

                            < div className='clrbth' >

                                <button className="nav-button reportbtn" onClick={handlePrev} >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                                < button className="nav-button reportbtn" onClick={handleNext} >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>

                            </div>
                        </>
                    )}
                </div>
            )
        } else if (description) {
            return (
                <>
                    <div style={{ float: "left" }}>
                        <strong>Patient Name: </strong>{patientName}<br />
                        <strong>Appointment ID: </strong>{AppointmentID}
                    </div>
                    <div className='center'>
                        <strong>ReportType: </strong> {fileType1?.name}<br />
                        <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
                    </div>
                    <div className='clrbth mt-5 textDesc'>
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                    < div className='clrbth' >

                        <button className="nav-button reportbtn" onClick={handlePrev} >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        < button className="nav-button reportbtn" onClick={handleNext} >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </>
            );
        } else {
            return <div>No content available</div>;
        }
    };

    return (

        <div>
            <Helmet>
                <title>Appointment History</title>
                <meta name="description" content="This is the My Appointment History page description" />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Appointment History</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Appointment History</li>
                </ul>
            </div>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-2" style={{ marginTop: '50px' }}>
            <Sidebar />
          </div> */}
                    {
                        !slug && (
                            <div className="col-md-12">
                                <div className='tablefs'>
                                    <div className="row">
                                        {logUserObject?.Role === "MasterAdmin" ? (
                                            <div className="pb-3">
                                                <Button
                                                    onClick={() => handleSubmit(false)} // Pass 'false' as the argument
                                                    style={{ marginRight: '10px', float: 'right' }}
                                                    className='btn btn-primary'
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        ) : null}
                                           <div className='mbtable' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='page-entry'>
                                    <Select
                                        value={pageSize}
                                        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                    entries per page
                                </div>
                                <div className='table-pagination mlefttable' style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search..."
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <SearchIcon />,
                                        }}
                                    />
                                </div>
                            </div>
                                    </div>
                                    <div style={{ overflowX: 'auto' }}>
                                        <table className="table table-bordered table-hover table-striped table-responsive  listAppointmentshistory">
                                            <thead>
                                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                    <th className="sno">S.No</th>
                                                    <th className='mdl'>Patient Name</th>
                                                    {/* <th className='mdl'>Email Id</th> */}
                                                    <th>Appointment Date & Time</th>
                                                    <th className='mdl'>AppointmentID</th>
                                                    <th className='mdl'>Phone Number</th>
                                                    <th className='mdl'>Doctor Name</th>
                                                    <th className='mdl'>Payment Mode</th>
                                                    <th className='mdl'>Amount</th>
                                                    {logUserObject?.Role === "MasterAdmin" && (
                                                        <th className='paymentStatus'>Payment Status</th>
                                                    )}
                                                    <th className='actions'>Actions</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedData?.map((row: Patient, index: number) => (
                                                    <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                        <td>{highlightText(row?.PatientName)}</td>
                                                        {/* <td>{highlightText(row?.EmailId)}</td> */}
                                                        <td>{highlightText(formatDate(row?.AppointmentDate))} {highlightText(row?.AppointmentTime)}</td>
                                                        {/* <td>{highlightText(row?.AppointmentDate)}</td> */}
                                                        <td>
                                                            {highlightText(row?.AppointmentID)}<br />
                                                            {/* ({highlightText(
                                                                new Date(row?.AppointmentDate).toLocaleDateString('en-GB', {
                                                                    day: 'numeric',
                                                                    month: 'short',
                                                                    year: 'numeric',
                                                                })
                                                            )}) */}
                                                        </td>
                                                        <td>{highlightText(row?.PhoneNumber)}</td>
                                                        <td>{highlightText(getDoctorNameById(row?.DoctorID))}</td>
                                                        <td>{highlightText(row?.PaymentMode)}</td>
                                                        <td style={{ padding: '10px' }}>{highlightText(row.Amount)}</td>
                                                        {logUserObject?.Role === "MasterAdmin" && (
                                                            <td style={{ padding: '10px' }}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={row?.PaymentStatus === "1"}
                                                                            onChange={handleStatusToggle(row)}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={
                                                                        <span className={row?.PaymentStatus === "1" ? 'PaymentStatus-Paid' : 'PaymentStatus-NotPayed'}>
                                                                            {row?.PaymentStatus === "1" ? 'Paid' : 'NotPaid'}
                                                                        </span>
                                                                    }
                                                                /></td>
                                                        )}
                                                        <td className="actions">

                                                            {/* <Button title="view Patient Profile"
                                                                onClick={() => openKYCModal(row)}
                                                                style={{ marginRight: '10px' }}
                                                                startIcon={<VisibilityIcon style={{ color: 'green' }} />}
                                                            >
                                                            </Button> */}
                                                            {(!logUserObject || logUserObject?.Role !== "Lab" && logUserObject?.Role !== "Doctor" && logUserObject?.Role !== "Pharmacy") && (
                                                                <Button
                                                                    onClick={() => handleEdit(row)} title='Edit'
                                                                    startIcon={<EditIcon style={{ color: 'green' }} />}
                                                                >
                                                                </Button>
                                                            )}
                                                            {(logUserObject && logUserObject?.Role === "MasterAdmin") && (
                                                                <Button title='Delete'
                                                                    onClick={() => handleDelete(row?.id)}
                                                                    startIcon={logUserObject?.Role !== "Patient" && <DeleteIcon style={{ color: ' #af0e0e' }} />}
                                                                >
                                                                </Button>
                                                            )}

                                                            {/* {logUserObject?.Role !== "Patient" && ( */}
                                                            {/* { logUserObject?.Role !== "Pharmacy" ? */}
                                                            {
                                                                (!logUserObject || logUserObject?.Role !== "Pharmacy") && (
                                                                    <Dropdown className="dropright">
                                                                        <Dropdown.Toggle className='dropdowncolor dropdown-no-hover' id="dropdown-basic" title="Reports" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                                            <FolderOpenIcon style={{ color: '#2cbcbc', cursor: 'pointer', marginTop: '-9px' }} />
                                                                        </Dropdown.Toggle>

                                                                        {
                                                                            !logUserObject ? (

                                                                                <Dropdown.Menu className='dropdownbg newdropdown'>
                                                                                    <span className='spanfont'>
                                                                                        <span style={{ margin: '2px' }}>Please </span>

                                                                                        <Link to="/register" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                            <span style={{ color: 'black' }}>register</span>
                                                                                        </Link>

                                                                                        <span style={{ margin: '2px', color: 'black' }}>/ </span>
                                                                                        <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                            <span style={{ color: 'black' }}>login</span>
                                                                                        </Link>
                                                                                        <span style={{ margin: '2px' }}>to view reports</span>
                                                                                    </span>

                                                                                </Dropdown.Menu>

                                                                            ) :
                                                                                (

                                                                                    <Dropdown.Menu className='dropdownbg dropmw'>


                                                                                        {logUserObject && logUserObject?.Role !== "Patient" && (
                                                                                            <Dropdown.Item className='dropdownbg' as="button" title="Manage Reports" onClick={() => manageReports(row)}>
                                                                                                <ContentPasteSearch style={{ color: 'blue' }} />
                                                                                            </Dropdown.Item>
                                                                                        )}

                                                                                        <Dropdown.Item className='dropdownbg'
                                                                                            as="button"
                                                                                            title="View Report" // This will show the tooltip when you hover over the item
                                                                                            onClick={() => {
                                                                                                ReportsFilesmanagement().handleView(row, setCurrentIndex, setViewFiles, setIsModalOpen, "reports");
                                                                                            }}
                                                                                        >
                                                                                            <VisibilityIcon style={{ color: 'blue' }} />
                                                                                        </Dropdown.Item>

                                                                                        <Dropdown.Item as="button" className='dropdownbg' title="Download Report" onClick={() => handleReportDownload(row, "reports")}>
                                                                                            <DownloadIcon style={{ color: 'blue' }} />
                                                                                        </Dropdown.Item>


                                                                                    </Dropdown.Menu>

                                                                                )
                                                                        }



                                                                    </Dropdown>
                                                                )}


                                                            {(!logUserObject || logUserObject?.Role !== "Lab") ?
                                                                <Dropdown className="dropright">
                                                                    <Dropdown.Toggle
                                                                        title="Prescriptions"
                                                                        className="dropdowncolor dropdown-no-hover"
                                                                        id="dropdown-basic"
                                                                        style={{ backgroundColor: 'transparent', border: 'none' }}
                                                                    >
                                                                        <InsertDriveFileIcon
                                                                            style={{ color: '#2cbcbc', cursor: 'pointer', marginTop: '-6px' }}
                                                                        />
                                                                    </Dropdown.Toggle>


                                                                    {
                                                                        !logUserObject ? (
                                                                            <Dropdown.Menu className='dropdownbg newdropdown1'>
                                                                                {/* <span className='spanfont'>Please register / login to view prescription</span> */}

                                                                                <span className='spanfont'>
                                                                                    <span style={{ margin: '2px' }}>Please </span>

                                                                                    <Link to="/register" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                        <span style={{ color: 'black' }}>register</span>
                                                                                    </Link>

                                                                                    <span style={{ margin: '2px', color: 'black' }}>/ </span>
                                                                                    <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                        <span style={{ color: 'black' }}>login</span>
                                                                                    </Link>
                                                                                    <span style={{ margin: '2px' }}>to view prescription</span>
                                                                                </span>


                                                                            </Dropdown.Menu>
                                                                        ) : (
                                                                            <Dropdown.Menu className="dropdownbg dropmw">
                                                                                {logUserObject && logUserObject?.Role !== "Patient" && (
                                                                                    <Dropdown.Item
                                                                                        className="dropdownbg"
                                                                                        as="button"
                                                                                        title="Manage Prescriptions"
                                                                                        onClick={() => managePrescriptions(row)}
                                                                                    >
                                                                                        <DescriptionIcon style={{ color: 'blue' }} />
                                                                                    </Dropdown.Item>
                                                                                )}

                                                                                <Dropdown.Item
                                                                                    className="dropdownbg"
                                                                                    as="button"
                                                                                    title="View Prescription"
                                                                                    onClick={() => {
                                                                                        ReportsFilesmanagement().handleView(
                                                                                            row,
                                                                                            setCurrentIndex,
                                                                                            setViewFiles,
                                                                                            setIsModalOpen,
                                                                                            "prescriptions"
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <VisibilityIcon style={{ color: 'blue' }} />
                                                                                </Dropdown.Item>

                                                                                <Dropdown.Item
                                                                                    className="dropdownbg"
                                                                                    as="button"
                                                                                    title="Download Prescription"
                                                                                    onClick={() => handleReportDownload(row, "prescriptions")}
                                                                                >
                                                                                    <DownloadIcon style={{ color: 'blue' }} />
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        )
                                                                    }

                                                                </Dropdown>

                                                                : null}


                                                            {(!logUserObject || logUserObject?.Role === "Patient" || logUserObject?.Role === "MasterAdmin") && (
                                                                // <Button

                                                                //     variant="contained"
                                                                //     style={{ marginRight: '10px', marginTop: '10px', height: '32px', fontSize: '11px' }}
                                                                //     title='cancel Appointment'
                                                                //     onClick={() => handleAppoinmentDelete(row?.id, row?.PaymentMode)}
                                                                //  startIcon={<CancelIcon style={{ color: 'red' }} />
                                                                // >
                                                                //     cancel
                                                                // </Button>

                                                                <Button
                                                                    title='cancel Appointment'
                                                                    onClick={() => handleAppoinmentDelete(row?.id, row?.PaymentMode)}
                                                                    startIcon={<CancelIcon style={{ color: 'red' }} />}
                                                                >
                                                                </Button>
                                                            )}


                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={`modal ${isModalOpen ? "open" : ""}`}>
                                        <div className="modal-content2" style={{ marginTop: '42px', height: '550px', width: '1000px' }}>
                                            <span className="close" onClick={closeModal}>
                                                &times;
                                            </span>
                                            <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "download")}><FontAwesomeIcon style={{ color: 'white' }} icon={faDownload} /></button>
                                            <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "print")}><FontAwesomeIcon style={{ color: 'white' }} icon={faPrint} /></button>
                                            {/* <iframe
                                        ref={iframeRef}
                                        style={{ display: 'none' }}
                                        onLoad={() => {
                                            if (iframeRef.current) {
                                                iframeRef.current.contentWindow.print();
                                            }
                                        }}
                                    /> */}
                                            {/* <h6 className="text-center">Review By {modalHeading}</h6> */}
                                            <div style={{ textAlign: 'center' }}>
                                                {viewFiles?.length > 0 ?
                                                    renderFile(viewFiles[currentIndex])
                                                    : "No file to View"
                                                }

                                                <div className="thumbnail-container">
                                                    {viewFiles?.map((file: any, index: number) => {
                                                        const extension = file?.name?.split('.').pop().toLowerCase()

                                                        // console.log('extension', extension),

                                                        return (

                                                            <img
                                                                key={index}
                                                                className={`thumb-view thumbnail ${currentIndex === index ? 'active' : ''}`}
                                                                src={(extension === "pdf" || !file?.name) ? 'images/pdf-thumbnail.png' : getFileUrl(file?.name)}
                                                                alt={`Thumbnail ${index + 1}`}
                                                                onClick={() => setCurrentIndex(index)}
                                                            />
                                                        )
                                                    })}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='Mmtpagination' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                        <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

                                        </div>
                                        <div className="paginationstyle">
                                            <div
                                                style={{
                                                    cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                                    marginRight: '10px',
                                                    borderBottom: '1px solid red',
                                                    fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                                }}
                                                onClick={() => {
                                                    if (currentPage > 1) {
                                                        handlePageChange(currentPage - 1);
                                                        window.scrollTo(0, 0);
                                                    }
                                                }}
                                                className={currentPage === 1 ? 'disabled' : ''}
                                            >
                                                Previous
                                            </div>
                                            <div className="pageNumb">{currentPage}</div>
                                            <div
                                                style={{
                                                    cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                                    marginLeft: '10px',
                                                    borderBottom: '1px solid red',
                                                    fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                                }}
                                                onClick={() => {
                                                    if (currentPage < totalPages) {
                                                        handlePageChange(currentPage + 1);
                                                        window.scrollTo(0, 0);
                                                    }
                                                }}
                                                className={currentPage === totalPages ? 'disabled' : ''}
                                            >
                                                Next
                                            </div>
                                        </div>

                                    </div>

                                    <div className={`modal ${kYCModalOpen ? "open" : ""}`}>
                                        <div className="modal-content1 modalmediaquerie" style={{ height: 'auto' }}>
                                            <span className="close" onClick={KYCcloseModal}>
                                                &times;
                                            </span>
                                            <h3 className="text-center">KYC</h3>
                                            {singleRegister && (
                                                <div className='modal-content-body' style={{ padding: '40px' }}>
                                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                        <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                            <strong>Name:</strong>
                                                        </div>
                                                        <div>
                                                            <span>{highlightText(singleRegister?.data?.getPatientAppointment?.PatientName)}</span>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                        <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                            <strong>Email ID:</strong>
                                                        </div>
                                                        <div>
                                                            <span>{highlightText(singleRegister?.data?.getPatientAppointment?.EmailId)}</span>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                        <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                            <strong>KYC:</strong>
                                                        </div>
                                                        <div>
                                                            {singleRegister?.data?.getPatientAppointment?.KYC && singleRegister?.data?.getPatientAppointment?.KYC.map((kyc: any, index: any) => (
                                                                <div key={index}>
                                                                    <p><span className="kyc-key">Alcohol Consumption:</span> {kyc?.AlcoholConsumption === '1' ? 'Yes' : 'No'} {highlightText(kyc?.AlcoholConsumptionDescription)}</p>
                                                                    <p><span className="kyc-key">Chronic Illnesses:</span> {kyc?.ChronicIllnesses === '1' ? 'Yes' : 'No'} {highlightText(kyc?.ChronicIllnessesDescription)}</p>
                                                                    <p><span className="kyc-key">Current Medications:</span> {kyc?.CurrentMedications === '1' ? 'Yes' : 'No'} {highlightText(kyc?.CurrentMedicationsDescription)}</p>
                                                                    <p><span className="kyc-key">Marital Status:</span> {kyc?.MaritalStatus === '1' ? 'Yes' : 'No'} {highlightText(kyc?.MaritalStatusDescription)}</p>
                                                                    <p><span className="kyc-key">Smoking Status:</span> {kyc?.SmokingStatus === '1' ? 'Yes' : 'No'} {highlightText(kyc?.SmokingStatusDescription)}</p>
                                                                    <p><span className="kyc-key">Food Allergies:</span> {kyc?.foodAllergies === '1' ? 'Yes' : 'No'}  {highlightText(kyc?.foodAllergiesDescription)}</p>
                                                                    <p><span className="kyc-key">Past Health Conditions:</span> {kyc?.pastHealthConditions === '1' ? 'Yes' : 'No'} - {highlightText(kyc?.pastHealthConditionsDescription)}</p>
                                                                    <p><span className="kyc-key">Heard From:</span> {kyc?.heardFrom}</p>
                                                                </div>

                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )}
                </div>
            </div>

        </div>
    );
};

export default AppoinementListHistory;
