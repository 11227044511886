import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { TextField, Button, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Sidebar from '../Dashboard/sidebar';
import PharmacyServices from '../../../services/Labs';
import LabServices from '../../../services/Labs';
import { Helmet } from "react-helmet";
import { updDelFile } from '../shared/fileupload';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
  title: string;
  description: string;
}
interface Labs {
  PhoneNumber: string;
  EmailId: string;
  //   Specialization: string;
  id: string;
  FirstName: string;
  LastName: string;
  //   TimeSlot: string;
  Status: string;
  //   ShowInTeam: string;
}

const ListLabs: React.FC<compProps> = ({ title, description }) => {

  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [labList, setLabList] = useState<Labs[]>([]);
  const [specializationtList, setspecializationtList] = useState<Labs[]>([]);
  // const logUser: any = localStorage?.getItem('userData');
  // const logUserObject = JSON?.parse(logUser);
  // const logUserObject:any = useAuthRedirect();

  // useEffect(() => {
  //   if (!logUserObject?.accessToken) {
  //     navigate('/ListPatient');
  //   }
  // }, [navigate]);

  const getDoctors = async () => {
    const response = await LabServices().ListLab('Lab') as any;
    setLabList(response);
    // console.log("response", response)
  };

  useEffect(() => {
    getDoctors();
    // getspec();
  }, []);
  //   const getDoctorNameById = (doctorId: string) => {
  //     const lab = specializationtList.find(doc => doc?.id === doctorId);
  //     return lab ? 
  //   };
  const handleDelete = async (id: string) => {
    const confirmBox = window.confirm("Are you sure you want to delete the selected lab?");
    if (confirmBox) {
      const user: any = await LabServices().getuserSingle(id);
      const filedel = await updDelFile(user?.data?.getUser?.userProfile, "images");
      if (filedel?.status === 200) {
        await LabServices().deleteLab(id);
        getDoctors();
      }
    } else {
      alert('Deletion canceled.');
    }
  };

  const highlightText = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) => (regex.test(part) ? <span key={index} className="highlighted-text">{part}</span> : part));
  };

  const filteredData = labList.filter((lab: Labs) =>
    Object.values(lab)?.some((value: any) => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
  );

  const totalPages = Math?.ceil(filteredData?.length / pageSize);
  const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleSearch = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    setSearchTerm('');
  }, [currentPage]);

  const handleEdit = (event: any) => {
    navigate('/addLab', { state: { id: event?.id } });
  }

  const handleSubmit = (setUpdate: boolean) => {
    navigate('/addLab');
  }

  const handleStatusToggle = async (rowData: any) => {
    const updatedStatus = rowData?.Status === "1" ? "0" : "1";
    const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
    console.log("updatedRowData", updatedRowData);
    const update = await LabServices().updateLabByData(updatedRowData);

    setLabList((prevRows) =>
      prevRows?.map((lab) =>
        lab?.id === rowData?.id ? { ...lab, Status: updatedStatus } : lab
      )
    );
  };


  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <section className="admin-section text-center"
      >
        <div className="container">
          <div className="bannerTitle">
            <h1>Labs</h1>
          </div>
        </div>
      </section>
      <div>
        <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
          <li>
            <a className="aColor" href="/">Home  /&nbsp;</a>
          </li>
          <li>Labs</li>
        </ul>
      </div>
      <div className="container">
        <div className='row'>
          {/* <div className="col-md-2">
          <Sidebar />
        </div> */}
          <div className="col-md-12">
            <div className='tablefs'>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <div className='page-entry'>
                  <Select
                    value={pageSize}
                    onChange={(e) => handlePageSizeChange(Number(e?.target?.value))}
                    style={{ marginRight: '10px' }}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                  entries per page
                </div>
                <div className='table-pagination' style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => handleSubmit(false)}
                    style={{ marginRight: '10px', backgroundColor: '#98B868', color: 'white', borderStyle: 'none', outline: 'none' }}
                    className='btn btn-primary'
                  >
                    Add
                  </Button>
                  <TextField
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: <SearchIcon />,
                    }}
                  />
                </div>
              </div>

              <div style={{ overflowX: 'auto' }}>
                <table className="table table-bordered table-striped listLabs">
                  <thead>
                    <tr>
                      <th className="sno">S.No</th>
                      <th className="mdl">Labs Name</th>
                      <th className="mdl">Phone Number</th>
                      <th className="mdl">Email Id</th>
                      <th className="actions">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((lab: Labs, index: number) => (
                      <tr key={lab.id} className="highlighted-row">
                        <td>{index + 1}</td>
                        <td>{highlightText(`${lab?.FirstName} ${lab?.LastName}`)}</td>
                        <td>{highlightText(lab?.PhoneNumber)}</td>
                        <td>{highlightText(lab?.EmailId)}</td>
                        <td className='actions'>
                          <Button title="Edit"
                            onClick={() => handleEdit(lab)}
                            style={{ marginRight: '10px' }}
                            startIcon={<EditIcon style={{ color: 'green' }} />}
                          >

                          </Button>
                          <Button title="Delete"
                            onClick={() => handleDelete(lab.id)}
                            startIcon={<DeleteIcon style={{ color: ' #af0e0e' }} />}
                          >

                          </Button>
                          <Button title="Status"
                            onClick={() => handleStatusToggle(lab)}
                            className={lab?.Status === '1' ? 'statusSuccess' : 'statusDanger'}
                            startIcon={<VisibilityIcon />}
                          >
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='Mmtpagination' >
                <div className="paginationstyle">
                  <div
                    style={{
                      cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                      marginRight: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage > 1 ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage > 1) {
                        handlePageChange(currentPage - 1);
                        window.scrollTo(0, 0); // Scroll to top
                      }
                    }}
                    className={currentPage === 1 ? 'disabled' : ''}
                  >
                    Previous
                  </div>
                  <div className="pageNumb">{currentPage}</div>
                  <div
                    style={{
                      cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                      marginLeft: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage < totalPages) {
                        handlePageChange(currentPage + 1);
                        window.scrollTo(0, 0);
                      }
                    }}
                    className={currentPage === totalPages ? 'disabled' : ''}
                  >
                    Next
                  </div>
                </div>
                <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListLabs;


