
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import ReviewServices from '../../../services/Reviews/review';
import userService from './../../../services/users/user.service'
import ContactServices from './../../../services/contact/contact'
import './mainContent.css';

interface MainContentProps {
    user: any;
}

export default function MainContent({ user }: MainContentProps) {
    const navigate = useNavigate();
    const logUser: any = localStorage?.getItem('userData');
    const logUserObject: any = JSON.parse(logUser);
    const [listReviews, setListReviews] = useState('');
    const [listDoctors, setListDoctors] = useState('');
    const [listPatients, setListPatients] = useState('');
    const [listAppointments, setListAppointments] = useState('');
    const [enquiryList, setEnquiryList] = useState('');
    const [doctorlistAppointments, setDoctorlistAppointments] = useState('');
    const [patientlistAppointments, setPatientlistAppointments] = useState('');

    async function getReviews() {
        const listReviews: any = await ReviewServices().reviewList();
        // console.log("listReviews", listReviews);
        return listReviews;
    }

    useEffect(() => {
        async function fetchReviews() {
            try {
                const reviews = await getReviews();
                setListReviews(reviews.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchReviews();
    }, []);


    async function getDoctors() {
        const response = await userService().ListDoctor('Doctor');
        console.log("response", response);
        return response;
    }

    useEffect(() => {
        async function fetchDoctors() {
            try {
                const doctors = await getDoctors();

                console.log("doctors", doctors)
                setListDoctors(doctors.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);

    async function getPatients() {
        const response = await userService().ListDoctor('Patient');
        console.log("response", response);
        return response;
    }

    useEffect(() => {
        async function fetchDoctors() {
            try {
                const Patients = await getPatients();

                console.log("doctors", Patients)
                setListPatients(Patients.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);


    async function getAppointments() {
        const response = await userService().ListAllPatientAppointments();
        console.log("response", response);
        return response;
    }

    useEffect(() => {
        async function fetchDoctors() {
            try {
                const Appointments = await getAppointments();

                console.log("doctors", Appointments)
                setListAppointments(Appointments.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);

    async function getEnquiryList() {
        const response = await ContactServices().contactList();
        console.log("response", response);
        return response;
    }

    useEffect(() => {
        async function fetchDoctors() {
            try {
                const EnquiryList = await getEnquiryList();

                console.log("doctors", EnquiryList)
                setEnquiryList(EnquiryList.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);

    async function getDoctorAppointmnets() {
        const response = await userService().ListPatientAppointments(logUserObject?.userId);
        console.log("response", response);
        return response;
    }

    useEffect(() => {
        async function fetchDoctors() {
            try {
                const Appointmnets = await getDoctorAppointmnets();

                console.log("doctors", Appointmnets)
                setDoctorlistAppointments(Appointmnets.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);


    async function getPatientAppointmnets() {
        const response = await userService().ListPatientAppointmentsById(logUser?.userId, '');
        const patients = response?.filter((user: any) => user?.patientID === logUserObject?.userId)
        console.log("response", patients);
        return patients;
    }

    useEffect(() => {
        async function fetchDoctors() {
            try {
                const Appointmnets = await getPatientAppointmnets();

                console.log("doctors", Appointmnets)
                setPatientlistAppointments(Appointmnets.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);






    return (
        <div className="container mediapl-0">
            <div className="row">
                <h1 className='fontsm' style={styles.h1 as CSSProperties}>Welcome, {logUserObject?.userName}</h1>

                <div style={styles.mainContent as CSSProperties}>

                    <div className="col-md-12 admin-log" style={{ width: '100%', display: 'flex' }}>

                        
                        {logUserObject?.Role === 'MasterAdmin' ? (
                            <div className="dashboard-menu" style={styles.stats as CSSProperties}>
                                     {/* <div className="col-md-3">
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/enquirylist')}>
                                        <i className="fa fa-user" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Profile <br />
                                             
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-md-3">
                               

                                    <div className="statres" style={styles.statItem as CSSProperties} onClick={() => navigate('/allRegisterpatients')}>
                                        <i className="fa fa-users" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Patients <br />
                                                <span>{listPatients}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="statres" style={styles.statItem as CSSProperties} onClick={() => navigate('/listpatients')}>
                                        <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Appointments <br />
                                                <span>{listAppointments}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="statres" style={styles.statItem as CSSProperties} onClick={() => navigate('/listdoctors')}>
                                        <i className="fa fa-stethoscope" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Doctors <br />
                                                <span>{listDoctors}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                             

                                
                            </div>

                        ) : null
                        }
                        {logUserObject?.Role === 'Doctor' ? (
                            <div style={styles.stats as CSSProperties}>

                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/UpdateProfile')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Profile <br />
                                            {/* <span>{listAppointments}</span> */}
                                        </p>
                                    </div>
                                </div>
                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/listpatients')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Appointments <br />
                                            <span>{doctorlistAppointments}</span>
                                        </p>
                                    </div>
                                </div>
                              
                            </div>
                        ) : null
                        }
                        {logUserObject?.Role === 'Patient' ? (
                            <div style={styles.stats as CSSProperties}>
                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/UpdateProfile')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Profile <br />
                                            {/* <span>{listAppointments}</span> */}
                                        </p>
                                    </div>
                                </div>

                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/listpatients')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Appointments <br />
                                            <span>{patientlistAppointments}</span>
                                        </p>
                                    </div>
                                </div>
                              
                            </div>
                        ) : null
                        }
                        {logUserObject?.Role === 'Pharmacy' ? (
                            <div style={styles.stats as CSSProperties}>

                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/UpdateProfile')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Profile <br />
                                            {/* <span>{listAppointments}</span> */}
                                        </p>
                                    </div>
                                </div>
                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/listpatients')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Appointments <br />
                                            <span>{listAppointments}</span>
                                        </p>
                                    </div>
                                </div>

                                {/* <div style={styles.statItem as CSSProperties} onClick={() => navigate('/listpatients')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Prescription <br />
                                            <span>{listAppointments}</span>
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        ) : null
                        }
                        {logUserObject?.Role === 'Lab' ? (
                            <div style={styles.stats as CSSProperties}>
                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/UpdateProfile')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Profile <br />
                                            {/* <span>{listAppointments}</span> */}
                                        </p>
                                    </div>
                                </div>
                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/listpatients')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Appointments <br />
                                            <span>{listAppointments}</span>
                                        </p>
                                    </div>
                                </div>
                                {/* <div style={styles.statItem as CSSProperties} onClick={() => navigate('/listpatients')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'white', fontSize: '20px', paddingRight: '50px' }}>
                                            Reports <br />
                                            <span>{listAppointments}</span>
                                        </p>
                                    </div>
                                </div> */}


                            </div>
                        ) : null
                        }

                    </div>

                </div>

            </div>
        </div>

    );
}

const styles = {
    mainContent: {
        flex: 1,
        padding: '20px',
    } as CSSProperties,
    h1: {
        fontSize: '36px',
        marginBottom: '50px',
        float: 'right' as CSSProperties['float'],
        // marginTop: '20px',
        textAlign: 'right',
    },
    stats: {
        display: 'flex',
        // justifyContent: 'space-around',
        flexWrap: 'wrap',
        marginTop: '20px',
        marginLeft: '30px',
        gap:'20px'
    } as CSSProperties,
    statItem: {
        background: '#48bdc5',
        color: 'white',
        padding: '20px 20px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        cursor: 'pointer',
        marginRight: '200px',
        flex: '1 0 21%', // Adjust this value as needed
        width: '264px', // Adjust this value as needed
        maxHeight:'100px',
        
    } as CSSProperties,
    icon: {
        fontSize: '30px',
        marginTop: '-28px'
    } as CSSProperties,
    texttitle: {
        color: "white",
    } as CSSProperties,
    p: {
        color: "none !important",
        fontSize: '25px',

    } as CSSProperties,
};

