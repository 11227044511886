
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './style.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { validationSchema } from './validationSchema';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from '../shared/fileupload';
import { useLocation, useNavigate } from 'react-router-dom';
import getblogs from '../../../services/Blogs';
import CareerComponent from './createCategory';
import getCareer from '../../../services/Careers';
import CKEditorComponent from '../../../utils/CKEditor';
import { Helmet } from "react-helmet";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatFile } from '../../../utils/Fileformat';

interface compProps {
    title: string;
    description: string;
}

const CreateCarrer: React.FC<compProps> = ({ title, description }) => {
    const location = useLocation();

    const initialValues = {
        category: '',
        title: '',
        description: '',
        careerImage: [],
        Slug: '',

    };

    const navigate = useNavigate();

    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [InputDetails, setInputDetails] = useState(initialValues);
    const [updateCarrer, setUpdateCarrer] = useState<boolean>(false);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const [ErrMsgSlug, setErrMsgSlug] = useState("");



    const fieldRefs: any = useRef({
        category: null as HTMLDivElement | null,
        title: null as HTMLDivElement | null,
        Slug: null as HTMLDivElement | null,
        description: null as HTMLDivElement | null            
    });
    async function provInfo() {
        const service = await getCareer().getSingleCareer(location?.state?.id);
        setInputDetails(service);
        setUpdateCarrer(true);
    }

    useEffect(() => {
        provInfo();
    }, [location?.state]);

    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.careerImage[0], setInputDetails, 'careerImage', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };

    const handleSubmit = async (values: any, { resetForm }: any) => {
        try {
            const generateSlug = (name: any) => name.replace(/\s+/g, '_').toLowerCase();
            const slug = generateSlug(values?.Slug)
            let slugError = false;
            if (values?.id) {
                const SlugDetails = await getCareer().validateSlug(slug);
                if (SlugDetails?.msg) {
                    SlugDetails?.data?.map((item: any) => {
                        if (item?.id !== values?.id) {
                            slugError = true;
                        }
                    });
                    if (slugError) {
                        setErrMsgSlug("Slug is already in use");
                        return;
                    } else {
                        setErrMsgSlug("");
                    }
                }
            } else {
                const SlugDetails = await getCareer().validateSlug(slug);
                if (SlugDetails?.msg) {
                    setErrMsgSlug("Slug is already in use");
                    return;
                }
            }

            // let newFilename: any;
            // if (file?.name) {
                // fileName = Date.now() + "_" + file?.name;
                // const updatedFileName = file?.name?.replace(/ /g, '_');
                // fileName = Date.now() + "_" + updatedFileName;
                // const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
                // newFilename = replaceSpecialChars(filename);
                const  newFilename = await FormatFile(file)
                if (values?.id) {
                    file?.name &&  await updDelFile(values?.careerImage, "images");
                }
                file?.name &&  await SendFile(file, newFilename, "images");
            // }

            const CareerData = {
                id: values?.id,
                title: values?.title,
                category: values?.category,
                description: values?.description,
                careerImage: file?.name ? newFilename : values?.careerImage,
                Slug: slug,
                status: 1,
                userID: logUserObject?.userId,
            };


            const CarrerResponse = values?.id
                ? await getCareer().CareerUpdate(CareerData)
                : await getCareer().CreateCareer(CareerData);

            if (CarrerResponse) {
                navigate('/listCareer');
            }

        } catch (error) {
            console.error('Error submitting Career:', error);
            throw new Error('Failed to submit Career');
        }
    };
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Create Career</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Create Career</li>
                </ul>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <Formik
                            initialValues={location?.state?.id ? InputDetails : initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({ isSubmitting, setFieldValue, values,errors }) => {

                                const errorCount = Object.keys(errors).length;
                                console.log({errorCount})
                                
                                if (isSubmitting && errorCount > 0) {

                                    for (const key in errors) {
                                        console.log({key})
                                        if (fieldRefs.current[key]) {
                                            fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                            break; // Stop at the first error we find
                                        }
                                    }
                                }

                                return(
                                <Form className="blog-form">
                                    <h3 className="section-title">{!location?.state?.id ? "Create Career" : "Update Career"}</h3>

                                    <div className="input-container"  ref={(el) => (fieldRefs.current.category = el)}>
                                        <CareerComponent
                                            InputDetails={InputDetails}
                                            setInputDetails={setInputDetails}
                                            setFieldValue={setFieldValue}
                                            editValue={values.category}

                                        />
                                        <ErrorMessage name="category" component="div" className="error-message" />
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-container" ref={(el) => (fieldRefs.current.title = el)}>
                                            <label htmlFor="title">Career Title:</label>
                                            <Field
                                                type="text"
                                                id="title"
                                                name="title"
                                                value={values.title}
                                                onChange={(e: any) => setFieldValue("title", e.target.value)}
                                            />
                                            <ErrorMessage name="title" component="div" className="error-message" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-container" ref={(el) => (fieldRefs.current.Slug = el)}>
                                            <label htmlFor="Slug">Slug Name:</label>
                                            <Field
                                                type="text"
                                                id="Slug"
                                                name="Slug"
                                                value={values?.Slug}
                                                onChange={(e: any) => setFieldValue("Slug", e?.target?.value)}
                                                disabled={location?.state?.id ? true : false}
                                            />
                                            <p style={{ color: 'red' }}>{ErrMsgSlug ? ErrMsgSlug : ''}</p>
                                            <ErrorMessage name="Slug" component="div" className="error-message" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-container" ref={(el) => (fieldRefs.current.description = el)}>
                                            <label htmlFor="description">Career Description:</label>
                                            <Field name="description">
                                                {({ field, form }: any) => (
                                                    <CKEditorComponent
                                                        data={field.value}
                                                        onDataChange={(newContent: string) => setFieldValue('description', newContent)}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="description" component="div" className="error-message" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-container">
                                            {InputDetails?.careerImage?.length > 0 && updateCarrer ? (
                                                <>
                                                    <img
                                                        src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.careerImage}`}
                                                        alt="blogImage"
                                                        style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }}
                                                    />
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                        <p style={{ margin: 0, lineHeight: '24px' }}>
                                                            <strong>FileName:</strong>{InputDetails?.careerImage}
                                                        </p>
                                                        <span
                                                            style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                                            onClick={handleRemove}
                                                        >
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </span>
                                                    </div>
                                                </>
                                            ) : (
                                                // <strong>No Profile Photo</strong>
                                                null
                                            )}
                                            <FileUpload
                                                label={location?.state?.id ? "Update image" : "Choose image"}
                                                setDirectory={setDirectory}
                                                setFile={setFile}
                                            />
                                            <ErrorMessage name="blogImage" component="div" className="error-message" />
                                        </div>
                                    </div>

                                    <button type="submit" disabled={isSubmitting}>
                                        {!location?.state?.id ? "Create Career" : "Update Career"}
                                    </button>
                                </Form>
                                )
}}
                        </Formik>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        </>
    );
};

export default CreateCarrer;





