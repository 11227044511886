
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Homeservices from '../../../services/homeServices';
import { Helmet } from "react-helmet";
import './listServices.css';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
    title: string;
    description: string;
}

interface ServiceCategory {
    id?: any;
    Title: string;
    Description: string;
    Status: any;
}

const ListServiceCategory: React.FC<compProps> = ({ title, description }) => {
    const initialState: ServiceCategory = {
        Title: '',
        Description: '',
        Status: ''
    };

    const navigate = useNavigate();
    const [data, setData] = useState<ServiceCategory>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [ServiceCategorylist, setServiceCategorylist] = useState<any[]>([]);
    const [isEditing, setIsEditing] = useState<string | null>(null); // Track which row is being edited
    const [editValue, setEditValue] = useState<string>(''); // Track the edited value
    // const logUser: any = localStorage.getItem('userData');
    // const logUserObject = JSON.parse(logUser);
   // const logUserObject:any = useAuthRedirect();


    
    const listServiceCategory = async () => {
        const response = await Homeservices().serviceCategoryList();
        setServiceCategorylist(response);
    };

    useEffect(() => {
        listServiceCategory();
    }, []);

    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected ServiceCategory?");
        if (confirmBox) {
            await Homeservices().ServiceCategoryDelete(id);
            listServiceCategory();
        } else {
            alert('Deletion cancelled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const filteredData: any = ServiceCategorylist?.filter((row: ServiceCategory) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleEdit = (row: any) => {
        setIsEditing(row.id);
        setEditValue(row.name); // Initialize with the current name value
    }

    const handleSaveEdit = async (row: any) => {
        // Call your API to update the row
        const updatedRow = { ...row, name: editValue };
        await Homeservices().ServiceCategoryUpdate(updatedRow);
        listServiceCategory(); // Refresh the list
        setIsEditing(null); // Exit edit mode
    };

    const handleCancelEdit = () => {
        setIsEditing(null); // Exit edit mode without saving
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <section className="admin-section text-center">
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Service Category List</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Service Category List</li>
                </ul>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className='tablefs'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <div className='page-entry'>
                                    <Select
                                        value={pageSize}
                                        onChange={(e) => handlePageSizeChange(Number(e?.target?.value))}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                    entries per page
                                </div>
                                <div className='table-pagination mlefttable' style={{ display: 'flex', alignItems: 'center' }}>
                                
                                    <TextField
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search..."
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <SearchIcon />,
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-bordered table-striped table-hover table-responsive listServices">
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                            <th className="sno">S.No</th>
                                            <th className='mdl'>Name</th>
                                            <th className="actions">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((row: any, index: number) => (
                                            <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>
                                                    {isEditing === row.id ? (
                                                        <TextField
                                                            value={editValue}
                                                            onChange={(e) => setEditValue(e.target.value)}
                                                            size="small"
                                                        />
                                                    ) : (
                                                        highlightText(row?.name)
                                                    )}
                                                </td>
                                                <td className='actions'>
                                                    {isEditing === row.id ? (
                                                        <>
                                                            <Button
                                                                title="Save"
                                                                onClick={() => handleSaveEdit(row)}
                                                                style={{ marginRight: '10px', color: 'green' }}
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                title="Cancel"
                                                                onClick={handleCancelEdit}
                                                                style={{ color: 'red' }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                title="Edit"
                                                                onClick={() => handleEdit(row)}
                                                                style={{ marginRight: '10px' }}
                                                                startIcon={<EditIcon style={{ color: 'green' }} />}
                                                            />
                                                            <Button
                                                                title="Delete"
                                                                onClick={() => handleDelete(row?.id)}
                                                                style={{ marginRight: '10px' }}
                                                                startIcon={<DeleteIcon style={{ color: ' #af0e0e'  }} />}
                                                            />
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='Mmtpagination' >
                                <div className="paginationstyle">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                handlePageChange(currentPage - 1);
                                                window.scrollTo(0, 0); // Scroll to top
                                            }
                                        }}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage < totalPages) {
                                                handlePageChange(currentPage + 1);
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>
                                <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries</div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListServiceCategory;

