
import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Button, TextField, Modal, CircularProgress } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import forgotPasswordServices from '../../services/ForgotPasswordServices';
import { ValidationSchema } from './validationScheme';
import pages from '../../utils/pages';

interface UserDetails {
    userName: string;
    PhoneNumber: string;
}

const initialState: UserDetails = {
    userName: '',
    PhoneNumber: '',
};

function ForgotPassword() {
    const navigate = useNavigate();
    const [errSubmitMsg, setSubmitMsg] = useState("");
    const [SuccessMsg, setSuccessMsg] = useState("");
    const [Loader, setLoader] = useState(false);
    const [getModal, setModal] = useState(false);
    const [Response, setResponse] = useState<any>();
    const [CurrentOTP, setCurrentOTP] = useState("");
    const [OTPErr, setOTPErr] = useState("");
    const [timer, setTimer] = useState<number>(60);
    const [userName, setUserName] = useState("");
    const [pageInfo, setPageInfo] = useState<any>()

    const { slug } = useParams();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            // Simulate fetching data
            const result = await pages(location?.pathname)
            console.log("result", result)

            result?.map((item: any) => {
                setPageInfo(item?.Banner);
            })

        };

        fetchData();
    }, [])

    let intervalId: NodeJS.Timeout | null = null;

    const setTimerDetails = () => {
        if (intervalId === null) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(intervalId as NodeJS.Timeout);
                        intervalId = null;
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
    };
    // const setTimerDetails = () => {
    //     let intervalId: any;
    //     if (timer > 0) {
    //         intervalId = setInterval(() => {
    //             setTimer((prevTimer: any) => prevTimer - 1);
    //         }, 1000);
    //     }
    // };

    // console.log("userName",userName)

    const handleSubmit = async (values: UserDetails) => {
        // console.log("values", values);
        setLoader(true);
        setUserName(values?.userName);
        const userResponse: any = await forgotPasswordServices().SendEmail(values?.userName);
        console.log("userResponse", userResponse);
        // debugger
        if (userResponse) {
            setLoader(false);
            if (userResponse?.type === "email" && userResponse?.status === 200) {
                setSuccessMsg(userResponse?.msg);
                setSubmitMsg("");
                setModal(true);
                setTimerDetails();
                setUserName(userResponse?.userDetails?.EmailId);
            } else if (userResponse?.type === "mobile" && userResponse?.status === 200) {
                setResponse(userResponse);
                setSubmitMsg("");
                setModal(true);
                setTimerDetails();
                setUserName(userResponse?.userDetails?.PhoneNumber);
            } else if (userResponse?.status === 404) {
                setSubmitMsg("User is not registered with us, please provide proper email/phone details");
            } else if (userResponse === 'undefined') {
                setSubmitMsg("User is not registered with us, please provide proper email/phone details");
            } else {
                setSubmitMsg(userResponse?.msg);
            }
        } else {
            setSubmitMsg("User is not registered with us, please provide proper email/phone details");
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    const SubmitOTP = async () => {
        console.log("SubmitOTP", userName);
        // debugger
        console.log("CurrentOTP", CurrentOTP);
        const currentTime = new Date();
        const formattedTime = currentTime.toISOString();
        const getOTP: any = await forgotPasswordServices().validateotp(userName);
        // console.log("getOTP", getOTP);

        if (getOTP && getOTP?.data?.listUsers.items[0]?.Otp === CurrentOTP) {
            const email = getOTP?.data?.listUsers?.items[0]?.EmailId;
            const phoneNumber = getOTP?.data?.listUsers?.items[0]?.PhoneNumber;

            navigate({
                pathname: '/updatepassword',
                search: `?email=${Response?.userDetails?.EmailId}&dt=${formattedTime}`
            }, {
                state: { email: email, phoneNumber: phoneNumber }
            });
        } else {
            setOTPErr("Invalid OTP");
        }
    };


    const ResendEmail = async () => {
        try {
            setCurrentOTP("");
            const userResponse: any = await forgotPasswordServices().SendEmail(userName);
            console.log("userResponse", userResponse);
            // console.log("userResponse", userResponse);
            setTimer(60);
            setTimerDetails();
        } catch (error) {
            console.error("Error resending OTP:", error);
        }
    };
    const closeModal = () => {
        setModal(false);
    };

    return (
        <>
            <section
                className="page-title text-center"
                // style={{ backgroundImage:"url(pageInfo? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})"}}
                style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/img-1.jpg' }}
            >
                <div className="container">
                    <div className="title-text">
                        {/* <ul className="title-menu clearfix" style={{ textAlign: "left" }}>
              <li>
                <a href="/">Home &nbsp;/</a>
              </li>
              <li>About Us</li>
            </ul> */}
                        <h1>Forgot Password</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
                    <li >
                        <a href="/" className="aColor" >Home  /&nbsp;</a>
                    </li>
                    <li> Forgot Password</li>
                </ul>
            </div>
            <Container>

                <Formik
                    initialValues={initialState}
                    validationSchema={ValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, resetForm }) => (
                        <FormikForm style={{ textAlign: 'center' }}>
                            {Loader &&
                                <Modal open={Loader}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                        <CircularProgress />
                                    </Box>
                                </Modal>
                            }

                            {getModal &&
                                <Modal open={getModal}>

                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                        <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 3 }}>
                                            <span className="close" onClick={closeModal}>
                                                &times;
                                            </span>
                                            <Typography variant="h4" color="primary" gutterBottom>OTP</Typography>
                                            {timer > 0 ?
                                                <>
                                                    <Box sx={{ textAlign: 'left', mb: 2 }}>
                                                        <Typography variant="body1" gutterBottom>Enter OTP</Typography>
                                                        <Typography variant="body2">Timer: {timer} seconds</Typography>
                                                        <TextField
                                                            type="text"
                                                            name="otp"
                                                            placeholder="Enter OTP"
                                                            onChange={(e: any) => setCurrentOTP(e?.target?.value)}
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                        <Typography color="error">{OTPErr}</Typography>
                                                    </Box>
                                                    <Button variant="contained" color="primary" onClick={SubmitOTP}>
                                                        Check
                                                    </Button>
                                                </>
                                                :
                                                <Typography>
                                                    <span style={{ color: 'red' }}>The OTP has expired. Please click the link to resend the OTP.</span>
                                                    <br />
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e: any) => { ResendEmail(); setTimer(60) }}
                                                        sx={{ mt: 2 }}
                                                    >
                                                        Click here to resend
                                                    </Button>
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Modal>
                            }

                            <Box sx={{ mt: 5, mb: 10 }}>
                                <Box sx={{ mx: 'auto', width: '30%', bgcolor: 'background.paper', p: 3, borderRadius: 1, boxShadow: 3 }}>
                                    <Typography variant="h4" color="primary" gutterBottom>Forgot Password</Typography>
                                    <Typography color="error">{errSubmitMsg}</Typography>
                                    <Typography color="success">{SuccessMsg}</Typography>

                                    <Box sx={{ textAlign: 'left', mb: 2 }}>
                                        <Typography>Email ID/Mobile Number</Typography>
                                        <Field as={TextField}
                                            type="text"
                                            name="userName"
                                            placeholder="Enter your email address/ mobile number"
                                            fullWidth
                                            margin="normal"
                                            autoComplete='new-userName'
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black', // Set the desired border color here
                                                    },
                                                },
                                            }}
                                        />
                                        <ErrorMessage name="userName" component="perror" />
                                    </Box>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting || SuccessMsg ? true : false}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </Button>
                                </Box>
                            </Box>
                        </FormikForm>
                    )}
                </Formik>
            </Container>
        </>
    );
}

export default ForgotPassword;

