import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import Select from 'react-select';
// import GetUsers from './services/getUsers';
import { createReportType } from '../../../graphql/mutations';
// import ReportServices from '../../Services/reportservices';
import ReportServices from "./../../../services/reportservices";


// interface Year {
//     label: string;
//     value: string;
// }

// function FileType(props: any) {
//     // console.log("props?.selectedYear", props?.selectedYear);

//     const [fileType, setfileType] = useState('');
//     const [typeStatus, setTypeStatus] = useState(false);
//     const [typeInsert, setTypeInsert] = useState(false);
//     const [typeName, setTypeName] = useState<Year[]>([]);
//     const [typeInp, setTypeInp] = useState<Year[]>([]);
//     const [selectedOption, setSelectedOption] = useState<Year | null>(null);


//     useEffect(() => {

//         async function provInfo() {

//             const AllFileTypes: any = await ReportServices().reportTypeList();
//             // if (AllYears.data.listYears.items) {
//             const yearOptions = AllFileTypes?.length > 0 && AllFileTypes?.map((type: any) => ({
//                 label: type?.name,
//                 value: type?.id,
//             }));
//             const allSectionsOptions = [{ label: 'All Types', value: '' }, ...yearOptions];

//             setTypeName(allSectionsOptions);
//             if (props?.selectedYear && !selectedOption) {
//                 const selected = allSectionsOptions?.find((option: any) => option?.value === props?.selectedYear);
//                 if (selected) {
//                     setSelectedOption(selected);
//                     props?.setselectedYear(selected);
//                 }
//             }
//         }
//         provInfo()

//     }, [typeStatus])
//     // console.log("selectedOption", selectedOption);

//     useEffect(() => {
//         if (props?.selectedYear && typeName?.length > 0) {
//             const filteredObject = typeName.find((obj: any) => obj?.value === props?.selectedYear);
//             if (filteredObject) {
//                 setSelectedOption(filteredObject);
//             }
//         }
//     }, [props?.selectedYear, typeName]);

//     // if (props?.selectedYear && typeName) {
//     //     if (props?.selectedYear) {
//     //         // console.log("in if")
//     //         const filteredObject = typeName?.find((obj: any) => obj?.value === props?.selectedYear);
//     //         // console.log("filteredObject", filteredObject);
//     //         if (filteredObject) {
//     //             props?.setselectedYear(filteredObject);
//     //         }
//     //     }
//     // }

//     const handleYear = (option: any) => {
//         // console.log("option", option);
//         props?.setselectedYear(option);
//         setTypeInsert(false)
//     };

//     const filterYear = (option: any, inputValue: any) => {
//         if (inputValue === "" || inputValue === null) {
//             setTypeInsert(false)
//             return true
//         } else {

//             const getValues = typeName.some((year: any) => year.label.toLowerCase().includes(inputValue.toLowerCase()));
//             const getValue = typeName.some((year: any) => year.label.toLowerCase() === inputValue.toLowerCase());
//             if (getValues === true) {
//                 if (getValue === true) {
//                     setTypeInsert(false)
//                 } else {
//                     setTypeInp(inputValue)
//                     setTypeInsert(true)
//                 }
//                 return true
//             } else {
//                 setTypeInp(inputValue)
//                 setTypeInsert(true)
//                 return false
//             }
//         }

//     }

//     const InsBatch = async () => {

//         const getYear: any = await API.graphql(
//             graphqlOperation(createReportType, { input: { name: fileType } })
//         );
//         typeName.push({ label: getYear?.data?.createReportType?.name, value: getYear?.data?.createReportType?.id });
//         props.setselectedYear({ label: getYear?.data?.createReportType?.name, value: getYear?.data?.createReportType?.id })
//         setTypeInsert(false)
//         setTypeStatus(true)
//     };

//     const InsertYear = async () => {
//         const getYear: any = await API.graphql(
//             graphqlOperation(createReportType, { input: { name: typeInp } })
//         );
//         typeName.push({ label: getYear?.data?.createReportType?.name, value: getYear?.data?.createReportType?.id });
//         props?.setselectedYear({ label: getYear?.data?.createReportType?.name, value: getYear?.data?.createReportType?.id })
//         setTypeInsert(false)
//     };



//     return (

//         <div className='form-Branch mt-3'>
//             <label className="form-label">{props?.label} :</label>
//             <div className='row col-md-12'>
//                 <div className='col-md-10'>

//                     {
//                         typeName?.length > 0 ?
//                             <Select
//                                 // value={props?.selectedYear}
//                                 value={selectedOption}
//                                 onChange={(e: any) => handleYear(e)}
//                                 options={typeName}
//                                 isSearchable={true}
//                                 filterOption={(option: any, inputValue: any) =>
//                                     filterYear(option, inputValue)
//                                 }
//                             />
//                             :
//                             <div className="form-group">
//                                 <input type='text' className="form-control" onChange={(e) => setfileType(e?.target?.value)} />
//                             </div>
//                     }

//                 </div>
//                 <div className='col-md-2'>

//                     {props?.hideBtn !== true && typeName?.length === 0 ? (
//                         <button type='button' className='btn btn-dark btn-md' onClick={InsBatch} disabled={fileType ? false : true}>
//                             Add
//                         </button>
//                     ) : props?.hideBtn !== true && typeInsert ? (
//                         <button className='btn btn-dark btn-md' onClick={InsertYear}>
//                             Add
//                         </button>
//                     ) :
//                         null
//                     }
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default FileType;




interface Year {
    label: string;
    value: string;
}

function FileType(props: any) {
    const [fileType, setfileType] = useState('');
    const [typeStatus, setTypeStatus] = useState(false);
    const [typeInsert, setTypeInsert] = useState(false);
    const [typeName, setTypeName] = useState<Year[]>([]);
    const [typeInp, setTypeInp] = useState<Year[]>([]);
    const [selectedOption, setSelectedOption] = useState<Year | null>(null);

    useEffect(() => {
        async function provInfo() {
            try {
                const AllFileTypes: any = await ReportServices().reportTypeList();
                const yearOptions = Array.isArray(AllFileTypes) ? AllFileTypes.map((type: any) => ({
                    label: type?.name,
                    value: type?.id,
                })) : [];
                const allSectionsOptions = [{ label: 'select type', value: '' }, ...yearOptions];

                setTypeName(allSectionsOptions);
                if (props?.selectedYear && !selectedOption) {
                    const selected = allSectionsOptions.find((option: any) => option?.value === props?.selectedYear || "");
                    if (selected) {
                        setSelectedOption(selected);
                        props?.setselectedYear(selected);
                    }
                }
            } catch (error) {
                console.error('Error fetching file types:', error);
            }
        }
        provInfo();
    }, [typeStatus, props?.selectedYear, selectedOption]);

    useEffect(() => {
        if (props?.selectedYear && typeName.length > 0) {
            const filteredObject = typeName.find((obj: any) => obj?.value === props?.selectedYear);
            if (filteredObject) {
                setSelectedOption(filteredObject);
            }
        }
    }, [props?.selectedYear, typeName]);

    const handleYear = (option: any) => {
        setSelectedOption(option);
        props?.setselectedYear(option);
        setTypeInsert(false);
    };

    const filterYear = (option: any, inputValue: any) => {
        if (!inputValue) {
            setTypeInsert(false);
            return true;
        } else {
            const getValues = typeName.some((year: any) => year.label.toLowerCase().includes(inputValue.toLowerCase()));
            const getValue = typeName.some((year: any) => year.label.toLowerCase() === inputValue.toLowerCase());
            if (getValues) {
                setTypeInsert(!getValue);
                setTypeInp(inputValue);
                return true;
            } else {
                setTypeInp(inputValue);
                setTypeInsert(true);
                return false;
            }
        }
    };

    const InsBatch = async () => {
        try {
            const getYear: any = await API.graphql(
                graphqlOperation(createReportType, { input: { name: fileType } })
            );
            typeName.push({ label: getYear?.data?.createReportType?.name, value: getYear?.data?.createReportType?.id });
            props.setselectedYear({ label: getYear?.data?.createReportType?.name, value: getYear?.data?.createReportType?.id });
            setTypeInsert(false);
            setTypeStatus(true);
        } catch (error) {
            console.error('Error inserting batch:', error);
        }
    };

    const InsertYear = async () => {
        try {
            const getYear: any = await API.graphql(
                graphqlOperation(createReportType, { input: { name: typeInp } })
            );
            typeName.push({ label: getYear?.data?.createReportType?.name, value: getYear?.data?.createReportType?.id });
            props?.setselectedYear({ label: getYear?.data?.createReportType?.name, value: getYear?.data?.createReportType?.id });
            setTypeInsert(false);
        } catch (error) {
            console.error('Error inserting year:', error);
        }
    };

    return (
        <div className='form-Branch mt-3'>
            <label className="form-label">{props?.label} :</label>
            <div className='row col-md-12'>
                <div className='col-md-10' style={{ paddingLeft: '6px' }}>
                    {typeName.length > 0 ? (
                        <Select
                            value={selectedOption}
                            onChange={(e: any) => handleYear(e)}
                            options={typeName}
                            isSearchable={true}
                            filterOption={(option: any, inputValue: any) => filterYear(option, inputValue)}
                        />
                    ) : (
                        <div className="form-group">
                            <input type='text' className="form-control" onChange={(e) => setfileType(e?.target?.value)} />
                        </div>
                    )}
                </div>
                <div className='col-md-2'>
                    {props?.hideBtn !== true && typeName.length === 0 ? (
                        <button type='button' className='btn btn-dark btn-md' onClick={InsBatch} disabled={!fileType}>
                            Add
                        </button>
                    ) : props?.hideBtn !== true && typeInsert ? (
                        <button type='button' className='btn btn-dark btn-md' onClick={InsertYear}>
                            Add
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default FileType;
