import { API, graphqlOperation } from "aws-amplify";
import {   createSpeciality,deleteSpeciality, deleteSpecialityCategory, updateSpeciality, updateSpecialityCategory } from "../../graphql/mutations";
import {  getSpeciality, getSpecialityCategory,listSpecialities, listSpecialityCategories } from "../../graphql/queries";

const getspeciality=()=>{
    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }

    const Createspeciality = async (userData:any) => {
        console.log("userData",userData);
        try {
            const result = await API.graphql(graphqlOperation(createSpeciality, { input: userData })) as any;
            return result?.data?.createSpeciality;
        } catch (error) {
            console.error('Error createSpeciality:', error);
            return null;
        }
    }



    const validateSlug = async (slug: string) => {
        console.log("slug", slug);
        try {
            const filterVar = {
                filter: {
                    Slug: { eq: slug }
                }
            };
         console.log("filterVar", filterVar);

            const existingSlugs: any = await API.graphql(graphqlOperation(listSpecialities, filterVar));

            // console.log("existingSlugs", existingSlugs)

            if (existingSlugs && existingSlugs?.data?.listSpecialities?.items?.length > 0) {
                return { msg: 'slug already exists with this slug', status: 1, data: existingSlugs?.data?.listSpecialities?.items};
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating slug:', error);
            return { msg: 'Error validating slug', status: 1 };
        }
    };

     
    const specialityUpdate = async (data: any) => {

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updateSpeciality, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateSpeciality;
        } catch (error) {
            console.error('Error updateSpeciality:', error);
            return null;
        }
    }

    const specialityDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteSpeciality, { input: { id: id } })) as any;
            return result?.data?.deleteSpeciality;
        } catch (error) {
            console.error('Error in deleteSpeciality:', error);
            return null;
        }
    }

    const getSinglespeciality = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getSpeciality, { id: id })) as any;
            return result?.data?.getSpeciality;
        } catch (error) {
            console.error('Error in getSpeciality:', error);
            return null;
        }
    }
    const specialityList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listSpecialities, filter)) as any;
                const specializations = result?.data?.listSpecialities?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listSpecialities?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listSpecialities:', error);
            return [];
        }
    }

    const getcategoryname = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getSpecialityCategory, { id: id })) as any;
            return result?.data?.getSpecialityCategory;
        } catch (error) {
            console.error('Error in getSpecialityCategory :', error);
            return null;
        }
    }

    const CategoryList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                   
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listSpecialityCategories, filter)) as any;
                const specializations = result?.data?.listSpecialityCategories?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listSpecialityCategories?.nextToken;
            } while (nextToken);
            console.log("reviews",reviews)
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
            
        } catch (error) {
            console.error('Error fetching listSpecialityCategories:', error);
            return [];
        }
    }

    const getcategory= async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let allCategories: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listSpecialityCategories, filter)) as any;
                // console.log("result",result)
                const Categories = result?.data?.listSpecialityCategories?.items || [];
                allCategories.push(...Categories);
                nextToken = result?.data?.listSpecialityCategories?.nextToken;
            } while (nextToken);
            allCategories.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allCategories;
        } catch (error) {
            console.error('Error fetching listSpecialityCategories:', error);
            return []; // Return an empty array in case of error
        }
    }
    const ActiveSpecialityList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    filter: {
                        status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listSpecialities, filter)) as any;
                const specializations = result?.data?.listSpecialities?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listSpecialities?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listSpecialities:', error);
            return [];
        }
    }
    const specialityCategoryList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                limit: limit,
                nextToken: nextToken
            };

            do {
                const result = await API.graphql(graphqlOperation(listSpecialityCategories, filterVar)) as any;
                const users = result?.data?.listSpecialityCategories?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listSpecialityCategories?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching listSpecialityCategories:', error);
            return [];
        }
    }

    const SpecialityCategoryUpdate = async (data: any) => {
        console.log("careerData", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        console.log('sanitizedUserInfo', sanitizedUserInfo)

        try {
            const result = await API.graphql(graphqlOperation(updateSpecialityCategory, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateSpecialityCategory;
        } catch (error) {
            console.error('Error updateSpecialityCategory:', error);
            return null;
        }
    }
    const SpecialityCategoryDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteSpecialityCategory, { input: { id: id } })) as any;
            return result?.data?.deleteSpecialityCategory;
        } catch (error) {
            console.error('Error in deleteSpecialityCategory:', error);
            return null;
        }
    }
return{
    Createspeciality,
    specialityUpdate,
    specialityDelete,
    getSinglespeciality,
    specialityList,
    getcategoryname,
    CategoryList,
    getcategory,
    validateSlug,
    ActiveSpecialityList ,
    specialityCategoryList,
    SpecialityCategoryUpdate,
    SpecialityCategoryDelete

    

}
}

export default getspeciality