import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import GalleryUtils from "../../../utils/galleryUploads";
import { Helmet } from 'react-helmet';
import pages from '../../../utils/pages';
import GalleryServices from '../../../services/galleryServices';
import Loader from '../shared/loadercomponent';

interface GalleryItem {
  id: any;
  Category: string;
  Files: [];
  fileType: string;
  url?: string;
}

interface compProps {
  title: string;
  description: string;
  keywords: string;
}


const Gallery: React.FC<compProps> = ({ title, description, keywords }) => {
  const [activeTab, setActiveTab] = useState('Photos');
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>("All");
  const [galleryItems, setGalleryItems] = useState<GalleryItem[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [videoCategories, setVideoCategories] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
  const [visibleItems, setVisibleItems] = useState(3);
  const [loadMore, setLoadMore] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<any>()
  const [displayedFilesCount, setDisplayedFilesCount] = useState(6);


  // const { slug } = useParams();
  // const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      // console.log("result", result)

      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      })

    };
    fetchData();
  }, [])



  const openModal = (videoUrl: string, heading: string) => {
    setSelectedVideoUrl(videoUrl);
    setModalHeading(heading);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideoUrl('');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const params = new URLSearchParams(location.search);
    const category = params.get('category');
    if (category) {
      setActiveTab(category === "Images" ? "Photos" : "Videos");
    }
  }, [location]);

  useEffect(() => {
    async function fetchGalleryItems() {
      setLoading(true);
      const response = await GalleryServices().ActivegalleryList();
      setGalleryItems(response);
      // console.log("response", response);

      const categories = response
        .filter((item: GalleryItem) => item?.fileType.toLowerCase() === "image")
        .map((item: GalleryItem) => item?.Category);

      setSubCategories(["All", ...Array.from(new Set(categories))]);

      const categoriesVideo = response
        .filter((item: GalleryItem) => item?.fileType.toLowerCase() === "video" || item?.fileType.toLowerCase() === "url")
        .map((item: GalleryItem) => item?.Category);

      setVideoCategories(["All", ...Array.from(new Set(categoriesVideo))]);
      setLoading(false);
    }
    fetchGalleryItems();
  }, []);

  const handleMouseOver = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const handleSubCategoryClick = (category: string) => {
    setSelectedSubCategory(category);
  };
  let galleryCount = 0;
  // const handleLoadMore = () => {
  //   setLoadMore(false);
  //   galleryCount++;
  //   setVisibleItems((prev) => prev + 3);
  //   // setVisibleItems(galleryItems.length); // Display all remaining items
  // };
  const handleLoadMore = () => {
    setDisplayedFilesCount(prevCount => prevCount + 3);
  };

  const mapFileTypeToCategory = (fileType: any) => {
    if (fileType.toLowerCase() === "video" || fileType.toLowerCase() === "url") {
      return "video";
    }
    return fileType.toLowerCase();
  };

  // const filteredGalleryItems = galleryItems?.filter((item: GalleryItem) =>
  //   item?.fileType.toLowerCase() === (activeTab === "Photos" ? "image" : "video" || "url") &&
  //   (selectedSubCategory === "All" || item?.Category === selectedSubCategory)
  // ).slice(0, visibleItems);
  const filteredGalleryItems = galleryItems?.filter((item: GalleryItem) =>
    (activeTab === "Photos" ? item?.fileType.toLowerCase() === "image" : ["video", "url"].includes(item?.fileType.toLowerCase()))
    // &&(selectedSubCategory === "All" || item?.Category === selectedSubCategory)
  )
  // .slice(0, visibleItems);

  const allFiles = filteredGalleryItems.flatMap((item: any) => item?.Files?.map((file: any) => ({ file, fileType: item?.fileType })));

  const itemsToDisplay = filteredGalleryItems?.slice(0, visibleItems);

  // Check if there are more items to load
  const hasMoreItems = visibleItems < galleryItems?.length;

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
        <Helmet>
          <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
          </Helmet>
        
        <section className="page-title text-center"
          // style={{ backgroundImage: 'url(images/background/nurse.jpg)' }}
          style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/background/nurse.jpg' }}
        >
            <div className="container">
              <div className="title-text">
                <h1>{bannerName}</h1>
                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
                <a onClick={() => navigate("/")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Home  /&nbsp;</a>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div><section className="video-gallery">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="tabs">
                    <ul className="nav nav-tabs" role="tablist">
                      <li role="presentation" className={activeTab === 'Photos' ? 'active' : ''}>
                        <a href="#Photos" onClick={() => { setActiveTab('Photos'); setLoadMore(true); setVisibleItems(3); setDisplayedFilesCount(3); }} data-toggle="tab" style={{ borderRadius: "15px" }}>Photos</a>
                      </li>
                      <li role="presentation" className={activeTab === 'Videos' ? 'active' : ''}>
                        <a href="#Videos" onClick={() => { setActiveTab('Videos'); setLoadMore(true); setVisibleItems(3); setDisplayedFilesCount(3); }} data-toggle="tab" style={{ borderRadius: "15px" }}>Videos</a>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content">
                    <div className={`gallery tab-pane  ${activeTab === 'Photos' ? 'in active' : ''} row`} id="Photos">
                      {allFiles?.length === 0 ? (
                        <div className="col-md-12">
                          <p>No items to display</p>
                        </div>
                      ) : (
                        allFiles.slice(0, displayedFilesCount)?.map((item: any, index) => {
                          return (
                            item?.fileType.toLowerCase() === "image" ? (
                              // item?.Files?.map((item1: any, i: any) => (
                              // galleryCount++,
                              <div key={index} className="col-md-4 col-sm-6">
                                <div className="gallery-item" onMouseOver={() => handleMouseOver(index)} onMouseOut={handleMouseOut}>
                                  <img
                                    src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.file}`}
                                    className="img-responsive"
                                    alt={item?.file}
                                    style={{
                                      filter: hoveredIndex === index ? "brightness(80%)" : "brightness(100%)",
                                      transition: "filter 0.3s ease",
                                      width: '100%',
                                      height: '300px'
                                    }} />
                                </div>
                              </div>
                              // ))
                            ) : item?.fileType.toLowerCase() === "video" ? (
                              // item?.Files?.map((file: any, i: any) => (
                              // galleryCount++,
                              // index++,
                              <div key={`video-${index}`} className="col-md-4 col-sm-6">
                                <div className="video-thumbnail" style={{ position: 'relative' }} onMouseOver={() => handleMouseOver(index)} onMouseOut={handleMouseOut}>
                                  <video
                                    src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/videos/${item?.file}`}
                                    className="img-fluid"
                                    controls={true}
                                    // onClick={() => openModal(`${process.env.REACT_APP_S3_BUCKET_FETCH}/videos/${file}`, file)}
                                    style={{
                                      filter: hoveredIndex === index ? "brightness(80%)" : "brightness(100%)",
                                      transition: "filter 0.3s ease",
                                      height: "200px",
                                      width: "100%",
                                      border: "1px solid #A9BED0",
                                    }} />
                                </div>
                              </div>
                              // ))
                            ) : item?.fileType.toLowerCase() === "url" ? (
                              // item?.Files?.map((item2: any, i: any) => {
                              (() => {
                                let embedLink = item?.file;
                                if (embedLink.includes("youtu.be")) {
                                  const videoId = embedLink.split("/").pop().split("?")[0];
                                  embedLink = `https://www.youtube.com/embed/${videoId}`;
                                } else if (embedLink.includes("youtube.com/watch")) {
                                  embedLink = embedLink.replace("watch?v=", "embed/").split("&")[0];
                                } else if (embedLink.includes("youtube.com/watch")) {
                                  embedLink = embedLink.replace("watch?v=", "embed/").split("&")[0];
                                }
                                // if (item2?.includes("youtube.com/watch")) {
                                //   embedLink = item2?.replace("watch?v=", "embed/");
                                // }
                                return (
                                  // galleryCount++,
                                  // index++,
                                  <div key={index} className="col-md-4 col-sm-6">
                                    <div className="video-thumbnail" style={{ position: 'relative' }}>
                                      <iframe
                                        src={embedLink}
                                        className="img-fluid"
                                        // onClick={() => openModal(embedLink, `${item2}`)}
                                        title={`Video ${index}`}
                                        style={{
                                          filter: hoveredIndex === index ? "brightness(80%)" : "brightness(100%)",
                                          transition: "filter 0.3s ease",
                                          height: "200px",
                                          width: "100%",
                                          border: "1px solid #A9BED0",
                                        }}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      ></iframe>
                                    </div>
                                  </div>
                                );
                              })()
                            ) : null

                          );
                        })
                      )
                        // (
                        //   filteredGalleryItems?.map((item: any, index) => (
                        //     item?.fileType.toLowerCase() === "image" ?
                        //       item?.Files?.map((fileImg: any, i: any) => (
                        //         <div key={index} className="col-md-4 col-sm-6">
                        //           <div
                        //             className="gallery-item"
                        //             onMouseOver={() => handleMouseOver(index)}
                        //             onMouseOut={handleMouseOut}
                        //           >
                        //             <img
                        //               key={i}
                        //               src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${fileImg}`}
                        //               className="img-responsive"
                        //               alt={`Image ${index}`}
                        //               style={{
                        //                 filter: hoveredIndex === index ? "brightness(80%)" : "brightness(100%)",
                        //                 transition: "filter 0.3s ease",
                        //                 width: '100%',
                        //                 height: '300px'
                        //               }}
                        //             />
                        //           </div>
                        //         </div>
                        //       ))
                        //       :
                        //       (
                        //         item?.Files?.map((item2: any, i: any) => {
                        //           let embedLink = item2;
                        //           if (item2?.includes("youtube.com/watch")) {
                        //             embedLink = item2?.replace("watch?v=", "embed/");
                        //           }
                        //           return (
                        //             <div key={i} className="col-md-4 col-sm-6">
                        //               <div className="video-thumbnail" style={{ position: 'relative' }}>
                        //                 <iframe
                        //                   src={embedLink}
                        //                   className="img-fluid"
                        //                   onClick={() => openModal(embedLink, `${item2}`)}
                        //                   title={`Video ${index}`}
                        //                   style={{
                        //                     filter: hoveredIndex === index ? "brightness(80%)" : "brightness(100%)",
                        //                     transition: "filter 0.3s ease",
                        //                     height: "400px",
                        //                     width: "100%",
                        //                     border: "1px solid #A9BED0",
                        //                   }}
                        //                   frameBorder="0"
                        //                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        //                   allowFullScreen
                        //                 ></iframe>
                        //               </div>
                        //               <div
                        //                 onClick={() => openModal(embedLink, `${embedLink}`)}
                        //                 style={{
                        //                   position: 'absolute',
                        //                   top: 0,
                        //                   left: 0,
                        //                   width: '100%',
                        //                   height: '100%',
                        //                   cursor: 'pointer',
                        //                 }}
                        //               ></div>
                        //             </div>
                        //           );
                        //         })
                        //       )
                        //   ))
                        // )
                      }
                    </div>
                  </div>

                  {/* {hasMoreItems && ( */}
                  {displayedFilesCount < allFiles?.length && (
                    <div className="text-center">
                      <button
                        className="load-more-button"
                        onClick={handleLoadMore}
                        style={{
                          backgroundColor: "#98b868",
                          color: "white",
                          padding: "10px 20px",
                          borderRadius: "8px",
                          border: "none",
                          cursor: "pointer",
                          marginTop: "20px",
                        }}
                      >
                        Load More
                      </button>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </section>

          {isModalOpen && (
            <div className={`modal ${isModalOpen ? "open" : ""}`}>
              <div className="modal-content2" style={{ marginTop: '177px', height: '627px' }}>
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <h6 className="text-center"><strong>{modalHeading}</strong></h6>
                <iframe
                  src={selectedVideoUrl}
                  className="img-fluid"
                  title={modalHeading}
                  style={{ width: '100%', height: '100%' }}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}

          <div className="scroll-to-top scroll-to-target" data-target=".header-top">
            <span className="icon fa fa-angle-up"></span>
          </div>
        </>
      )}
    </div>
  );
}

export default Gallery;