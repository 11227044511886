import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './ServiceCarousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../shared/loadercomponent';
import { useNavigate } from 'react-router-dom';

interface Service {
    title: string;
    image: string;
    description: string;
    link: string;
    button: string;
}

const services: Service[] = [
    {
        title: 'Prominent healthcare experts',
        image: 'images/gallery/doctors.jpg',
        description: 'Doctors here are known for their exceptional expertise and skills.',
        link: 'servicepage',
        button: 'Team',
    },
    {
        title: 'Therapeutic Choices',
        image: 'images/gallery/treatments.jpg',
        description: 'Embracing personalized strategies tailored to patients needs ensures individualized care',
        link: 'servicepage',
        button: 'service',
    },
    {
        title: 'Portfolio Gallery',
        image: 'images/gallery/gallery.jpg',
        description: 'Explore our curated collection showcasing our medical expertise and patient care.',
        link: 'servicepage',
        button: 'gallery',
    },
];

const ServiceCarousel: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        // Simulate a data fetch
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // 2 seconds delay for demonstration

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <section className="service-section bg-gray section">
            <div className="container">
                <div className="section-title text-center">
                    <h3>The Assurance We Offer</h3>
                    {/* <p>We provide Different services to Our Patients</p> */}
                </div>
                <div className="service-carousel-row">
                    {services?.map((service, index) => (
                        <div key={index} className="col-md-4">
                            <div className="item">
                                <div className="inner-box">
                                    <div className="img_holder">
                                        {/* <a href={service.link}> */}
                                        <img src={service.image} alt="images" className="img-responsive" />
                                        {/* </a> */}
                                    </div>
                                    <div className="image-content text-center">
                                        {/* <span>Better Service At Low Cost</span> */}

                                        <h6 style={{ marginLeft: '-18px' }}>{service?.title}</h6>

                                        <p>{service.description}</p>
                                        <div className="more-text" style={{ color: '#48bdc5', marginRight: '140px', fontSize: '17px', cursor: 'pointer' }}>
                                            <div onClick={() => navigate(`/${service?.button}`)} className="anchorAsButton_a">
                                                Know More
                                                <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: '7px' }} />
                                            </div>

                                        </div>
                                        {/* <div className="more-text" style={{ color: '#48bdc5', marginRight: '140px', fontSize: '17px' }}>
                                            <a href={service?.button} className="anchorAsButton_a">
                                                Know More<FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: '7px' }} />
                                            </a>

                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServiceCarousel;
