import React, { useRef, useEffect, useState } from 'react';
// import '../css/font-awesome.min.css'; // Replace with actual path
// import '../css/contact.css'; // Replace with actual path
import './contact.css';
import ContactUtils from '../../../utils/contact/contact';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { contactValidationSchema } from './contactValidationSchema'
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import pages from '../../../utils/pages';
import Loader from '../shared/loadercomponent';

interface Contact {
  Name: string;
  emailID: string;
  phoneNumber: string;
  Message: string;
}

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

const initialValues: Contact = {
  Name: '',
  emailID: '',
  phoneNumber: '',
  Message: ''
};
const ContactPage: React.FC<compProps> = ({ title, description, keywords }) => {

  const [pageInfo, setPageInfo] = useState<any>()
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()
  const [loading, setLoading] = useState(true);
  const [alertMsg, setAlertMsg] = useState<any>('');
  const location = useLocation();

  const formRef = useRef<HTMLDivElement | null>(null); // Create ref for the form container
  
  const fieldRefs: any = useRef({
    Name: null as HTMLDivElement | null,
    emailID: null as HTMLDivElement | null,
    phoneNumber: null as HTMLDivElement | null,
    Message: null as HTMLDivElement | null
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // Simulate fetching data
      const result = await pages(location?.pathname)
      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      })
    };
    fetchData();
    setLoading(false);
  }, [])


  const handleSubmit = async (values: Contact, { setSubmitting, resetForm }: { setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void }) => {

    try {
      setLoading(true);
      const submit = await ContactUtils().handleSubmit(values);
      // resetForm();

      if (submit) {
        window.scrollTo(0, 0);
        setAlertMsg("Contact form submitted successfully");
        resetForm(); // Reset the form
        return { data: submit, status: 200 };
      }
    }
    catch (error) {
      console.error("Error submitting form", error);
      setLoading(false)
    } finally {
      setSubmitting(false);
      setLoading(false) // Ensure setSubmitting is called
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTopOnErrors = (isValid: boolean) => {
    if (!isValid && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <><>

          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
          </Helmet>

          <section className="page-title text-center"
            // style={{ backgroundImage: 'url(images/background/banner.jpg)' }}
            style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/background/banner.jpg' }}

          >
            <div className="container">
              <div className="title-text">

                {/* <ul className="title-menu clearfix" style={{ textAlign: 'left' }}>
<li>
<a href="/">home &nbsp;/</a>
</li>
<li>Contact</li>
</ul> */}
                <h1>{bannerName}</h1>
                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
                <a href="/" className="aColor">Home /&nbsp;</a>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>

          {
            alertMsg && (
              <div className="alert alert-success alert-dismissible fade show alertMessage">
                <strong>Success!</strong> {alertMsg}
                <i
                  className="alertClose fa fa-close"
                  aria-hidden="true"
                  onClick={() => setAlertMsg('')}
                ></i>
              </div>
            )
          }
        </>
          <section className="section contact">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  {/* <h1 style={{marginBottom:"60px"}}>CONTACT US</h1> */}
                  <div className="address-block">
                    {/* Location */}
                    <div className="media">
                      <i className="fa fa-map-o"></i>
                      <div className="media-body">
                        <h3>Location</h3>
                        <p>Trust Hospital, Khammam - 507002<br />India</p>
                      </div>
                    </div>
                    {/* Phone */}
                    <div className="media">
                      <i className="fa fa-phone"></i>
                      <div className="media-body">
                        <h3>Phone</h3>
                        <p>+91 98480 22338<br />+91 95252 63338</p>
                      </div>
                    </div>
                    {/* Email */}
                    <div className="media">
                      <i className="fa fa-envelope-o"></i>
                      <div className="media-body">
                        <h3>Email</h3>
                        <p>support@TrustHospital.com<br />help@trusthospital.com</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-form">

                    <Formik
                      initialValues={initialValues}
                      validationSchema={contactValidationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ isSubmitting, isValid,errors }) => {
                        scrollToTopOnErrors(isValid);

                        const errorCount = Object.keys(errors).length;
                        console.log({ errorCount })

                        if (isSubmitting && errorCount > 0) {

                          for (const key in errors) {
                            console.log({ key })
                            if (fieldRefs.current[key]) {
                              fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                              break; // Stop at the first error we find
                            }
                          }
                        }
                        return (
                          <Form autoComplete='off'>
                            {/* Name */}
                            <div className="col-md-6" ref={(el) => (fieldRefs.current.Name = el)}>
                              {/* <input type="text" name="name" className="form-control main" placeholder="Name" required autoComplete="off" />
         */}
                              <Field
                                type="text"
                                className="form-control"
                                placeholder=" Name"
                                name="Name" autoComplete="new-name" />
                              <ErrorMessage name="Name" component="div" className="error-message  errmt" />
                            </div>
                            {/* Email */}
                            <div className="col-md-6" ref={(el) => (fieldRefs.current.emailID = el)}>
                              {/* <input type="email" name="email" className="form-control main" placeholder="Email" required autoComplete="off"/>
         */}
                              <Field
                                type="email"
                                className="form-control"
                                placeholder=" Email"
                                name="emailID" />
                              <ErrorMessage name="emailID" component="div" className="error-message errmt" />
                            </div>
                            {/* Phone */}
                            <div className="col-md-12" ref={(el) => (fieldRefs.current.phoneNumber = el)}>
                              {/* <input type="text" name="phone" className="form-control main" placeholder="Phone" required autoComplete="off" />
         */}
                              <Field
                                type="tel"
                                className="form-control"
                                placeholder="  Phone Number"
                                name="phoneNumber" autoComplete="new-name" />
                              <ErrorMessage name="phoneNumber" component="div" className="error-message errmt" />
                            </div>
                            {/* Message */}
                            <div className="col-md-12" ref={(el) => (fieldRefs.current.Message = el)}>
                              {/* <textarea name="message" rows={15} className="form-control main" placeholder="Description" required autoComplete="off"></textarea>
         */}
                              <Field
                                as="textarea"
                                name="Message"
                                id="Message"
                                cols={30}
                                rows={7}
                                className="form-control"
                                placeholder="Message"
                                style={{ resize: 'none' }} />
                              <ErrorMessage name="Message" component="div" className="error-message errmt" />
                            </div>
                            {/* Submit Button */}
                            <div className="col-md-12 text-right">
                              {/* <button type="submit" className="btn btn-style-one" style={{color:'white'}}>Send Message</button>
         */}
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn py-3 px-5"
                                style={{ border: 'none', outline: 'none' }}>
                                Submit
                              </button>
                            </div>

                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="map">
            <div className='col-md-12 map container'>
              <iframe
                width="96%"
                height="600px"
                frameBorder="0"
                style={{ border: '0', paddingLeft: "60px", marginBottom: "50px" }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3811.0158171802273!2d80.11675107493062!3d17.21802198364054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a34578a3a8eca8b%3A0xa2888e7ea1e3534a!2sTRUST%20HOSPITAL!5e0!3m2!1sen!2sin!4v1724223619048!5m2!1sen!2sin"
                allowFullScreen>
              </iframe>

              <div id="map"></div></div>
          </section>
          <div className="scroll-to-top scroll-to-target" data-target=".header-top">
            <span className="icon fa fa-angle-up"></span>
          </div></>
      )}
    </div>
  );
};

export default ContactPage;