import getTermsAndConditions from "../../services/TermsAndCondtions"

const pages = async (pathname:any) => {
  try{

    const pathSegment = pathname.split('/').filter(Boolean).pop();

    const listItems = await getTermsAndConditions().TermsAndConditionsList();
    const pageInfo = listItems.filter((item: any) => item?.Link === pathSegment)
    return pageInfo
//    console.log("listItems",pageInfo) //{'listItems': listItems, 'pathSegment': pathSegment})
  }
  catch(error){
    console.log("error",error)
  }
   

    

}

export default pages