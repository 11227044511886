import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import getspeciality from "../../../services/specialities";
// import getspeciality from "../../../services/specialitys";
// import getblogs from '../../../services/Blogs';
// import './BlogPage.css';
import { Helmet } from 'react-helmet';
import pages from "../../../utils/pages";
import Loader from "../shared/loadercomponent";

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

const SpecialityPage: React.FC<compProps> = ({ title, description, keywords }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [specialityItems, setspecialityItems] = useState<any>([]);

  const [filiteredSpeciality, setFiliteredSpeciality] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [blogsPerPage] = useState<number>(10);

  const [allCats, setAllCats] = useState<any>(false);

  const [categories, setCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [pageInfo, setPageInfo] = useState<any>()
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()
  const [loading, setLoading] = useState(true);

  const SpecialityRef = useRef<HTMLDivElement>(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const SpecialityPerPage = 6;
  // const { slug } = useParams();
  // const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)

      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      })

    };
    fetchData();
  }, [])



  const listSpeciality = async () => {
    try {
      setLoading(true);
      const response = await getspeciality().ActiveSpecialityList();
      // console.log("filiteredSpeciality", filiteredSpeciality);
      const categoryname = await getspeciality().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.category.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category?.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });

      setspecialityItems(updatedResponse);
      const categoryIds = updatedResponse.map((item: any) => item?.category);
      // console.log("categoryIds", categoryIds);

      // category List

      const catResponse = await getspeciality().CategoryList();
      setCategories(catResponse);
      location?.state?.category && setSelectedCategory(location?.state?.category);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Speciality:', error);
      setLoading(false);
    }
  };

  useMemo(() => {

    listSpeciality();

    window.scrollTo(0, 0);
  }, [location?.state?.category]);

  useMemo(() => {
    // const filterCat = selectedCategory ? selectedCategory : location?.state?.category
    const filterCat = selectedCategory
    const filterSpecialities: any = filterCat && specialityItems?.filter((specialitys: any) => {
      const categoryIds = specialitys?.category[0]
      const splitString = categoryIds?.includes(',') ? categoryIds?.split(',') : [categoryIds];
      return splitString.includes(filterCat)
    });
    filterSpecialities && setFiliteredSpeciality(filterSpecialities);
  }, [selectedCategory, location?.state?.category, specialityItems]);

  const removeHtmlTags = (str: any) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const handleReadMore = (slug: any) => {
    navigate(`/speciality/${slug}`);
  };


  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };

  // const SpecialitiesFiltered = filiteredSpeciality?.length > 0 ? filiteredSpeciality : specialityItems;
  const SpecialitiesFiltered: any = !selectedCategory ? specialityItems : filiteredSpeciality?.length > 0 ? filiteredSpeciality : [];

  const countByCategory = specialityItems && specialityItems?.reduce((acc: any, specialitys: any) => {

    const categoryIds = specialitys?.category[0]

    const splitString = categoryIds.includes(',') ? categoryIds?.split(',') : [categoryIds];

    // console.log("specialitys", splitString)

    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });

    return acc;
  }, {});



  // console.log("specialityItems", SpecialitiesFiltered);

  // Pagination logic
  const indexOfLastSpeciality = currentPage * SpecialityPerPage;
  const indexOfFirstSpeciality = indexOfLastSpeciality - SpecialityPerPage;
  // const currentDoctors = blogsFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const currentSpecialities = SpecialitiesFiltered.slice(indexOfFirstSpeciality, indexOfLastSpeciality);
  const totalPages = Math.ceil(SpecialitiesFiltered.length / SpecialityPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  useEffect(() => {
    if (SpecialityRef.current) {
      SpecialityRef.current.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);
  }, [currentPage]);
  const getActiveStyle = ({ isActive }: { isActive: boolean }) => ({
    color: isActive ? '#98B868' : '#48bdc5',
    fontWeight: isActive ? 'bold' : '600',
  });
  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (

        <>
        <Helmet>
          <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
          </Helmet>
        
        <section
          className="page-title text-center"
          // style={{ backgroundImage: "url(images/background/bannerr1.jpg)" }}
          style={{ backgroundImage: pageInfo?.length > 0 ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/background/bannerr1.jpg' }}
        >
            <div className="container">
              <div className="title-text">
                <h1>{bannerName}</h1>
                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
              <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                  Home  /&nbsp;
                </span>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div><section className="blog-section style-four section">
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  {currentSpecialities?.length > 0 ? (
                    currentSpecialities?.map((item: any) => (
                      // console.log("item", item),
                      <div className="col-md-6">
                        <div className="left-side">
                          <div className="item-holder">
                            <div className="image-box">
                              <figure>
                                {/* <a href="#"> */}
                                {/* <img src="images/blog/blog-gen-med.jpg" alt="" /> */}

                                <img
                                  src={item?.specialityImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.specialityImage[0]}` : 'images/blog/blog2.jpg'}
                                  alt={item?.title}
                                  className="img-fluid imgcareer" />
                                {/* </a> */}
                              </figure>
                            </div>
                            <div className="content-text">
                              {/* <a href="#"> */}
                              <h6>
                                {item?.title}
                              </h6>
                              {/* </a> */}
                              {/* <span>By Neeraj / 08 January 2018</span> */}
                              {/* <span>{item?.createdAt}</span> */}
                              {/* <p><strong>{item?.catergoryName}</strong></p> */}
                              {/* <p>  {item?.categoryName?.map((catName: any, index: number) => (
                <div key={index} style={{ marginRight: '5px' }}>
                  {catName}{index < item.categoryName.length - 1 ? ',' : ''}
                </div>
              ))}</p> */}
                              {/* <p>{truncateText(removeHtmlTags(item?.description), 80)}</p>
               */}

                              <p className="htdesc" dangerouslySetInnerHTML={{ __html: truncateText(item?.description, 80) }} />
                              {/* <p>
                      Cough and Cold are the most prevalent infections among
                      children as they are transmitted through various
                      viruses,they do weaken the system, and only through
                      adequate care and rest can children recover more quickly.
                    </p> */}
                              <div className="link-btn">
                                <a href='javascript:void(0)' className="btn MedMT"
                                  // onClick={() => navigate(`/singleSpeciality`, { state: { id: item?.id } })}
                                  onClick={() => handleReadMore(item?.Slug)}
                                >
                                  Read More
                                </a>
                              </div>
                            </div>
                          </div>




                          {/* <div className="item-holder">
                  <div className="image-box">
                    <figure>
                      <a href="#">
                        <img src="images/blog/blog-cancer.jpg" alt="" />
                      </a>
                    </figure>
                  </div>
                  <div className="content-text">
                    <a href="#">
                      <h6>Decoding Genetic Mysteries Of Cancer</h6>
                    </a>
                    <span>By Ajay Guptha / 30 March 2020</span>
                    <p>
                      <strong>oncologist</strong>
                    </p>
                    <p>
                      In the expansive realm of medical concerns, lower back
                      discomfort emerges as a widespread issue, impacting
                      millions globally. Its origins span from harmless to
                      serious, frequently prompting individuals to seek
                      alleviation.
                    </p>
                    <div className="link-btn">
                      <a href="#" className="btn">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>

                <div className="item-holder">
                  <div className="image-box">
                    <figure>
                      <a href="#">
                        <img
                          src="images/blog/blog-ortho.jpg"
                          alt=""
                          style={{ height: "275px", objectFit: "cover" }}
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="content-text">
                    <a href="#">
                      <h6>Symptoms and Causes of Leg Cramps</h6>
                    </a>
                    <span>By Anuhya / 12 December 2022</span>
                    <p>
                      <strong>Orthopaedics</strong>
                    </p>
                    <p>
                      Leg cramps manifest as sudden, tight contractions, which
                      can last from a few seconds to several minutes. The exact
                      cause of leg cramps remains unclear, but several factors
                      contribute, including dehydration, and mineral imbalances.
                    </p>
                    <div className="link-btn">
                      <a href="#" className="btn">
                        Read More
                      </a>
                    </div>
                  </div>
                </div> */}

                        </div>
                      </div>

                    ))) : (
                    <div className="col-12">
                      <p style={{ textAlign: 'center', fontSize: '30px' }}>No specialities available</p>
                    </div>
                  )}



                  {/* <div className="col-md-5">
          <div className="left-side">
            <div className="item-holder">
              <div className="image-box">
                <figure>
                  <a href="#">
                    <img src="images/blog/blog-gen-med-water.jpg" alt="" />
                  </a>
                </figure>
              </div>
              <div className="content-text">
                <a href="#">
                  <h6>
                    The Most Common Waterborne Diseases and Prevention
                  </h6>
                </a>
                <span>By Arshad / 13 November 2021</span>
                <p>
                  <strong>General Medicine</strong>
                </p>
                <p>
                  Caused by the bacterium Vibrio cholerae, cholera spreads
                  through water contaminated with feces. Prevention involves
                  ensuring access to safe drinking water, proper sanitation,
                  and hygiene practices
                </p>
                <div className="link-btn">
                  <a href="#" className="btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="item-holder">
              <div className="image-box">
                <figure>
                  <a href="#">
                    <img src="images/blog/blog-lapro.jpg" alt="" />
                  </a>
                </figure>
              </div>
              <div className="content-text">
                <a href="#">
                  <h6>Advanced Surgical Techniques</h6>
                </a>
                <span>By Karthikeya / july 15 2023</span>
                <p>
                  <strong>laparoscopic surgeon</strong>
                </p>
                <p>
                  laparoscopic surgery is defined by utilizing minimally
                  invasive techniques that promote faster recovery. We
                  perform advanced procedures using small incisions and
                  specialized tools, ensuring precision and safety.
                </p>
                <div className="link-btn">
                  <a href="#" className="btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="item-holder">
              <div className="image-box">
                <figure>
                  <a href="#">
                    <img src="images/blog/blog-gen-med-dia.jpg" alt="" />
                  </a>
                </figure>
              </div>
              <div className="content-text">
                <a href="#">
                  <h6>Risk factors for developing diabetes</h6>
                </a>
                <span>By Arjun shourya / 31 December 2023</span>
                <p>
                  <strong>General Medicine</strong>
                </p>
                <p>
                  Risk factors include family history,lack of physical
                  activity, poor diet, older age,gestational diabetes during
                  pregnancy and conditions like polycystic ovary syndrome
                  (PCOS) also increase the risk of developing diabetes later
                  in life.
                </p>
                <div className="link-btn">
                  <a href="#" className="btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

                </div>

                {/* <div className="col-md-1"></div> */}
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    {/* <div className="text-title">
            <h6>Search</h6>
          </div>
          <div className="search-box">
            <form method="post" action="/">
              <input type="search" name="search" placeholder="Enter to Search" required />
            </form>
          </div> */}
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6>Categories</h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">

                          <li style={{ backgroundColor: selectedCategory === "" ? '#48BDC5' : 'transparent', color: selectedCategory === "" ? 'white' : 'black', padding: '8px', cursor: 'pointer' }} onClick={() => setSelectedCategory("")}>All</li>

                          {categories?.map((item: any) => {
                            // console.log("countByCategory", countByCategory);



                            const blogCount = countByCategory[item?.id] || 0;


                            return (


                              <li key={item?.id} className="capitalTxt" onClick={() => { setSelectedCategory(item?.id); setAllCats(false); window.scrollTo(0, 0); }} style={{ backgroundColor: selectedCategory === item?.id ? '#48BDC5' : 'transparent', color: selectedCategory === item?.id ? 'white' : 'black', cursor: 'pointer', padding: '8px' }} >

                                {item?.name.toLowerCase()} <span>({blogCount})</span>



                              </li>
                            );
                          }
                          )}

                          {/* <li>
          <a href="#">
            General Medicine<span>(20)</span>
          </a>
        </li>
        <li>
          <a href="#">
            laparoscopic surgeon<span>(4)</span>
          </a>
        </li>
        <li>
          <a href="#">
            orthopedic <span>(9)</span>
          </a>
        </li>
        <li>
          <a href="#">
            oncologist<span>(2)</span>
          </a>
        </li> */}
                        </ul>
                      </div>
                    </div>

                    {/* <div className="tag-list">
            <div className="text-title">
              <h6>Tags</h6>
            </div>
            <a href="#">General Medicine</a>
            <a href="#">Laparoscopic surgeon</a>
            <a href="#">Orthopedic</a>
            <a href="#">Oncologist</a>
            <a href="#">Dormamu</a><a href="#">Medical</a>
          </div> */}

                  </div>
                </div>
              </div>
              {/* Pagination Controls */}
              {currentSpecialities?.length > 0 ?
                <div className="custom-pagination">
                  <ul className="custom-pagination-list">
                    <li className={`custom-pagination-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button onClick={() => paginate(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`custom-pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
                : null}

              <div className="styled-pagination">
                <ul>
                  <li>
                    <a className="prev" href="#">
                      <span className="fa fa-angle-left" aria-hidden="true"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a className="next" href="#">
                      <span className="fa fa-angle-right" aria-hidden="true"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section></>
      )}

    </div>
  );
};

export default SpecialityPage;

