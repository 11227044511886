import { API, graphqlOperation } from "aws-amplify";
import { createBlog, createPages, deleteBlog, deletePages, updateBlog, updatePages } from "../../graphql/mutations";
import { getBlog, getBlogCategory, getPages, listBlogCategories, listPages } from "../../graphql/queries";

const getTermsAndConditions=()=>{
    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }

    const CreateTermsAndConditions = async (userData:any) => {
        console.log("userData",userData);
        try {
            const result = await API.graphql(graphqlOperation(createPages, { input: userData })) as any;
            return result?.data?.createPages;
        } catch (error) {
            console.error('Error createPages:', error);
            return null;
        }
    }

     
    const UpdateTermsAndConditions = async (data: any) => {
        console.log("data", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updatePages, { input: sanitizedUserInfo })) as any;
            return result?.data?.updatePages;
        } catch (error) {
            console.error('Error updatePages:', error);
            return null;
        }
    }

    const DeleteTermsAndConditions = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deletePages, { input: { id: id } })) as any;
            return result?.data?.deletePages;
        } catch (error) {
            console.error('Error in deletePages:', error);
            return null;
        }
    }

    const getSingleTermsAndConditions = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getPages, { id: id })) as any;
            return result?.data?.getPages;
        } catch (error) {
            console.error('Error in getPages :', error);
            return null;
        }
    }
    const TermsAndConditionsList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listPages, filter)) as any;
                const specializations = result?.data?.listPages?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listPages?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listPages:', error);
            return [];
        }
    }

    const validateSlug = async (Link: string) => {
        console.log("Link", Link);
        try {
            const filterVar = {
                filter: {
                    Link: { eq: Link }
                }
            };
         console.log("filterVar", filterVar);

            const existingSlugs: any = await API.graphql(graphqlOperation(listPages, filterVar));

            // console.log("existingSlugs", existingSlugs)

            if (existingSlugs && existingSlugs?.data?.listPages?.items?.length > 0) {
                return { msg: 'Link already exists with this Link', status: 1, data: existingSlugs?.data?.listPages?.items};
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating Link:', error);
            return { msg: 'Error validating Link', status: 1 };
        }
    };

return{
    CreateTermsAndConditions,
    UpdateTermsAndConditions,
    DeleteTermsAndConditions,
    getSingleTermsAndConditions,
    TermsAndConditionsList,
    validateSlug
    

}
}

export default getTermsAndConditions