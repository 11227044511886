
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './style.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { validationSchema } from './validationSchema';
import { FileUpload, SendFile, updDelFile } from '../shared/fileupload';
import { useLocation, useNavigate } from 'react-router-dom';
import getblogs from '../../../services/Blogs';
// import SpecialityComponent from './createSpecialityCategory';
// import getFAQs from '../../../services/specialitys';
import getFAQs from '../../../services/FAQ';
import CKEditorComponent from '../../../utils/CKEditor';
import { Helmet } from "react-helmet";
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
  title: string;
  description: string;
}

const CreateFAQ: React.FC<compProps> = ({ title, description }) => {
  const location = useLocation();
  const initialValues = {
    Title: '',
    Description: '',
  };

  const navigate = useNavigate();
  const [file, setFile] = useState<any>([]);
  const [directory, setDirectory] = useState();
  const [InputDetails, setInputDetails] = useState(initialValues);
  const [updateSliders, setUpdateSliders] = useState<boolean>(false);
  // const logUser: any = localStorage.getItem('userData');
  // const logUserObject = JSON.parse(logUser);
  const [ErrMsgSlug, setErrMsgSlug] = useState("");

  const fieldRefs: any = useRef({
    Title: null as HTMLDivElement | null,
    Description: null as HTMLDivElement | null,
  });

  // const logUserObject:any = useAuthRedirect();
  async function provInfo() {
    const service = await getFAQs().getSingleFAQ(location?.state?.id);
    setInputDetails(service);
    setUpdateSliders(true);
  }

  useEffect(() => {
    provInfo();
  }, [location?.state]);

  const handleSubmit = async (values: any, { resetForm }: any) => {

    try {

      // let slugError = false;

      // if (values?.id) {
      //     const SlugDetails = await getFAQs().validateSlug(values?.Slug);
      //     console.log("SlugDetails", SlugDetails?.data);

      //     if (SlugDetails?.msg) {
      //         SlugDetails?.data?.map((item: any) => {
      //             if (item?.id !== values?.id) {
      //                 slugError = true;
      //             }
      //         });
      //         if (slugError) {
      //             setErrMsgSlug("Slug is already in use");
      //             return;
      //         } else {
      //             setErrMsgSlug("");
      //         }
      //     }
      // } else {
      //     const SlugDetails = await getFAQs().validateSlug(values?.Slug);
      //     console.log("SlugDetails", SlugDetails);
      //     if (SlugDetails?.msg) {
      //         setErrMsgSlug("Slug is already in use");
      //         return;
      //     }
      // }


      const FAQdata = {
        id: values?.id,
        Title: values?.Title,
        Description: values?.Description,

      };


      const FAQresponse = values?.id
        ? await getFAQs().UpdateFAQ(FAQdata)
        : await getFAQs().CreateFAQ(FAQdata);

      if (FAQresponse) {
        navigate('/listFAQs');
      }

    } catch (error) {
      console.error('Error submitting FAQ:', error);
      throw new Error('Failed to submit FAQ');
    }
  };



  return (
    <>
      <Helmet>
        <title>Add FAQ</title>
        <meta name="description" content="This is the My Dashboard page description" />
      </Helmet>
      <section className="admin-section text-center"
      >
        <div className="container">
          <div className="bannerTitle">
            <h1>Add FAQ</h1>
          </div>
        </div>
      </section>
      <div>
        <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
          <li>
            <a className="aColor" href="/">Home  /&nbsp;</a>
          </li>
          <li>Add FAQ</li>
        </ul>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <Formik
              initialValues={location?.state?.id ? InputDetails : initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ isSubmitting, setFieldValue, values,errors}) => {
                
                const errorCount = Object.keys(errors).length;
                console.log({ errorCount })

                if (isSubmitting && errorCount > 0) {

                  for (const key in errors) {
                    console.log({ key })
                    if (fieldRefs.current[key]) {
                      fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                      break; // Stop at the first error we find
                    }
                  }
                }
                return (
                  <Form className="blog-form">
                    <h3 className="section-Title">{!location?.state?.id ? "Create FAQ" : "Update FAQ"}</h3>


                    <div className="col-md-12">
                      <div className="input-container">
                        <label htmlFor="Title">FAQ Title:*</label>
                        <Field
                          className="Mmt"
                          type="text"
                          id="Title"
                          name="Title"
                          value={values?.Title}
                          onChange={(e: any) => setFieldValue("Title", e?.target?.value)}
                        />
                        <ErrorMessage name="Title" component="div" className="error-message" />
                      </div>
                    </div>



                    <div className="col-md-12">
                      <div className="input-container">
                        <label htmlFor="Description">FAQ Description:*</label>
                        <Field name="Description">
                          {({ field, form }: any) => (
                            <CKEditorComponent
                              data={field.value}
                              onDataChange={(newContent: string) => setFieldValue('Description', newContent)}
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="Description" component="div" className="error-message" />
                      </div>
                    </div>



                    <button type="submit" disabled={isSubmitting}>
                      {!location?.state?.id ? "Create FAQ" : "Update FAQ"}
                    </button>
                  </Form>
                )
              }}
            </Formik>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default CreateFAQ;





