import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import userService from '../../../services/users/user.service';
import SearchIcon from '@mui/icons-material/Search';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Helmet } from "react-helmet";
import './style.css';
import Users from '../../../utils/users';
import Loader from "../shared/loadercomponent";

//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
  title: string;
  description: string;
}

interface Doctor {
  id: string;
  FirstName: string;
  LastName: string;
}

interface Patient {
  id: string;
  PatientName: string;
  // patientID: string;
  PhoneNumber: string;
  DoctorID: string;
  EmailId: string;
  AppointmentDate: string;
  PaymentMode: string;
  Amount: string;
  AppointmentID: string;
  userType: string


}

const ListPatient: React.FC<compProps> = ({ title, description }) => {
  const initialState: Patient = {
    id: '',
    PatientName: '',
    // patientID: '',
    PhoneNumber: '',
    DoctorID: '',
    EmailId: '',
    AppointmentDate: '',
    PaymentMode: '',
    Amount: '',
    AppointmentID: '',
    userType: ''

  };

  const navigate = useNavigate();
  const [data, setData] = useState<Patient>(initialState);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [patientList, setPatientList] = useState<Patient[]>([]);
  const [doctorList, setDoctorList] = useState<Doctor[]>([]);
  // const [logUserObject, setLogUserObject] = useState<any>(null);

  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);
  const [loading, setLoading] = useState(false);


  //  useEffect(() => {

  //   const fetchData = async () => {

  //     const userData = await Users().userSessionData();
  //     userData?.length > 0 && setLogUserObject(userData);

  //   }
  //     fetchData();
  //     }, [])


  const mergeAppointments = (appointments: any[]): any[] => {
    const mergedMap = new Map<string, any>();

    appointments.forEach((appointment) => {
      const phoneNumber = appointment?.PhoneNumber || '';
      if (!mergedMap.has(phoneNumber)) {
        // Initialize the map entry with the current appointment
        mergedMap.set(phoneNumber, {
          ...appointment,
          appointments: [appointment],
        });
      } else {
        // Merge the current appointment with the existing one
        const existingEntry = mergedMap.get(phoneNumber);
        if (existingEntry) {
          existingEntry.appointments.push(appointment);
        }
      }
    });

    // Map to an array of unique patient entries
    return Array.from(mergedMap.values()).map((entry) => ({
      ...entry,
      appointments: entry.appointments,
    }));
  };

  const getPatients = async () => {
    setLoading(true);
    try {
      let response: any;
      if (logUserObject?.Role === "Doctor") {
        response = await userService().ListPatientAppointments(logUserObject?.userId);
      } else if (logUserObject?.Role === "MasterAdmin" || logUserObject?.Role === "Lab" || logUserObject?.Role === "Pharmacy") {
        response = await userService().ListPatientAppointments("");
      } else {
        const patientList = await userService().ListPatientAppointments("");
        response = patientList?.filter((user: any) => user?.patientID === logUserObject?.userId);
      }

      if (!response || response.length === 0) {
        console.warn("No appointments found.");
        setPatientList([]);
        return;
      }
      const uniquePatientList = mergeAppointments(response);
      setLoading(false);
      setPatientList(uniquePatientList);
    } catch (error) {
      console.error("Failed to fetch patients:", error);
      setLoading(false);
    }
  };

  // const getPatients = async () => {


  //   let response: any
  //   if (logUserObject?.Role === "Doctor") {
  //     response = await userService().ListPatientAppointments(logUserObject?.userId);
  //   } else if (logUserObject?.Role === "MasterAdmin" || logUserObject?.Role === "Lab" || logUserObject?.Role === "Pharmacy") {
  //     response = await userService().ListPatientAppointments("");     
  //   } else {
  //     const patientList = await userService().ListPatientAppointments("");
  //     response = patientList?.filter((user: any) => user?.patientID === logUserObject?.userId)
  //   }





  //   const mergeAppointments = async (appointments: any[]) => {
  //     const mergedMap = new Map();

  //     appointments?.forEach((appointment) => {
  //       if (!mergedMap.has(appointment?.PhoneNumber)) {
  //         // Initialize the map entry with the current appointment
  //         mergedMap.set(appointment?.PhoneNumber, {
  //           ...appointment,
  //           appointments: [appointment]
  //         });
  //       } else {
  //         // Merge the current appointment with the existing one
  //         const existingEntry = mergedMap?.get(appointment?.PhoneNumber);
  //         existingEntry?.appointments?.push(appointment);
  //         mergedMap?.set(appointment?.PhoneNumber, existingEntry);
  //       }
  //     });

  //     return Array.from(mergedMap?.values())?.map((entry) => {
  //       // Combine the appointment details as needed
  //       return {
  //         ...entry,
  //         appointments: entry?.appointments
  //       };
  //     });
  //   };

  //   const uniquePatientList: any = mergeAppointments(response);
  //   console.log("uniquePatients", {response, uniquePatientList});
  //   setPatientList(uniquePatientList);
  // };



  const getDoctors = async () => {
    setLoading(true);
    const response = await userService().ListDoctor('Doctor');
    setLoading(false);
    setDoctorList(response);
  };

  useEffect(() => {
    getPatients();
    getDoctors();
  }, [logUserObject?.Role]);

  const handleDelete = async (id: string) => {
    const confirmBox = window?.confirm("Are you sure you want to delete the selected patient?");
    if (confirmBox) {
      await userService().Deletepatient(id);
      getPatients();
    } else {
      alert('Deletion canceled.');
    }
  };
  const handleUpload = async (row: any) => {
    navigate("/addReports", { state: { appointmentId: row?.id, patientID: row?.patientID } });
  };

  const handleUploadprescription = async (row: any) => {
    navigate("/addprescription", { state: { appointmentId: row?.id, patientID: row?.patientID, doctorid: row?.DoctorID } });
  };

  const handleHistory = async (row: any, index: any) => {
    navigate("/ViewAppointment", { state: { patientID: row?.patientID, PhoneNumber: row?.PhoneNumber, patientList: patientList, index: index } })
    // navigate("/appointmentHistory", { state: { patientID: row?.patientID, PhoneNumber: row?.PhoneNumber } })
    // const history = await ReportServices().reportsListwithpatientID(row?.patientID);
    // console.log("history", history);
  }
  const highlightText = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) => (regex.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
  };

  const getDoctorNameById = (doctorId: string) => {
    const doctor = doctorList.find(doc => doc?.id === doctorId);
    return doctor ? `${doctor.FirstName} ${doctor.LastName}` : 'Unknown Doctor';
  };

  console.log({ patientList })
  const filteredData: any = patientList?.filter((row: Patient) =>
    Object.values(row)?.some((value: any) => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredData?.length / pageSize);
  const paginatedData = filteredData && filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleSearch = () => {
    if (!searchTerm) {
      setData(initialState);
    } else {
      setData(filteredData);
    }
    setCurrentPage(1);
  };
  const handleSubmit = (setUpdate: boolean) => {
    navigate('/appointment', { state: { setUpdate: false } });
  }
  useEffect(() => {
    setSearchTerm('');
  }, [currentPage]);

  const handleEdit = (row: Patient) => {
    navigate("/appointment", { state: { patientId: row?.id } });
  }

  return (

    <div>
      {loading ? ( // Show loader while loading is true
        <Loader />
      ) : (
        <><Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet><section className="admin-section text-center"
        >
            <div className="container">
              <div className="bannerTitle">
                <h1>Appointments</h1>
              </div>
            </div>
          </section><div>
            <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
              <li>
                <a className="aColor" href="/">Home  /&nbsp;</a>
              </li>
              <li>Appointments</li>
            </ul>
          </div><div className="container">

            <div className="row">
              {/* <div className="col-md-2" style={{ marginTop: '50px' }}>
      <Sidebar />
    </div> */}
              <div className="col-md-12">
                <div className='tablefs'>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <div className='page-entry'>
                      <Select
                        value={pageSize}
                        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                        style={{ marginRight: '10px' }}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                      </Select>
                      entries per page
                    </div>
                    <div className='table-pagination' style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        onClick={() => handleSubmit(false)}
                        style={{ marginRight: '10px', backgroundColor: '#98B868', color: 'white', borderStyle: 'none', outline: 'none' }}
                        className='btn btn-primary'
                      >
                        Add
                      </Button>
                      <TextField
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: <SearchIcon />,
                        }} />
                    </div>
                  </div>
                  <div style={{ overflowX: 'auto' }}>
                    <table className="table table-bordered table-striped table-hover table-responsive listPatients">
                      <thead>
                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                          <th className="sno">S.No</th>
                          <th className='mdl'>Patient Name</th>
                          <th className='mdl'>AppointmentID</th>
                          <th className='mdl'>EmailId</th>
                          {/* <th>Appointment Date</th> */}
                          <th className='mdl'>Phone Number</th>
                          <th className='mdl'>Doctor Name</th>
                          <th className="paymentMode mdl">PaymentMode</th>
                          <th className="usertype mdl">userType</th>

                          <th className="actions">Actions</th>

                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData && paginatedData?.map((row: Patient, index: number) => {
                          let sno = (currentPage - 1) * pageSize + index + 1;
                          let ind = sno - 1;
                          return (
                            <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                              <td>{(currentPage - 1) * pageSize + index + 1}</td>
                              <td>{highlightText(row?.PatientName)}</td>
                              {/* <td>{highlightText(row?.AppointmentDate)}</td> */}
                              <td>
                                {highlightText(row?.AppointmentID)}<br />
                                ({highlightText(
                                  new Date(row?.AppointmentDate).toLocaleDateString('en-GB', {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric',
                                  })
                                )})
                              </td>
                              <td>{highlightText(row?.EmailId)}</td>
                              {/* <td>{highlightText(row?.AppointmentDate)}</td> */}

                              <td>{highlightText(row?.PhoneNumber)}</td>
                              <td>{highlightText(getDoctorNameById(row.DoctorID))}</td>
                              <td>{highlightText(row?.PaymentMode)}</td>
                              <td>{highlightText(row?.userType)}</td>
                              <td className="actions">

                                {/* <Button
            onClick={() => handleEdit(row)} title='Edit'
            style={{ marginRight: '10px' }}
            startIcon={<EditIcon style={{ color: 'green' }} />}
          >

          </Button>
          <Button title='Delete'
            onClick={() => handleDelete(row?.id)}
            startIcon={<DeleteIcon style={{ color: 'red' }} />}
          >
          </Button> */}
                                <Button title='View history'
                                  onClick={() => handleHistory(row, ind)}
                                  startIcon={<VisibilityIcon style={{ color: '#2cbcbc' }} />}
                                >
                                </Button>
                                {/* <Button
            onClick={() => handleUpload(row)}
            startIcon={<UploadIcon style={{ color: 'blue' }} />}
          >
            Upload Reports
          </Button> */}
                                {/* <Button
            onClick={() => handleUploadprescription(row)}
            startIcon={<UploadIcon style={{ color: 'blue' }} />}
          >
            Upload Prescription
          </Button> */}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className='Mmtpagination'>

                    <div className="paginationstyle">
                      <div
                        style={{
                          cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                          marginRight: '10px',
                          borderBottom: '1px solid red',
                          fontWeight: currentPage > 1 ? 'bold' : 'normal',
                        }}
                        onClick={() => {
                          if (currentPage > 1) {
                            handlePageChange(currentPage - 1);
                            window.scrollTo(0, 0); // Scroll to top
                          }
                        }}
                        className={currentPage === 1 ? 'disabled' : ''}
                      >
                        Previous
                      </div>
                      <div className="pageNumb">{currentPage}</div>
                      <div
                        style={{
                          cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                          marginLeft: '10px',
                          borderBottom: '1px solid red',
                          fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                        }}
                        onClick={() => {
                          if (currentPage < totalPages) {
                            handlePageChange(currentPage + 1);
                            window.scrollTo(0, 0);
                          }
                        }}
                        className={currentPage === totalPages ? 'disabled' : ''}
                      >
                        Next
                      </div>
                    </div>
                    <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

                    </div>

                  </div>

                </div>

              </div>
            </div>
          </div></>
      )}

    </div>
  );
};

export default ListPatient;