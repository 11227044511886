import * as Yup from 'yup';

const characterValidation = (value: any) => {
    return /^[A-Za-z ]+$/.test(value);
};

export const AppointmnetValidationSchema =(id:any)=> Yup.object().shape({

    PatientName: Yup.string()
        .required('Patient Name is required')
        .min(3, 'Message must be at least 3 characters')
        .test('character-validation', 'Patient Name must contain only characters', characterValidation),
    // EmailId: Yup.string()
    //     .email('Invalid email format')
    //     .required('Email is required')
    //     .test(
    //         'valid-domain',
    //         'Invalid domain extension',
    //         (value) => {
    //             // Custom validation for domain extension
    //             if (value) {
    //                 const domainRegex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
    //                 return domainRegex.test(value);
    //             }
    //             return true; // Allow empty values, Yup's required handles that
    //         }
    //     ),
    EmailId: Yup.string()
    .email('Invalid email format')
    // .required('Email is required')
    .test(
        'valid-domain',
        'Invalid domain extension',
        (value) => {
            // Custom validation for domain extension
            if (value) {
                const domainRegex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
                return domainRegex.test(value);
            }
            return true; // Allow empty values, Yup's required handles that
        }
    ),
    PhoneNumber: Yup.string()
        .required('Phone Number is required')
        .test(
            'phone-validation',
            'Phone number must have exactly 10 digits',
            (value) => {
                const inputValue = value.replace(/\D/g, '');
                const phoneRegex = /^\d{10}$/;
                return phoneRegex.test(inputValue);
            }
        ),
    // Message: Yup.string().required('Message is required')
    // .min(3, 'Message must be at least 3 characters'),
    // DoctorID: Yup.string().required('Please select your dentist').nullable(),
    // AppointmentDate: Yup.string().required('Appointment date and time are required'),
    AppointmentDate: Yup.date()
    .required('Appointment date and time is required')
    .test('not-midnight', 'Please select a valid time', (value) => {
      if (!value) return false;
      const hours = value.getHours();
      const minutes = value.getMinutes();
      // Ensure time is not midnight (00:00)
      return !(hours === 0 && minutes === 0);
    }),
    patientID: Yup.string().required('patientID is required').nullable(),
    // PaymentMode: Yup.string().required('Payment mode is required'),
    // MaritalStatus: Yup.string().required('Marital status is required'),
    // pinCode: Yup.string().required('Pincode is required'),
    // DOB: Yup.string().required('Date of Birth is required'),
   
})
