

import React, { useEffect, useState } from 'react';
import { createBlogCategory } from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { listBlogCategories } from '../../../graphql/queries';
import Multiselect from 'multiselect-react-dropdown';
import getblogs from '../../../services/Blogs';

interface Blog {
  label: string;
  value: string;
}

// function BlogsComponent(props: any) {
//   const [getBlog, setBlog] = useState<string | undefined>();
//   const [BlogData, setBlogData] = useState<Blog[]>([]);
//   const [BlogRes, setBlogRes] = useState(true);
//   const [selectedCats, setSelectedCats] = useState<string[]>([]);

//   let transformedOptions: any, getObjects: any;

  

//   useEffect(() => {
//     async function fetchBlogs() {
//       try {
//         // const AllBlogs = await API.graphql(graphqlOperation(listBlogCategories)) as any;
//         const AllBlogs = await getblogs().BlogCategoryList();
//         const blogs = AllBlogs?.map((item: any) => ({
//           label: item?.name,
//           value: item?.id,
//         }));
//         setBlogData(blogs);
//         setBlogRes(true);
//       } catch (err) {
//         console.log('error fetching blogs', err);
//       }
//     }
//     fetchBlogs();
//   }, [BlogRes]);

//   // console.log('BlogData', BlogData)

//   transformedOptions = BlogData;
//   const selectedCategories = selectedCats?.length > 0 ? selectedCats : props?.editValue

//     //  console.log('selectedCategories', selectedCategories);

//    getObjects = selectedCategories && typeof selectedCategories[0] === 'string' ? transformedOptions?.filter((option: any) => selectedCategories[0]?.split(',').some((value: string) => option?.value === value)) : [];

  

//   // useEffect(() => {

//   //    transformedOptions = BlogData;

//   //    const selectedCategories = props?.editValue ? props?.editValue : selectedCats;

//   //    console.log('selectedCategories', selectedCategories);

//   //  getObjects = selectedCategories && typeof selectedCategories[0] === 'string' ? transformedOptions?.filter((option: any) => selectedCategories[0]?.split(',').some((value: string) => option?.value === value)) : [];

//   // }, [selectedCats])

//   // console.log("currentCategoriesselectedCats",selectedCats)

//   const InsertBlog = async () => {
//     if (getBlog) {
//       try {
//         const createdBlog = await API.graphql(
//           graphqlOperation(createBlogCategory, { input: { name: getBlog } })
//         ) as any;

//         const newGroup = {
//           label: createdBlog?.data?.createBlogCategory?.name,
//           value: createdBlog?.data?.createBlogCategory?.id,
//         };

//         const currentCategories = props?.InputDetails?.catergory 
//           ? Array.isArray(props?.InputDetails?.catergory) 
//             ? props?.InputDetails?.catergory 
//             : props?.InputDetails?.catergory.split(',')
//           : [];

//         const updatedCategory = [...currentCategories, newGroup?.value]?.join(',');

//         props.setInputDetails((prevState: any) => ({
//           ...prevState,
//           catergory: updatedCategory,
//         }));
//         setSelectedCats([updatedCategory])
//         props.setFieldValue('catergory', updatedCategory);

//         setBlogRes(false);
//         setBlog(undefined);
//       } catch (err) {
//         console.log('error creating blog', err);
//       }
//     }
//   };

//   const handleSearch = (e: string) => {
//     const blogExists = transformedOptions?.some((option: any) => option?.label === e);
//     console.log("blogExists",blogExists)
//     setBlog(blogExists ? undefined : e);
//   };

//   const handleSelect = (selectedList: any, selectedItem: any) => {
//     const currentCategories = props?.InputDetails?.catergory 
//       ? Array.isArray(props?.InputDetails?.catergory) 
//         ? props?.InputDetails?.catergory 
//         : props?.InputDetails?.catergory.split(',')
//       : [];

//       console.log('selectedOne', {'currentCategories':currentCategories, 'selectedItem':selectedItem?.value})

//     const updatedCategory = [...currentCategories, selectedItem.value].join(',');

//     console.log('updatedCategory', updatedCategory)

//     props.setInputDetails((prevState: any) => ({
//       ...prevState,
//       catergory: [updatedCategory],
//     }));

//     setSelectedCats([updatedCategory])

//     props.setFieldValue('catergory', updatedCategory);
//   };

//   const handleRemove = (selectedList: any, removedItem: any) => {
//     const currentCategories = props?.InputDetails?.category ? props?.InputDetails?.category : [];
//     const catArr: any = currentCategories[0]?.split(',')
//    const updatedCategory = catArr?.filter((id: string) => id !== removedItem?.value).join(',');
//    const finalCategory = updatedCategory ? [updatedCategory] : [];
//     console.log({finalCategory})
//     props.setInputDetails((prevState: any) => ({
//       ...prevState,
//       category: finalCategory,
//     }));
//     setSelectedCats(finalCategory)
//     props.setFieldValue('category', updatedCategory);
//   };

//   return (
//     <div className='form-group mt-3'>
//       <label>Select Category*:</label>
//       <div className='row col-md-12'>
//         <div className='col-md-10' style={{ paddingRight: '0px' }}>
//           <Multiselect
//             style={{ borderRadius: '0px' }}
//             isObject={true}
//             onRemove={handleRemove}
//             onSearch={handleSearch}
//             onSelect={handleSelect}
//             options={transformedOptions}
//             displayValue='label'
//             // showCheckbox
//             selectedValues={getObjects}
//           />
//         </div>
//         <div className='col-md-2 px-0'>
//           {getBlog && (
//             <button type='button' className='btn btn-dark btn-md' onClick={InsertBlog}>
//               Add
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

function BlogsComponent(props: any) {
  const [getBlog, setBlog] = useState<string | undefined>();
  const [BlogData, setBlogData] = useState<Blog[]>([]);
  const [BlogRes, setBlogRes] = useState(true);
  const [selectedCats, setSelectedCats] = useState<string[]>([]);
  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);
  


  let transformedOptions: any, getObjects: any;

  useEffect(() => {
    async function fetchBlogs() {
      try {
        const AllBlogs = await getblogs().getcategory();
        // const AllBlogs = await API.graphql(graphqlOperation(listBlogCategories)) as any;
        const blogs = AllBlogs?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setBlogData(blogs);
        setBlogRes(true);
      } catch (err) {
        console.log('error fetching blogs', err);
      }
    }
    fetchBlogs();
  }, [BlogRes]);



  transformedOptions = BlogData;
  const selectedCategories = selectedCats?.length > 0 ? selectedCats : props?.editValue

  console.log('selectedCategories', selectedCategories);

  getObjects = selectedCategories && typeof selectedCategories[0] === 'string' ? transformedOptions?.filter((option: any) => selectedCategories[0]?.split(',').some((value: string) => option?.value === value)) : [];



  // useEffect(() => {

  //    transformedOptions = BlogData;

  //    const selectedCategories = props?.editValue ? props?.editValue : selectedCats;

  //    console.log('selectedCategories', selectedCategories);

  //  getObjects = selectedCategories && typeof selectedCategories[0] === 'string' ? transformedOptions?.filter((option: any) => selectedCategories[0]?.split(',').some((value: string) => option?.value === value)) : [];

  // }, [selectedCats])



  const InsertBlog = async () => {
    if (getBlog) {
      try {
        const createdBlog = await API.graphql(
          graphqlOperation(createBlogCategory, { input: { name: getBlog } })
        ) as any;

        const newGroup = {
          label: createdBlog?.data?.createBlogCategory?.name,
          value: createdBlog?.data?.createBlogCategory?.id,
        };

        const currentCategories = props?.InputDetails?.catergory
          ? Array.isArray(props?.InputDetails?.catergory)
            ? props?.InputDetails?.catergory
            : props?.InputDetails?.catergory.split(',')
          : [];

        const updatedCategory = [...currentCategories, newGroup.value].join(',');

        props.setInputDetails((prevState: any) => ({
          ...prevState,
          catergory: updatedCategory,
        }));

        props.setFieldValue('catergory', updatedCategory);
        setSelectedCats([updatedCategory])

        setBlogRes(false);
        setBlog(undefined);
      } catch (err) {
        console.log('error creating blog', err);
      }
    }
  };

  const handleSearch = (e: string) => {
    const blogExists = transformedOptions.some((option: any) => option.label === e);
    setBlog(blogExists ? undefined : e);
  };

  const handleSelect = (selectedList: any, selectedItem: any) => {
    const currentCategories = props?.InputDetails?.catergory
      ? Array.isArray(props?.InputDetails?.catergory)
        ? props?.InputDetails?.catergory
        : props?.InputDetails?.catergory.split(',')
      : [];

    console.log('selectedOne', { 'currentCategories': currentCategories, 'selectedItem': selectedItem?.value })

    const updatedCategory = [...currentCategories, selectedItem.value].join(',');

    console.log('updatedCategory', updatedCategory)

    props.setInputDetails((prevState: any) => ({
      ...prevState,
      catergory: [updatedCategory],
    }));

    setSelectedCats([updatedCategory])

    props.setFieldValue('catergory', updatedCategory);
  };

  const handleRemove = (selectedList: any, removedItem: any) => {
    const currentCategories = props?.InputDetails?.catergory ? props?.InputDetails?.catergory : [];
    // const catArr: any = currentCategories[0]?.split(',')
    const catArr: string[] = Array.isArray(currentCategories) && currentCategories[0]
    ? (currentCategories[0].includes(',') ? currentCategories[0].split(',') : [currentCategories[0]])
    : [];
   const updatedCategory = catArr?.filter((id: string) => id !== removedItem?.value).join(',');
   const finalCategory = updatedCategory ? [updatedCategory] : [];
    props.setInputDetails((prevState: any) => ({
      ...prevState,
      catergory: finalCategory,
    }));
    setSelectedCats(finalCategory)
    props.setFieldValue('categcatergoryory', updatedCategory);
  };

  return (
    <div className='form-group mt-3'>
      <label>Select Category*:</label>
      <div className='row col-md-12'>
        <div className='col-md-10' style={{ paddingRight: '0px' }}>
          <Multiselect
            // className="custom-multiselect-container"
            style={{ borderRadius: '0px' }}
            isObject={true}
            onRemove={handleRemove}
            onSearch={handleSearch}
            onSelect={handleSelect}
            options={transformedOptions}
            displayValue='label'
            // showCheckbox
            selectedValues={getObjects}
          />
        </div>
        <div className='col-md-2'>
          {getBlog && (
            <button type='button' className='btn btn-dark btn-md' onClick={InsertBlog}>
              Add
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlogsComponent;
