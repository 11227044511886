
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './styles.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { validationSchema } from './validationSchema';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from '../shared/fileupload';
import Homeservices from '../../../services/homeServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ServiceComponent from './CreateServiceCategory';
import CKEditorComponent from '../../../utils/CKEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormatFile } from '../../../utils/Fileformat';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';




const CreateServices: React.FC = () => {
    const location = useLocation();

    const initialValues = {
        Title: '',
        Description: '',
        Profile: [],
        Slug: '',
        category: ''
    };

    const navigate = useNavigate();
    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [InputDetails, setInputDetails] = useState(initialValues);
    const [updateService, setUpdateService] = useState<boolean>(false);
    const [ErrMsgSlug, setErrMsgSlug] = useState("");
    const [serviceId, setServiceId] = useState('')
    // const logUser: any = localStorage.getItem('userData');
    // const logUserObject = JSON.parse(logUser);
    // const logUserObject:any = useAuthRedirect();

    const fieldRefs: any = useRef({
        category: null as HTMLDivElement | null,
        Title: null as HTMLDivElement | null,
        Slug: null as HTMLDivElement | null,
        Description: null as HTMLDivElement | null
    });

    async function provInfo() {
        const service = await Homeservices().getServiceSingle(location?.state?.id);
        setInputDetails(service)
        setUpdateService(true)
    }
    useEffect(() => {
        provInfo()
    }, [location?.state])


    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.Profile[0], setInputDetails, 'Profile', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };

    const handleSubmit = async (values: any, { resetForm }: any) => {
        try {

            const generateSlug = (name: any) => name.replace(/\s+/g, '_').toLowerCase();
            const slug = generateSlug(values?.Slug)
            let slugError = false;
            if (values?.id) {
                const SlugDetails = await Homeservices().validateSlug(slug);

                if (SlugDetails.msg) {
                    SlugDetails?.data?.map((item: any) => {
                        if (item?.id !== values?.id) {
                            slugError = true;
                        }
                    });
                    if (slugError) {
                        setErrMsgSlug("Slug is already in use");
                        return;
                    } else {
                        setErrMsgSlug("");
                    }
                }
            } else {
                const SlugDetails = await Homeservices().validateSlug(slug);

                if (SlugDetails.msg) {
                    setErrMsgSlug("Slug is already in use");
                    return;
                }
            }

            // let newFilename: any;
            // if (file?.name) {
            //     const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            //     newFilename = replaceSpecialChars(filename);
            const newFilename = await FormatFile(file)
            if (values?.id) {
                file?.name && await updDelFile(values?.Profile, "images");
            }
            file?.name && await SendFile(file, newFilename, "images");
            // }

            const serviceData = {
                id: values?.id,
                Title: values?.Title,
                category: values?.category,
                Slug: slug,
                Description: values?.Description,
                Profile: file?.name ? newFilename : values?.Profile,
                Status: 1,
            };

            console.log("serviceData", serviceData);

            const serviceResponse = values?.id
                ? await Homeservices().ServiceUpdate(serviceData)
                : await Homeservices().CreateServices(serviceData);

            if (serviceResponse) {
                navigate('/listServices');
            }

        } catch (error) {
            console.error('Error submitting Service:', error);
            throw new Error('Failed to submit Service');
        }
    };


    console.log({ InputDetails })

    return (

        <>
            <Helmet>
                <title>Add Services</title>
                <meta name="description" content="This is the My Dashboard page description" />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Add Services</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Add Services</li>
                </ul>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <Formik
                            initialValues={location?.state?.id ? InputDetails : initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({ isSubmitting, setFieldValue, values, errors }) => {

                                const errorCount = Object.keys(errors).length;
                                console.log({ errorCount })
                                if (isSubmitting && errorCount > 0) {

                                    for (const key in errors) {
                                        console.log({ key })
                                        if (fieldRefs.current[key]) {
                                            fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                            break; // Stop at the first error we find
                                        }
                                    }

                                }
                                return (
                                    <Form className="blog-form">
                                        <h3 className="section-title"> {!location?.state?.id ? "Create Service" : "Update Service"}</h3>

                                        <div className="input-container" ref={(el) => (fieldRefs.current.category = el)}>
                                            <ServiceComponent
                                                InputDetails={InputDetails}
                                                setInputDetails={setInputDetails}
                                                setFieldValue={setFieldValue}
                                                editValue={values?.category}

                                            />
                                            <ErrorMessage name="category" component="div" className="error-message" />
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.Title = el)}>
                                                <label htmlFor="Title">Service Title:</label>
                                                <Field
                                                    type="text"
                                                    id="Title"
                                                    name="Title"
                                                    value={values?.Title}
                                                    onChange={(e: any) => setFieldValue("Title", e?.target?.value)}
                                                />
                                                <ErrorMessage name="Title" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.Slug = el)}>
                                                <label htmlFor="Slug">Slug Name:</label>
                                                <Field
                                                    type="text"
                                                    id="Slug"
                                                    name="Slug"
                                                    value={values?.Slug}
                                                    onChange={(e: any) => setFieldValue("Slug", e?.target?.value)}
                                                    disabled={location?.state?.id ? true : false}
                                                />
                                                <p style={{ color: 'red' }}>{ErrMsgSlug ? ErrMsgSlug : ''}</p>
                                                <ErrorMessage name="Slug" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.Description = el)}>
                                                <label htmlFor="Description">Service Description:</label>
                                                <Field name="Description">
                                                    {({ field, form }: any) => (
                                                        <CKEditorComponent
                                                            data={field.value}
                                                            onDataChange={(newContent: string) => setFieldValue('Description', newContent)}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="Description" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="input-container">
                                                {InputDetails?.Profile?.length > 0 && updateService ?
                                                    <><img src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.Profile}`} alt="Profile" style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }} />
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                            <p style={{ margin: 0, lineHeight: '24px' }}>
                                                                <strong>FileName:</strong>{InputDetails?.Profile}
                                                            </p>
                                                            <span
                                                                style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                                                onClick={handleRemove}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </span>
                                                        </div></>
                                                    :
                                                    // <strong>No Profile Photo</strong>
                                                    null
                                                }
                                                <FileUpload label={location?.state?.id ? "Update image" : "Choose image"} setDirectory={setDirectory} setFile={setFile} />
                                                <ErrorMessage name="Profile" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <button type="submit" disabled={isSubmitting}>
                                            {/* {updateService ? 'update Service' : 'create Service'} */}
                                            {!location?.state?.id ? "create Service" : "update Service"}
                                        </button>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        </>
    );
};

export default CreateServices;