
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './style.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { validationSchema } from './validationSchema';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from '../shared/fileupload';
import { useLocation, useNavigate } from 'react-router-dom';
import getblogs from '../../../services/Blogs';
// import SpecialityComponent from './createSpecialityCategory';
// import getSliders from '../../../services/specialitys';
import getSliders from '../../../services/sliders';
import CKEditorComponent from '../../../utils/CKEditor';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Helmet } from "react-helmet";
import { FormatFile } from '../../../utils/Fileformat';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
  title: string;
  description: string;
}
const CreateSlider: React.FC<compProps> = ({ title, description }) => {

  const initialValues = {
    Title: '',
    Caption: '',
    Files: [],
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [file, setFile] = useState<any>([]);
  const [directory, setDirectory] = useState();
  const [InputDetails, setInputDetails] = useState<any>(initialValues);
  const [updateSliders, setUpdateSliders] = useState<boolean>(false);
  // const logUser: any = localStorage.getItem('userData');
  // const logUserObject = JSON.parse(logUser);
  const [ErrMsgSlug, setErrMsgSlug] = useState("");

  const fieldRefs: any = useRef({
    Title: null as HTMLDivElement | null,
    Caption: null as HTMLDivElement | null,
    Files: null as HTMLDivElement | null,
});

  async function provInfo() {
    const service = await getSliders().getSingleSlider(location?.state?.id);
    setInputDetails(service);
    setUpdateSliders(true);
  }

  useEffect(() => {
    provInfo();
  }, [location?.state]);

  const handleRemove = () => {
    if (window.confirm('Are you sure you want to remove this file?')) {
      RemoveProfileforAll(InputDetails?.Files, setInputDetails, 'Files', 'images'); // Pass the key and S3 directory
    } else {
      alert('Deletion canceled');
    }
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      // let slugError = false;

      // if (values?.id) {
      //     const SlugDetails = await getSliders().validateSlug(values?.Slug);
      //     console.log("SlugDetails", SlugDetails?.data);

      //     if (SlugDetails?.msg) {
      //         SlugDetails?.data?.map((item: any) => {
      //             if (item?.id !== values?.id) {
      //                 slugError = true;
      //             }
      //         });
      //         if (slugError) {
      //             setErrMsgSlug("Slug is already in use");
      //             return;
      //         } else {
      //             setErrMsgSlug("");
      //         }
      //     }
      // } else {
      //     const SlugDetails = await getSliders().validateSlug(values?.Slug);
      //     console.log("SlugDetails", SlugDetails);
      //     if (SlugDetails?.msg) {
      //         setErrMsgSlug("Slug is already in use");
      //         return;
      //     }
      // }

      // let newFilename: any;
      // if (file?.name) {
      //   const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
      //   newFilename = replaceSpecialChars(filename);
      const newFilename = await FormatFile(file)
      if (values?.id) {
        await updDelFile(values?.Files, "images");
      }
      await SendFile(file, newFilename, "images");
      // }

      const SliderData = {
        id: values?.id,
        Title: values?.Title,
        Caption: values?.Description,
        Files: file?.name ? newFilename : values?.Files,
      };

      const SliderResponse = values?.id
        ? await getSliders().SliderUpdate(SliderData)
        : await getSliders().CreateSliders(SliderData);

      if (SliderResponse) {
        navigate('/listSliders');
      }

    } catch (error) {
      console.error('Error submitting Sliders:', error);
      throw new Error('Failed to submit Sliders');
    }
  };



  return (
    <>
      <Helmet>
        <title>Add Sliders</title>
        <meta name="description" content="This is the My Sliders page description" />
      </Helmet>
      <section className="admin-section text-center"
      >
        <div className="container">
          <div className="bannerTitle">
            <h1>Add Sliders</h1>
          </div>
        </div>
      </section>
      <div>
        <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
          <li>
            <a className="aColor" href="/">Home  /&nbsp;</a>
          </li>
          <li>Add Sliders</li>
        </ul>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <Formik
              initialValues={location?.state?.id ? InputDetails : initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ isSubmitting, setFieldValue, values,errors }) => {

                const errorCount = Object.keys(errors).length;
                if (isSubmitting && errorCount > 0) {
                  for (const key in errors) {
                    if (fieldRefs.current[key]) {
                      fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                      break; // Stop at the first error we find
                    }
                  }

                }


                return (
                  <Form className="blog-form">
                    <h3 className="section-Title">{!location?.state?.id ? "Create Slider" : "Update Slider"}</h3>
                    <div className="col-md-12">
                      <div className="input-container" ref={(el) => (fieldRefs.current.Title = el)}>
                        <label htmlFor="Title">Slider Title:*</label>
                        <Field
                          type="text"
                          id="Title"
                          name="Title"
                          value={values?.Title}
                          onChange={(e: any) => setFieldValue("Title", e?.target?.value)}
                        />
                        <ErrorMessage name="Title" component="div" className="error-message" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-container" ref={(el) => (fieldRefs.current.Caption = el)}>
                        <label htmlFor="Caption">Slider Description:</label>
                        <Field name="Caption">
                          {({ field, form }: any) => (
                            <CKEditorComponent
                              data={field.value}
                              onDataChange={(newContent: string) => setFieldValue('Caption', newContent)}
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="Caption" component="div" className="error-message" />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="input-container" ref={(el) => (fieldRefs.current.Files = el)}>
                        {InputDetails?.Files?.length > 0 && updateSliders ? (
                          <>
                            <img
                              src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.Files}`}
                              alt="Files"
                              style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }}
                            />
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                              <p style={{ margin: 0, lineHeight: '24px' }}><strong>FileName:</strong>{InputDetails?.Files}</p>
                              <span
                                style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                onClick={handleRemove}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </span>
                            </div>
                          </>
                        ) : (
                          // <strong>No Profile Photo</strong>
                          null
                        )}

                        <FileUpload
                          label={location?.state?.id ? "Update image" : "Choose image"}
                          setDirectory={setDirectory}
                          setFile={setFile}
                        />
                        <ErrorMessage name="Files" component="div" className="error-message" />
                      </div>
                    </div>

                    <button type="submit" disabled={isSubmitting}>
                      {!location?.state?.id ? "Create Slider" : "Update Slider"}
                    </button>
                  </Form>
                )
              }}
            </Formik>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default CreateSlider;





