import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './listServices.css';
import Homeservices from '../../../services/homeServices';
import { Helmet } from "react-helmet";
import { updDelFile } from '../shared/fileupload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HomeIcon from '@mui/icons-material/Home';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
    title: string;
    description: string;
}

interface Services {
    Title: string;
    Description: string;

    Status: any;
}

const ListService: React.FC<compProps> = ({ title, description }) => {
    const initialState: Services = {
        Title: '',
        Description: '',

        Status: ''
    };

    const navigate = useNavigate();
    const [data, setData] = useState<Services>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [reviewsList, setReviewList] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('PDF Viewer Modal');
    const [singleReview, setSingleReview] = useState<any>({});


    // const logUser: any = localStorage.getItem('userData');
    // const logUserObject = JSON.parse(logUser);
   // const logUserObject:any = useAuthRedirect();


    const listServices = async () => {
        const response = await Homeservices().servicesList();
        setReviewList(response);

    };

    useEffect(() => {
        listServices();
    }, []);

    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected Services?");
        if (confirmBox) {
            const service: any = await Homeservices().getServiceSingle(id);
            const filedel = await updDelFile(service?.Profile[0], "images");
            if (filedel?.status === 200) {
                await Homeservices().serviceDelete(id);
                listServices();
            }
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const filteredData: any = reviewsList?.filter((row: Services) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

    // const openModal = async (files: any) => {
    //     const singleReview = await ReviewServices().getreviewSingle(files?.id);
    //     setSingleReview(singleReview);
    //     setIsModalOpen(true);
    //     setModalHeading(files?.Name);
    // };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalHeading("");
    };

    const handleShowteam = async (rowData: any) => {
        const updatedStatus = rowData?.ShowInHome === "1" ? "0" : "1";
        const updatedRowData = { ...rowData, id: rowData?.id, ShowInHome: updatedStatus };
        const update = await Homeservices().ServiceUpdate(updatedRowData);

        setReviewList((prevRows: any) =>
            prevRows?.map((doctor: any) =>
                doctor?.id === rowData?.id ? { ...doctor, ShowInHome: updatedStatus } : doctor
            )
        );
    };


    const handleStatusToggle = async (rowData: any) => {
        const updatedStatus = rowData?.Status === "1" ? "0" : "1";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        const update = await Homeservices().ServiceUpdate(updatedRowData);

        setReviewList((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, Status: updatedStatus } : row
            )
        );
    };
    async function handleEdit(event: any) {
        console.log("event", event);
        navigate('/addServices', { state: { id: event?.id } });
    }

    const handleSubmit = (setUpdateService: boolean) => {
        navigate('/addServices', { state: { setUpdateService: false } });
    };
    const truncateText = (text: any) => {
        if (!text) return '';
        const truncatedText = text?.length > 10 ? text?.substring(0, 100) + '...' : text;
        return truncatedText;
    };
    const removeHtmlTags = (str: any) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Services List</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Services</li>
                </ul>
            </div>
            <div className="container">
                <div className="row">
                    {/* <div className='col-md-2'>
                        < Sidebar />
                    </div> */}
                    <div className="col-md-12">
                        <div className='tablefs'>
                            <div className='mbtable'  style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='page-entry'>
                                    <Select
                                        value={pageSize}
                                        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                    entries per page
                                </div>
                                <div className='table-pagination' style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        onClick={() => handleSubmit(false)}
                                        style={{ marginRight: '10px', backgroundColor: '#98B868', color: 'white', borderStyle: 'none', outline: 'none' }}
                                        className='btn btn-primary'
                                    >
                                        Add
                                    </Button>
                                    <TextField
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search..."
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <SearchIcon />,
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-bordered table-striped  table-responsive listServices">
                                    <thead>
                                        <tr>
                                            <th className="sno">S.No</th>
                                            <th className='mdl'>Title</th>
                                            <th className='mdl'>Description</th>
                                            <th className='mdl'>Slug</th>
                                            <th className="actions">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((row: any, index: number) => (
                                            <tr key={row.id}>
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>{highlightText(row?.Title)}</td>
                        
                                                    <td
                                                     
                                                        dangerouslySetInnerHTML={{
                                                            __html: row?.Description?.substring(0, 30) + (row?.Description?.length > 30 ? "..." : "")
                                                        }}
                                                    />
                                                <td>{highlightText(row?.Slug)}</td>
                                                <td className='actions'>
                                                    <Button title="Edit"
                                                        onClick={() => handleEdit(row)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<EditIcon style={{ color: 'green' }} />}
                                                    >

                                                    </Button>
                                                    <Button title="Delete"
                                                        onClick={() => handleDelete(row?.id)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<DeleteIcon style={{ color: ' #af0e0e'  }} />}
                                                    >

                                                    </Button>

                                                    <Button title="Status"
                                                        onClick={() => handleStatusToggle(row)}
                                                        className={row?.Status === '1' ? 'statusSuccess' : 'statusDanger'}
                                                        startIcon={<VisibilityIcon />}
                                                    ></Button>

                                                 
                                                    <Button title="Show in Home"
                                                        onClick={() => handleShowteam(row)}
                                                        className={row?.ShowInHome === '1' ? 'statusSuccess' : 'statusDanger'}
                                                        startIcon={<HomeIcon />}
                                                    >
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className='Mmtpagination' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

                                </div>
                                <div className="paginationstyle">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                handlePageChange(currentPage - 1);
                                                window.scrollTo(0, 0); // Scroll to top
                                            }
                                        }}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage < totalPages) {
                                                handlePageChange(currentPage + 1);
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>

                            </div>

                            <div className={`modal ${isModalOpen ? "open" : ""}`}>
                                <div className="modal-content1" style={{ marginTop: '177px', height: '380px' }}>
                                    <span className="close" onClick={closeModal}>
                                        &times;
                                    </span>
                                    <h6 className="text-center">Services By <strong>{modalHeading}</strong></h6>
                                    {singleReview && (
                                        <div style={{ padding: '40px' }}>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Name:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.Name)}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Email ID:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.EmailId)}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Rating:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.Rating)}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Comment:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.Comment)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >

        </>

    );
};

export default ListService;
