// const replaceSpecialChars = (filename:any) => {
//     // Extract the file extension
//     const [baseName, extension] = filename.split('.');
  
//     // Replace all special characters and spaces with underscores, keeping the extension intact
//     const newBaseName = baseName.replace(/[^a-zA-Z0-9]/g, '_');
  
//     // Reattach the file extension
//     return `${newBaseName}.${extension}`;
//   };

//   export default replaceSpecialChars;


export const FormatFile = async (file: any) => {

  let newFilename: any;
  if (file) {
    const extension = file?.name?.split('.').pop();
    // newFilename = Date.now() + '.' + extension; 
    newFilename = `${Date.now()}-${Math.floor(Math.random() * 10000)}.${extension}`;   
  }
  // console.log({newFilename})
  return newFilename
}