import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, Button, Box, Container, Typography } from '@mui/material';
import { validationSchema } from './validationSchema';
import { useState } from "react";
import BulkUploadUtils from "../../../utils/bulkupload";
import BulkServices from '../../../services/bulkuploadservices';

const BulkUpload: any = () => {
    const navigate = useNavigate();
    const [csvData, setCSVData] = useState<any[]>([]);
    const [ErrFile, setErrFile] = useState("");

    const handleSubmit = async () => {
        await Promise.all(
            csvData?.map(async (item: any) => {
                const existing: any = await BulkServices().checkExistingUsers(item?.EmailId, item?.PhoneNumber);
                let GetInsertData: any;
                if (existing && existing?.length > 0) {
                    try {
                        GetInsertData = {
                            ...item, id: existing[0]?.id, EmailId: item?.EmailId, Password: "NA", Role: item?.Role, Status: "1", FirstName: item?.FirstName, LastName: item?.LastName, PhoneNumber: item?.PhoneNumber, countryCode: "+91", Slug: "NA", Specialization: "NA",
                        };
                        if (existing[0]?.userType === "E") {
                            const update = await BulkUploadUtils().updateBulkuser(GetInsertData);
                            if (update) {
                                navigate("/allRegisterpatients");
                            }
                        }
                    } catch (err) {
                        console.log("err", err);
                    }
                } else {
                    try {
                        GetInsertData = {
                            ...item, EmailId: item?.EmailId, Password: "NA", Role: item?.Role, Status: "1", userType: "E",
                            FirstName: item?.FirstName, LastName: item?.LastName, PhoneNumber: item?.PhoneNumber,
                            countryCode: "+91", Slug: "NA", resetPWD: "Yes", Specialization: "NA",
                        };
                        const register = await BulkUploadUtils().createBulkUser(GetInsertData);
                        if (register) {
                            navigate("/allRegisterpatients");
                        }
                    } catch (err) {
                        console.log("err", err);
                    }
                }
            })
        );
    }

    const downloadUrl = '/example.csv';


    return (
        <>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Bulk Upload</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Bulk Upload</li>
                </ul>
            </div>

            {/* Bulk Upload Form */}
            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Formik
                            initialValues={{ bulkupload: null }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ setFieldValue, isSubmitting }) => (
                                <Form autoComplete="off">
                                    <Box sx={{ mb: 3 }}>
                                        <input
                                        className="bulkupload"
                                            id="file"
                                            name="bulkupload"
                                            type="file"
                                            accept=".csv"
                                            onChange={(event: any) => {
                                                setFieldValue("bulkupload", event?.currentTarget?.files[0]);
                                                BulkUploadUtils().handleFileChange(event, setCSVData, setErrFile);
                                            }}
                                            placeholder="Select file"
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                marginBottom: '1rem',
                                                boxSizing: 'border-box',
                                            }}
                                        />
                                        <ErrorMessage name="bulkupload" component="div" className="text-danger mt-1" />
                                    </Box>
                                    <a href={downloadUrl} download="example.csv">
                                        Download Excel
                                    </a>
                                    <Button className="btn btn-sm"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        sx={{
                                            width: '20%',
                                            padding: { xs: '10px', sm: '12px', md: '5px' },
                                            fontSize: { xs: '14px', sm: '16px', md: '10px' },
                                            display: 'block',
                                            margin: '0 auto', marginBottom: '10px'
                                        }}
                                    >
                                        Upload
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default BulkUpload;