import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { SHA256 } from "crypto-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Box,
    Typography,
    Container,
    Paper,
    Grid,
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup,
    Button,
    IconButton,
} from "@mui/material"; // Material-UI components imported here
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import bg_img from "./docReg.jpg";
import { FileUpload, MultipleFileUpload, RemovePrevFile, SendFile, updDelFile } from "../shared/fileupload";
import GalleryUtils from "./../../../utils/galleryUploads";
import Sidebar from "../Dashboard/sidebar";
import { validationSchema } from "./validationSchema";
import { Helmet } from "react-helmet";
// import useAuthRedirect from "../../../utils/Redirect/useAuthRedirect";

interface compProps {
    title: string;
    description: string;
}
interface Gallery {
    id: any
    Category: string
    Files: []
    fileType: string
    url?: string
}


const initialState: Gallery = {
    id: '',
    Category: '',
    Files: [],
    fileType: '',
    url: ''
};


const GalleryUpload: React.FC<compProps> = ({ title, description }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errMsgMatch, setErrMsgMatch] = useState("");
    const [InputDetails, setInputDetails] = useState<Gallery | any>(initialState);
    const [directory, setDirectory] = useState();
    const [newState, setNewState] = useState<any>({ fileType: "" });
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // const logUserObject:any = useAuthRedirect();


    const fieldRefs: any = useRef({
        Category: null as HTMLDivElement | null,
        fileType: null as HTMLDivElement | null,
    });
    async function ProvInfo() {
        const response = await GalleryUtils().fetchSingleGallery(location?.state?.id);
        console.log({ response })
        if (response?.fileType === "url") {
            // Update Files from array to string
            const updatedResponse = {
                ...response,
                Files: response?.Files[0]  // Convert the array to a string
            };
            setNewState({ fileType: response?.fileType });
            setInputDetails(updatedResponse);
        } else {
            setNewState({ fileType: response?.fileType });
            setInputDetails(response);
        }
    }

    useEffect(() => {
        ProvInfo();
    }, [location?.state?.id]);


    const handleSumit = async (values: Gallery) => {

        let updatedGalleryObject: any;
        if (location?.state?.id && values?.fileType !== InputDetails?.fileType) {
            const deleteFilesByExtension = async (files: string[]) => {
                const allowedImageExtensions = ["png", "jpg", "jpeg", "gif"];
                const allowedVideoExtensions = ["mp4", "mov", "avi"];

                const filesToDelete = {
                    images: [] as string[],
                    videos: [] as string[],
                    urls: [] as string[]
                };
                const filesArray = Array.isArray(files) ? files : [files];
                // Categorize files by extension
                filesArray?.forEach(file => {
                    // const extension: any = file.split('.').pop()?.toLowerCase();
                    const extension: any = typeof file === 'string' ? file.split('.').pop()?.toLowerCase() : null;
                    if (allowedImageExtensions.includes(extension)) {
                        filesToDelete?.images.push(file);
                    } else if (allowedVideoExtensions.includes(extension)) {
                        filesToDelete?.videos.push(file);
                    } else if (typeof file === 'string' && (file.startsWith('http') || file.startsWith('https'))) {
                        filesToDelete?.urls.push(file);
                    }
                });
                // console.log("filesToDelete", filesToDelete);
                // Delete image files concurrently
                const imageDeletionPromises: any = filesToDelete?.images?.map(imageFile => {
                    updDelFile(imageFile, "images");
                    const filesArray = Array.isArray(values?.Files) ? values?.Files : [values?.Files];
                    const updatedFiles = filesArray.filter((file: string) => typeof file !== 'string');
                    updatedGalleryObject = {
                        ...values,
                        Files: updatedFiles
                    };
                    // console.log("updatedGalleryObject", updatedGalleryObject);
                });

                // Delete video files concurrently
                const videoDeletionPromises: any = filesToDelete?.videos?.map(videoFile => {
                    updDelFile(videoFile, "videos");
                    const filesArray = Array.isArray(values?.Files) ? values?.Files : [values?.Files];

                    const updatedFiles = filesArray?.filter((file: string) => typeof file !== 'string');
                    updatedGalleryObject = {
                        ...values,
                        Files: updatedFiles
                    };
                    console.log("updatedGalleryObject", updatedGalleryObject);
                });
                const urlDeletionPromises = filesToDelete?.urls?.map(async (url) => {
                    const updatedGallery = {
                        ...InputDetails,
                        Files: []
                    };
                    const galleryUp = await GalleryUtils().galleryUpdateUtil(updatedGallery);
                    updatedGalleryObject = {
                        ...values,
                        Files: values?.Files
                    };
                });

                await Promise.all([...imageDeletionPromises, ...videoDeletionPromises, ...urlDeletionPromises]);
            };
            // let deletedFiles: any;
            // if (InputDetails?.fileType === "url") {
            //     const response = await GalleryUtils().fetchSingleGallery(location?.state?.id);
            //     deletedFiles = await deleteFilesByExtension(response?.Files);
            // } else {
            const deletedFiles = await deleteFilesByExtension(InputDetails?.Files);
            // }
        }

        let submit: any;
        if (location?.state?.id) {
            values.id = location?.state?.id;
            if ((values?.fileType === "image" || values?.fileType === "video") && values?.Files?.length > 0) {
                if (values?.fileType !== InputDetails?.fileType) {
                    values.Files = updatedGalleryObject?.Files
                }
                const s3Check = await GalleryUtils().check(values);
                submit = await GalleryUtils().galleryUpdateUtil(values);
            } else {
                submit = await GalleryUtils().galleryUpdateUtil(values);
            }
        } else {
            submit = await GalleryUtils().handleSubmit(values);
        }
        if (submit) {
            navigate('/listGallery');
        }
    };



    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Upload Gallery</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Upload Gallery</li>
                </ul>
            </div>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-2" style={{marginTop:'50px'}}>
                  <Sidebar />
                  </div> */}
                    <div className="col-md-2"></div>
                    <div className="col-md-8">

                        <div
                            className="mlgallery"
                            style={{
                                display: "flex",
                                height: "auto",
                                marginBottom: "10px !important",

                            }}
                        >

                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",

                                }}
                            >
                                <p style={{ color: 'red' }}>{errMsgMatch ? errMsgMatch : ''}</p>
                                <Container>

                                    <Paper
                                        elevation={3}
                                        style={{
                                            // padding: "30px",
                                            background: "transparent !important",
                                            boxShadow: "none",
                                        }}
                                    >
                                        <Formik
                                            initialValues={{
                                                id: location?.state?.id ? location?.state?.id : "",
                                                Category: location?.state?.id ? InputDetails?.Category : "",
                                                fileType: location?.state?.id ? InputDetails?.fileType : "",
                                                Files: location?.state?.id ? InputDetails?.Files : [],
                                            }}

                                            onSubmit={handleSumit}
                                            validationSchema={validationSchema}
                                            enableReinitialize
                                            sx={{ width: "100%" }}
                                        >
                                            {({ isSubmitting, values, setFieldValue, touched, errors }: any) => {


                                                const errorCount = Object.keys(errors).length;
                                                if (isSubmitting && errorCount > 0) {
                                                    for (const key in errors) {
                                                        if (fieldRefs.current[key]) {
                                                            fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                                            break; // Stop at the first error we find
                                                        }
                                                    }

                                                }
                                                return (
                                                    <Form>
                                                        <Box mb={3} ref={(el) => (fieldRefs.current.Category = el)}>
                                                            <TextField
                                                                name="Category"
                                                                label="Category/Title *"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={values?.Category}
                                                                onChange={(e) => setFieldValue("Category", e?.target?.value)}
                                                                error={touched?.Category && Boolean(errors?.Category)}
                                                                helperText={touched?.Category && errors?.Category}
                                                            />
                                                        </Box>
                                                        <div className="d-flex" ref={(el) => (fieldRefs.current.fileType = el)}>
                                                            <label className="form-label me-3">File Type * </label>
                                                            <div className="form-check-container" style={{ display: 'flex' }}>
                                                                <div className="form-check" style={{ display: 'flex', marginRight: '30px' }}>
                                                                    <Field
                                                                        type="radio"
                                                                        name="fileType"
                                                                        value="image"
                                                                        className="form-check-input"
                                                                        id="image"
                                                                        // disabled={!!location?.state?.id}
                                                                        onChange={(e: { target: { value: any; }; }) => {
                                                                            setFieldValue("fileType", e?.target?.value);
                                                                            if (!location?.state?.id) {
                                                                                setFieldValue("Files", []);
                                                                                setInputDetails((prev: any) => ({ ...prev, Files: [] }));
                                                                            }
                                                                            else {
                                                                                setNewState((prev: any) => ({ ...prev, fileType: e?.target?.value }));
                                                                                setFieldValue("Files", Array.isArray(values?.Files) && values?.Files?.map((item: any) => (typeof item !== 'string' ? [] : item)))
                                                                            }
                                                                        }}
                                                                        style={{ marginRight: '10px', marginTop: '10px' }} />
                                                                    <label className="form-check-label" htmlFor="image">
                                                                        Image
                                                                    </label>
                                                                </div>
                                                                <div className="form-check" style={{ display: 'flex' }}>
                                                                    <div className="form-check" style={{ display: 'flex', marginRight: '30px' }}>
                                                                        <Field
                                                                            type="radio"
                                                                            name="fileType"
                                                                            value="video"
                                                                            className="form-check-input"
                                                                            id="video"
                                                                            // disabled={!!location?.state?.id}
                                                                            onChange={(e: { target: { value: any; }; }) => {
                                                                                setFieldValue("fileType", e?.target?.value);
                                                                                if (!location?.state?.id) {
                                                                                    setFieldValue("Files", []);
                                                                                    setInputDetails((prev: any) => ({ ...prev, Files: [] }));
                                                                                }
                                                                                else {
                                                                                    setNewState((prev: any) => ({ ...prev, fileType: e?.target?.value }));
                                                                                    setFieldValue("Files", Array.isArray(values?.Files) && values?.Files?.map((item: any) => (typeof item !== 'string' ? [] : item)))
                                                                                }
                                                                            }}
                                                                            style={{ marginRight: '10px', marginTop: '10px' }} />
                                                                        <label className="form-check-label" htmlFor="video">
                                                                            Video
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="form-check" style={{ display: 'flex' }}>
                                                                    <div className="form-check" style={{ display: 'flex', marginRight: '30px' }}>
                                                                        <Field
                                                                            type="radio"
                                                                            name="fileType"
                                                                            value="url"
                                                                            className="form-check-input"
                                                                            id="url"
                                                                            // disabled={!!location?.state?.id}
                                                                            onChange={(e: { target: { value: any; }; }) => {
                                                                                setFieldValue("fileType", e?.target?.value);
                                                                                if (!location?.state?.id) {
                                                                                    setFieldValue("Files", []);
                                                                                    setInputDetails((prev: any) => ({ ...prev, Files: [] }));
                                                                                }
                                                                                else {
                                                                                    setNewState((prev: any) => ({ ...prev, fileType: e?.target?.value }));
                                                                                    setFieldValue("Files", Array.isArray(values?.Files) && values?.Files?.map((item: any) => (typeof item !== 'string' ? [] : item)));
                                                                                }
                                                                            }}
                                                                            style={{ marginRight: '10px', marginTop: '10px' }}
                                                                        // onChange={(e: any) => setFieldValue("fileType", e?.target?.value)}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="video">
                                                                            URL
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {touched?.fileType && errors?.fileType && (
                                                                    <div className="error-message" style={{ color: 'red' }}>
                                                                        {errors?.fileType}
                                                                    </div>
                                                                )}
                                                            </div>


                                                            {values?.fileType === 'image' ? (
                                                                <div className="mt-3 
                                                          ">
                                                                    <MultipleFileUpload label="Upload Photos" setDirectory={setDirectory} setFieldValue={setFieldValue} InputDetails={InputDetails} setInputDetails={setInputDetails} type="image" setNewState={setNewState}
                                                                        newState={newState}
                                                                        locationID={location?.state?.id} />
                                                                    {touched?.Files && errors?.Files && (
                                                                        <div className="error-message">{errors?.Files}</div>
                                                                    )}

                                                                    <div className="file-list" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                        {Array.isArray(values?.Files) && values?.Files
                                                                            .filter((file1: any) => {
                                                                                const extension: any = typeof file1 === 'string' ? file1.split('.').pop()?.toLowerCase() : null;
                                                                                const isImage = ['png', 'jpg', 'jpeg', 'gif'].includes(extension);
                                                                                const isVideo = ['mp4', 'mov', 'avi'].includes(extension);
                                                                                const isURL = typeof file1 === 'string' && (file1.startsWith('http') || file1.startsWith('https'));

                                                                                // Show only images if fileType is image, videos if fileType is video, or URLs if fileType is URL
                                                                                if (values?.fileType === 'image' && (isImage || file1 instanceof File)) {
                                                                                    return true;
                                                                                }
                                                                                if (values?.fileType === 'video' && (isVideo || file1 instanceof File)) {
                                                                                    return true;
                                                                                }
                                                                                if (values?.fileType === 'url' && isURL) {
                                                                                    return true;
                                                                                }
                                                                                return false;
                                                                            })
                                                                            .map((file1: any, fileIndex: any) => (
                                                                                <span key={fileIndex} className="mx-2 py-1 px-2 bg-secondary text-white" style={{ borderRadius: "5px", marginTop: "5px" }}>
                                                                                    {file1?.name ? file1?.name : file1}
                                                                                    <span onClick={() => RemovePrevFile(file1, fileIndex, InputDetails, setInputDetails, "images", setFieldValue, values)} style={{ paddingLeft: "10px", cursor: "pointer" }} role="button">
                                                                                        <FontAwesomeIcon icon={faTimes} />
                                                                                    </span>
                                                                                </span>
                                                                            ))}
                                                                    </div>

                                                                </div>
                                                            ) : values?.fileType === 'video' ? (
                                                                <div>
                                                                    <MultipleFileUpload label="Upload Video" setDirectory={setDirectory} setFieldValue={setFieldValue} InputDetails={InputDetails} setInputDetails={setInputDetails} type="video"
                                                                        setNewState={setNewState}
                                                                        newState={newState}
                                                                        locationID={location?.state?.id} />
                                                                    {touched?.Files && errors?.Files && (
                                                                        <div className="error-message">{errors?.Files}
                                                                        </div>
                                                                    )}
                                                                    <div className="file-list" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                        {Array.isArray(values?.Files) && values?.Files
                                                                            .filter((file1: any) => {
                                                                                const extension: any = typeof file1 === 'string' ? file1.split('.').pop()?.toLowerCase() : null;
                                                                                const isImage = ['png', 'jpg', 'jpeg', 'gif'].includes(extension);
                                                                                const isVideo = ['mp4', 'mov', 'avi'].includes(extension);
                                                                                const isURL = typeof file1 === 'string' && (file1.startsWith('http') || file1.startsWith('https'));

                                                                                // Show only images if fileType is image, videos if fileType is video, or URLs if fileType is URL
                                                                                if (values?.fileType === 'image' && (isImage || file1 instanceof File)) {
                                                                                    return true;
                                                                                }
                                                                                if (values?.fileType === 'video' && (isVideo || file1 instanceof File)) {
                                                                                    return true;
                                                                                }
                                                                                if (values?.fileType === 'url' && isURL) {
                                                                                    return true;
                                                                                }
                                                                                return false;
                                                                            })?.map((file1: any, fileIndex: any) => (
                                                                                <span key={fileIndex} className="mx-2 py-1 px-2 bg-secondary text-white" style={{ borderRadius: "5px", marginTop: "5px" }}>
                                                                                    {file1?.name ? file1?.name : file1}
                                                                                    <span onClick={() => RemovePrevFile(file1, fileIndex, InputDetails, setInputDetails, "videos", setFieldValue, values)} style={{ paddingLeft: "10px", cursor: "pointer" }} role="button">
                                                                                        <FontAwesomeIcon icon={faTimes} />
                                                                                    </span>
                                                                                </span>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            ) : values?.fileType === 'url' ? (
                                                                <Box mb={3} mt={7}  >

                                                                    <TextField
                                                                        name="url"
                                                                        label="Paste your YouTube url"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        defaultValue={location?.state?.id && InputDetails?.fileType === "url" ? InputDetails?.Files : ""}
                                                                        // defaultValue={values?.url}
                                                                        onChange={(e: any) => setFieldValue("Files", e?.target?.value)}
                                                                    />
                                                                    {touched?.Files && errors?.Files && (
                                                                        <div className="error-message">{errors?.Files}</div>
                                                                    )}
                                                                </Box>
                                                            ) : null}
                                                        </div>

                                                        <Box textAlign="center" marginTop="50px" marginBottom="20px">
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                                // fullWidth
                                                                style={{ padding: "10px", fontSize: "16px" }}
                                                            >
                                                                {location?.state?.id ? "Update" : "Upload"}
                                                            </Button>
                                                        </Box>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </Paper>
                                </Container>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </>

    );
}

export default GalleryUpload;