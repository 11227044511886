import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import userService from '../../../services/users/user.service';
import { AppointmnetValidationSchema } from './AppointmentValidationSchema';
import { useIdleTimeout } from '../../views/shared/header/sessionExpire';
import './appointment.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './DatePickerStyles.css';
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import AppointmentId from '../../../services/Appointment';
import Users from '../../../utils/users';
import Loader from '../shared/loadercomponent';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Payment } from '@mui/icons-material';
import forgotPasswordServices from '../../../services/ForgotPasswordServices';
import ModelComponent from '../shared/appoinmentOTP';
import { SHA256 } from 'crypto-js';
import ModelComponentAppoinment from '../shared/appoinmentOTP';
const CryptoJS = require('crypto-js');

// import { FaCalendarAlt } from "react-icons/fa"; // Import a calendar icon from react-icons

interface compProps {
    title: string;
    description: string;
    keywords: string;
}

interface Kyc {
    pastHealthConditions: String;
    pastHealthConditionsDescription: String;
    foodAllergies: String;
    foodAllergiesDescription: String;
    CurrentMedications: String;
    CurrentMedicationsDescription: String;
    ChronicIllnesses: String;
    ChronicIllnessesDescription: String;
    SmokingStatus: String;
    AlcoholConsumption: String;
    heardFrom: String;
    Diabetes: String,
    bloodPressure: String,
    heartAttack: String,
    pregnacy: String,

}
interface PatientAppoinment {
    id?: string,
    PatientName: string,
    patientID: string,
    PhoneNumber: string,
    DoctorID: string,
    EmailId: string,
    Message: string,
    AppointmentDate: string,
    AppointmentTime: string,
    PaymentMode: string,
    Amount: any,
    Address: String,
    City: String,
    Street: String,
    pinCode: String,
    DOB: String,
    HouseNumber: String,
    MaritalStatus: String,
    KYC: Kyc[],
    AppointmentID: any,
    userType: String,
    PaymentStatus: String

}

const AppointmentPage: React.FC<compProps> = ({ title, description, keywords }) => {
    const navigate = useNavigate();
    const [ErrMsgMatch, setErrMsgMatch] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [doctors, setDoctors] = useState<PatientAppoinment[]>([]);
    const [doctorID, setDoctorID] = useState('');
    const [doctAvail, setDoctAvail] = useState<any>([]);
    const lastDayOfMonthString = new Date().toISOString().split('T')[0];
    const [patientDetails, setPatientDetails] = useState<any>();
    const location = useLocation();
    const isIdle = useIdleTimeout(1000 * 900);
    const isActive = useIdleTimeout(1000 * 900);
    const [showRazorpay, setShowRazorpay] = useState(false);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    let [updateDoctor, setUpdateDoctor] = useState<boolean>(false);
    const [doctorSelected, setDoctorSelected] = useState(false);
    const [doctorDate, setdoctorDate] = useState<string[]>([]);
    const [timeSlot, setTimeSlot] = useState<any>(15)
    const [availableTimes, setAvailableTimes] = useState([]);
    const [appointmentDate, setAppointmentDate] = useState('');
    const [appointmentTime, setAppointmentTime] = useState<any>('');
    const [UserDetails, setUserDetails] = useState<any>();
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [appDecId, setAppDecId] = useState<any>('')
    const [storePaymentDetails, setStorePaymentDetails] = useState<any>([]);
    const { slug } = useParams();
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [existMsg, setExistMsg] = useState("");
    const [alertMsg, setAlertMsg] = useState<any>('');
    // console.log("logUserObject?.Role",logUserObject?.Role)
    const setFieldValueRef = useRef<any>(null);
    const [doctorUnavailableMessage, setDoctorUnavailableMessage] = useState('');
    const [availableMessage, setAvailableMessage] = useState('')
    const [getModal, setModal] = useState(false);
    const [otpStatus, setOtpStatus] = useState<any>(false);
    const [errSubmitMsg, setSubmitMsg] = useState("");
    const [SuccessMsg, setSuccessMsg] = useState('');
    const [timer, setTimer] = useState(60);
    const [userName, setUserName] = useState("");
    const [CurrentOTP, setCurrentOTP] = useState("");
    // const [OTPErr, setOTPErr] = useState("");
    const [OTPErr, setOtpError] = useState("");
    const [getvalues, setvalues] = useState<any>('');
    const [getExistingPhone, setExistingPhone] = useState<any>('');
    const [getExistingEmail, setExistingEmail] = useState<any>('');
    const [getcheckPhone, setCheckPhone] = useState<any>('');
    const [getcheckEmail, setCheckEmail] = useState<any>('');
    const [getappointmentModal, setAppointmentModal] = useState(false);
    const [getappointmentvalues, setAppointmenttvalues] = useState<any>('');
    const [appointmentSuccessMsg, setAppointmentSuccessMsg] = useState("")
    const [isTimerRunning, setIsTimerRunning] = useState(false);



    const fieldRefs: any = useRef({
        PatientName: null as HTMLDivElement | null,
        PhoneNumber: null as HTMLDivElement | null,
        DoctorID: null as HTMLDivElement | null,
        EmailId: null as HTMLDivElement | null,
        Message: null as HTMLDivElement | null,
        AppointmentDate: null as HTMLDivElement | null,
        AppointmentTime: null as HTMLDivElement | null,
        Amount: null as HTMLDivElement | null | undefined,
        Address: null as HTMLDivElement | null,
        City: null as HTMLDivElement | null,
        Street: null as HTMLDivElement | null,
        pinCode: null as HTMLDivElement | null,
        DOB: null as HTMLDivElement | null,
        HouseNumber: null as HTMLDivElement | null,
        MaritalStatus: null as HTMLDivElement | null,
        KYC: null as HTMLDivElement | null,
    });

    const getUserDetails = async () => {
        if (logUserObject?.userId && logUserObject?.Role === "Patient") {
            const userDetails: any = await userService().getuserSingle(logUserObject?.userId)
            if (userDetails) {
                setUserDetails(userDetails?.data?.getUser);
            }
        }
        const encodSlug = slug && await Users().decodeBase64(slug)
        setAppDecId(encodSlug)
    }
    useEffect(() => {
        getDoctor();
        getUserDetails();
    }, []);

    useEffect(() => {
        const today = new Date();
        const maxDay = new Date();
        maxDay.setDate(today.getDate() + 90);
        setMinDate(today);
        setMaxDate(maxDay);
    }, []);

    const validateForm = (values: any) => {
        const requiredFields = ['PatientName', 'PhoneNumber'];
        const isComplete = requiredFields?.every(field => values[field]);
        setIsFormComplete(isComplete);
    };
    useEffect(() => {
        const getExistingUser = async () => {

            const today = new Date();
            today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds

            if (location?.state?.patientId || appDecId) {
                const getPatientDetails = await userService().ListPatientAppointmentsById(location?.state?.patientId, appDecId);

                console.log({ getPatientDetails });

                if (getPatientDetails[0]?.DoctorID) {
                    // handleDoctorChange('', getPatientDetails[0]?.DoctorID);
                    handleDoctorChange('', getPatientDetails[0]?.DoctorID, '');

                    // Check if the appointment date is today or in the future
                    const appointmentDate = new Date(getPatientDetails[0]?.AppointmentDate);


                    // if (appointmentDate >= today) {
                    //     if (setFieldValueRef.current) {
                    //         handleAppoinmentDate(appointmentDate);
                    //     }
                    // }
                    if (appointmentDate >= today) {
                        handleDateChange(appointmentDate, setFieldValueRef.current);
                    }
                }
                getPatientDetails &&
                    // setInputDetails(getPatientDetails[0]);
                    // setInitialValues(getPatientDetails[0]);
                    setTimeout(() => {
                        setInputDetails(getPatientDetails[0]);
                    }, 200);


                setUpdateDoctor(true);
                setUpdate(true);
            } else {
                console.log('appointment', "else create appoinment")
                handleDateChange(today, setFieldValueRef.current);
            }

        };
        getExistingUser();
    }, [location?.state?.patientId, appDecId]);

    const initialState: PatientAppoinment = {
        PatientName: UserDetails ? UserDetails?.FirstName : "",
        patientID: 'NA',
        PhoneNumber: UserDetails ? UserDetails?.PhoneNumber : "",
        DoctorID: '',
        EmailId: UserDetails ? UserDetails?.EmailId : "",
        Message: '',
        AppointmentDate: '',
        AppointmentTime: '',
        PaymentMode: '',
        Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
        Address: '',
        City: '',
        Street: '',
        pinCode: '',
        DOB: '',
        HouseNumber: '',
        MaritalStatus: '',
        userType: (logUserObject && logUserObject?.Role === "Patient") ? 'R' : 'G',
        KYC: [{
            pastHealthConditions: '0',
            foodAllergies: '0',
            CurrentMedications: '0',
            ChronicIllnesses: '0',
            SmokingStatus: '',
            AlcoholConsumption: '',
            heardFrom: '',
            pastHealthConditionsDescription: '',
            foodAllergiesDescription: '',
            CurrentMedicationsDescription: '',
            ChronicIllnessesDescription: '',
            Diabetes: '',
            bloodPressure: '',
            heartAttack: '',
            pregnacy: '',
        }],
        AppointmentID: '',
        PaymentStatus: ''
    };
    const [InputDetails, setInputDetails] = useState<PatientAppoinment>(initialState);
    const getDoctor = async () => {
        try {
            const response = await userService().ListDoctor('Doctor');
            const doctorIds = response.map(doctor => doctor.id);
            setdoctorDate(doctorIds);
            setDoctors(response);
        } catch (error) {
            console.error('Error fetching doctors:', error);
        }
    };
    const handleDoctorChange = async (event: any, doctorId: any, setFieldValue: any) => {
        const selectedDoctorID = event?.target?.value || doctorId;

        setDoctorID(selectedDoctorID);
        setDoctorSelected(selectedDoctorID && selectedDoctorID !== 'Choose Doctor');
        setFieldValue && setFieldValue('AppointmentDate', '');

        if (!selectedDoctorID || selectedDoctorID === 'Choose Doctor') {

            const today = new Date();
            today.setHours(0, 0, 0, 0);
            setAvailableMessage('');
            // const today = new Date();
            const availableTimes = getDefaultAvailableTimes(today);
            setAvailableTimes(formatAvailableTimes(availableTimes, today));
            handleDateChange(today, setFieldValueRef.current);
            return;
        }

        const doctorAppointments = await userService().ListPatientAppointments(selectedDoctorID);
        const appointmentTimes = doctorAppointments.map(item => ({
            date: item?.AppointmentDate,
            time: item?.AppointmentTime,
        }));

        setAppointmentTime(appointmentTimes);
        const doctAvailable = await userService().getlistDoctorAvailableDates(selectedDoctorID);
        if (Array.isArray(doctAvailable) && doctAvailable.length > 0) {
            const today = new Date();
            const todayStr = today.toISOString().split('T')[0];
            const ninetyDaysLater = new Date();
            ninetyDaysLater.setDate(today.getDate() + 90);
            const ninetyDaysLaterStr = ninetyDaysLater.toISOString().split('T')[0];
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
            const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            const year = lastDayOfMonth.getFullYear();
            const month = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
            const day = String(lastDayOfMonth.getDate()).padStart(2, '0');
            const formattedLastDay = `${year}-${month}-${day}`;
            const futureAvailableDates = doctAvailable.filter(item => {
                const availableDate = new Date(item?.Date).toISOString().split('T')[0];
                return availableDate >= todayStr && availableDate <= ninetyDaysLaterStr;
            });

            const availableDatesForCurrentMonth = futureAvailableDates.filter(item => {
                const availableDate = new Date(item?.Date).toISOString().split('T')[0];
                return availableDate >= firstDayOfMonth && availableDate <= formattedLastDay;
            });

            if (futureAvailableDates.length > 0) {
                const availableDates = futureAvailableDates.map(item => ({ date: item?.Date, times: item?.Time }));
                const isTodayAvailable = futureAvailableDates.some(item => new Date(item?.Date).toISOString().split('T')[0] === todayStr);

                setDoctAvail(availableDates);
                setDoctorUnavailableMessage('');

                if (isTodayAvailable) {
                    const todayTimes = availableDates?.find(item => item.date === todayStr)?.times || [];
                    const currentTime = new Date();
                    const filteredTimes = todayTimes.filter((time: any) => {
                        const [hours, minutes] = time.split(':').map(Number);
                        const timeDate = new Date(todayStr);
                        timeDate.setHours(hours, minutes, 0, 0);
                        return timeDate > currentTime;
                    });
                    const formattedTimes: any = formatAvailableTimes(filteredTimes, todayStr);
                    todayTimes?.length > 0 && setAvailableTimes(formattedTimes);
                } else {
                    setAvailableTimes([]);
                }
                if (availableDatesForCurrentMonth?.length === 0) {
                    const firstAvailableDate = futureAvailableDates.find(item => new Date(item?.Date) > today);
                    if (firstAvailableDate) {
                        const firstAvailableDateStr = new Date(firstAvailableDate.Date).toLocaleDateString();
                        setAvailableMessage(`This doctor is not available for the current month. Next available date is ${firstAvailableDateStr}.`);
                    } else {
                        setDoctorUnavailableMessage('This doctor is not available for the next few months.');
                    }
                    setAvailableTimes([]);
                } else {
                    setAvailableMessage('');
                }
            } else {
                setDoctAvail([]);
                setAvailableMessage('');
                setDoctorUnavailableMessage('This doctor is not available.');
                setAvailableTimes([]);
            }
        } else {
            setDoctAvail([]);
            setDoctorUnavailableMessage('This doctor is not available.');
            setAvailableTimes([]);
            setAvailableMessage('');
        }
        const doctorDetails = await userService().ListDoctorsById(selectedDoctorID);
        setTimeSlot(doctorDetails?.[0]?.TimeSlot || 15);
    };

    const isDateAvailable = (date: any) => {
        const moment = require('moment');
        const today = moment().startOf('day');
        const oneWeekLater = moment().add(7, 'days').startOf('day');

        if (!doctorSelected || doctorID === '' || doctorID === 'Choose Doctor') {
            return date >= today && date <= oneWeekLater;
        }

        const previousDay = new Date(date);
        previousDay.setDate(date.getDate() + 1);

        const formattedDate = previousDay.toISOString().split("T")[0];
        // console.log("isDateAvailable", formattedDate, doctAvail);
        // console.log("doctAvail", doctAvail);
        return doctAvail.some((item: any) => item.date === formattedDate);
    };
    const getDefaultAvailableTimes = (date: any) => {
        const times = [];
        const start = new Date(date);
        start.setHours(10, 0, 0, 0);
        const end = new Date(date);
        end.setHours(20, 0, 0, 0);
        while (start < end) {
            times.push(`${start.getHours()}:${start.getMinutes() === 0 ? '00' : start.getMinutes()}`);
            start.setMinutes(start.getMinutes() + timeSlot);
        }

        return times;
    };

    const getAvailableTimes = (date: any) => {
        const formattedDate = format(date, "yyyy-MM-dd");
        const dateAvailability = doctAvail.find((item: any) => item?.date === formattedDate);
        let availableTimes = dateAvailability ? dateAvailability.times : getDefaultAvailableTimes(date);
        const bookedTimesForDate = appointmentTime && appointmentTime
            .filter((item: any) => item?.date === formattedDate)
            .map((item: any) => item?.time);
        availableTimes = availableTimes.filter((time: any) => !bookedTimesForDate.includes(time));
        const today = new Date();
        if (formattedDate === format(today, "yyyy-MM-dd")) {
            const currentTime = `${today.getHours()}:${today.getMinutes() < 10 ? '0' : ''}${today.getMinutes()}`;
            availableTimes = availableTimes.filter((time: any) => {
                const [hours, minutes] = time.split(":");
                const formattedTime = `${parseInt(hours)}:${parseInt(minutes) < 10 ? '0' : ''}${parseInt(minutes)}`;
                return formattedTime >= currentTime;
            });
        }
        return availableTimes;
    };

    const formatAvailableTimes: any = (times: string[], date: Date) => {
        return times.map(time => {
            // console.log("times", time);
            const [hours, minutes] = time.split(":");
            const newDate = new Date(date);
            newDate.setHours(parseInt(hours), parseInt(minutes));
            return newDate;
        });
    };

    const handleDateChange = (date: any, setFieldValue: any) => {
        const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm");
        console.log({ formattedDate });
        setFieldValue("AppointmentDate", formattedDate);
        const doctorAvailableTimes = getAvailableTimes(date);
        const formattedTimes: any = formatAvailableTimes(doctorAvailableTimes, date);
        console.log('formattedTimes', typeof (doctorAvailableTimes))
        doctorAvailableTimes?.length > 0 && setAvailableTimes(formattedTimes);
    };

    const handleAppoinmentDate = (date: any) => {
        const availableTimes = getAvailableTimes(date);
        const formattedTimes: any = formatAvailableTimes(availableTimes, date);

        setAvailableTimes(formattedTimes);
    }
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
            (day % 10 === 2 && day !== 12) ? 'nd' :
                (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

        return `${day}${suffix} ${month} ${year}`;
    };
    const handleUpdate = async (values: PatientAppoinment, { setSubmitting, resetForm }: any) => {
        window.scrollTo(0, 0);
        const [date, time] = values?.AppointmentDate.split('T');

        try {
            setLoading(true);
            const formatEmail = values?.EmailId.toLowerCase();
            const GetInsertDataUpdate = {
                id: values?.id,
                PatientName: values?.PatientName,
                // patientID: logUserObject?.Role === "Patient" ? logUserObject?.userId : null,
                // patientID: logUserObject?.Role === "Patient" ? logUserObject?.userId : InputDetails?.patientID ? InputDetails?.patientID : null,
                patientID: values?.patientID,
                PhoneNumber: values?.PhoneNumber,
                DoctorID: values?.DoctorID,
                EmailId: formatEmail,
                Message: values?.Message,
                AppointmentDate: date,
                AppointmentTime: time,
                PaymentMode: values?.PaymentMode,
                Amount: values?.PaymentMode === 'online' ? 500 : values?.Amount,
                Address: values?.Address,
                City: values?.City,
                Street: values?.Street,
                pinCode: values?.pinCode,
                DOB: values?.DOB,
                HouseNumber: values?.HouseNumber,
                MaritalStatus: values?.MaritalStatus,
                KYC: values?.KYC,
                AppointmentID: values?.AppointmentID,
                userType: values?.userType,
                PaymentStatus: values?.PaymentStatus
            };

            const userResponse = await userService().updatePatientAppointmentByData(GetInsertDataUpdate);
            const appDate = formatDate(userResponse?.data?.updatePatientAppointment
                ?.AppointmentDate)
            const appEncId = userResponse && await Users().encodeBase64(userResponse?.data?.updatePatientAppointment?.AppointmentID)
            if (userResponse && userResponse?.data?.updatePatientAppointment) {
                if (userResponse?.data?.updatePatientAppointment?.EmailId) {
                    try {
                        await Users().generateEmailSMS(userResponse?.data?.updatePatientAppointment?.EmailId,
                            `<html><body><div>Welcome to Trust Hospitals!<br /><br />Thank you for contacting us,your appointment has been Re-Scheduled  succesfully on ${appDate} at ${userResponse?.data?.updatePatientAppointment
                                ?.AppointmentTime} and Your AppointmentId is ${userResponse?.data?.updatePatientAppointment?.AppointmentID}. We appreciate your interest and will get back to you shortly.<br/>Please <a href='${process.env.REACT_APP_HOST}appointment/${appEncId}'>click here</a> to Re-Schedule your Appointment<br />Please<a href='${process.env.REACT_APP_HOST}appointmentHistory/${appEncId}'>click here</a>to cancle your Appointment<br/><br />Best Regards,<br />Trust Hospital Team</div></body></html>`,
                            'Trust Hospitals,Patient Appointment',
                            'Your appointment Re-Scheduled  successfully'
                        );
                    } catch (err) {
                        console.error("Error sending email:", err);
                    }
                }
                try {
                    await Users().generateMobileMsgBulk("+91" + userResponse?.data?.updatePatientAppointment
                        ?.PhoneNumber, `Thank you! Your appointment for a consultation has been successfully rescheduled on ${appDate} at ${userResponse?.data?.updatePatientAppointment?.AppointmentTime}. Your appointment ID is ${userResponse?.data?.updatePatientAppointment?.AppointmentID} - CLUSTERIT`, "success")
                } catch (err) {
                    console.error("Error sending SMS:", err);
                }
                if (userResponse && userResponse?.data?.updatePatientAppointment) {
                    setAlertMsg("Appointment updated successfully!");
                    setLoading(false);
                    resetForm();
                    setLoading(false);
                    // setSuccessMessage("Patient updated successfully!");

                    // Redirect based on user role
                    // if (logUserObject?.userId) {
                    //   navigate('/listpatients');
                    // } else {
                    //   navigate('/');
                    // }
                }
                // resetForm();
                // navigate("/listpatients");
            } else {
                setLoading(false);
                setErrMsg("Patient update failed!");
            }
            setSubmitting(false);
            // navigate('/listpatients');
            // if (logUserObject?.userId) {
            //   setLoading(false);
            //   alert("Appointment update successfully!");
            //   navigate('/listpatients');
            // } else {
            //   setLoading(false);
            //   alert("Appointment update successfully!");
            //   navigate('/');
            // }
        } catch (error) {
            setLoading(false);
            setErrMsg("An error occurred while updating the patient appointment.");
            alert("An error occurred. Please try again.");
            console.log(error);
        }
    };
    const maskEmail = (email: any) => {
        const emailParts = email.split("@");
        const localPart = emailParts[0]; // The part before '@'
        const domainPart = emailParts[1]; // The part after '@'
        // Get first 4 letters of the local part
        const firstFour = localPart.substring(0, 4);
        // Get last 2 letters of the local part
        const lastTwoBeforeAt = localPart.slice(-2);
        // Mask the middle portion with '*'
        const maskedPart = "*".repeat(localPart.length - 6); // 6 because 4 characters are shown at the start and 2 at the end
        // Construct the final masked email
        return `${firstFour}${maskedPart}${lastTwoBeforeAt}@${domainPart}`;
    };
    const maskPhoneNumber = (phone: any) => {
        const lastFourDigits = phone.slice(-4);  // Get last 4 digits
        const maskedPart = "*".repeat(6);        // Mask the first 6 digits
        // Construct the final masked phone number
        return `${maskedPart}${lastFourDigits}`;
    };

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerRunning(false);
        }
        // Clean up the interval when the component unmounts or the timer is stopped
        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);

    // Resend Otp to Email 
    const ResendEmailAppoinment = async () => {
        setOtpError("");
        const otpResp = await AppointmentId().OTPgenerate(getappointmentvalues?.EmailId);
        // console.log("otpResp",otpResp)
        if (!otpResp?.ok) {
            throw new Error(`Error: ${otpResp?.statusText}`);
        }
        else {
            setLoading(false)
            setAppointmentModal(true);
            setCurrentOTP("");
            setOtpStatus(true);
            setAppointmentSuccessMsg("OTP sent to your EmailId");
            setSubmitMsg("");
            setIsTimerRunning(true);
            setTimer(60);
        }
    }

    const closeModal = () => {
        setModal(false);
        setAppointmentModal(false);
    };


    const handleSubmit = async (values: PatientAppoinment, { setSubmitting, resetForm }: any) => {
        window.scrollTo(0, 0);
        setAppointmenttvalues(values);
        let errorMessage: any, maskedEmail: any, maskedPhone: any, GetInsertDataUpdate: any, appDate: any, appEncId: any;
        setExistMsg("");
        let check: any;
        const checkEmail: any = await userService().validateEmail(values?.EmailId.toLowerCase());
        const checkPhone: any = await userService().validatePhonenumber(values?.PhoneNumber);
        setCheckPhone(checkPhone);
        const emailAndPhone: any = await AppointmentId().checkExistingUsers(values?.EmailId, values?.PhoneNumber);
        // console.log({ checkEmail, checkPhone, emailAndPhone, values })
        // if (!emailAndPhone && emailAndPhone?.length === 0 && !checkEmail && checkEmail?.length === 0 && !checkPhone && checkPhone?.length === 0) {
        if (emailAndPhone?.length === 0 && checkEmail?.length === 0 && checkPhone?.length === 0) {
            // check = emailAndPhone;
            check = [];
        } else if (checkEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.EmailId === values?.EmailId && checkPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.PhoneNumber !== values?.PhoneNumber) {
            check = 'exist';
        } else {
            check = checkPhone?.existingPhoneUser?.data?.listUsers?.items;
        }
        let userResponse: any;
        const [date, time] = values?.AppointmentDate.split('T');

        try {
            setLoading(true);
            let newId;
            const appIDResponse = await AppointmentId().generateAppointmentID();
            if (appIDResponse?.status === 400) {
                newId = "TRUST001";
            } else if (appIDResponse?.status === 200) {
                const generatedID = appIDResponse?.data?.AppointmentID;
                // newId = generatedID + 1;
                const prefix = generatedID.slice(0, 5);
                const numericPart = generatedID.slice(5);
                const newNumericPart = (parseInt(numericPart, 10) + 1).toString().padStart(3, '0');
                newId = prefix + newNumericPart;
            }
            values.PaymentMode = 'offline';
            const formatEmail = values?.EmailId.toLowerCase();

            // const userResponse = await userService().getcreateAppointment(GetInsertDataUpdate);
            if (values?.PaymentMode === 'offline') {
                try {
                    // const check: any = await BulkServices().checkExistingUsers(userResponse?.data?.createPatientAppointment?.EmailId, userResponse?.data?.createPatientAppointment?.PhoneNumber);
                    let create: any, errmsg: any;
                    if (check?.length === 0) {
                        const createData = {
                            EmailId: formatEmail ? formatEmail : 'NA',
                            PhoneNumber: values?.PhoneNumber,
                            FirstName: values?.PatientName,
                            LastName: "NA",
                            userType: "G",
                            Role: "Patient",
                            resetPWD: "Yes",
                            Specialization: "NA",
                            Password: "NA",
                            countryCode: "+91",
                            Slug: "NA",
                            Status: 1,
                        }
                        create = !errorMessage && await userService().getcreateUser(createData);
                        // update the userType and patientID ( which is primary key reference in user table)
                        if (create) {
                            GetInsertDataUpdate = {
                                PatientName: values.PatientName,
                                // patientID: logUserObject?.Role === "Patient" ? logUserObject?.userId : 'NA',
                                // patientID: check?.length > 0 ? check[0]?.id : 'NA',
                                patientID: create?.data?.createUser?.id,
                                PhoneNumber: values?.PhoneNumber,
                                DoctorID: values?.DoctorID,
                                EmailId: formatEmail || 'N/A',
                                Message: values?.Message,
                                AppointmentDate: date,
                                AppointmentTime: time,
                                PaymentMode: 'offline',
                                // PaymentMode: values?.PaymentMode,
                                // Amount: values?.Amount,
                                Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
                                Address: values?.Address,
                                City: values?.City,
                                Street: values?.Street,
                                pinCode: values?.pinCode,
                                DOB: values?.DOB,
                                HouseNumber: values?.HouseNumber,
                                MaritalStatus: values?.MaritalStatus,
                                KYC: values?.KYC,
                                AppointmentID: newId,
                                // userType: values?.userType,
                                userType: check?.length > 0 ? check[0]?.userType : "G",
                                // PaymentStatus: values?.PaymentMode === 'online' ? '1' : '0'
                                PaymentStatus: '0',
                                //  Status: "open"
                            };
                            userResponse = await userService().getcreateAppointment(GetInsertDataUpdate);
                        }
                        setAlertMsg("Patient Appointment Booked successfully!");
                        setLoading(false);
                    }
                    //check the email and phone number
                    else if (check?.length === 1) {
                        // Retrieve the existing user details based on the phone number.
                        const existingPhone = checkPhone?.existingPhoneUser?.data?.listUsers?.items[0];
                        // Check if the provided email is not the same as the existing email.
                        if (formatEmail && existingPhone?.EmailId !== formatEmail) {
                            // Retrieve the existing email ID if it exists.
                            const existingEmail = checkEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.EmailId;
                            setExistingEmail(existingEmail);
                            // If the existing email is the same as the provided email, display an error message and stop submission.
                            if (existingEmail === formatEmail) {
                                setSubmitMsg("User already exists with this email ID. Please provide another email ID.");
                                // errmsg = "User already exists with this email ID. Please provide another email ID.";
                                // setLoading(false);
                                setSubmitting(false);
                                return;
                            } else {
                                // If the email is new, generate and send an OTP to the provided email.
                                const userResp = await AppointmentId().OTPgenerate(formatEmail)
                                try {
                                    if (!userResp?.ok) {
                                        // If OTP generation fails, throw an error.
                                        throw new Error(`Error: ${userResp?.statusText}`);
                                    } else {
                                        // OTP sent successfully; update state variables and notify the user.
                                        setLoading(false)
                                        setAppointmentModal(true);
                                        setSubmitting(false);
                                        setOtpStatus(true);
                                        // setAppointmentSuccessMsg("OTP sent to your EmailId");
                                        setAppointmentSuccessMsg(`Please provide the OTP Sent to your email ${formatEmail}`);
                                        setSubmitMsg("");
                                        setIsTimerRunning(true);
                                        // setUserName(formatEmail);
                                    }
                                } catch (error) {
                                    console.error(error)
                                }
                                // }
                            }
                        }
                        // If the phone number already exists, update user details like `userType`.
                        else {
                            const updateData = {
                                id: check[0]?.id,
                                userType: check[0]?.userType === "G" ? "E" : check[0]?.userType
                            }
                            // Update the user information in the users table.
                            const userUpdate = await userService().updateDoctorByData(updateData);
                            // Create an object with the updated appointment details.
                            GetInsertDataUpdate = {
                                PatientName: values?.PatientName,
                                // patientID: logUserObject?.Role === "Patient" ? logUserObject?.userId : 'NA',
                                // patientID: check?.length > 0 ? check[0]?.id : 'NA',
                                patientID: check[0]?.id,
                                PhoneNumber: values?.PhoneNumber,
                                DoctorID: values?.DoctorID,
                                EmailId: formatEmail ? formatEmail : 'N/A',
                                Message: values?.Message,
                                AppointmentDate: date,
                                AppointmentTime: time,
                                PaymentMode: 'offline',
                                // PaymentMode: values?.PaymentMode,
                                // Amount: values?.Amount,
                                Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
                                Address: values?.Address,
                                City: values?.City,
                                Street: values?.Street,
                                pinCode: values?.pinCode,
                                DOB: values?.DOB,
                                HouseNumber: values?.HouseNumber,
                                MaritalStatus: values?.MaritalStatus,
                                KYC: values?.KYC,
                                AppointmentID: newId,
                                // userType: values?.userType,
                                userType: userUpdate?.data?.updateUser?.userType,
                                // PaymentStatus: values?.PaymentMode === 'online' ? '1' : '0'
                                PaymentStatus: '0'
                            };
                            // Create a new appointment for the user using the updated data.
                            userResponse = await userService().getcreateAppointment(GetInsertDataUpdate);
                            // If the user details are updated successfully, update the user type in the appointment.
                            if (userUpdate) {
                                const updateAppointment = await AppointmentId().updateuserType(userUpdate?.data?.updateUser?.id, values?.PhoneNumber, userUpdate?.data?.updateUser?.userType);
                            }
                            setAlertMsg("Patient Appointment Booked successfully!");
                            setLoading(false);
                        }
                    }
                    // check exist email id
                    else if (check === 'exist') {
                        maskedEmail = maskEmail(checkEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.EmailId);
                        setExistMsg(`User already exists with ${maskedEmail}`);
                        // errorMessage = "User already exists with this email id";
                        setLoading(false);
                    }
                }
                catch (err) {
                    console.log("error creating user", err);
                }
                // after getting the reponse send sms,email and push notification
                if (userResponse?.data?.createPatientAppointment?.EmailId) {
                    const appDate = formatDate(userResponse?.data?.createPatientAppointment?.AppointmentDate)
                    const appEncId = await Users().encodeBase64(userResponse?.data?.createPatientAppointment?.AppointmentID)
                    try {
                        const mailResponse: any = await Users().generateEmailSMS(
                            userResponse?.data?.createPatientAppointment?.EmailId,
                            `<html><body><div>Welcome to Trust Hospitals!<br /><br />Thank you for contacting us,your appointment has been  booked successfully on ${appDate} at ${userResponse?.data?.createPatientAppointment?.AppointmentTime} and Your AppointmentId is ${userResponse?.data?.createPatientAppointment?.AppointmentID}. We appreciate your interest and will get back to you shortly.
                    <br/>Please <a href='${process.env.REACT_APP_HOST}appointment/${appEncId}'>click here</a> to Re-Schedule your Appointment<br />
                     Please <a href='${process.env.REACT_APP_HOST}appointmentHistory/${appEncId}'>click here</a> to cancle your Appointment<br/><br />Best Regards,<br />Trust Hospital Team</div></body></html>`,
                            'Trust Hospitals,Patient Appointment',
                            'Your appointment created successfully'
                        );
                    } catch (err) {
                        console.error("Error sending email:", err);
                    }
                }

                try {
                    const MobileSMSResponse = await Users().generateMobileMsgBulk(
                        "+91" + userResponse?.data?.createPatientAppointment?.PhoneNumber,
                        `Thank you! Your appointment for a doctor consultation has been successfully scheduled on ${appDate} at ${userResponse?.data?.createPatientAppointment?.AppointmentTime}. Your appointment ID is ${userResponse?.data?.createPatientAppointment?.AppointmentID} - CLUSTERIT`,
                        'Your appointment created successfully'
                    )
                } catch (err) {
                    console.error("Error sending SMS:", err);
                }

                if (userResponse && userResponse?.data) {
                    setAlertMsg("Patient Appointment Booked successfully!");
                    setLoading(false);
                }
                resetForm();
                setSubmitting(false);
                // logUserObject?.userId ? navigate('/listpatients') : navigate('/');
                //check if payment mode is online
            } else if (values?.PaymentMode === 'online') {
                const userPaymentresponse: any = await handlePay(GetInsertDataUpdate, setSubmitting, resetForm);
                // console.log({ userPaymentresponse });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error during form submission:', error);
            setSubmitting(false);
        }
    };

    const handleAppoinmentOTPSubmit = async () => {
        try {
            // Initial declarations
            let GetInsertDataUpdate: any, userResponse: any;
            let newId = ""; // Initialize `newId` with an empty string
            const [date, time] = getappointmentvalues?.AppointmentDate?.split('T');
            const formatEmail = getappointmentvalues?.EmailId?.toLowerCase();
            // Encrypt and retrieve local OTP
            // const encrypted = SHA256(CurrentOTP.toString()).toString();
            const localOTP = localStorage.getItem("appointmentOTP");
            // Compare the stored OTP with the encrypted OTP
            if (localOTP === CurrentOTP) {
                setAppointmentModal(false);
                localStorage.removeItem("appointmentOTP");
                const emailUpdate = await forgotPasswordServices().updateEmail(
                    getappointmentvalues?.EmailId,
                    getcheckPhone.existingPhoneUser?.data.listUsers.items[0].id
                );
                const existingPhone = getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.PhoneNumber;
                // Generate a new Appointment ID
                const appIDResponse = await AppointmentId().generateAppointmentID();
                if (appIDResponse?.status === 400) {
                    newId = "TRUST001";
                } else if (appIDResponse?.status === 200) {
                    const generatedID = appIDResponse?.data?.AppointmentID;
                    const prefix = generatedID.slice(0, 5);
                    const numericPart = generatedID.slice(5);
                    const newNumericPart = (parseInt(numericPart, 10) + 1).toString().padStart(3, '0');
                    newId = prefix + newNumericPart;
                }
                const updateData = {
                    id: getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.id,
                    userType: getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.userType === "G" ? "E" : getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.userType,
                };
                // Update userType in users table
                const userUpdate = await userService().updateDoctorByData(updateData);
                // Prepare appointment data for insertion
                GetInsertDataUpdate = {
                    PatientName: getappointmentvalues?.PatientName,
                    patientID: getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.id,
                    PhoneNumber: getappointmentvalues?.PhoneNumber,
                    DoctorID: getappointmentvalues?.DoctorID,
                    EmailId: formatEmail ? formatEmail : 'N/A',
                    Message: getappointmentvalues?.Message,
                    AppointmentDate: date,
                    AppointmentTime: time,
                    PaymentMode: 'offline',
                    Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
                    Address: getappointmentvalues?.Address,
                    City: getappointmentvalues?.City,
                    Street: getappointmentvalues?.Street,
                    pinCode: getappointmentvalues?.pinCode,
                    DOB: getappointmentvalues?.DOB,
                    HouseNumber: getappointmentvalues?.HouseNumber,
                    MaritalStatus: getappointmentvalues?.MaritalStatus,
                    KYC: getappointmentvalues?.KYC,
                    AppointmentID: newId,
                    userType: userUpdate?.data?.updateUser?.userType,
                    PaymentStatus: '0',
                };
                // Create user appointment
                await userService().getcreateAppointment(GetInsertDataUpdate);
                // Update the user appointments if the user update was successful
                if (userUpdate) {
                    await AppointmentId().updateuserType(
                        userUpdate?.data?.updateUser?.id,
                        getappointmentvalues?.PhoneNumber,
                        userUpdate?.data?.updateUser?.userType
                    );
                }
                setAlertMsg("Patient Appointment Booked successfully!");
                setLoading(false);

            } else {
                setOtpError("Invalid OTP. Please check the OTP and try again.");
            }
        } catch (error) {
            console.error("Error in OTP submission:", error);
            setLoading(false);
            setOtpError("An error occurred while submitting OTP. Please try again.");
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [alertMsg]);

    const handlePayment = async (options: any) => {
        // Ensure Razorpay is loaded
        if (!(window as any).Razorpay) {
            console.error("Razorpay is not loaded");
            return;
        }
        try {
            const rzp = new (window as any).Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error("Error in Razorpay payment:", error);
        }
    };

    const handlePay = async (GetInsertDataUpdate: any, setSubmitting: any, resetForm: any) => {

        // console.log('values', values);

        // if (logUserObject?.accessToken) {
        try {

            const amt: any = process.env.REACT_APP_APPOINTMENT_AMOUNT;
            const options: any = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                amount: amt * 100,
                currency: 'INR',
                name: 'Trust Hospitals',
                description: 'Course Description',

                handler: async (response: any) => {
                    setStorePaymentDetails(response?.razorpay_payment_id);

                    // Create appointment for online payment
                    const userResponse: any = response?.razorpay_payment_id && await userService().getcreateAppointment(GetInsertDataUpdate);

                    const paymentData = {
                        appointmentId: userResponse?.data?.createPatientAppointment?.id,
                        razorpay_payment_id: response?.razorpay_payment_id,
                        Amount: userResponse?.data?.createPatientAppointment?.Amount,

                    }
                    const PyResponse = await AppointmentId().PaymentDetails(paymentData);

                    const appDate = await formatDate(userResponse?.data?.createPatientAppointment?.AppointmentDate)
                    const appEncId = await Users().encodeBase64(userResponse?.data?.createPatientAppointment?.AppointmentID)

                    if (userResponse?.status === 200) {
                        if (userResponse?.data?.createPatientAppointment?.EmailId) {
                            try {
                                const SMSresponse: any = await Users().generateEmailSMS(
                                    userResponse?.data?.createPatientAppointment?.EmailId,
                                    `<html><body><div>Welcome to Trust Hospitals!<br /><br />Thank you for contacting us,your appointment has been  booked succesfully on ${appDate} at ${userResponse?.data?.createPatientAppointment?.AppointmentTime}  and Your AppointmentId is ${userResponse?.data?.createPatientAppointment?.AppointmentID}. We appreciate your interest and will get back to you shortly.
              <br/>Please <a href='${process.env.REACT_APP_HOST}appointment/${appEncId}'>click here</a> to Re-Schedule your Appointment<br />
               Please <a href='${process.env.REACT_APP_HOST}appointmentHistory/${appEncId}'>click here</a>to cancle your Appointmnet<br/><br />Best Regards,<br />Trust Hospital Team</div></body></html>`,
                                    'Trust Hospitals,Patient Appointment',
                                    'Your appointment created successfully'
                                );
                            }
                            catch (err) {
                                console.error("Error sending Email:", err);
                            }
                        }
                        try {
                            const MobileSMSResponse = await Users().generateMobileMsgBulk(
                                "+91" + userResponse?.data?.createPatientAppointment?.PhoneNumber,
                                `Thank you! Your appointment for a doctor consultation has been successfully scheduled on ${appDate} at ${userResponse?.data?.createPatientAppointment?.AppointmentTime}. Your appointment ID is ${userResponse?.data?.createPatientAppointment?.AppointmentID} - CLUSTERIT`,
                                'Your appointment created successfully'
                            )
                        } catch (err) {
                            console.error("Error sending SMS:", err);
                        }

                        if (PyResponse?.razorpay_payment_id) {
                            if (userResponse && userResponse?.data?.createPatientAppointment) {
                                setLoading(false)
                                // resetForm();
                                // setAlertMsg(true)
                                // setTimeout(() => {
                                alert('Appointment booked successfully');
                                if (logUserObject?.userId) {
                                    navigate('/listpatients');
                                } else {
                                    navigate('/');
                                }
                                // }, 1000); 
                                // Delay navigation by 1 second (1000ms)
                            }
                        }
                    }
                },
                prefill: {
                    email: 'Trust Hospitals',
                    contact: '9848022338',
                },
                notes: {
                    address: 'Trust Hospitals',
                },
                theme: {
                    color: '#528FF0',
                },
            };

            handlePayment(options);
            // }
        } catch (error) {
            console.error('Error payment:', error);
        }
    };


    const fields = [
        { name: 'pastHealthConditions', label: 'Past Health Conditions' },
        { name: 'foodAllergies', label: 'Food Allergies' },
        { name: 'CurrentMedications', label: 'Current Medications' },
        { name: 'ChronicIllnesses', label: 'Chronic Illnesses' },
        { name: 'SmokingStatus', label: 'Smoking Status' },
        { name: 'AlcoholConsumption', label: 'Alcohol Consumption' },
        { name: 'Diabetes', label: 'Sugar' },
        { name: 'bloodPressure', label: 'bloodPressure' },
        { name: 'heartAttack', label: 'heartAttack' },
        { name: 'pregnacy', label: 'pregnacy' }
    ];

    const handleClose: any = (alertMsg: any) => {
        if (logUserObject?.userId && alertMsg === "Appointment updated successfully!") {
            navigate("/ViewAppointment", { state: { phoneNumber: location?.state?.phoneNumber, patientID: location?.state?.patientID } })
        } else if (logUserObject?.userId) {
            navigate('/listpatients');
        } else {
            navigate('/');
        }
    }

    return (
        <div className="page-wrapper">
            {/* {alertMsg && <h2>Appointment booked successfully</h2>} */}
            {/* {loading ? (
                <Loader />
            ) : ( */}
            <>

                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta name="keywords" content={keywords} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                </Helmet>
                <section className="page-title text-center" style={{ backgroundImage: 'url(images/img-5.jpg)' }}>
                    <div className="container">
                        <div className="title-text">

                            <h1>appointment</h1>
                        </div>
                    </div>
                </section>
                <div>
                    <ul className="title-menu clearfix paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
                        <li>
                            <a href="/">Home &nbsp;/</a>
                        </li>
                        &nbsp;<li>Appointment</li>
                    </ul>
                </div>

                <section className="blog-section section1 style-three pb-0">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center">
                                <div className="contact-area style-two" style={{ width: '100%', margin: '0 auto' }}>
                                    {
                                        alertMsg &&
                                        <div className="alert alert-success alert-dismissible fade show alertMessage">
                                            <strong>Success!</strong> {alertMsg}
                                            <i className="alertClose fa fa-close" aria-hidden="true"
                                                onClick={() => { handleClose(alertMsg) }}
                                            // onClick={() => { logUserObject?.userId ? navigate('/listpatients') : navigate('/') }}
                                            ></i>
                                        </div>
                                    }

                                    <div className="section-title">
                                        <h3>Request Appointment </h3>
                                    </div>

                                    <Formik
                                        enableReinitialize
                                        initialValues={(location?.state?.patientId || slug) ? InputDetails : initialState}
                                        // validationSchema={!updateDoctor ? AppointmnetValidationSchema : null}
                                        validationSchema={AppointmnetValidationSchema(location?.state?.patientId)}
                                        onSubmit={!updateDoctor ? handleSubmit : handleUpdate}

                                    >
                                        {({ isSubmitting, handleChange, setFieldValue, values, setSubmitting, resetForm, errors }) => {
                                            let combinedDateTime: any;
                                            if (values?.AppointmentDate && values?.AppointmentTime) {
                                                const appDate: any = values?.AppointmentDate;
                                                const appTime: any = values?.AppointmentTime;
                                                const updDateTime = appDate.includes('T') ? appDate : appDate + 'T' + appTime;
                                                combinedDateTime = new Date(updDateTime);
                                            }
                                            setFieldValueRef.current = setFieldValue;
                                            const errorCount = Object.keys(errors).length;

                                            if (isSubmitting && errorCount > 0) {
                                                for (const key in errors) {
                                                    if (fieldRefs.current[key]) {
                                                        fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                                        break; // Stop at the first error we find
                                                    }
                                                }


                                            }

                                            return (
                                                <Form onChange={() => validateForm(values)} autoComplete='off'>
                                                    {successMessage && (
                                                        <div className="alert alert-success" role="alert" style={{ color: 'green' }}>
                                                            {successMessage}
                                                        </div>
                                                    )}
                                                    <p style={{ color: 'red' }}>{existMsg ? existMsg : ''}</p>

                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-12 col-xs-12" ref={(el) => (fieldRefs.current.PatientName = el)}>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <label htmlFor="PatientName" className="form-label">Patient Name*</label>
                                                                <Field name="PatientName" type="text" className="form-control" autoComplete="off" readOnly={logUserObject?.Role === "Patient" ? true : false} />
                                                                <ErrorMessage name="PatientName" component="div" className="errMsg" />
                                                            </div>
                                                            <div className="form-group" style={{ marginBottom: '10px' }} ref={(el) => (fieldRefs.current.EmailId = el)}>
                                                                <label htmlFor="EmailId" className="form-label">EmailId</label>
                                                                <Field
                                                                    name="EmailId"
                                                                    type="text"
                                                                    className="form-control"
                                                                    readOnly={location?.state?.patientId && logUserObject?.Role === "Patient" ? true : false}
                                                                    autoComplete="off" />
                                                                <ErrorMessage name="EmailId" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 col-xs-12" ref={(el) => (fieldRefs.current.DOB = el)}>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <label htmlFor="DateOfbirth" className="form-label">Date Of birth</label>
                                                                <Field
                                                                    name="DOB"
                                                                    type="date"
                                                                    className="form-control frmControl"
                                                                    placeholder="DateOfbirth"
                                                                    max={lastDayOfMonthString}
                                                                />

                                                            </div>
                                                            <div className="form-group" style={{ marginBottom: '10px' }} ref={(el) => (fieldRefs.current.pinCode = el)}>
                                                                <label htmlFor="PinCode" className="form-label">PinCode</label>
                                                                <Field
                                                                    name="pinCode"
                                                                    type="text"
                                                                    className="form-control frmControl" autoComplete="new-pincode"
                                                                />
                                                                <ErrorMessage name="PinCode" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                                            <div className="form-group" style={{ marginBottom: '10px' }} ref={(el) => (fieldRefs.current.PhoneNumber = el)}>
                                                                <label htmlFor="PhoneNumber" className="form-label">Phone Number*</label>
                                                                <Field
                                                                    name="PhoneNumber"
                                                                    type="tel"
                                                                    min={0}
                                                                    className="form-control"
                                                                    readOnly={location?.state?.patientId ? true : false}
                                                                    autoComplete="off" />
                                                                <ErrorMessage name="PhoneNumber" component="div" className="errMsg" />
                                                            </div>
                                                            <div className="form-group" style={{ marginBottom: '10px' }} ref={(el) => (fieldRefs.current.DoctorID = el)}>
                                                                <label htmlFor="Doctor" className="form-label">Doctor</label>
                                                                <Field
                                                                    as="select"
                                                                    name="DoctorID"
                                                                    className="form-control"
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                        handleDoctorChange(e, '', setFieldValue);
                                                                    }}
                                                                >
                                                                    <option>Choose Doctor</option>
                                                                    {doctors?.map((doctor: any) => (

                                                                        <option key={doctor?.id} value={doctor?.id}>
                                                                            {doctor?.FirstName + " " + doctor?.LastName}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                {doctorUnavailableMessage && (
                                                                    <div style={{ fontSize: '12px', marginTop: '5px', color: 'red' }}>
                                                                        {doctorUnavailableMessage}
                                                                    </div>
                                                                )}
                                                                <ErrorMessage name="DoctorID" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                                            <div className="form-group" style={{ marginBottom: '10px' }} ref={(el) => (fieldRefs.current.MaritalStatus = el)}>
                                                                <label htmlFor="Marital Status" className="form-label">Marital Status</label>
                                                                <Field as="select" name="MaritalStatus" className="form-select" value={values?.MaritalStatus}
                                                                    onChange={(e: any) => setFieldValue("MaritalStatus", e?.target?.value)}>
                                                                    <option value="" label="Select marital status" />
                                                                    <option value="Single" label="Single" />
                                                                    <option value="Married" label="Married" />
                                                                    <option value="Divorced" label="Divorced" />
                                                                    <option value="Widowed" label="Widowed" />
                                                                </Field>
                                                            </div>
                                                            <div className="form-group" style={{ marginBottom: '10px' }} >
                                                                <label htmlFor="KYC[0].heardFrom" className="form-label">Where have you heard about us?</label>
                                                                <Field as="select" name="KYC[0].heardFrom" className="form-select">
                                                                    <option value="">Select an option</option>
                                                                    <option value="Friends">Friends</option>
                                                                    <option value="Social Media">Social Media</option>
                                                                    <option value="Family members">Family members</option>
                                                                    <option value="None of the above">None of the above</option>
                                                                </Field>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                                            <div className="form-group" style={{ marginBottom: '10px' }} ref={(el) => (fieldRefs.current.AppointmentDate = el)}>
                                                                <label htmlFor="AppointmentDate" className="form-label">Select Appointment*</label>
                                                                {availableMessage && (
                                                                    <div style={{ fontSize: '12px', color: 'green' }}>
                                                                        {availableMessage}
                                                                    </div>
                                                                )}
                                                                <div>
                                                                    <DatePicker
                                                                        // selected={values.AppointmentDate ? new Date(values.AppointmentDate) : null}
                                                                        selected={combinedDateTime ? combinedDateTime : values?.AppointmentDate ? new Date(values?.AppointmentDate) : null}

                                                                        onChange={(date: any) => handleDateChange(date, setFieldValue)}
                                                                        minDate={new Date()}
                                                                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                                                                        filterDate={isDateAvailable}
                                                                        dateFormat="yyyy-MM-dd'T'HH:mm"
                                                                        placeholderText="Select your appointment date and time *"
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm"
                                                                        timeIntervals={timeSlot}
                                                                        timeCaption="time"
                                                                        includeTimes={availableTimes}
                                                                        name="AppointmentDate"
                                                                        autoComplete="off" />


                                                                    {/* <DatePicker
                  selected={combinedDateTime ? combinedDateTime : values?.AppointmentDate ? new Date(values?.AppointmentDate) : null}
                  onChange={(date) => handleDateChange(date, setFieldValue)}
                  minDate={new Date()}
                  maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                  filterDate={isDateAvailable}
                  dateFormat="yyyy-MM-dd'T'HH:mm"
                  placeholderText="Select your appointment date and time *"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={timeSlot}
                  timeCaption="time"
                  includeTimes={availableTimes}
                  name="AppointmentDate"
                  autoComplete="off"
                  className="form-select selectui"
                  customInput={
                    <div className="input-group mt-10">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select your appointment date and time *"
                        value={combinedDateTime
                          ? combinedDateTime.toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', ' ')
                          : values?.AppointmentDate
                            ? new Date(values?.AppointmentDate).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', ' ')
                            : ''
                        }

                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <FaCalendarAlt />
                        </span>
                      </div>
                    </div>
                  }
                /> */}

                                                                </div>
                                                                <ErrorMessage name="AppointmentDate" component="div" className="errMsg" />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="mb-3 mbmob-0" ref={(el) => (fieldRefs.current.City = el)}>
                                                                <label htmlFor="City" className="form-label">City</label>
                                                                <Field
                                                                    name="City"
                                                                    type="text"
                                                                    className="form-control frmControl"
                                                                    autoComplete="new-city" />
                                                                {/* <ErrorMessage name="City" component="div" className="errMsg" /> */}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="mb-3 mbmob-0" ref={(el) => (fieldRefs.current.Address = el)}>
                                                                <label htmlFor="Address" className="form-label">Address</label>
                                                                <Field
                                                                    name="Address"
                                                                    type="text"
                                                                    className="form-control frmControl" />
                                                                {/* <ErrorMessage name="pinCode" component="div" className="errMsg" /> */}
                                                            </div>
                                                        </div>




                                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <label htmlFor="Message" className="form-label">Description</label>
                                                                <Field
                                                                    name="Message"
                                                                    as="textarea"
                                                                    cols="40"
                                                                    rows="3"
                                                                    className="form-control"
                                                                    aria-invalid="false"
                                                                    placeholder="Description" />
                                                            </div>

                                                            <FieldArray name="KYC">
                                                                {() => (
                                                                    <div className="row"> <br />
                                                                        {fields?.map((field, index) => {

                                                                            const fieldName = field?.name;
                                                                            const descriptionFieldName = `${fieldName}Description`;

                                                                            // Type definition for KYCField
                                                                            type KYCField = 'pastHealthConditions' | 'foodAllergies' | 'CurrentMedications' | 'ChronicIllnesses' | 'SmokingStatus' | 'AlcoholConsumption' | 'Diabetes' | 'bloodPressure' | 'heartAttack' | 'pregnacy';

                                                                            // Handle rendering for SmokingStatus and AlcoholConsumption as radio buttons
                                                                            if (['SmokingStatus', 'AlcoholConsumption', 'Diabetes', 'bloodPressure', 'heartAttack', 'pregnacy'].includes(fieldName)) {
                                                                                return (


                                                                                    <div key={index} className="col-md-12" style={{ top: '20px' }}>
                                                                                        <label>{field?.label}</label> <br />
                                                                                        <label style={{ marginRight: '10px' }}>
                                                                                            <Field
                                                                                                type="radio"
                                                                                                name={`KYC[0].${fieldName}`}
                                                                                                value="1"
                                                                                                checked={String(values?.KYC?.[0]?.[fieldName as KYCField]) === '1'}
                                                                                                onChange={() => setFieldValue(`KYC[0].${fieldName as KYCField}`, '1')}
                                                                                                className="mr-1" />
                                                                                            Yes
                                                                                        </label>
                                                                                        <label>
                                                                                            <Field
                                                                                                type="radio"
                                                                                                name={`KYC[0].${fieldName}`}
                                                                                                value="0"
                                                                                                checked={String(values?.KYC?.[0]?.[fieldName as KYCField]) === '0'}
                                                                                                onChange={() => setFieldValue(`KYC[0].${fieldName as KYCField}`, '0')}
                                                                                                className="mr-1" />
                                                                                            No
                                                                                        </label>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return (
                                                                                <div key={index} className='col-md-12'>
                                                                                    <label>
                                                                                        <Field
                                                                                            type="checkbox"
                                                                                            style={{ width: '15px', height: '15px', display: 'inline', marginRight: '10px', marginTop: '20px' }}
                                                                                            name={`KYC[0].${field?.name}`}
                                                                                            checked={String(values?.KYC?.[0]?.[field?.name as KYCField]) === '1'}
                                                                                            onChange={() => {
                                                                                                const newValue = String(values?.KYC?.[0]?.[field?.name as KYCField]) === '1' ? '0' : '1';
                                                                                                setFieldValue(`KYC[0].${field?.name as KYCField}`, newValue);
                                                                                            }} />
                                                                                        {field?.label}
                                                                                    </label>
                                                                                    {String(values?.KYC?.[0]?.[field?.name as KYCField]) === '1' && !['SmokingStatus', 'AlcoholConsumption']?.includes(fieldName) && (
                                                                                        <div>
                                                                                            {/* <label htmlFor={`KYC[0].${descriptionFieldName}`}>
                                                Description for {field?.label}</label><br/> */}
                                                                                            <Field
                                                                                                as="textarea"
                                                                                                name={`KYC[0].${descriptionFieldName}`}
                                                                                                placeholder={`Enter description for ${field?.label}`}
                                                                                                rows={5}
                                                                                                style={{ resize: 'none', width: '100%' }} />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </FieldArray>


                                                            {/* <div className="mb-3">
                                  <label htmlFor="PaymentMode" className="form-label" style={{ marginTop: '20px' }}>Make Payment*</label>
                                  <div className="d-flex align-items-center" style={{ display: 'flex' }}>
                                    <div className="form-check me-3   " style={{ marginBottom: '0', display: 'flex', alignItems: 'center' }}>
                                    
                                      <Field
                                        type="radio"
                                        name="PaymentMode"
                                        value="offline"
                                        className="form-check-input radioleft "
                                        id="offline"
                                        onClick={() => {
                                          setShowRazorpay(false);
                                        }}
                                        disabled={!isFormComplete || update} />
                                      <label className="form-check-label ms-2 ml-10" htmlFor="offline" style={{ height: '18px' }}>
                                        Offline
                                      </label>
                                    </div>
                                    <div className="form-check" style={{ marginBottom: '0', display: 'flex', alignItems: 'center' }}>
                                      <Field
                                        type="radio"
                                        name="PaymentMode"
                                        value="online"
                                        className="form-check-input"
                                        id="online"
                                        onClick={() => {
                                          setShowRazorpay(true);
                                        }}
                                        disabled={!isFormComplete || update} />
                                      <label className="form-check-label ms-2  ml-10" htmlFor="online" style={{ height: '18px', width: '15px' }}>
                                        Online
                                      </label>
                                    </div>
                                  </div>
                                  <ErrorMessage name="PaymentMode" component="div" className="errMsg" /> */}
                                                            {/* {showRazorpay && (
                <div id="razorpay-container">
                  <button onClick={() => handlePay(values, setSubmitting, resetForm)} type="button" className="btn btn-primary">
                    Make Payment Here
                  </button>
                </div>
              )} */}
                                                            {/* <ErrorMessage name="paymentMethod" component="div" className="errMsg" /> */}
                                                            {/* </div> */}

                                                            {/* {values?.PaymentMode === 'offline' && (
                                  <div className="form-group">
                                    <Field
                                      type="number"
                                      name="Amount"
                                      className="form-control"
                                      placeholder="Enter amount*"
                                      value={process.env.REACT_APP_APPOINTMENT_AMOUNT}
                                      disabled={update} />
                                    <ErrorMessage name="Amount" component="div" className="text-danger" />
                                  </div>
                                )}
                                {values?.PaymentMode === 'online' && (
                                  <div className="form-group">
                                    <Field
                                      type="number"
                                      name="Amount"
                                      className="form-control"
                                      placeholder="Amount"
                                      value={process.env.REACT_APP_APPOINTMENT_AMOUNT}
                                      disabled />
                                    <ErrorMessage name="Amount" component="div" className="text-danger" />
                                  </div>
                                )} */}

                                                            <div className="reg">
                                                                <button type="submit" className="btn" disabled={isSubmitting} style={{ marginTop: '30px', height: '34px', border: 'none', fontWeight: '500px' }}>
                                                                    {!updateDoctor ? "Register" : "update"}
                                                                </button>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center" style={{ marginTop: '20px' }}>
                                <div className="appointment">
                                    <figure className="w-80 h-80">
                                        <img src={`${process.env.REACT_APP_HOST}images/background/appointmentreg.jpg`} alt="Appointment" className="img-fluid appointment-image" style={{ height: '728px' }} />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div>
                    {getappointmentModal &&
                        <ModelComponentAppoinment setCurrentOTP={setCurrentOTP} appointmentSuccessMsg={appointmentSuccessMsg} timer={timer} OTPErr={OTPErr} handleAppoinmentOTPSubmit={handleAppoinmentOTPSubmit} ResendEmailAppoinment={ResendEmailAppoinment} closeModal={closeModal} getappointmentModal={getappointmentModal} CurrentOTP={CurrentOTP} />}
                </div>
            </>
            {/* // )} */}

        </div>
    );
}

export default AppointmentPage;

