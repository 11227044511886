// import React, { useState, useEffect } from 'react';
// import { Container, Grid, TextField, Button, Typography } from '@mui/material';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { Formik, Field, Form, ErrorMessage } from 'formik';
// import { SHA256 } from 'crypto-js';
// import userService from '../../../services/users/user.service';
// import { loginValidationSchema } from './loginValidationSchema';
// import { useIdleTimeout } from '../../views/shared/header/sessionExpire';
// import './login.css';
// import { Helmet } from 'react-helmet';

// interface FormValues {
//   userName: string;
//   password: string;
// }

// const initialValues: FormValues = {
//   userName: '',
//   password: '',
// };
// interface compProps {
//   title: string;
//   description: string;
// }

// // function Login() {
//   const Login: React.FC<compProps>  = ({ title, description }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [err, setErr] = useState('');
//   const isIdle = useIdleTimeout(1000 * 900);
//   const isActive = useIdleTimeout(1000 * 900);
//   const [token, setToken] = useState<any>();
//   const logUser: any = localStorage.getItem('userData');
//   const logUserObject = JSON.parse(logUser);
//   const [registerAdmin, setRegisterAdmin] = useState(true);

//   useEffect(() => {
//     const adminRegister = async () => {
//       if (registerAdmin === true) {
//         setRegisterAdmin(false);
//       } else {
//         await userService().MasterAdmin();
//         console.log("RegisterAdmin", registerAdmin);
//       }
//     };
//     adminRegister();
//   }, [registerAdmin]);

//   const handleLogin = async (values: FormValues, { setSubmitting, setErrors }: any) => {
//     try {
//       const { userName, password } = values;
//       const hashedPassword = SHA256(password).toString();

//       const userResps: any = await userService().validateLogin(userName, hashedPassword);

//       console.log('userResps in login', userResps);

//       if (userResps?.status === 400) {
//         setErr('User not registered with us / Invalid Credentials');
//       } else if (userResps?.data?.accessToken) {
//         const { accessToken, details, userRole, PhoneNumber, userName } = userResps.data;
//         const userData = { accessToken, userId: details, Role: userRole, PhoneNumber, userName };
//         localStorage.setItem('userData', JSON.stringify(userData));
//         console.log("userData", userData);
//         if (location?.state?.url) {
//           navigate(location?.state?.url);
//         } else {
//           navigate('/dashboard');
//         }
//       }
//       if ('data' in userResps && userResps.data) {
//         // Handle successful response if needed
//       }

//       if ('msg' in userResps) {
//         setErrors({ userName: userResps.msg || 'User not registered with us / Invalid Credentials' });
//       }
//     } catch (error) {
//       console.error('Error during login:', error);
//       setErrors({ userName: 'An error occurred during login' });
//     }
//   };

//   return (

//     <>
//       <Helmet>
//     <title>{title}</title>
//     <meta name="description" content={description} />
//   </Helmet>
//       <section
//         className="page-title text-center"
//         style={{ backgroundImage: "url(images/background/banner.jpg)" }}
//       >
//         <Container>
//           <div className="title-text">
//             <h1>Login</h1>
//           </div>
//         </Container>
//       </section>
//       <div>
//         <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
//           <li>
//             <a href="/">Home /&nbsp;</a>
//           </li>
//           <li>Login</li>
//         </ul>
//       </div>
//       <Container>
//         <Grid container justifyContent="center" alignItems="center" style={{ height: '75vh' }}>
//           <Grid item md={4} style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
//             <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>Login</Typography>
//             <Formik
//               initialValues={initialValues}
//               validationSchema={loginValidationSchema}
//               onSubmit={handleLogin}
//             >
//               {({ handleSubmit, isSubmitting }) => (
//                 <Form onSubmit={handleSubmit}>
//                   <Field
//                     as={TextField}
//                     fullWidth
//                     type="text"
//                     name="userName"
//                     label="Email/Phone"
//                     variant="outlined"
//                     placeholder="Enter your Email/Phone"
//                     autoComplete='off'
//                     helperText={<ErrorMessage name="userName" className="error-message" />}
//                   />
//                   <Field
//                     as={TextField}
//                     fullWidth
//                     type="password"
//                     name="password"
//                     label="Password"
//                     variant="outlined"
//                     autoComplete='off'
//                     placeholder="Enter your password"
//                     helperText={<ErrorMessage name="password" className="error-message" />}
//                   />
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     fullWidth
//                     disabled={isSubmitting}
//                     style={{ marginTop: '20px', backgroundColor: '#98B868',fontSize:'14px' }}
//                   >
//                     Login
//                   </Button>
//                   <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
//                     <Typography variant="body2" onClick={() => navigate('/Register')} style={{ cursor: 'pointer',fontSize:'14px' }}>
//                       Create Account
//                     </Typography>
//                     <Typography variant="body2" onClick={() => navigate('/forgotpassword')} style={{ cursor: 'pointer',fontSize:'14px' }}>
//                     Forgot Password
//                   </Typography>
//                   </div>
//                 </Form>
//               )}
//             </Formik>
//             {err && <Typography variant="body1" style={{ color: 'red', marginTop: '10px', textAlign: 'center',fontSize:'14px'}}>{err}</Typography>}
//           </Grid>
//         </Grid>
//       </Container>
//     </>
//   );
// }

// export default Login;
























import React, { useState, useEffect } from 'react';
import { Container, Grid, TextField, Button, Typography, Modal, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SHA256 } from 'crypto-js';
import userService from '../../../services/users/user.service';
import { loginValidationSchema } from './loginValidationSchema';
import { useIdleTimeout } from '../../views/shared/header/sessionExpire';
import './login.css';
import forgotPasswordServices from '../../../services/ForgotPasswordServices';
import Users from '../../../utils/users';
import OTPServices from '../../../services/OtpServices';
import { Helmet } from 'react-helmet';
interface compProps {
  title: string;
  description: string;
  keywords: string;
}
interface FormValues {
  userName: string;
  password: string;
}

const initialValues: FormValues = {
  userName: '',
  password: '',
};

// function Login() {
const Login: React.FC<compProps> = ({ title, description, keywords }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [err, setErr] = useState("");
  const [errors, setErrors] = useState({});
  const isIdle = useIdleTimeout(1000 * 900);
  const isActive = useIdleTimeout(1000 * 900);
  // console.log("isIdle", isIdle);
  const [token, setToken] = useState<any>();
  const logUser: any = localStorage.getItem("userData");
  const [successMsg, setSuccessMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const logUserObject = JSON.parse(logUser);
  const [registerAdmin, setRegisterAdmin] = useState(true);
  const [errSubmitMsg, setSubmitMsg] = useState("");
  const [getModal, setModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [timer, setTimer] = useState<number>(60);
  const [currentUserName, setCurrentUserName] = useState('');
  const [response, setResponse] = useState<any>();
  const [otpError, setOtpError] = useState("");
  const [otp, setOtp] = useState<string>("");
  const [userloginDetails, setUserLoginDetails] = useState<any>([]);
  const [showMsg, setShowMsg] = useState("");

  useEffect(() => {
    const adminRegister = async () => {
      if (registerAdmin === true) {
        setRegisterAdmin(false);
      } else {
        await userService().MasterAdmin();
        console.log("RegisterAdmin", registerAdmin);
      }
    };
    adminRegister();
  }, [registerAdmin]);

  let intervalId: NodeJS.Timeout | null = null;

  const setTimerDetails = () => {
    if (intervalId === null) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalId as NodeJS.Timeout);
            intervalId = null;
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
  };


  const userhandleLogin = async (values: FormValues) => {
    let type: any;
    // console.log("values", values);
    setLoader(true);

    const userLoginResp: any = await Users().handleLogin(values, setErrors, setErr, location?.state?.url, navigate);
    console.log("userLoginResp", userLoginResp);


    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[1-9]\d{1,14}$/; // This is a simple example, adapt as needed for your phone number format

    if (emailPattern.test(userLoginResp?.userName)) {
      type = 'email';
    } else if (phonePattern.test(userLoginResp?.userName)) {
      type = 'phone';
    }
    // console.log("type", type)
    const sendOtpEmail: any = await forgotPasswordServices().SendEmail(userLoginResp?.userName, userLoginResp?.userName, type);
    setShowMsg(sendOtpEmail?.msg);
    sendOtpEmail?.status === 200 && setModal(true)
    setTimer(60);
    setTimerDetails();
    setCurrentUserName(userLoginResp?.userName)

  };

  const handleOTPSubmit = async () => {

    try {
      setLoader(true);
      const getOTP: any = await OTPServices().validateotpTwoStep(currentUserName, otp);
      console.log("getOTP", getOTP);
      setLoader(false);
      if (getOTP && getOTP?.data?.listUsers.items[0]?.Otp === otp) {
        const { accessToken, details, userRole, PhoneNumber, userName } = getOTP.data.listUsers.items[0];
        const userData = { accessToken, userId: details, PhoneNumber, userName };
        // console.log("userData", userData);
        localStorage.setItem('userData', JSON.stringify(userData));
        const userTwoAuth: any = await Users().handleTwoStepOTP({ userName: currentUserName, otp: otp }, setErrors, setErr, location?.state?.url, navigate);
        setModal(false);
        setTimer(60);
        setTimerDetails();
        // console.log("userResps", userTwoAuth);
        if (userTwoAuth) {
          const userResps: any = await Users().handleLogin({ userName: currentUserName, password: '' },
            setErrors, setErr, location?.state?.url, navigate);
          // console.log("userResps",userResps)
          if (userResps) {
            navigate('/DashBoard');
          }
        }
      } else {
        setOtpError("Invalid OTP");


      }
    } catch (error) {
      console.error('Error during OTP validation:', error);
      setOtpError("An error occurred during OTP validation");
    }
  };

  const ResendEmail = async () => {
    const userResponse: any = await forgotPasswordServices().SendEmail(currentUserName);
    console.log("userResponse", userResponse);
    setTimer(60);
    setTimerDetails();
  };
  const closeModal = () => {
    setModal(false);
  };


  return (
    <>
      <Helmet>
          <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
          </Helmet>

      <section
        className="page-title text-center"
        style={{ backgroundImage: "url(images/background/banner.jpg)" }}
      >
        <Container>
          <div className="title-text">
            <h1>Login</h1>
          </div>
        </Container>
      </section>
      <div>
        <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
          <li>
            <a href="/">Home /&nbsp;</a>
          </li>
          <li>Login</li>
        </ul>
      </div>

      <Container>
        <Grid container justifyContent="center" alignItems="center" style={{ height: '75vh' }}>
          <Grid item md={4} style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>Login</Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={loginValidationSchema}
              onSubmit={userhandleLogin}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <Field
                    as={TextField}
                    fullWidth
                    type="text"
                    name="userName"
                    label="Email/Phone"
                    variant="outlined"
                    placeholder="Enter your Email/Phone"
                    autoComplete='off'
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'black', // Change border color to black when focused
                        },
                      },
                      '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                          color: 'black', // Change label color to black when focused
                        },
                      },
                    }}
                    helperText={<ErrorMessage name="userName" className="error-message" />}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    type="password"
                    name="password"
                    label="Password"
                    variant="outlined"
                    autoComplete='off'
                    placeholder="Enter your password"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'black', // Change border color to black when focused
                        },
                      },
                      '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                          color: 'black', // Change label color to black when focused
                        },
                      },
                    }}
                    helperText={<ErrorMessage name="password" className="error-message" />}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={isSubmitting}
                    style={{ marginTop: '20px', backgroundColor: '#98B868', fontSize: '14px' }}
                  >
                    Login
                  </Button>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                    <Typography variant="body2" onClick={() => navigate('/register')} style={{ cursor: 'pointer', fontSize: '14px' }}>
                      Create Account
                    </Typography>
                    <Typography variant="body2" onClick={() => navigate('/forgotpassword')} style={{ cursor: 'pointer', fontSize: '14px' }}>
                      Forgot Password
                    </Typography>
                  </div>
                </Form>
              )}
            </Formik>
            {err && <Typography variant="body1" style={{ color: 'red', marginTop: '10px', textAlign: 'center', fontSize: '14px' }}>{err}</Typography>}
          </Grid>
        </Grid>
      </Container>

      {/* <Modal open={getModal} onClose={setModal}>
      
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          
          <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: '8px', textAlign: 'center', maxWidth: 400 }}>
          <span className="close" onClick={closeModal}>
            &times;
          </span>
            <Typography variant="h6">OTP Validation</Typography>
            {otpError && (
              <Typography color="error" sx={{ mt: 2 }}>
                {otpError}
              </Typography>
            )}
            <TextField
              label="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              sx={{ mt: 2 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleOTPSubmit}>
                Submit
              </Button>
              <Button variant="outlined" color="secondary" onClick={ResendEmail}>
                Resend OTP
              </Button>
            </Box>
            {timer > 0 && (
              <Typography sx={{ mt: 2 }}>
                Resend OTP in {timer} seconds
              </Typography>
            )}
          </Box>
        </Box>
      
      </Modal> */}

      <Modal open={getModal} onClose={closeModal}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: '8px', textAlign: 'center', maxWidth: 400 }}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <Typography variant="h6" style={{ color: '#2cbcbc', marginBottom: '20px' }}>OTP Validation</Typography>
            <p className='text-success text-center' style={{ fontSize: '15px', color: 'red' }}>{showMsg}</p>
            {otpError && (
              <Typography color="error" sx={{ mt: 2 }}>
                {otpError}
              </Typography>
            )}
            <TextField
              label="Enter Your OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              sx={{ mt: 2 }}
              autoComplete="off"
            />
            {timer > 0 ? (
              <>
                <Typography sx={{ mt: 2 }}>
                  Resend OTP in {timer} seconds
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                  <Button variant="contained" color="primary" onClick={handleOTPSubmit}>
                    Submit
                  </Button>
                </Box>
              </>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Typography color="textSecondary">The OTP has expired. Please click the link to resend the OTP.</Typography>
                <Button variant="outlined" sx={{ mt: 2, borderColor: '#2cbcbc', color: '#2cbcbc' }} onClick={() => { ResendEmail(); setTimer(60); }} >
                  Click here to resend
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>


    </>
  );
}

export default Login;