import React, { useEffect, useState } from 'react';
import getblogs from '../../../services/Blogs';
import { Helmet } from 'react-helmet';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Loader from '../shared/loadercomponent';

interface compProps {
  title: string;
  description: string;
}

const BlogDetails: React.FC<compProps> = ({ title, description }) => {

  const navigate = useNavigate();

  const location = useLocation();
  // const [singleBlog, setSingleBlog] = useState<any>();
  // const [blogItems, setBlogItems] = useState<any>([]);
  const [blogsItems, setBlogItems] = useState<any>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const { slug } = useParams();
  const [singleBlog, setSingleBlog] = useState<any>();
  const [loading, setLoading] = useState(true);

  const listBlogs = async () => {
    try {
      const response = await getblogs().ActiveBlogList();
      // console.log("filteredBlogs", filteredBlogs);
      const categoryname = await getblogs().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.catergory.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });

      console.log("updatedResponse", response)
      setBlogItems(updatedResponse);
      const categoryIds = updatedResponse.map((item: any) => item?.catergory);
      // console.log("categoryIds", categoryIds);

      // category List

      const catResponse = await getblogs().CategoryList();
      setCategories(catResponse);

      console.log("catResponse", catResponse);



    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  async function ProvInfo() {
    setLoading(true);
    // const SingleBlog = await getblogs().getSingleBlog(location?.state?.id);
    const singleBlog: any = (await getblogs().BlogList()).find((blog: any) => blog?.Slug === slug);
    setSingleBlog(singleBlog);
    setLoading(false);
  }
  // console.log("blogItems", blogItems);


  useEffect(() => {
    ProvInfo();
    listBlogs();
  }, [])
  const removeHtmlTags = (str: any) => {
    return str && str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const countByCategory = blogsItems?.reduce((acc: any, blog: any) => {
  //   blog?.catergory?.forEach((catId: any) => {
  //     if (!acc[catId]) {
  //       acc[catId] = 0;
  //     }
  //     acc[catId]++;
  //   });
  //   return acc;
  // }, {});

  const countByCategory = blogsItems && blogsItems?.reduce((acc: any, blog: any) => {
    const categoryIds = blog?.catergory[0]
    const splitString = categoryIds.includes(',') ? categoryIds.split(',') : [categoryIds];

    // console.log("blog", splitString)

    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });

    return acc;
  }, {});

  const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/blog/blog2.jpg`;


  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
      (day % 10 === 2 && day !== 12) ? 'nd' :
        (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

    return `${day}${suffix} ${month} ${year}`;
  };

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <><Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet><section className="page-title text-center" style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/background/bannerr1.jpg` }}>
            <div className="container">
              <div className="title-text">
                <h1>{singleBlog?.title}</h1>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
                <a onClick={() => navigate("/")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Home  /&nbsp;</a>
              </li>
              <li>
                <a onClick={() => navigate("/blogs")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Blogs  /&nbsp;</a>
              </li>

              <li>{singleBlog?.title}</li>
            </ul>
          </div><section className="blog-section section style-four style-five">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-sm-12 col-xs-12">
                  <div className="left-side">
                    <div className="item-holder">
                      <div className="image-box">
                        <figure>
                          {/* <a href="Singleblog"> */}
                          {/* <img src="images/blog/blog4.jpg" alt="" />*/}

                          <img
                            className="flaticon-tooth"
                            // src={singleBlog?.blogImage[0] ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleBlog?.blogImage[0]}` : 'images/blog2.jpg'}
                            src={singleBlog?.bolgImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleBlog?.bolgImage[0]}` : bgImageStylesingle}
                            alt={singleBlog?.title}
                            width="40%"
                            style={{ marginBottom: '9px', width: '50%' }} />
                          {/* </a> */}
                        </figure>
                      </div>

                      <div className="content-text">
                        {/* <a href="Singleblog"> */}
                        <h5>{singleBlog?.title}</h5>
                        {/* </a> */}
                        <span>{formatDate(singleBlog?.createdAt)}</span>
                        {/* <span>By HariKrishna / {singleBlog?.createdAt}</span> */}
                        {/* <p>{singleBlog?.description}</p> */}
                        <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleBlog?.description }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6>Categories</h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">
                          <li style={{ cursor: 'pointer' }} onClick={() => navigate('/blogs')}>All</li>
                          {categories?.map((item: any) => {
                            // console.log("countByCategory", countByCategory);
                            const blogCount = countByCategory[item?.id] || 0;
                            return (
                              <li key={item?.id} className="capitalTxt" onClick={() => { navigate(`/blogs`, { state: { category: item?.id } }); }} style={{ cursor: 'pointer' }}>

                                {item?.name.toLowerCase()} <span>({blogCount})</span>
                              </li>
                            );
                          }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section><div className="scroll-to-top scroll-to-target" data-target=".header-top">
            <span className="icon fa fa-angle-up"></span>
          </div></>
      )}
    </div>
  );
};

export default BlogDetails;