import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Header from './components/views/Header/header';
import Footer from './components/views/Footer/footer';
import Home from './components/views/Home/home';
import ServicePage from './components/views/ServicePage/ServicePage';
import Team from './components/views/Team/Team';
import About from './components/views/About/about';
import Contact from './components/views/contact/contact';
import Appointment from './components/views/PatientAppointment/appointment';
import Blog from './components/views/Blogs/blog';
import Singleblog from './components/views/Blogs/singleblog';
import Gallery from './components/views/Gallery/gallery';

import './App.css';
import Login from './components/users/Login';
import Register from './components/users/Register';
import DoctorRegister from './components/users/Register/DoctorRegister';
import ListDoctor from './components/views/ListDoctors';
import ListPatient from './components/views/ListPatient/PatientList';
import Faq from './components/views/FAQ/faq';
// import ListPatientAppointments from './components/views/PatientAppointment/ListAppointments';
import ListAllPatients from './components/views/ListPatient/listRegisterPatients';
import Dashboard from './components/views/Dashboard/dashboard';
import ListReviews from './components/views/Reviews/listreviews';
import ReviewForm from './components/views/Reviews';
import ListContacts from './components/views/contact/contactusList';
import ForgotPassword from './components/ForgotPassword';
import DoctorAvailability from './components/views/ListDoctors/doctorAvailability';
import Dashboard1 from './components/views/Dashboard/dashboard';
import UpdatePassword from './components/ForgotPassword/updatePassword';
import GalleryUpload from './components/views/Gallery/galleryUpload';
import ListGallery from './components/views/Gallery/listGallery';
import AvailabilityList from './components/views/AvailabilityList';
import CreateServices from './components/views/ServicePage/AddServices';
import ListService from './components/views/ServicePage/listServices';
import CreateBlog from './components/views/Blogs/addBlog';
import ListBlogs from './components/views/Blogs/listBlogs';
import UserProfile from './components/users/myAccount';
import SingleService from './components/views/ServicePage/SingleService';
import AddReports from './components/views/Reports/addReports';
import Reports from './components/views/Reports';
import AddPrescription from './components/views/Prescription/addPrescription';
import AppoinementListHistory from './components/views/PatientAppointment/AppoitnmemtHistroy';
import SingleDoctor from './components/views/Team/SingleDoctor';
import ListCareer from './components/views/careeres/listCareer';
import CreateCarrer from './components/views/careeres/addCareers';
import CareerPage from './components/views/careeres/career';
import CareerDetails from './components/views/careeres/singleCareer';
import ListSpeciality from './components/views/specialities/listSpeciality';
import Createspeciality from './components/views/specialities/AddSpeciality';
import SpecialityPage from './components/views/specialities/speciality';
import SpecialityDetails from './components/views/specialities/singleSpeciality';
import PrescriptionsList from './components/views/Prescription/listPrescription';
import ListPharmacy from './components/views/Pharmacy';
import PharmacyRegister from './components/users/Register/PharmacyRegister';
import ListLabs from './components/views/Lab';
import LabRegister from './components/users/Register/LabRegister';
import ListTermsAndConditions from './components/views/TermsAndConditions/listTermsAndCondtions';
import CreateTermsAndCondtions from './components/views/TermsAndConditions/addTermsAndConditions';
import TermsAndConditions from './components/views/TermsAndConditions/TermsAndConditions';
import CreateSlider from './components/views/Sliders/AddSliders';
import ListSliders from './components/views/Sliders/listSliders';
import CreateFAQ from './components/views/FAQ/AddFAQ';
import ListFAQs from './components/views/FAQ/listFAQs';
import AppointmentTrack from './components/views/trackappointment';
import ListCarrerCategory from './components/views/careeres/categoryList';
import ListBlogCategory from './components/views/Blogs/BlogCategoryList';
import ListSpecialityCategory from './components/views/specialities/specialityCategoryList';
import ListServiceCategory from './components/views/ServicePage/ServiceCategoryList';
import { isAuthenticated } from './utils/Redirect/auth';
import BulkUpload from './components/views/bulkUpload/index';
import ViewAppointment from './components/views/PatientAppointment/ViewAppointment';


// google analytics code

const TRACKING_ID: any = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK_CODE;
ReactGA.initialize(TRACKING_ID);


// const PageTracker = () => {
//   const location = useLocation();

//   useEffect(() => {
//     ReactGA.pageview(location.pathname + location.search);
//   }, [location]);

//   return null;
// };

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("Tracking page view for:", location.pathname + location.search); // Debugging log
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
};

function App() {
  // Protected Route Wrapper
  const ProtectedRoute = ({ children }: { children: any }) => {
    if (!isAuthenticated()) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };


  return (
    <Router>
      <PageTracker />
      <Header />
      <Routes>

        {/* <Route path="/dashboard1" element={<Dashboard />} /> */}
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard1 /></ProtectedRoute>} />
        <Route path="/registerdoctor" element={<ProtectedRoute><DoctorRegister title="DoctorRegister" description=" DoctorRegister" /></ProtectedRoute>}
        />
        <Route path="/listpatients" element={<ProtectedRoute><ListPatient title="ListPatientAppointments" description=" ListPatientAppointments" /></ProtectedRoute>}
        />
        <Route path="/listdoctors" element={<ProtectedRoute><ListDoctor title="ListDoctor" description=" ListDoctor" /></ProtectedRoute>}
        />
        <Route path="/listReviews" element={<ProtectedRoute><ListReviews title="ListReviews" description=" ListReviews" /></ProtectedRoute>}
        />
        <Route path="/reports" element={<ProtectedRoute><Reports title="Reports" description="Reports" /></ProtectedRoute>}
        />
        <Route path='/UpdateProfile' element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
        <Route path="/allRegisterpatients" element={<ProtectedRoute><ListAllPatients title="ListPatients" description=" ListPatients" /></ProtectedRoute>}
        />
        <Route path="/updatepassword" element={<UpdatePassword />} />
        <Route path="/galleryUpload" element={<ProtectedRoute><GalleryUpload title="GalleryUpload" description=" GalleryUpload" /></ProtectedRoute>}
        />
        <Route path="/listGallery" element={<ProtectedRoute><ListGallery title="ListGallery" description=" ListGallery" /></ProtectedRoute>}
        />
        <Route path="/enquirylist" element={<ProtectedRoute><ListContacts /></ProtectedRoute>} />
        <Route path='/AvailabilityList' element={<ProtectedRoute><AvailabilityList /></ProtectedRoute>} />
        <Route path='/addServices' element={<ProtectedRoute><CreateServices /></ProtectedRoute>} />
        <Route path="/listServices" element={<ProtectedRoute><ListService title="ListServices" description=" ListServices" /></ProtectedRoute>}
        />
        <Route path="/listblogs" element={<ProtectedRoute><ListBlogs title="ListBlogs" description=" ListBlogs" /></ProtectedRoute>}
        />
        <Route path="/addBlogs" element={<ProtectedRoute><CreateBlog title="CreateBlog" description=" CreateBlog" /></ProtectedRoute>}
        />
        <Route path='/doctorAvailability' element={<ProtectedRoute><DoctorAvailability /></ProtectedRoute>} />
        <Route path="/addReports" element={<ProtectedRoute><AddReports /></ProtectedRoute>} />
        <Route path='/addprescription' element={<ProtectedRoute><AddPrescription /></ProtectedRoute>} />
        <Route path='/appointmentHistory/:slug?' element={<AppoinementListHistory />} />
        <Route path="/prescriptions" element={<ProtectedRoute><PrescriptionsList title="PrescriptionsList" description="PrescriptionsList" /></ProtectedRoute>}
        />
        <Route path="/listSpeciality" element={<ProtectedRoute><ListSpeciality title="ListSpeciality" description="ListSpeciality" /></ProtectedRoute>}
        />
        <Route path="/addSpeciality" element={<ProtectedRoute><Createspeciality title="Createspeciality" description="Createspeciality" /></ProtectedRoute>}
        />
        <Route path="/listPharmacy" element={<ProtectedRoute><ListPharmacy title="ListPharmacy" description="ListPharmacy" /></ProtectedRoute>}
        />
        <Route path="/addPharmacy" element={<ProtectedRoute><PharmacyRegister title="PharmacyRegister" description="PharmacyRegister" /></ProtectedRoute>}
        />
        <Route path="/listLab" element={<ProtectedRoute><ListLabs title="ListLabs" description="ListLabs" /></ProtectedRoute>}
        />
        <Route path="/addLab" element={<ProtectedRoute><LabRegister title="LabRegister" description="LabRegister" /> </ProtectedRoute>}
        />
        <Route path="/addTermsAndCondtions" element={<ProtectedRoute><CreateTermsAndCondtions title="CreatePages" description="CreatePages" /> </ProtectedRoute>}
        />
        <Route path="/listTermsAndCondtions" element={<ProtectedRoute>
          <ListTermsAndConditions title="pages" description="pages" /> </ProtectedRoute>}
        />
        <Route path="/listCareer" element={<ProtectedRoute><ListCareer title="ListCareer" description="ListCareer" /></ProtectedRoute>}
        />
        <Route path="/addCareer" element={<ProtectedRoute><CreateCarrer title="CreateCareer" description="CreateCareer" /></ProtectedRoute>}
        />
        <Route path="/careerCategoryList" element={<ProtectedRoute><ListCarrerCategory title="careerCategoryList" description="careerCategoryList" /></ProtectedRoute>}
        />
        <Route path="/BlogCategoryList" element={<ProtectedRoute><ListBlogCategory title="BlogCategoryList" description="BlogCategoryList" /></ProtectedRoute>}
        />
        <Route path="/specialityCategoryList" element={<ProtectedRoute><ListSpecialityCategory title="specialityCategoryList" description="specialityCategoryList" /></ProtectedRoute>}
        />
        <Route path="/serviceCategoryList" element={<ProtectedRoute><ListServiceCategory title="serviceCategoryList" description="serviceCategoryList" /></ProtectedRoute>}
        />
        <Route path="/listSliders" element={<ProtectedRoute><ListSliders title="ListSliders" description="ListSliders" /></ProtectedRoute>}
        />
        <Route path="/addSliders" element={<ProtectedRoute><CreateSlider title="CreateSlider" description="CreateSlider" /></ProtectedRoute>}
        />
        <Route path="/addFAQs" element={<ProtectedRoute><CreateFAQ title="CreateFAQ" description="CreateFAQ" /></ProtectedRoute>}
        />
        <Route path="/listFAQs" element={<ProtectedRoute><ListFAQs title="ListFAQs" description="ListFAQs" /></ProtectedRoute>}
        />
        <Route path='/ViewAppointment' element={<ViewAppointment title="ViewAppointment" description="ViewAppointment" />} />

        {/* public Routes */}

        <Route path="/" element={<Home title="Trust Multispeciality Hospital – Leading Healthcare with Advanced Medical Services" keywords="Trust Multispeciality Hospital, healthcare services, multispeciality hospital, advanced medical care, cardiology, orthopedics, neurology, expert doctors, medical treatments, patient care, hospital near me, surgery, healthcare facility, best hospital, specialist care" description="Trust Multispeciality Hospital provides top-notch healthcare services with expert specialists across multiple fields including cardiology, orthopedics, neurology, and more. We are dedicated to offering compassionate care and advanced treatments for all your medical needs" />} />


        <Route path="/about" element={<About title="About Us – Trust Multispeciality Hospital | Compassionate & Expert Healthcare" keywords="About Trust Multispeciality Hospital, hospital mission, expert doctors, patient care, healthcare services, multispeciality hospital, advanced medical technology, compassionate care, hospital values, hospital team, specialist doctors, healthcare excellence" description="Learn more about Trust Multispeciality Hospital, our mission to provide high-quality, compassionate healthcare. With a team of expert doctors and state-of-the-art facilities, we are committed to delivering exceptional patient care across various medical specialties" />}
        />


        <Route path="/service" element={<ServicePage title="Services – Trust Multispeciality Hospital | Comprehensive Medical Specialties" keywords="Trust Multispeciality Hospital services, cardiology, orthopedics, neurology, pediatrics, general surgery, medical specialties, healthcare services, advanced treatments, specialist doctors, hospital services, patient care, multispeciality healthcare" description="Discover the wide range of healthcare services at Trust Multispeciality Hospital, including cardiology, orthopedics, neurology, pediatrics, general surgery, and more. Our expert doctors and cutting-edge technology ensure the best care for every patient." />} />


        <Route path="/Team" element={<Team title="Meet Our Doctors – Trust Multispeciality Hospital | Expert Medical Specialists" keywords="Trust Multispeciality Hospital doctors, medical specialists, expert doctors, cardiology specialists, orthopedic doctors, neurology experts, healthcare team, specialist doctors, hospital doctors, experienced physicians, best doctors, doctor profiles" description="Get to know the team of expert doctors at Trust Multispeciality Hospital. Our specialists in cardiology, orthopedics, neurology, and more provide personalized care with the highest level of expertise and compassion" />}
        />



        <Route path="/contact" element={<Contact title="Contact Us – Trust Multispeciality Hospital | Get in Touch for Healthcare Support" keywords="Contact Trust Multispeciality Hospital, hospital contact information, appointment scheduling, healthcare inquiries, get in touch, hospital support, patient services, contact details, reach us, visit us" description="Contact Trust Multispeciality Hospital for inquiries, appointment scheduling, and support. Reach out via phone, email, or visit our facility to experience our commitment to exceptional patient care." />}
        />
        <Route path="/Appointment/:slug?" element={<Appointment title="Appointment – Trust Multispeciality Hospital | Schedule Your Visit" keywords="Trust Multispeciality Hospital appointment, book an appointment, healthcare scheduling, online appointment, schedule visit, patient appointments, hospital appointments, appointment support, expert specialists" description="Book an appointment at Trust Multispeciality Hospital easily online or by phone. Our dedicated team is here to assist you in scheduling your visit with our expert specialists for personalized healthcare" />}
        />
        <Route path="/blogs" element={<Blog title="Blog – Trust Multispeciality Hospital | Health Tips & Medical Insights" keywords="Trust Multispeciality Hospital blog, health tips, medical insights, wellness advice, healthcare blog, expert health articles, medical updates, healthcare trends, patient education, wellness tips, hospital blog" description="Stay informed with Trust Multispeciality Hospital's blog. Explore expert health tips, medical advice, and the latest updates on treatments, wellness, and healthcare trends from our team of specialists" />} />


        <Route path="/blog/:slug" element={<Singleblog title="Single Blog" description="Single Blog" />} />

        <Route path="/gallery" element={<Gallery title="Gallery – Trust Multispeciality Hospital | Explore Our Facilities & Services" keywords="Trust Multispeciality Hospital gallery, hospital facilities, healthcare environment, patient care, hospital images, medical technology, hospital tour, multispeciality hospital, modern healthcare, hospital infrastructure" description="View the gallery of Trust Multispeciality Hospital showcasing our state-of-the-art facilities, advanced medical technology, and compassionate patient care environment. Experience the excellence in healthcare through our visual tour." />}
        />

        <Route path="/login" element={<Login title="Sign In – Trust Multispeciality Hospital | Access Your Patient Portal" keywords="Sign in Trust Multispeciality Hospital, patient portal login, access health records, hospital sign in, healthcare account, patient access, appointment details, secure patient login, online healthcare management" description=" Sign in to your Trust Multispeciality Hospital patient portal for easy access to your health records, appointment details, and personalized healthcare information. Stay connected with your healthcare team." />}
        />
        <Route path="/register" element={<Register title="Sign Up – Trust Multispeciality Hospital | Create Your Patient Account" keywords="Sign up Trust Multispeciality Hospital, create a patient account, register for healthcare services, patient registration, online health management, hospital sign up, healthcare account creation, access medical records" description=" Sign up for a patient account at Trust Multispeciality Hospital to access your health information, schedule appointments, and communicate with your healthcare team. Join us for personalized healthcare management." />}
        />
        <Route path="/faq" element={<Faq />} />
        <Route path="/reviews" element={<ReviewForm title="ReviewForm" description=" ReviewForm" />}
        />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/services/:slug" element={<SingleService title="SingleService" description="SingleService" />}
        />
        <Route path="/team/:slug" element={<SingleDoctor title="Single Doctor" description="Single Doctor" />}
        />
        <Route path="/career" element={<CareerPage title="Career" keywords="" description="Carreers" />}
        />
        <Route path="/career/:slug" element={<CareerDetails title="SingleCareer" description="SingleCareer" />}
        />
        <Route path="/speciality" element={<SpecialityPage title="Specialties – Trust Multispeciality Hospital | Comprehensive Medical Care" keywords="Trust Multispeciality Hospital specialties, medical specialties, cardiology, orthopedics, neurology, pediatrics, gastroenterology, ENT, urology, healthcare services, specialist care, comprehensive medical care" description="Discover the diverse specialties offered at Trust Multispeciality Hospital, including cardiology, orthopedics, neurology, pediatrics, and more. Our expert teams are dedicated to providing high-quality care tailored to your health needs." />}
        />
        <Route path="/speciality/:slug" element={<SpecialityDetails title="SpecialityDetails" description="SpecialityDetails" />}
        />
        <Route path="/termsConditions" element={<TermsAndConditions title="TermsAndConditions" description="TermsAndConditions" />}
        />
        <Route path="/TrackAppointment" element={<AppointmentTrack title="Track Appointment – Trust Multispeciality Hospital | Stay Updated on Your Visit" keywords="Track appointment Trust Multispeciality Hospital, appointment updates, check appointment status, healthcare appointment tracking, appointment management, hospital appointment system, patient tracking, appointment reminders" description="Track your appointment at Trust Multispeciality Hospital easily. Get real-time updates on your scheduled visit and ensure a seamless experience with our healthcare team" />} />

        <Route path='/BulkUpload' element={<BulkUpload />}></Route>
      </Routes>
      <Footer />


    </Router>
  );
}

export default App;

