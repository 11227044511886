import { API, graphqlOperation } from "aws-amplify";
import { getUser, listUsers } from "../../graphql/queries";

const OTPServices = () => {


    const validateotp = async (userId: any) => {
        console.log(userId, '...userId');
        const userResp: any = await API.graphql(graphqlOperation(getUser, { id: userId }));
        console.log('userResp', userResp);
        return userResp;
    }

    const generateOTP = async (EmailId: any, userId: any) => {

        console.log(userId, '...userId');

        const dataSend = {
            requestBody: {
                email: EmailId,
                userId: userId,
                type: "registration",
                action: 'sendOTP'
            }
        }

        const options = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json, text/plain, */*"
            },
            body: JSON.stringify(dataSend)
        }
        console.log('options', options);
        // const userResp: any = await API.graphql(graphqlOperation(getGSCMUser, { id: userId }));
        // const userResp = await fetch(`${process.env.REACT_APP_SEND_EMAIL_API}`, options);
        const userResp = await fetch(`${process.env.REACT_APP_SNS_Function}`, options);
        
        return userResp;
    }


    const validateotpTwoStep = async (userName: any,Otp: any) => {
        let nextToken: string | null = null;
        const limit = 100;

        let type: any, filterVar: any;

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^\+?[1-9]\d{1,14}$/; // This is a simple example, adapt as needed for your phone number format
    
        if (emailPattern.test(userName)) {
          type = 'email';
        } else if (phonePattern.test(userName)) {
          type = 'phone';
        }
        try {
          console.log("userName", userName);

          if (type === 'email') {
            const userEmail = userName.toLowerCase();
            console.log("userEmail", userEmail);

             filterVar = {
              filter: {
                or: [
                  { EmailId: { eq: userEmail } },
                  { Otp: { eq: Otp } }
                ]
              },
              limit: limit,
              nextToken: nextToken
            }

          } else if (type === 'phone') {
            const phone = '+91' + userName;

            filterVar = {
              filter: {
                or: [
                  { PhoneNumber: { eq: phone } },
                  { Otp: { eq: Otp } }
                ]
              },
              limit: limit,
              nextToken: nextToken
            }
          }
          // const filterVar = {
          //   filter: {
          //     or: [
          //       { EmailId: { eq: userName } },
          //       { Otp: { eq: Otp } }
          //     ]
          //   },
          //   limit: limit,
          //   nextToken: nextToken
          // }
          const userResp = await API.graphql(graphqlOperation(listUsers, filterVar));
          // console.log("servicesuserResp", userResp);
        //   if (userResp?.type === "email" && userResp?.status === 200) { 

        //   }
          return userResp;
        } catch (error) {
          console.error("Error validating OTP:", error);
          throw new Error("Unable to validate OTP");
        }
    };

    return {
        validateotp,
        generateOTP,
        validateotpTwoStep
    }
}

export default OTPServices;