import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ListTermsAndConditions from '../../../services/TermsAndCondtions';
import Loader from "../../views/shared/loadercomponent";
// import pages from "../../../utills/pageUtils";
import pages from "../../../utils/pages";
import "./style.css";
import userService from "../../../services/users/user.service";
import ReportServices from "../../../services/reportservices";
import PrescriptionServices from "../../../services/prescription";
import AppointmentId from "../../../services/Appointment";
import Dropdown from 'react-bootstrap/Dropdown';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { ContentPasteSearch } from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import JSZip from "jszip";
import ReportsFilesmanagement from "../shared/downloadandprint";
import { saveAs } from 'file-saver';
// import ViewAppointmentServices from "../../services/viewappointmentservices";
import ViewAppointmentServices from "../../../services/viewappointmentservices";
import ViewappointmentModel from "./model";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DescriptionIcon from '@mui/icons-material/Description';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ModelComponent from "../../views/shared/Model";
import { TrustedAdvisor } from "aws-sdk";
import { Alert } from 'react-bootstrap';



interface compProps {
    title: string;
    description: string;
}

interface Appointment {
    id: string;
    date: string;
}



const ViewAppointment: React.FC<compProps> = ({ title, description }) => {
    const [pageInfo, setPageInfo] = useState<any>(null);
    const [about, setAbout] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const [singleAppointment, setSingleAppointment] = useState<any>(null);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const [history, setHistory] = useState([]);
    const [index, setIndex] = useState<any>("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [viewFiles, setViewFiles] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [SuccessMsg, setSuccessMsg] = useState("");
    const [timer, setTimer] = useState<number>(60);
    const [getModal, setModel] = useState(false);
    const [CurrentOTP, setCurrentOTP] = useState("");
    const [OTPErr, setOtpError] = useState("");
    const [appointmentID, setAppointmentID] = useState<any>("");
    const [cancel, setCancel] = useState(true);
    const [getpatientID, setpatientID] = useState("");
    const [getdelete, setDelete] = useState(false);
    const [doctorList, setDoctorList] = useState<any>([]);
    const [formattedDate, setFormattedDate] = useState("NA");

    const [alertMsg, setAlertMsg] = useState<any>('');

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerRunning(false);
        }
        // Clean up the interval when the component unmounts or the timer is stopped
        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);


    useEffect(() => {
        const fetchFormattedDate = async () => {
            if (singleAppointment?.AppointmentDate) {
                const formated = await ViewAppointmentServices().formatDate(singleAppointment?.AppointmentDate);
                setFormattedDate(formated ? formated : "NA");
            } else {
                setFormattedDate("NA");
            }
        };
        fetchFormattedDate();
    }, [singleAppointment?.AppointmentDate]);

    // useEffect(() => {
    //     const getrenderAppointment = async () => {
    //         if (getdelete === true) {
    //             console.log("getpatientID", getpatientID);
    //             const singleLatestAppointment = (getpatientID && getpatientID !== "NA") ? await userService().ListPatientAppointmentsBypatientID(getpatientID) : null;
    //             console.log("singleLatestAppointment", singleLatestAppointment);
    //             // await userService().ListPatientAppointmentsByphoneNumber(location?.state?.PhoneNumber);
    //             const sortedAppointments: any = singleLatestAppointment && singleLatestAppointment?.sort((a: any, b: any) => {
    //                 const dateTimeA = new Date(`${a?.AppointmentDate}T${a?.AppointmentTime}`).getTime();
    //                 const dateTimeB = new Date(`${b?.AppointmentDate}T${b?.AppointmentTime}`).getTime();
    //                 return dateTimeA - dateTimeB; // Sort in ascending order
    //             });
    //             console.log("sortedAppointments", sortedAppointments);
    //             if (sortedAppointments && sortedAppointments?.length === 0) {
    //                 setHistory([]);
    //                 setDelete(false);
    //             } else {
    //                 setHistory(sortedAppointments);
    //                 setDelete(false);
    //             }
    //             setSingleAppointment(sortedAppointments[0]);
    //             setDelete(false);
    //         }
    //     }
    //     getrenderAppointment()
    // }, [getdelete, getpatientID]);


    //setting index to state when component loaded
    const getDoctors = async () => {
        const response = await userService().ListDoctor('Doctor');
        setDoctorList(response);
    };
    useEffect(() => {
        getDoctors();
        setIndex(location?.state?.index);
    }, [])


    const getDoctorNameById = (doctorId: string) => {
        const doctor = doctorList?.find((doc: any) => doc?.id === doctorId);
        return doctor ? `${doctor?.FirstName} ${doctor?.LastName}` : 'Unknown Doctor';
    };
    //fetching appointment history of perticular patient
    const getAppointmentHistory = async (patientID: any) => {

        // console.log("singleAppointmentLocation", location?.state?.patientID);
        // console.log('singleAppointment', singleAppointment?.patientID);
        // console.log("history", history);

        const ptID = location?.state?.patientID || patientID;
        let response: any
        if (logUserObject?.Role === "Doctor") {
            // const apponitment = (ptID && ptID !== "NA") ? await userService().ListPatientAppointmentsBypatientID(ptID) : await userService().ListPatientAppointmentsByphoneNumber(singleAppointment?.PhoneNumber);
            const apponitment = ptID && await userService().ListPatientAppointmentsBypatientID(ptID);
            response = apponitment?.filter((user: any) => user?.DoctorID === logUserObject?.userId)
        } else {
            if (location?.state?.appointmentDetails) {
                response = location?.state?.appointmentDetails;
            } else {
                // response = (singleAppointment?.patientID && singleAppointment?.patientID !== "NA") ? await userService().ListPatientAppointmentsBypatientID(singleAppointment?.patientID) : await userService().ListPatientAppointmentsByphoneNumber(singleAppointment?.PhoneNumber);
                response = ptID && await userService().ListPatientAppointmentsBypatientID(ptID);
                console.log("response", response);
            }
        }
        const sortedAppointments = response?.sort((a: any, b: any) => {
            const dateTimeA = new Date(`${a.AppointmentDate}T${a.AppointmentTime}`).getTime();
            const dateTimeB = new Date(`${b.AppointmentDate}T${b.AppointmentTime}`).getTime();
            return dateTimeA - dateTimeB; // Sort in ascending order
        });
        console.log("sortedAppointments", sortedAppointments);
        setHistory(sortedAppointments);
        return sortedAppointments;
    };
    useEffect(() => {
        getAppointmentHistory(getpatientID);
    }, []);

    // Filtered appointments based on search term
    const filteredAppointments: any = history?.filter((appointment: any) =>
        appointment?.AppointmentID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        appointment?.AppointmentDate?.includes(searchTerm)
    );
    const handleAppoinmentDelete = async (appoinmentID: any, key: any) => {
        console.log("key at delete", key);
        const deleteApp: any = await ViewAppointmentServices().handleAppoinmentDelete(appoinmentID, "offline", key);
        if (deleteApp) {
            // setDelete(true);
            const hist = await getAppointmentHistory(getpatientID);
            if (hist?.length > 0) {
                setSingleAppointment(hist[0]);
            } else {
                setSingleAppointment(null);
                setAlertMsg("There are no appointments for this patient");
                // setIndex(index + 1);
            }
        }
    }
    // fetching single latest appointment based on patientID or phoneNumber and setting to state
    useEffect(() => {
        setpatientID(location?.state?.patientID);
        const getAppointment = async () => {
            const singleLatestAppointment = (location?.state?.patientID && location?.state?.patientID !== "NA") ? await userService().ListPatientAppointmentsBypatientID(location?.state?.patientID) : await userService().ListPatientAppointmentsByphoneNumber(location?.state?.PhoneNumber);

            const sortedAppointments = singleLatestAppointment?.sort((a: any, b: any) => {
                const dateTimeA = new Date(`${a?.AppointmentDate}T${a?.AppointmentTime}`).getTime();
                const dateTimeB = new Date(`${b?.AppointmentDate}T${b?.AppointmentTime}`).getTime();
                return dateTimeA - dateTimeB; // Sort in ascending order
            });
            setSingleAppointment(sortedAppointments[0]);
        }
        getAppointment();
    }, [])

    console.log("singleAppointment", singleAppointment);
    // edit reports
    const handleReportEdit = async (event: any) => {
        navigate('/addReports', { state: { appointmentID: event?.id, patientID: event?.patientID, PhoneNumber: event?.PhoneNumber } });
    }
    //upload reports
    const handleUpload = async (row: any) => {
        navigate("/addReports", { state: { appointmentId: row?.id, patientID: row?.patientID, PhoneNumber: row?.PhoneNumber } });
    };
    //manage reports (upload or edit)
    const manageReports = async (event: any) => {
        const getReport = await ReportServices().reportsListBypatientID(event?.id);
        if (getReport?.length > 0) {
            handleReportEdit(event);
        } else {
            handleUpload(event);
        }
    }
    // edit prescriptions
    const handlePrescriptionEdit = async (event: any) => {
        navigate('/addprescription', { state: { appointmentID: event?.id, patientID: event?.patientID, PhoneNumber: event?.PhoneNumber } });
    }
    // upload prescriptions
    const handleUploadprescription = async (row: any) => {
        navigate("/addprescription", { state: { appointmentId: row?.id, patientID: row?.patientID, doctorid: row?.DoctorID, PhoneNumber: row?.PhoneNumber } });
    };
    //manage prescriptions(edit or upload)
    const managePrescriptions = async (event: any) => {
        const getPrescription = await PrescriptionServices().prescriptionListBypatientID(event?.id);

        if (getPrescription?.length > 0) {
            handlePrescriptionEdit(event);
        } else {
            handleUploadprescription(event);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const link = location.pathname;

                // Fetch data in parallel for efficiency
                const [pageData, termsData] = await Promise.all([
                    pages(link),
                    ListTermsAndConditions().TermsAndConditionsList()
                ]);

                // Extract the page from fetched page data
                const page = pageData?.[0];

                // Only update the state if page exists
                if (page) {
                    setPageInfo({
                        banner: page.Banner,
                        title: page.Title,
                        description: page.Description,
                    });
                }
                // Find the about page in the terms data
                const aboutPage = termsData.find((item: any) => item.Link === "about");

                aboutPage && setAbout(aboutPage); setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(); // Call the fetch function on component mount

        // Scroll to top of the page immediately (not inside async)
        window.scrollTo(0, 0);

        // Cleanup logic or unmounting side effects (if needed)
        return () => {
            setLoading(false); // In case the component unmounts while fetching
        };
    }, [location.pathname]); // Dependency array to re-trigger on location change

    //navigate to previous appointment by clicking previous
    const handlePrevious: any = async () => {
        const appointments = location?.state?.patientList;
        const prevIndex: any = index - 1;
        const ptntID = appointments[prevIndex].patientID;
        const prev = appointments[prevIndex];
        setSingleAppointment(prev);
        setIndex(prevIndex);
        location.state.patientID = null;
        setHistory([]);
        await getAppointmentHistory(ptntID);
    }

    //navigate to Next appointment by clicking Next
    const handleNext = async () => {
        const appointments = location?.state?.patientList;
        const nextIndex: any = index + 1;
        const ptntID = appointments[nextIndex].patientID;
        const Next = appointments[nextIndex];
        setSingleAppointment(Next);
        setIndex(nextIndex);
        location.state.patientID = null;
        setHistory([]);
        await getAppointmentHistory(ptntID);
    }
    //update appointment status to open/close
    const handleStatusUpdate = async (status: any, appointment: any) => {
        const updateStatus = await ViewAppointmentServices().StatusUpdate(status, appointment);
        setSingleAppointment(updateStatus?.data?.updatePatientAppointment);
        await getAppointmentHistory(updateStatus?.data?.updatePatientAppointment?.patientID);
    }
    //download reportss
    const handleReportDownload: any = async (doctor: any, type: any) => {
        const download = await ViewAppointmentServices().handleReportDownload(doctor, type);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setModel(false);
    };

    const handleaddAppointment = () => {
        navigate("/PatientAppointment", { state: { phoneNumber: location?.state?.PhoneNumber, patientID: location?.state?.patientID } });
    }
    const handleEditAppointment = (appoinment: any) => {
        navigate("/appointment", { state: { patientId: appoinment?.id, phoneNumber: appoinment?.PhoneNumber, patientID: appoinment?.patientID } });
    }

    //OTP form open call for cancelling the appointment when user not logged in
    const handleOTPFormOpen: any = async (appID: string, key: any, PaymentMode: string,) => {
        setAppointmentID(appID);
        await AppointmentId().handleOTPFormOpenService(appID, PaymentMode, setAppointmentID, logUserObject, setLoading, setSuccessMsg, setModel, setIsTimerRunning, setTimer, handleAppoinmentDelete, key)
    }

    //OTP submit call for cancelling the appointment when user not logged in
    const handleOTPSubmit = async () => {
        const key = localStorage.getItem("key");
        console.log("key at otp submit", key);
        const www = await AppointmentId().handleOTPSubmitService(setOtpError, timer, CurrentOTP, setModel, handleAppoinmentDelete, appointmentID, setIsTimerRunning, setTimer, handleAppoinmentDelete, setCancel, key);
    };
    const ResendEmail = async () => {
        setOtpError("");
        handleOTPFormOpen(appointmentID, "cancel", "offline");
    };

    const handleNavigation = (path: string) => {
        navigate(path, { state: { patientID: singleAppointment?.patientID, path: "/ViewAppointment" } });
    };

    return (
        <div className="appointment-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description} />
                    </Helmet><section className="admin-section text-center"
                    >
                        <div className="container">
                            <div className="bannerTitle">
                                <h1>View Appointment</h1>
                            </div>
                        </div>
                    </section><div>
                        <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                            <li>
                                <a className="aColor" href="/">Home  /&nbsp;</a>
                            </li>
                            <li>View Appointment</li>
                        </ul>
                    </div>
                    {
                        alertMsg ? (
                            <div className='alertMessage'>
                                <Alert variant="success" onClose={() => { logUserObject?.userId ? navigate('/AppointmentList') : navigate('/') }} dismissible>
                                    {alertMsg}
                                </Alert>
                            </div>) : (
                            <section className="appointment-details desktopView">
                                <div className="container-fluid">
                                    <div className="responsive-row mobFont">

                                        <div className="col-md-12 tabresp" style={{ display: 'flex', justifyContent: 'center' }}>
                                            {/* {(logUserObject && logUserObject?.Role === "Patient" || logUserObject?.Role === "MasterAdmin") && (
                                        <button type="button" className="custom-btn" onClick={handleaddAppointment}>
                                            <i className="fas fa-plus"></i> New Appointment
                                        </button>
                                    )} */}

                                            {(!logUserObject || ["Patient", "MasterAdmin"].includes(logUserObject?.Role)) && (
                                                <>
                                                    {/* Edit Button */}
                                                    <button
                                                        type="button"
                                                        className="custom-btn"
                                                        onClick={singleAppointment?.Status === 'close' ? undefined : () => handleEditAppointment(singleAppointment)}
                                                        style={{ opacity: singleAppointment?.Status === 'close' ? '0.2' : '1' }}
                                                    >
                                                        <i className="fas fa-edit"></i> Edit
                                                    </button>

                                                    {/* Cancel Button */}
                                                    <button
                                                        type="button"
                                                        className="custom-btn"
                                                        onClick={singleAppointment?.Status === 'close' ? undefined : () => handleOTPFormOpen(singleAppointment?.id, "cancel", singleAppointment?.PaymentMode)}
                                                        style={{ opacity: singleAppointment?.Status === 'close' ? '0.2' : '1' }}
                                                    >
                                                        <i className="fas fa-times-circle"></i> Cancel
                                                    </button>
                                                </>
                                            )}

                                            {/* Delete Button (only for MasterAdmin) */}
                                            {logUserObject?.Role === "MasterAdmin" && (
                                                <button
                                                    type="button"
                                                    className="custom-btn"
                                                    onClick={() => handleAppoinmentDelete(singleAppointment?.id, "delete")}
                                                >
                                                    <i className="fas fa-trash-alt"></i> Delete
                                                </button>
                                            )}

                                        </div>

                                        <div className="col-md-7 tabpr-0">

                                            <div className="responsive-row">

                                                <div className="col-md-4 pl-0 displayFlex">
                                                    <div className="d-flex">
                                                        <label className="mr-10">Patient Name:</label>
                                                        <p>{singleAppointment?.PatientName ? singleAppointment?.PatientName : "NA"}</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 pl-0">
                                                    <div className="d-flex">
                                                        <label className="mr-10">Doctor Name:</label>
                                                        <p>{getDoctorNameById(singleAppointment?.DoctorID)}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 pl-0">
                                                    <div className="d-flex ">
                                                        <label className="mr-10">Address:</label>
                                                        <p>{singleAppointment?.Address ? singleAppointment?.Address : "NA"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 pl-0 displayFlex">
                                                    <div className="d-flex">
                                                        <label className="mr-10">Email:</label>
                                                        <p>{singleAppointment?.EmailId ? singleAppointment?.EmailId : "NA"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 pl-0">
                                                    <div className="d-flex ">
                                                        <label className="mr-10">Date Time:</label>
                                                        {/* <p>{singleAppointment?.AppointmentDate ? singleAppointment?.AppointmentDate : "NA"}</p> */}
                                                        <p>{formattedDate}
                                                            <span style={{ marginLeft: "5px" }}>{singleAppointment?.AppointmentTime ? singleAppointment?.AppointmentTime : "NA"}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 pl-0">
                                                    <div className="d-flex ">
                                                        <label className="mr-10">AppointmentID:</label>
                                                        <p>{singleAppointment?.AppointmentID
                                                            ? singleAppointment?.AppointmentID
                                                            : "NA"}</p>
                                                    </div>
                                                </div>


                                                <div className="col-md-4 pl-0">
                                                    <div className="d-flex">
                                                        <label className="mr-10">Ph.No:</label>
                                                        <p>{singleAppointment?.PhoneNumber ? singleAppointment?.PhoneNumber : "NA"}</p>
                                                    </div>
                                                </div>


                                                <div className="col-md-4 pl-0">
                                                    <div className="d-flex">
                                                        <label className="mr-10">D.O.B:</label>
                                                        <p>{singleAppointment?.DOB ? singleAppointment?.DOB : "NA"}</p>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className="col-md-12">
                                                    <h4 className="kyc-title">Description</h4>
                                                    <p>{singleAppointment?.Message ? singleAppointment?.Message : "NA"}</p>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <h4 className="kyc-title">KYC</h4>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 mobResp">Smoking:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.SmokingStatus === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.SmokingStatus === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>
                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 mobResp">Alcohol Consumption:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.AlcoholConsumption === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.AlcoholConsumption === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>

                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 mobResp">Diabetes:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.Diabetes === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.Diabetes === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>

                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 mobResp">Blood Pressure:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.bloodPressure === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.bloodPressure === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>
                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 mobResp">Pregnant:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.pregnacy === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.pregnacy === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>
                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 mobResp">Heart Attack:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.heartAttack === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.heartAttack === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row marginTop">
                                                <div>
                                                    {singleAppointment?.KYC[0]?.pastHealthConditions === "1" && singleAppointment?.KYC[0]?.pastHealthConditionsDescription ?
                                                        <><label className="labelDekstop">Past Health Conditions</label><p>{singleAppointment?.KYC[0]?.pastHealthConditionsDescription}</p></>
                                                        : null
                                                    }

                                                    {singleAppointment?.KYC[0]?.foodAllergies === "1" && singleAppointment?.KYC[0]?.foodAllergiesDescription ?
                                                        <><label className="labelDekstop">Allergies</label><p>{singleAppointment?.KYC[0]?.foodAllergiesDescription}</p></>
                                                        : null
                                                    }
                                                    {singleAppointment?.KYC[0]?.CurrentMedications === "1" && singleAppointment?.KYC[0]?.CurrentMedicationsDescription ?
                                                        <><label className="labelDekstop">Current Medications</label><p>{singleAppointment?.KYC[0]?.CurrentMedicationsDescription}</p></>
                                                        : null
                                                    }
                                                    {singleAppointment?.KYC[0]?.ChronicIllnesses === "1" && singleAppointment?.KYC[0]?.ChronicIllnessesDescription ?
                                                        <><label className="labelDekstop">ChronicIllnesses</label><p>{singleAppointment?.KYC[0]?.ChronicIllnessesDescription}</p></>
                                                        : null
                                                    }
                                                </div>
                                            </div>


                                            <div className="paginationbuttons d-flex justify-content-between align-items-center">
                                                <div className="d-flex">
                                                    {(!logUserObject || logUserObject?.Role !== "Lab") ?
                                                        <Dropdown className="dropright">
                                                            <Dropdown.Toggle
                                                                title="Prescriptions"
                                                                className="dropdowncolor dropdown-no-hover"
                                                                id="dropdown-basic"
                                                                style={{ backgroundColor: 'transparent', border: 'none' }}
                                                            >
                                                                {/* <InsertDriveFileIcon
                                                            style={{ color: '#2cbcbc', cursor: 'pointer', marginTop: '-6px' }}
                                                        /> */}
                                                                <button className="custom-btn">Prescriptions</button>
                                                            </Dropdown.Toggle>


                                                            {
                                                                !logUserObject ? (
                                                                    <Dropdown.Menu className='dropdownbg newdropdown1'>
                                                                        {/* <span className='spanfont'>Please register / login to view prescription</span> */}

                                                                        <span className='spanfont'>
                                                                            <span style={{ margin: '2px' }}>Please </span>
                                                                            <Link to="/register" style={{ textDecoration: 'none', color: 'inherit' }}
                                                                                onClick={() => handleNavigation("register")}>
                                                                                <span style={{ color: 'black' }}>register</span>
                                                                            </Link>

                                                                            <span style={{ margin: '2px', color: 'black' }}>/ </span>
                                                                            <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                <span style={{ color: 'black' }} onClick={() => handleNavigation("login")}>login</span>
                                                                            </Link>
                                                                            <span style={{ margin: '2px' }}>to view prescription</span>
                                                                        </span>
                                                                    </Dropdown.Menu>
                                                                ) : (
                                                                    <Dropdown.Menu className="dropdownbg">
                                                                        {logUserObject && logUserObject?.Role !== "Patient" && (
                                                                            <Dropdown.Item
                                                                                className="dropdownbg"
                                                                                as="button"
                                                                                title="Manage Prescriptions"
                                                                                onClick={() => managePrescriptions(singleAppointment)}
                                                                            >
                                                                                <DescriptionIcon style={{ color: 'blue' }} />
                                                                            </Dropdown.Item>
                                                                        )}

                                                                        <Dropdown.Item
                                                                            className="dropdownbg"
                                                                            as="button"
                                                                            title="View Prescription"
                                                                            onClick={() => {
                                                                                ReportsFilesmanagement().handleView(
                                                                                    singleAppointment,
                                                                                    setCurrentIndex,
                                                                                    setViewFiles,
                                                                                    setIsModalOpen,
                                                                                    "prescriptions"
                                                                                );
                                                                            }}
                                                                        >
                                                                            <VisibilityIcon style={{ color: 'blue' }} />
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className="dropdownbg"
                                                                            as="button"
                                                                            title="Download Prescription"
                                                                            onClick={() => handleReportDownload(singleAppointment, "prescriptions")}
                                                                        >
                                                                            <DownloadIcon style={{ color: 'blue' }} />
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                )
                                                            }
                                                        </Dropdown>
                                                        : null}
                                                    {
                                                        (!logUserObject || logUserObject?.Role !== "Pharmacy") && (
                                                            <Dropdown className="dropright">
                                                                <Dropdown.Toggle className='dropdowncolor dropdown-no-hover' id="dropdown-basic" title="Reports" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                                    {/* <FolderOpenIcon style={{ color: '#2cbcbc', cursor: 'pointer', marginTop: '-9px' }} /> */}
                                                                    <button className="custom-btn">Reports</button>
                                                                </Dropdown.Toggle>
                                                                {
                                                                    !logUserObject ? (
                                                                        <Dropdown.Menu className='dropdownbg newdropdown'>
                                                                            <span className='spanfont'>
                                                                                <span style={{ margin: '2px' }}>Please </span>
                                                                                <Link to="/register" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                    <span style={{ color: 'black' }}>register</span>
                                                                                </Link>
                                                                                <span style={{ margin: '2px', color: 'black' }}>/ </span>
                                                                                <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                    <span style={{ color: 'black' }}>login</span>
                                                                                </Link>
                                                                                <span style={{ margin: '2px' }}>to view reports</span>
                                                                            </span>
                                                                        </Dropdown.Menu>
                                                                    ) :
                                                                        (
                                                                            <Dropdown.Menu className='dropdownbg'>
                                                                                {logUserObject && logUserObject?.Role !== "Patient" && logUserObject?.Role !== "Doctor" && (
                                                                                    <Dropdown.Item className='dropdownbg' as="button" title="Manage Reports" onClick={() => manageReports(singleAppointment)}>
                                                                                        <ContentPasteSearch style={{ color: 'blue' }} />
                                                                                    </Dropdown.Item>
                                                                                )}

                                                                                <Dropdown.Item className='dropdownbg'
                                                                                    as="button"
                                                                                    title="View Report" // This will show the tooltip when you hover over the item
                                                                                    onClick={() => {
                                                                                        ReportsFilesmanagement().handleView(singleAppointment, setCurrentIndex, setViewFiles, setIsModalOpen, "reports");
                                                                                    }}
                                                                                >
                                                                                    <VisibilityIcon style={{ color: 'blue' }} />
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item as="button" className='dropdownbg' title="Download Report" onClick={() => handleReportDownload(singleAppointment, "reports")}>
                                                                                    <DownloadIcon style={{ color: 'blue' }} />
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        )
                                                                }
                                                            </Dropdown>
                                                        )}
                                                </div>
                                                {logUserObject && (logUserObject?.Role === "MasterAdmin" || logUserObject?.Role === "Doctor") ? (

                                                    <><div className="statusstyle">
                                                        <span>Status</span>
                                                        <select
                                                            name="status"
                                                            id="status"
                                                            value={singleAppointment?.Status}
                                                            onChange={(e) => handleStatusUpdate(e?.target?.value, singleAppointment)}
                                                        >
                                                            <option value="open">Open</option>
                                                            <option value="close">Close</option>
                                                        </select>
                                                    </div><div className="d-flex">
                                                            <button className="custom-btn mr-2" style={{ opacity: index === 0 ? 0.5 : 1 }} disabled={index === 0 ? true : false} onClick={handlePrevious}>Previous</button>
                                                            <button className="custom-btn" style={{ opacity: location?.state?.patientList?.length - 1 === index ? 0.5 : 1 }} disabled={location?.state?.patientList?.length - 1 === index ? true : false} onClick={handleNext}>Next</button>
                                                        </div></>
                                                ) : null}

                                            </div>

                                        </div>
                                        {/* <div className="col-md-4"></div> */}
                                        {logUserObject ?
                                            <div className="col-md-5">
                                                <h4 style={{ fontSize: '14px !important' }}>Appointment History</h4>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e?.target?.value)}
                                                        className="search-bar" placeholder="Search"
                                                    />
                                                </div>
                                                <div className="mobSize" style={{ fontSize: '14px' }}>

                                                    <span className="patientName">{singleAppointment?.PatientName}</span>
                                                    {filteredAppointments?.length > 0 ? (
                                                        filteredAppointments?.map((appointment: any, index: any) => {
                                                            const formated: any = ViewAppointmentServices().formatDate(appointment?.AppointmentDate)
                                                            console.log("formated", formated);
                                                            return (
                                                                <div className="PatientHistoryLabel"
                                                                    key={index}
                                                                // onClick={() => handleSelect(appointment)} // Highlight on click
                                                                // style={{ cursor: 'pointer' }}
                                                                >
                                                                    <span className="PatientHistoryLabel labelDekstop">Appointment Id:</span>
                                                                    <span>{appointment?.AppointmentID}</span>
                                                                    <span className="PatientHistoryLabel labelDekstop">Date Time:</span>
                                                                    <p>{formated}
                                                                        <span style={{ marginLeft: "5px" }}>{appointment?.AppointmentTime}</span>
                                                                    </p>
                                                                    <span className="viewText" onClick={() => setSingleAppointment(appointment)}>
                                                                        View
                                                                    </span>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <p>No appointments found</p>
                                                    )
                                                    }
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </section>
                        )}



                    {/* Tab View */}
                    {/* {
                        alertMsg ? (
                            <div className='alertMessage'>
                                <Alert variant="success" onClose={() => { logUserObject?.userId && navigate('/AppointmentList') }} dismissible>
                                    {alertMsg}
                                </Alert>
                            </div>) : (
                            <section className="appointment-details tabView">
                                <div className="container-fluid">
                                    <div className="responsive-row mobFont">

                                        <div className="col-md-12 tabresp" style={{ display: 'flex', justifyContent: 'center' }}>
                                            {(!logUserObject || ["Patient", "MasterAdmin"].includes(logUserObject?.Role)) && (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="custom-btn"
                                                        onClick={singleAppointment?.Status === 'close' ? undefined : () => handleEditAppointment(singleAppointment)}
                                                        style={{ opacity: singleAppointment?.Status === 'close' ? '0.2' : '1' }}
                                                    >
                                                        <i className="fas fa-edit"></i> Edit
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="custom-btn"
                                                        onClick={singleAppointment?.Status === 'close' ? undefined : () => handleOTPFormOpen(singleAppointment?.id, "cancel", singleAppointment?.PaymentMode)}
                                                        style={{ opacity: singleAppointment?.Status === 'close' ? '0.2' : '1' }}
                                                    >
                                                        <i className="fas fa-times-circle"></i> Cancel
                                                    </button>
                                                </>
                                            )}

                                            {logUserObject?.Role === "MasterAdmin" && (
                                                <button
                                                    type="button"
                                                    className="custom-btn"
                                                    onClick={() => handleAppoinmentDelete(singleAppointment?.id, "delete")}
                                                >
                                                    <i className="fas fa-trash-alt"></i> Delete
                                                </button>
                                            )}

                                        </div>

                                        <div className="col-md-12 tabpr-0">

                                            <div className="responsive-row">

                                                <div className="col-md-4 displayFlex">
                                                    <div className="d-flex">
                                                        <label className="mr-10">Patient Name:</label>
                                                        <p>{singleAppointment?.PatientName ? singleAppointment?.PatientName : "NA"}</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="d-flex">
                                                        <label className="mr-10">Doctor Name:</label>
                                                        <p>{getDoctorNameById(singleAppointment?.DoctorID)}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex ">
                                                        <label className="mr-10">Address:</label>
                                                        <p>{singleAppointment?.Address ? singleAppointment?.Address : "NA"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 displayFlex">
                                                    <div className="d-flex">
                                                        <label className="mr-10">Email:</label>
                                                        <p>{singleAppointment?.EmailId ? singleAppointment?.EmailId : "NA"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex ">
                                                        <label className="mr-10">Date Time:</label>
                                                        <p>{formattedDate}
                                                            <span style={{ marginLeft: "5px" }}>{singleAppointment?.AppointmentTime ? singleAppointment?.AppointmentTime : "NA"}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex ">
                                                        <label className="mr-10">AppointmentID:</label>
                                                        <p>{singleAppointment?.AppointmentID
                                                            ? singleAppointment?.AppointmentID
                                                            : "NA"}</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 pl-0">
                                                    <div className="d-flex">
                                                        <label className="mr-10">Ph.No:</label>
                                                        <p>{singleAppointment?.PhoneNumber ? singleAppointment?.PhoneNumber : "NA"}</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="d-flex">
                                                        <label className="mr-10">D.O.B:</label>
                                                        <p>{singleAppointment?.DOB ? singleAppointment?.DOB : "NA"}</p>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className="col-md-12">
                                                    <h4 className="kyc-title">Description</h4>
                                                    <p>{singleAppointment?.Message ? singleAppointment?.Message : "NA"}</p>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <h4 className="kyc-title">KYC</h4>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 kyc-label1tab mobResp">Smoking:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.SmokingStatus === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.SmokingStatus === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>
                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 kyc-label1tab mobResp">Alcohol Consumption:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.AlcoholConsumption === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.AlcoholConsumption === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>

                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 kyc-label1tab mobResp">Diabetes:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.sugar === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.sugar === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>

                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 kyc-label1tab mobResp">Blood Pressure:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.bloodPressure === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.bloodPressure === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>
                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 kyc-label1tab mobResp">Pregnant:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.pregnacy === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.pregnacy === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>
                                                </div>
                                                <div className="kyc-item">
                                                    <span className="kyc-label1 kyc-label1tab mobResp">Heart Attack:</span>
                                                    <span className="kyc-value kyc-valueTab">
                                                        {singleAppointment?.KYC[0]?.heartAttack === "1"
                                                            ? "Yes"
                                                            : singleAppointment?.KYC[0]?.heartAttack === "0"
                                                                ? "No"
                                                                : "Not Specified"}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row marginTop">
                                                <div>
                                                    {singleAppointment?.KYC[0]?.pastHealthConditions === "1" && singleAppointment?.KYC[0]?.pastHealthConditionsDescription ?
                                                        <><label className="labelDekstop">Past Health Conditions</label><p>{singleAppointment?.KYC[0]?.pastHealthConditionsDescription}</p></>
                                                        : null
                                                    }

                                                    {singleAppointment?.KYC[0]?.foodAllergies === "1" && singleAppointment?.KYC[0]?.foodAllergiesDescription ?
                                                        <><label className="labelDekstop">Allergies</label><p>{singleAppointment?.KYC[0]?.foodAllergiesDescription}</p></>
                                                        : null
                                                    }
                                                    {singleAppointment?.KYC[0]?.CurrentMedications === "1" && singleAppointment?.KYC[0]?.CurrentMedicationsDescription ?
                                                        <><label className="labelDekstop">Current Medications</label><p>{singleAppointment?.KYC[0]?.CurrentMedicationsDescription}</p></>
                                                        : null
                                                    }
                                                    {singleAppointment?.KYC[0]?.ChronicIllnesses === "1" && singleAppointment?.KYC[0]?.ChronicIllnessesDescription ?
                                                        <><label className="labelDekstop">ChronicIllnesses</label><p>{singleAppointment?.KYC[0]?.ChronicIllnessesDescription}</p></>
                                                        : null
                                                    }
                                                </div>
                                            </div>


                                            <div className="paginationbuttons d-flex justify-content-between align-items-center">
                                                <div className="d-flex">
                                                    {(!logUserObject || logUserObject?.Role !== "Lab") ?
                                                        <Dropdown className="dropright">
                                                            <Dropdown.Toggle
                                                                title="Prescriptions"
                                                                className="dropdowncolor dropdown-no-hover"
                                                                id="dropdown-basic"
                                                                style={{ backgroundColor: 'transparent', border: 'none' }}
                                                            >
                                                                <button className="custom-btn">Prescriptions</button>
                                                            </Dropdown.Toggle>


                                                            {
                                                                !logUserObject ? (
                                                                    <Dropdown.Menu className='dropdownbg newdropdown1'>

                                                                        <span className='spanfont'>
                                                                            <span style={{ margin: '2px' }}>Please </span>
                                                                            <Link to="/register" style={{ textDecoration: 'none', color: 'inherit' }}
                                                                                onClick={() => handleNavigation("register")}>
                                                                                <span style={{ color: 'black' }}>register</span>
                                                                            </Link>

                                                                            <span style={{ margin: '2px', color: 'black' }}>/ </span>
                                                                            <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                <span style={{ color: 'black' }} onClick={() => handleNavigation("login")}>login</span>
                                                                            </Link>
                                                                            <span style={{ margin: '2px' }}>to view prescription</span>
                                                                        </span>
                                                                    </Dropdown.Menu>
                                                                ) : (
                                                                    <Dropdown.Menu className="dropdownbg">
                                                                        {logUserObject && logUserObject?.Role !== "Patient" && (
                                                                            <Dropdown.Item
                                                                                className="dropdownbg"
                                                                                as="button"
                                                                                title="Manage Prescriptions"
                                                                                onClick={() => managePrescriptions(singleAppointment)}
                                                                            >
                                                                                <DescriptionIcon style={{ color: 'blue' }} />
                                                                            </Dropdown.Item>
                                                                        )}

                                                                        <Dropdown.Item
                                                                            className="dropdownbg"
                                                                            as="button"
                                                                            title="View Prescription"
                                                                            onClick={() => {
                                                                                ReportsFilesmanagement().handleView(
                                                                                    singleAppointment,
                                                                                    setCurrentIndex,
                                                                                    setViewFiles,
                                                                                    setIsModalOpen,
                                                                                    "prescriptions"
                                                                                );
                                                                            }}
                                                                        >
                                                                            <VisibilityIcon style={{ color: 'blue' }} />
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className="dropdownbg"
                                                                            as="button"
                                                                            title="Download Prescription"
                                                                            onClick={() => handleReportDownload(singleAppointment, "prescriptions")}
                                                                        >
                                                                            <DownloadIcon style={{ color: 'blue' }} />
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                )
                                                            }
                                                        </Dropdown>
                                                        : null}
                                                    {
                                                        (!logUserObject || logUserObject?.Role !== "Pharmacy") && (
                                                            <Dropdown className="dropright">
                                                                <Dropdown.Toggle className='dropdowncolor dropdown-no-hover' id="dropdown-basic" title="Reports" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                                    <button className="custom-btn">Reports</button>
                                                                </Dropdown.Toggle>
                                                                {
                                                                    !logUserObject ? (
                                                                        <Dropdown.Menu className='dropdownbg newdropdown'>
                                                                            <span className='spanfont'>
                                                                                <span style={{ margin: '2px' }}>Please </span>
                                                                                <Link to="/register" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                    <span style={{ color: 'black' }}>register</span>
                                                                                </Link>
                                                                                <span style={{ margin: '2px', color: 'black' }}>/ </span>
                                                                                <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                    <span style={{ color: 'black' }}>login</span>
                                                                                </Link>
                                                                                <span style={{ margin: '2px' }}>to view reports</span>
                                                                            </span>
                                                                        </Dropdown.Menu>
                                                                    ) :
                                                                        (
                                                                            <Dropdown.Menu className='dropdownbg'>
                                                                                {logUserObject && logUserObject?.Role !== "Patient" && logUserObject?.Role !== "Doctor" && (
                                                                                    <Dropdown.Item className='dropdownbg' as="button" title="Manage Reports" onClick={() => manageReports(singleAppointment)}>
                                                                                        <ContentPasteSearch style={{ color: 'blue' }} />
                                                                                    </Dropdown.Item>
                                                                                )}

                                                                                <Dropdown.Item className='dropdownbg'
                                                                                    as="button"
                                                                                    title="View Report" 
                                                                                    onClick={() => {
                                                                                        ReportsFilesmanagement().handleView(singleAppointment, setCurrentIndex, setViewFiles, setIsModalOpen, "reports");
                                                                                    }}
                                                                                >
                                                                                    <VisibilityIcon style={{ color: 'blue' }} />
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item as="button" className='dropdownbg' title="Download Report" onClick={() => handleReportDownload(singleAppointment, "reports")}>
                                                                                    <DownloadIcon style={{ color: 'blue' }} />
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        )
                                                                }
                                                            </Dropdown>
                                                        )}
                                                </div>
                                                {logUserObject && (logUserObject?.Role === "MasterAdmin" || logUserObject?.Role === "Doctor") ? (

                                                    <><div className="statusstyle">
                                                        <span>Status</span>
                                                        <select
                                                            name="status"
                                                            id="status"
                                                            value={singleAppointment?.Status}
                                                            onChange={(e) => handleStatusUpdate(e?.target?.value, singleAppointment)}
                                                        >
                                                            <option value="open">Open</option>
                                                            <option value="close">Close</option>
                                                        </select>
                                                    </div><div className="d-flex">
                                                            <button className="custom-btn mr-2" style={{ opacity: index === 0 ? 0.5 : 1 }} disabled={index === 0 ? true : false} onClick={handlePrevious}>Previous</button>
                                                            <button className="custom-btn" style={{ opacity: location?.state?.patientList?.length - 1 === index ? 0.5 : 1 }} disabled={location?.state?.patientList?.length - 1 === index ? true : false} onClick={handleNext}>Next</button>
                                                        </div></>
                                                ) : null}

                                            </div>

                                        </div>
                                        {logUserObject ?
                                            <div className="col-md-12">
                                                <h4 style={{ fontSize: '14px !important' }}>Appointment History</h4>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e?.target?.value)}
                                                        className="search-bar" placeholder="Search"
                                                    />
                                                </div>
                                                <div className="mobSize" style={{ fontSize: '14px' }}>

                                                    <span className="patientName">{singleAppointment?.PatientName}</span>
                                                    {filteredAppointments?.length > 0 ? (
                                                        filteredAppointments?.map((appointment: any, index: any) => (
                                                            <div
                                                                className="kyc-item"
                                                                key={index}
                                                            >
                                                                <span className="kyc-label">Appointment Id:</span>
                                                                <span>{appointment?.AppointmentID}</span>
                                                                <span className="kyc-label1 kyc-label1tab">Date Time:</span>
                                                                <p>{formattedDate}
                                                                    <span style={{ marginLeft: "5px" }}>{appointment?.AppointmentTime}</span>
                                                                </p>
                                                                <span className="viewText" onClick={() => setSingleAppointment(appointment)}>
                                                                    View
                                                                </span>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No appointments found</p>
                                                    )
                                                    }
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </section>
                        )} */}
                </>
            )}

            <ViewappointmentModel isModalOpen={isModalOpen} closeModal={closeModal} currentIndex={currentIndex} viewFiles={viewFiles} setCurrentIndex={setCurrentIndex} />

            {getModal && (
                <ModelComponent setCurrentOTP={setCurrentOTP} SuccessMsg={SuccessMsg} timer={timer} OTPErr={OTPErr} handleOTPSubmit={handleOTPSubmit} ResendEmail={ResendEmail} closeModal={closeModal} getModal={getModal} />
            )
            }
        </div>
    );
}

export default ViewAppointment;