import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { getUser, listUsers } from '../../graphql/queries';
import { SHA256 } from 'crypto-js';
import { updateUser } from '../../graphql/mutations';



const forgotPasswordServices: any = () => {

  const validateInput = async (input: any) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[1-9]\d{1,14}$/; // This is a simple example, adapt as needed for your phone number format

    if (emailPattern.test(input)) {
      return 'email';
    } else if (phonePattern.test(input)) {
      return 'phone';
    } else {
      return 'invalid';
    }
  }

  const ListExisting = async (userName: any) => {
    try {
      let allUsers: any[] = [];
      let nextToken: string | null = null;
      const limit = 100;

      do {
        const filterVar = {
          filter: {
            or: [
              { EmailId: { eq: userName } },
              { PhoneNumber: { eq: userName } }
            ]
          },
          limit: limit,
          nextToken: nextToken
        };

        // console.log("filter", filterVar);

        const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;

        console.log(result, "result");

        const users = result?.data?.listUsers?.items || [];
        allUsers.push(...users);

        nextToken = result?.data?.listUsers?.nextToken;
      } while (nextToken);
      allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      return allUsers;
    } catch (error) {
      console.error('Error fetching users:', error);
      return []; // Return an empty array in case of error
    }
  }

  const SendEmail = async (email: any, userName: any, type: any) => {
    console.log("email sendEmail", email);
    const checkingType = await validateInput(email);

    try {
      if (type === 'new') {

        const existinNewgUsers: any = await API.graphql(graphqlOperation(listUsers,
          {
            filter: {
              or: [
                { EmailId: { eq: email } },
                { PhoneNumber: { eq: email } }
              ]
            }
          }
        )
        );
        console.log("existinNewgUsers", existinNewgUsers);
        const userNewDetails = existinNewgUsers?.data?.listUsers?.items[0];
        console.log("userNewDetails", userNewDetails);
        if (userNewDetails) {
          if (checkingType === "email") {
            return { msg: 'user already existed with this email, please continue with other one', type: 'email' }
          } else {
            return { msg: 'user already existed with this number, please continue with other one', type: 'phone' }
          }
        } 
        else {
          const existingUsers: any = await API.graphql(
            graphqlOperation(listUsers,
              {
                filter: {
                  or: [
                    { EmailId: { eq: email } },
                    { PhoneNumber: { eq: email } }
                  ]
                }
              }
            )
          );

          const userDetails = existingUsers?.data?.listUsers?.items[0];
          // console.log("type", checkingType);
          const existing = await ListExisting(userName);
          // const newExisting = await ListExisting(email);
          // console.log("newExisting", newExisting);
          if (checkingType === "email") {
            try {
              const dataSend = {
                action: "sendOTP",
                email: email,
                userId: existing[0]?.id,
              }

              const options = {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json, text/plain, */*"
                },
                body: JSON.stringify(dataSend)
              }
              console.log("options", options);
              const response: any = await fetch(`${process.env.REACT_APP_FORGOTPASSWORD_API}`, options);
              console.log("after response in else", response);
              const data = await response.json();
              console.log("response in else", data);
              const updatedUserDetails = {
                ...userDetails,
                EmailId: email
              };

              // console.log("updatedUserDetails", userDetails);

              // console.log("updatedUserDetails1", updatedUserDetails);

              if (response?.status === 200) {
                return { response: response, msg: "Please check your email account for the OTP.", status: 200, type: "email", userDetails: updatedUserDetails, data: data, userId: existing[0]?.id, stateType: type };
              }

            } catch (error) {
              console.error("An error occurred:", error);
            }
          }
          else if (checkingType === "phone") {
            try {
              const countryCode = existing[0]?.countryCode || '+91'; // Handle missing country code
              const formattedPhoneNumber = `${countryCode}${email}`;
              // console.log("formattedPhoneNumber", formattedPhoneNumber);
              const options: any = {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json, text/plain, */*",
                },

                body: JSON.stringify({
                  action: "sendOTP",
                  phoneNumber: formattedPhoneNumber,
                  userId: existing[0]?.id
                })
              }
              console.log("options of phone", options);
              const response = await fetch(`${process.env.REACT_APP_FORGOTPASSWORD_API}`, options);
              console.log("response", response);

              const data = await response.json();
              console.log("data", data);
              const updatedUserDetails = {
                ...userDetails,
                PhoneNumber: email
              };

              if (response?.status === 200) {
                return { response: data, msg: "Please check your Mobile number for the OTP.", status: 200, type: "mobile", userDetails: updatedUserDetails, data: data, userId: existing[0]?.id, stateType: type }
              }
            } catch (error) {
              console.error("An error occurred:", error);
            }
          }
        }
      } else {

        const existingUsers: any = await API.graphql(
          graphqlOperation(listUsers,
            {
              filter: {
                or: [
                  { EmailId: { eq: email } },
                  { PhoneNumber: { eq: email } }
                ]
              }
            }
          )
        );

        const userDetails = existingUsers?.data?.listUsers?.items[0];
        console.log({ email, userDetails })

        if (userDetails) {

          if (userDetails?.EmailId === email) {
            try {
              const dataSend = {
                action: "sendOTP",
                email: email,
                userId: existingUsers?.data?.listUsers?.items[0].id,
              }
              // console.log("dataSend", dataSend)
              const options = {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json, text/plain, */*"
                },
                body: JSON.stringify(dataSend)
              }
              // console.log("options", options)
              const response = await fetch(`${process.env.REACT_APP_FORGOTPASSWORD_API}`, options);

              console.log("response existing users find", response);
              if (response?.status === 200) {
                return { response: response, msg: "Please check your email account for the OTP.", status: 200, type: "email", userDetails: userDetails };
              }

            } catch (error) {
              console.error("An error occurred:", error);
            }
          } 
          else {
            console.log("in else");
            try {
              const countryCode = userDetails?.countryCode || '+91'; // Handle missing country code
              const formattedPhoneNumber = `${countryCode}${userDetails?.PhoneNumber}`;
              // console.log("formattedPhoneNumber", formattedPhoneNumber);
              const options: any = {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json, text/plain, */*",
                },

                body: JSON.stringify({
                  action: "sendOTP",
                  phoneNumber: formattedPhoneNumber,
                  userId: userDetails?.id
                })
              }
              console.log("options", options);
              const response = await fetch(`${process.env.REACT_APP_FORGOTPASSWORD_API}`, options);

              const data = await response.json();
              console.log("response", data);

              if (response?.status === 200) {
                return { response: data, msg: "Please check your mobile for the OTP.", status: 200, type: "mobile", userDetails: userDetails }
              }
            } catch (error) {
              console.error("An error occurred:", error);
            }
          }

        } else {
          return { status: 400, msg: 'User not found' }
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };


  // const SendEmail = async (email: any) => {
  //   const existingUsers: any = await API.graphql(
  //     graphqlOperation(listUsers,
  //       {
  //         filter: {
  //           or: [
  //             { EmailId: { eq: email } },
  //             { PhoneNumber: { eq: email } }
  //           ]
  //         }
  //       }

  //     )
  //   );
  //   // console.log("existingUsers", existingUsers)
  //   const userDetails = existingUsers?.data?.listUsers?.items[0];
  //   // console.log("userDetails", userDetails)
  //   if (existingUsers && existingUsers?.data?.listUsers?.items?.length > 0) {
  //     if (userDetails?.EmailId === email) {
  //       try {
  //         const dataSend = {
  //           action: "sendOTP",
  //           email: email,
  //           userId: existingUsers?.data?.listUsers?.items[0].id,
  //         }
  //         console.log("dataSend", dataSend)
  //         const options = {
  //           method: 'POST',
  //           headers: {
  //             "Content-Type": "application/json",
  //             "Accept": "application/json, text/plain, */*"
  //           },
  //           body: JSON.stringify(dataSend)
  //         }
  //         console.log("options", options)
  //         const response = await fetch(`${process.env.REACT_APP_FORGOTPASSWORD_API}`, options);

  //         console.log("response", response);
  //         if (response?.status === 200) {
  //           return { response: response, msg: "Please check your email account for the OTP.", status: 200, type: "email", userDetails: existingUsers?.data?.listUsers?.items[0] };
  //         }

  //       } catch (error) {
  //         console.error("An error occurred:", error);
  //       }
  //     } else {
  //       try {
  //         const countryCode = userDetails?.countryCode || ''; // Handle missing country code
  //         const formattedPhoneNumber = `${countryCode}${userDetails?.PhoneNumber}`;
  //         // console.log("formattedPhoneNumber", formattedPhoneNumber);
  //         const options: any = {
  //           method: 'POST',
  //           headers: {
  //             "Content-Type": "application/json",
  //             "Accept": "application/json, text/plain, */*",
  //           },

  //           body: JSON.stringify({
  //             action: "sendOTP",
  //             phoneNumber: formattedPhoneNumber,
  //             userId: userDetails.id
  //           })
  //         }
  //         // console.log("options", options);
  //         const response = await fetch(`${process.env.REACT_APP_FORGOTPASSWORD_API}`, options);
  //         // console.log("response", response);
  //         const data = await response.json();

  //         if (response?.status === 200) {
  //           return { response: data, msg: "Please check your mobile for the OTP.", status: 200, type: "mobile", userDetails: userDetails }
  //         }
  //       } catch (error) {
  //         console.error("An error occurred:", error);
  //       }
  //     }
  //   } else {
  //     return { status: 404, msg: 'user not found' };
  //   }
  // };

  const validateotp = async (userName: any, setEmailID: any, phoneNumber: any) => {
    try {
      console.log("userName", userName);
      const filterVar = {
        filter: {
          or: [
            { EmailId: { eq: userName } },
            { PhoneNumber: { eq: userName } }
          ]
        }
      }
      const userResp = await API.graphql(graphqlOperation(listUsers, filterVar));
      console.log("servicesuserResp", userResp);
      //  if(userResp){
      //    setEmailID(email);  // Update the state with the email
      //  } 
      return userResp;
    } catch (error) {
      console.error("Error validating OTP:", error);
      throw new Error("Unable to validate OTP");
    }
  };

  const UpdatePassword = async (email: any, password: any, restPWD: any) => {
    // console.log("email", email);
    // console.log("password", password);

    const hashPassword = (password: any) => {
      try {
        const hashedPassword = SHA256(password).toString();
        // console.log("hashedPassword", hashedPassword)
        return hashedPassword;
      } catch (error) {
        console.error('Error hashing password:', error);
        return '';
      }
    };
    // console.log("hashedPassword", hashPassword)

    const existingUsers: any = await API.graphql(
      graphqlOperation(listUsers, {

        filter: {
          or: [
            { EmailId: { eq: email } },
            { PhoneNumber: { eq: email } }
          ]
        }
      })

    );
    // debugger

    if (existingUsers && existingUsers?.data?.listUsers?.items?.length > 0) {
      const data: any = {
        id: existingUsers?.data?.listUsers?.items[0].id,
        Password: hashPassword(password)
      }

      if (restPWD === "Yes") {
        data.resetPWD = "No"
      }
      const userResp: any = await API.graphql(graphqlOperation(updateUser, { input: data }));

      return { 'msg': 'user updated successfully', data: userResp };
    }
  };

  const updateEmail = async (email: any, userId: any) => {
    const data = {
      id: userId,
      EmailId: email
    }
    const userResp: any = await API.graphql(graphqlOperation(updateUser, { input: data }));
    // console.log("userResp update", userResp);
    if (userResp?.data?.updateUser?.EmailId) {
      return userResp
    }
  }

  const updatePhone = async (email: any, userId: any) => {
    const data = {
      id: userId,
      PhoneNumber: email
    }
    const userResp: any = await API.graphql(graphqlOperation(updateUser, { input: data }));
    console.log("userResp update", userResp);
    if (userResp?.data?.updateUser?.PhoneNumber) {
      return userResp
    }
  }

  return {
    SendEmail,
    validateotp,
    UpdatePassword,
    updateEmail,
    updatePhone
  }
}

export default forgotPasswordServices;
