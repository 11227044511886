import { useState, useEffect } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dayjs from "dayjs";
import { CiCalendarDate } from "react-icons/ci";
import "dayjs/locale/es";
import isBetween from "dayjs/plugin/isBetween";
import userService from '../../../services/users/user.service';
// import './style.css';
import { SlotInfo } from 'react-big-calendar';
import './AvailabilityList.css';
import { useNavigate } from "react-router-dom";
// import useAuthRedirect from "../../../utils/Redirect/useAuthRedirect";
import Loader from "../shared/loadercomponent";

dayjs.extend(isBetween);
dayjs.locale("en");

function AvailabilityList() {
  const localizer = dayjsLocalizer(dayjs);

  const [selectedDates, setSelectedDates] = useState<{ Date: string; Timings: string[] }[]>([]);
  const [selectedDateTime, setSelectedDateTime] = useState<{ Date: string; Time: string } | undefined>(undefined);
  const [timeSlot, setTimeSlot] = useState<any>('');

  const [deletedSlots, setDeletedSlots] = useState<{ [key: string]: string[] }>({});
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('week');
  const [availableDate, setAvailableDate] = useState('');
  // const logUser: any = localStorage.getItem('userData');
  // const logUserObject = JSON.parse(logUser);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [doctors, setDoctors] = useState<any>('');
  const [doctorDate, setdoctorDate] = useState<string[]>([]);
 // const logUserObject:any = useAuthRedirect();
 

  useEffect(() => {
    const fetchDoctorDates = async () => {

      console.log({ selectedDoctor })
      try {
        setLoading(true); 
        const doctorDates = await userService().getlistDoctorAvailableDates(selectedDoctor);
        const datesMap: any = doctorDates.reduce((acc: any, date: any) => {

          const dateString = dayjs(date?.Date).format("YYYY-MM-DD");

          const timeString = date?.Time;
          if (!acc[dateString]) {
            acc[dateString] = [];
          }
          acc[dateString].push(timeString);

          return acc;
        }, {});
        console.log("datesMap", datesMap)


        selectedDoctor && setSelectedDates(
          Object.entries(datesMap).map(([Date, Timings]) => ({
            Date,
            Timings: Timings as string[],
          }))
        );

        setLoading(false);

      } catch (error) {
        console.error('Error fetching doctor dates:', error);
        setLoading(false);
      }finally {
        setLoading(false); // Stop loading after fetching completes
      }
    };
    fetchDoctorDates();



  }, [selectedDoctor]);




  const handleDoctorChange = async (event: any) => {
    // console.log("event", event?.target.value)

    setSelectedDoctor(event?.target?.value);

    const response: any = await userService().ListDoctorsById(event?.target?.value);
    // console.log("doctorresponse", response);


    return setTimeSlot(response[0]?.TimeSlot || 15);
  };

  // console.log("selectedDoctor",selectedDoctor)


  console.log("selectedDoctor", selectedDoctor)



  const getDoctor = async () => {
    try {


      const time = await userService().ListDoctor('Doctor');
      console.log("response", time);

      const doctorIds = time.map(doctor => doctor.id);
      setdoctorDate(doctorIds);
      setDoctors(time);


      // console.log("selectedDoctor", doctorid)
      const doctorId = time.map(doctor => doctor.id);


    } catch (error) {
      console.error('Error fetching doctors:', error);
    }
  };

  useEffect(() => {
    getDoctor();
  }, []);




  const handleSelectSlot = async (slotInfo: SlotInfo) => {
    const startDate = dayjs(slotInfo.start);
    const dateString = startDate.format("YYYY-MM-DD");
    const timeString = startDate.format("HH:mm");


    try {

      // const isDisabled = !dayjs(slotInfo.start).isBetween(dayjs(), dayjs().add(30, "day"), 'day', '[]');
      // if (isDisabled) {
      //   console.log("Slot is disabled.");
      //   return;
      // }
      const isDateInRange = startDate.isBetween(dayjs(), dayjs().add(90, "day"), 'day', '[]');
      const isTimeDisabled = startDate.isBefore(dayjs(), 'minute');

      if (!isDateInRange || isTimeDisabled) {
        console.log("Slot is disabled.");
        return;
      }

      if (selectedDoctor && selectedDoctor != 'Choose Doctor') {
        setSelectedDateTime(prevState => ({ ...prevState, Date: dateString, Time: timeString }));

        try {
          const doctorDates = await userService().getlistDoctorAvailableDates(selectedDoctor);
          const dateRecord = doctorDates.find((date: any) =>
            dayjs(date.Date).format("YYYY-MM-DD") === dateString
          );
          console.log("view", view)

          if (view === 'month') {
            const times: any = [];
            const timeSlotMig = parseInt(timeSlot);
            for (let hour = 10; hour <= 19; hour++) {
              for (let minutes = 0; minutes < 60; minutes += timeSlotMig) {
                times.push(dayjs().hour(hour).minute(minutes).format("HH:mm"));
              }
            }

            const existingRecord = selectedDates.find(dateObj => dateObj.Date === dateString);

            if (existingRecord) {
              const newTimes = existingRecord.Timings.filter((time: string) => !times.includes(time));

              if (newTimes.length < 10) {
                await userService().DeleteDoctorAvaiableDate(dateRecord.id);
                setSelectedDates(prev => prev.filter(dateObj => dateObj.Date !== dateString));
              } else {
                await userService().UpdateDoctorAvaiableDate({
                  id: dateRecord.id,
                  DoctorID: selectedDoctor,
                  Date: dateString,
                  Time: newTimes,
                });

                setSelectedDates(prev => prev.map(dateObj =>
                  dateObj.Date === dateString ? { ...dateObj, Timings: newTimes } : dateObj
                ));
              }
            } else {
              console.log("monthcreate");
              await userService().CreateDoctorAvailableDate({
                DoctorID: selectedDoctor,
                Date: dateString,
                Time: times,
              });
              setSelectedDates(prev => [...prev, { Date: dateString, Timings: times }]);
            }
          } else if (view === 'week') {

            if (dateRecord) {
              const existingDate = selectedDates.find(dateObj => dateObj.Date === dateString);
              console.log("existingDate", existingDate)

              // const timeExists = existingDate?.Timings.includes(timeString);
              const timeExists = existingDate?.Timings.flat()

              const exist = timeExists?.includes(timeString)

              console.log("timeExists", exist)
              if (exist) {
                console.log("deleteexist week times", timeString)

                // const newTimes = existingDate?.Timings.filter((time: string) => time !== timeString) || [];
                const existTime = existingDate?.Timings.flat()
                const newTimes = existTime?.filter((time: string) => time !== timeString) || [];

                console.log("deleteexist week times", newTimes)
                if (newTimes.length === 0) {
                  await userService().DeleteDoctorAvaiableDate(dateRecord.id);
                  setSelectedDates(prev => prev.filter(dateObj => dateObj.Date !== dateString));
                } else {
                  await userService().UpdateDoctorAvaiableDate({
                    id: dateRecord.id,
                    DoctorID: selectedDoctor,
                    Date: dateRecord.Date,
                    Time: newTimes,
                  });
                  console.log("delete week times", newTimes)
                  setSelectedDates(prev => prev.map(dateObj =>
                    dateObj.Date === dateString ? { ...dateObj, Timings: newTimes } : dateObj
                  ));
                }
                setDeletedSlots(prev => ({
                  ...prev,
                  [dateString]: [...(prev[dateString] || []), timeString],
                }));
              } else {
                console.log("weektimecreate");
                const newTimes = [...(timeExists || []), timeString];


                console.log("newTimes", newTimes)

                await userService().UpdateDoctorAvaiableDate({
                  id: dateRecord.id,
                  DoctorID: selectedDoctor,
                  Date: dateRecord.Date,
                  Time: newTimes,
                });
                console.log("updated")
                setSelectedDates(prev => prev.map(dateObj =>
                  dateObj.Date === dateString ? { ...dateObj, Timings: newTimes } : dateObj
                ));
                setDeletedSlots(prev => {
                  const newDeletedSlots = { ...prev };
                  if (newDeletedSlots[dateString]) {
                    newDeletedSlots[dateString] = newDeletedSlots[dateString].filter(time => time !== timeString);
                    if (newDeletedSlots[dateString].length === 0) {
                      delete newDeletedSlots[dateString];
                    }
                  }
                  return newDeletedSlots;
                });
              }
            } else {

              console.log("weekcreate");
              await userService().CreateDoctorAvailableDate({
                DoctorID: selectedDoctor,
                Date: dateString,
                Time: [timeString],
              });
              setSelectedDates(prev => [...prev, { Date: dateString, Timings: [timeString] }]);
            }
          }
        } catch (error) {
          console.error("Error updating doctor availability:", error);
        }

      } else {
        alert("please select doctor")
      }



    } catch (error) {
      console.error("Error updating doctor availability:", error);
    }
  };



  // const doctorlist =doctors
  // console.log("doctorlist",doctorlist)


  const navigate = (action: 'PREV' | 'NEXT') => {
    setCurrentDate(prevDate =>
      action === 'PREV' ? prevDate.subtract(1, 'month') : prevDate.add(1, 'month')
    );
  };

  const components = {
    event: (props: any) => {
      const { data } = props.event;
      return (
        <div style={{ background: data.x > 15 ? "white" : "green" }}>
          <CiCalendarDate />
          {props.title}
        </div>
      );
    },
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  const maxDate = currentDate.add(60, "day");
  const stepValue = timeSlot ? parseInt(timeSlot) : 15;
  // console.log("selectedDoctor",selectedDoctor)
  console.log(doctors, "doctors")

  return (
    <>
    {loading ? ( // Show loader while loading is true
        <Loader />
      ) : (
      <><section className="admin-section text-center"
          >
            <div className="container">
              <div className="bannerTitle">
                <h1>Doctor Availability</h1>
              </div>
            </div>
          </section><div>
              <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                <li>
                  <a className="aColor" href="/">Home  /&nbsp;</a>
                </li>
                <li>Doctor Availability</li>
              </ul>
            </div><div className="container">
              <div className="calenderhmb"
                style={{
                  height: "95vh",
                  // width: "90vw",
                  marginBottom: "62px",
                }}
              >

                <div className="row">

                  {/* <div style={{ display: "flex", justifyContent: "left", marginTop: "40px", marginLeft: '20px' }}> */}
                  <select className="selectdoctor" onChange={handleDoctorChange} value={selectedDoctor}>
                    <option>Choose Doctor</option>
                    {Array.isArray(doctors) && doctors?.map((doctor: any) => (
                      <option key={doctor.id} value={doctor.id}>
                        {doctor.FirstName + " " + doctor.LastName}
                      </option>
                    ))}
                  </select>
                  {/* </div> */}



                  {/* <button onClick={() => navigate('PREV')}>Previous Month</button>
<button onClick={() => navigate('NEXT')}>Next Month</button> */}
                  <Calendar
                    localizer={localizer}
                    date={currentDate.toDate()}
                    toolbar={true}
                    defaultView="week"
                    views={["month", "week"]}
                    onNavigate={(date, view) => setCurrentDate(dayjs(date))}
                    selectable={true}
                    onSelectSlot={handleSelectSlot}
                    onView={(view) => setView(view)}
                    timeslots={1}
                    step={stepValue}
                    min={dayjs().hour(10).minute(0).toDate()}
                    max={dayjs().hour(20).minute(0).toDate()}
                    formats={{
                      dayHeaderFormat: (date: any) => dayjs(date).format("ddd, MMM D"),
                      dayFormat: (date: any) => dayjs(date).format("D"),
                      weekdayFormat: (date: any) => dayjs(date).format("dddd"),
                      timeGutterFormat: (date: any) => dayjs(date).format("HH:mm"),
                      agendaTimeFormat: (date: any) => dayjs(date).format("HH:mm"),
                      agendaDateFormat: (date: any) => dayjs(date).format("dddd, MMMM DD, YYYY"),
                    }}
                    components={components}
                    dayPropGetter={(date: Date, time: any) => {
                      const dateString = dayjs(date).format("YYYY-MM-DD");
                      const isDisabled = !dayjs(date).isBetween(dayjs(), dayjs().add(90, "day"), 'day', '[]');
                      const hasAvailability = selectedDates?.find(dateObj => dateObj.Date === dateString);
                      return {
                        style: {
                          backgroundColor: hasAvailability ? "#90ee90" : "white",
                          pointerEvents: isDisabled ? 'none' : undefined,
                          opacity: isDisabled ? 0.5 : 1,
                          ...(isDisabled ? { backgroundColor: "#ddd" } : {}),
                        },
                      };
                    } }
                    slotPropGetter={(date: Date) => {
                      const dateString = dayjs(date).format("YYYY-MM-DD");
                      const timeString = dayjs(date).format("HH:mm");
                      const now = dayjs();

                      const isDateInRange = dayjs(date).isBetween(dayjs(), dayjs().add(90, "day"), 'day', '[]');
                      const isTimeDisabled = dayjs(date).isBefore(now, 'minute');

                      const isSelectedSlot = selectedDates?.find(dateObj => {
                        if (dateObj?.Date === dateString) {
                          const flattenedTimings = dateObj?.Timings.flat();
                          return flattenedTimings?.includes(timeString);
                        }
                        return false;
                      });

                      const isDeletedSlot = deletedSlots[dateString]?.includes(timeString);

                      return {
                        style: {
                          backgroundColor: isSelectedSlot ? "#90ee90" : "white",
                          pointerEvents: isDateInRange && !isTimeDisabled ? 'auto' : 'none',
                          opacity: isDateInRange && !isTimeDisabled ? 1 : 0.5,
                          ...(isDateInRange && !isTimeDisabled ? {} : { backgroundColor: "#ddd" }),
                        },
                      };
                    } } />
                </div>
              </div>
            </div></>
       )}
    </>

  );
}

export default AvailabilityList;