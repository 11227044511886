import { useState, useEffect, useCallback } from 'react';


export function useIdleTimeout(timeout = 1000 * 900) {
    const [isActive, setIsActive] = useState(true);

    const handleActivity = useCallback(() => {
        setIsActive(true);
    }, []);

    useEffect(() => {
        let timer: any;

        const resetTimer = () => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                setIsActive(false);
            }, timeout);
        };

        const handleMouseMove = () => {
            resetTimer();
            handleActivity();
        };

        const handleKeyDown = () => {
            resetTimer();
            handleActivity();
        };

        const handleScroll = () => {
            resetTimer();
            handleActivity();
        };

        resetTimer();

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('scroll', handleScroll);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleActivity, timeout]);

    return isActive;
}
