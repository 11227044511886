import React, { useEffect, useRef, useState } from "react";
import userService from './../../../services/users/user.service';
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PrescriptionUtils from "./../../../utils/prescription";
import PrescriptionServices from "../../../services/prescription";
import FileInput from "../Reports/renderfiletype";
import { validationSchema } from "../Reports/validationSchema";
import { Helmet } from 'react-helmet';

interface Prescription {
  patientID: string;
  updatedBy: string;
  patientName: string;
  patientEmail: string;
  patientPhoneNumber: string;
  doctorID: string;
  Status: string;
  file: any[];
  appointmentID: string;
  type: string;
  foodDiet: string;
}

const initialState: Prescription = {
  patientID: "",
  updatedBy: "",
  patientName: "",
  patientEmail: "",
  patientPhoneNumber: "",
  doctorID: "",
  Status: "",
  file: [{ fileType: "", name: [], type: "", description: "" }],
  appointmentID: "",
  type: "prescriptions",
  foodDiet: ""
}

const AddPrescription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errMsg, setErrorMsg] = useState<string>();
  // const [formData, setFormData] = useState(initialState);
  const [formData, setFormData] = useState(initialState);
  const [users, setUsers] = useState([]);
  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);

  async function ProvInfo() {
    const patients: any = await userService().ListDoctor("Patient");
    setUsers(patients);

    // const selectedUser = patients?.find((user: any) => user?.id === userID?.id);
    // setSingleuser(selectedUser);
    // const singleReport = await ReportServices().getReportSingle(location?.state?.id);

    const reportsbasedonpatirntId = await PrescriptionServices().prescriptionListBypatientID(location?.state?.appointmentID);
    // console.log("reportsbasedonpatirntId", reportsbasedonpatirntId);
    const combinedFiles: any = {};
    reportsbasedonpatirntId.forEach((report: any) => {
      report?.file?.forEach((file: any) => {
        const { fileType, name } = file;
        if (!combinedFiles[fileType]) {
          combinedFiles[fileType] = {
            fileType,
            names: [],
            type: file?.type,
            description: file?.description,
            data: "existed"
          };
        }
        combinedFiles[fileType].names.push(...name);
      });
    });
    const combinedFileList: any = Object.values(combinedFiles)?.map((fileGroup: any) => ({
      fileType: fileGroup?.fileType,
      name: fileGroup?.names,
      type: fileGroup?.type,
      description: fileGroup?.description,
      data: "existed"
    }));
    // console.log("combinedFileList", combinedFileList);
    const combinedReport: any = {
      id: reportsbasedonpatirntId[0]?.id,
      reports: reportsbasedonpatirntId,
      file: combinedFileList,
      foodDiet: reportsbasedonpatirntId[0]?.foodDiet,
      patientID: reportsbasedonpatirntId[0]?.patientID,
      appointmentID: reportsbasedonpatirntId[0]?.appointmentID,
      updatedBy: reportsbasedonpatirntId[0]?.updatedBy,
      type: "prescriptions"
    }
    setFormData(combinedReport);
  }



  useEffect(() => {
    ProvInfo();
  }, [location?.state?.appointmentID]);

  const handleSubmit = async (values: Prescription) => {
    let result: any;
    if (location?.state?.appointmentID) {
      // values.doctorID = location?.state?.doctorId
      const s3Check = await PrescriptionUtils().check(values);
      // console.log("s3Check", s3Check);
      // values.type = "prescriptions"
      result = await PrescriptionUtils().prescriptionUpdateUtil(values);
      // console.log("result", result);
    } else {
      values.appointmentID = location?.state?.appointmentId
      values.patientID = location?.state?.patientID
      values.doctorID = location?.state?.doctorId
      result = await PrescriptionUtils().handleSubmit(values);
    }
    if (result) {
      // navigate('/appointmentHistory');
      navigate('/ViewAppointment', { state: { patientID: location?.state?.patientID, PhoneNumber: location?.state?.PhoneNumber } });
    }
  };


  // console.log("userID", userID);
  return (
    <>
      <Helmet>
        <title>Add Prescription</title>
        <meta name="description" content="This is the My Prescription page description" />
      </Helmet>
      <section className="admin-section text-center"
      >
        <div className="container">
          <div className="bannerTitle">
            <h1>Add Prescription</h1>
          </div>
        </div>
      </section>
      <div>
        <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
          <li>
            <a className="aColor" href="/">Home  /&nbsp;</a>
          </li>
          <li>Add Prescription</li>
        </ul>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="mb-3">
              <Formik
                initialValues={location?.state?.appointmentID ? formData : initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form autoComplete="off">
                    <h2 className="justify-content-center" style={{ marginTop: "100px" }}>Upload Prescription</h2>
                    <div>
                      {/* {formData?.file?.map((file: any, index: any) => ( */}
                      <FileInput
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                      {/* ))} */}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <button type="submit" className="btn btn-primary mt-3">
                        Upload
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPrescription;