import React, { useState, useEffect, useRef } from 'react';
// import { Card, Button, Tab, Tabs, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimeout } from '../../views/shared/header/sessionExpire';
import { PasswordInput } from '../Register/registerValidationSchema';
import { FileUpload, RemoveProfileforAll } from '../../views/shared/fileupload';
import Users from '../../../utils/users';
import forgotPasswordServices from '../../../services/ForgotPasswordServices';
import { SpinningCircles } from 'react-loading-icons';
import { Card, Button, Tab, Tabs, Col, Row, Form, Modal } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import { ProfileDetailsValidationSchema, ProfilePhoneValidationSchema, ProfileValidationSchema } from './profileValidationSchema';
import { SHA256 } from 'crypto-js';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import DoctorSpecialization from '../../views/shared/DoctorSpec';
import userService from '../../../services/users/user.service';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';


function UserProfile() {
    const navigate = useNavigate();
    const location = useLocation();

    const [key, setKey] = useState<string>('profile');
    const [LogUser, setLogUser] = useState<any>();

    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [ErrMsg, setErrMsg] = useState("");
    const [PasswordErr, setPasswordErr] = useState("");
    const [SuccessMsg, setSuccessMsg] = useState("");
    const [directory, setDirectory] = useState('');
    const [UserDetails, setUserDetails] = useState<any>();

    const [timer, setTimer] = useState<number>(60);
    const [userName, setUserName] = useState<any>({ userName: "", newUserName: "", stateType: "" });
    const [errSubmitMsg, setSubmitMsg] = useState("");
    const [Response, setResponse] = useState<any>();
    const [Loader, setLoader] = useState(false);
    const [getModal, setModal] = useState(false);
    const [CurrentOTP, setCurrentOTP] = useState("");
    const [OTPErr, setOTPErr] = useState("");
    const [ResendMsg, setResendMsg] = useState('');
    const [userID, setUserID] = useState("");
    const [showMsg, setShowMsg] = useState("");

    const [newProfile, setNewProfile] = useState<any>(true);
    const [file, setFile] = useState<any>([]);

    const logUserOTP: any = localStorage.getItem('profileDetails');
    const logUserObjectOTP = JSON.parse(logUserOTP);
    const [passwordMsg, setPasswordMsg] = useState('');
    const [emailMsg, setEmailMsg] = useState('');
    const [mobileMsg, setMobileMsg] = useState('');
    const [special, setSpecial] = useState();
    const [profileMsg, setProfileMsg] = useState("");
    const [type, setType] = useState("");
    const [showFields, setShowFields] = useState(false);
    const [phoneFields, setPhoneFields] = useState(false);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const fileInputRef: any = useRef(null);
    const [fileUploadKey, setFileUploadKey] = useState(Date.now());

    const [email, setEmail] = useState(UserDetails?.EmailId || ''); // Keep track of the current email
    const [phoneNumber, setPhoneNumber] = useState(UserDetails?.PhoneNumber || '');



    let intervalId: NodeJS.Timeout | null = null;

    const setTimerDetails = () => {
        // Clear the existing interval before starting a new one
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        // Reset timer value (assuming it's 60 seconds initially)
        setTimer(60);
        // Start a new interval
        intervalRef.current = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(intervalRef.current as NodeJS.Timeout);
                    intervalRef.current = null;
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000)
    };

    useEffect(() => {
        // Clean up the interval on component unmount
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const initialValues = {
        PhoneNumber: '',
        type: 'new',
    };
    const handleKeyPress = (e: any, callback: any) => {
        if (e?.key === 'Enter') {
            e.preventDefault();
            callback();
        }
    };

    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(UserDetails?.userProfile, setUserDetails, 'userProfile', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };
    // validating and submitting OTP for Old EmailId and new EmailId
    const SubmitOTP = async () => {

        if (userName?.stateType === "new") {
            const currentTime = new Date();
            const formattedTime = currentTime.toISOString();
            const getOTP: any = await forgotPasswordServices().validateotp(userName?.newUserName);
            const hashOTP = await SHA256(CurrentOTP).toString();
            if (getOTP?.data?.listUsers?.items?.length > 0 && getOTP?.data?.listUsers?.items[0]?.Otp === CurrentOTP) {
                const email = getOTP?.data?.listUsers?.items[0]?.EmailId;
                const phoneNumber = getOTP?.data?.listUsers?.items[0]?.PhoneNumber;
                setModal(false);
                setTimer(60);
                const fieldKey = type === "email" ? 'email' : 'PhoneNumber';
                // const fieldState = type === "email" ? { setShowFields: true } : { setPhoneFields: true };
                // const fieldState = type === "email" ? (setShowFields(true) && setPhoneFields(true)) : setPhoneFields(true);

                const fieldState = type === "email" ? (setShowFields(true), setPhoneFields(false)) : (setShowFields(false), setPhoneFields(true));
                navigate({
                    pathname: '/UpdateProfile',
                    // search: `?email=${Response?.userDetails?.EmailId}&dt=${formattedTime}`
                }, {
                    state: { email: email, phoneNumber: phoneNumber, key: fieldKey, fieldState }
                });
                // navigate({
                //     pathname: '/UpdateProfile',
                //     // search: `?email=${Response?.userDetails?.EmailId}&dt=${formattedTime}`
                // }, {
                //     state: { email: email, phoneNumber: phoneNumber, key: 'email', setShowFileds: true },
                // });
            } else if (logUserObjectOTP?.otp === hashOTP && logUserObjectOTP?.type === "email") {
                const updateemail = await forgotPasswordServices().updateEmail(userName?.newUserName, userID);
                if (updateemail) {
                    setModal(false);
                    setSuccessMsg("");
                    setTimer(60);
                    setEmailMsg("");
                    setShowMsg("Your Email Id has been updated successfully. Please login again to continue");
                    setTimeout(() => {
                        localStorage.removeItem("profileDetails");
                        localStorage.removeItem("userData");
                        navigate('/login');
                    }, 5000); // 5000 milliseconds = 5 seconds
                }
            } else if (logUserObjectOTP?.otp === hashOTP && logUserObjectOTP?.type === "mobile") {
                const updatePhone = await forgotPasswordServices().updatePhone(userName?.newUserName, userID);
                // console.log("updatePhone", updatePhone);
                if (updatePhone) {
                    setModal(false);
                    setSuccessMsg("");
                    setTimer(60);
                    setMobileMsg("");
                    setShowMsg("Your Mobile number has been updated successfully. Please login again to continue");
                    setTimeout(() => {
                        localStorage.removeItem("profileDetails");
                        localStorage.removeItem("userData");
                        navigate('/login');
                    }, 5000); // 5000 milliseconds = 5 seconds
                }
            }
            else {
                setOTPErr("Invalid OTP");
            }

        } else {
            // console.log("CurrentOTP", CurrentOTP);
            const currentTime = new Date();
            const formattedTime = currentTime.toISOString();
            const getOTP: any = await forgotPasswordServices().validateotp(userName?.userName);
            // console.log("getOTP", getOTP);

            const hashOTP = await SHA256(CurrentOTP).toString();
            // console.log("hashOTP", hashOTP);

            if (getOTP && getOTP?.data?.listUsers?.items[0]?.Otp === CurrentOTP) {
                const email = getOTP?.data?.listUsers?.items[0]?.EmailId;
                const phoneNumber = getOTP?.data?.listUsers?.items[0]?.PhoneNumber;
                setModal(false);
                setTimer(60);
                const fieldKey = type === "email" ? 'email' : 'PhoneNumber';
                // const fieldState = type === "email" ? { setShowFields: true } : { setPhoneFields: true };
                // const fieldState = type === "email" ? (setShowFields(true) && setPhoneFields(true)) : setPhoneFields(true);

                const fieldState = type === "email" ? (setShowFields(true), setPhoneFields(false)) : (setShowFields(false), setPhoneFields(true));

                navigate({
                    pathname: '/UpdateProfile',
                    // search: `?email=${Response?.userDetails?.EmailId}&dt=${formattedTime}`
                }, {
                    state: { email: email, phoneNumber: phoneNumber, key: fieldKey, fieldState }
                });
            } else if (logUserObjectOTP?.otp === hashOTP && logUserObjectOTP?.type === "email") {
                const updateemail = await forgotPasswordServices().updateEmail(userName, userID);
                if (updateemail) {
                    setModal(false);
                    setSuccessMsg("");
                    setTimer(60);
                    // setShowMsg("Your Email Id has been updated successfully. Please login again to continue");
                    setTimeout(() => {
                        localStorage.removeItem("profileDetails");
                        localStorage.removeItem("userData");
                        navigate('/login');
                    }, 10000); // 10000 milliseconds = 10 seconds
                }
            } else if (logUserObjectOTP?.otp === hashOTP && logUserObjectOTP?.type === "mobile") {
                const updatePhone = await forgotPasswordServices().updatePhone(userName, userID);
                if (updatePhone) {
                    setModal(false);
                    setSuccessMsg("");
                    setTimer(60);
                    // setShowMsg("Your Mobile number has been updated successfully. Please login again to continue");
                    setTimeout(() => {
                        localStorage.removeItem("profileDetails");
                        localStorage.removeItem("userData");
                        navigate('/login');
                    }, 10000); // 10000 milliseconds = 10 seconds
                }
            }
            else {
                setOTPErr("Invalid OTP");
            }
        }
    };

    useEffect(() => {
        const logUser: any = localStorage.getItem('userData');// for userId
        const logUserObject = JSON.parse(logUser);
        setLogUser(logUserObject)
        if (!logUserObject?.accessToken) {
            navigate('/login', { state: { url: "/UpdateProfile" } });
        }
    }, []);

    const isIdle = useIdleTimeout(1000 * 30);
    const isActive = useIdleTimeout(1000 * 30);
    const [token, setToken] = useState<any>();
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const getUserDetails = async () => {
        if (LogUser?.userId) {
            const specializationtList = await userService().getlistSpecializations() as any;
            const userDetails: any = await userService().getuserSingle(LogUser?.userId);
            const user = userDetails?.data?.getUser;
            if (userDetails) {
                const specName = specializationtList?.find((spec: any) => spec?.id === userDetails?.data?.getUser?.Specialization);
                setSpecial(specName?.name)
                // const updatedUserDetails = {
                //     ...userDetails?.data?.getUser,
                //     Specialization: specName?.name,
                // };
                if (logUserObject) {
                    logUserObject.userName = user?.FirstName + " " + user?.LastName;
                    const updatedUserData = JSON.stringify(logUserObject);
                    localStorage.setItem('userData', updatedUserData);
                }
                setUserDetails(user);
                setNewProfile(false);
            }
        }
    }
    // console.log("UserDetails", UserDetails);
    // console.log("username", userName);
    useEffect(() => {
        getUserDetails()
    }, [LogUser, newProfile]);

    //for profile picture update
    const handleupdate = async () => {
        const profile = await Users().handleUpdateProfilePicture(file, UserDetails, LogUser, directory, setNewProfile);
        if (profile?.msg === "Profile picture updated successfully") {
            // Clear the file input after successful upload
            setFile(null);
            setFileUploadKey(Date.now());
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    }

    const ChangePassword = async () => {
        if (!Password || !ConfirmPassword) {
            setErrMsg("Please enter the password and confirm it.");
            setTimeout(() => {
                setErrMsg("");
            }, 5000);
            return;
        }
        if (ConfirmPassword === Password) {
            const userResponse = await userService().UpdatePassword(UserDetails?.EmailId, Password);
            console.log("userResponse", userResponse);
            if (userResponse) {
                setErrMsg("");
                setPasswordMsg("Password updated successfully");
                setPassword('');
                setConfirmPassword('');
                setTimeout(() => {
                    setPasswordMsg("");
                }, 5000);
                setNewProfile(true);
            }
        } else {
            setErrMsg("Password and Confirm Password should be same");
        }
    }

    const handlePhoneSubmit: any = async (values: any, { setSubmitting, resetForm }: any) => {
        console.log("values", values);
        setCurrentOTP("");
        localStorage?.removeItem("profileDetails");
        setOTPErr("");
        setResendMsg("");
        const sendvalues = values?.EmailId ? values?.EmailId : values?.PhoneNumber;
        setLoader(true);
        // setUserName(sendvalues);
        const userResponse: any = await forgotPasswordServices().SendEmail(sendvalues, userName?.userName, values?.type);
        console.log("userResponse in account", userResponse);
        if (userResponse) {
            setLoader(false);
            if (userResponse?.type === "email" && userResponse?.status === 200) {
                setSuccessMsg(userResponse?.msg);
                setSubmitMsg("");
                setModal(true);
                setEmail("");
                setCurrentOTP("");
                resetForm("");
                setTimer(60);
                setType("email");
                setTimerDetails();

                if (userResponse?.stateType === "new") {
                    // Update newUserName while keeping userName the same
                    setUserName((prevState: any) => ({
                        ...prevState,
                        newUserName: userResponse?.userDetails?.EmailId,
                        stateType: userResponse?.stateType
                    }));
                } else {
                    setUserName({
                        userName: userResponse?.userDetails?.EmailId,
                        // newUserName: prevState?.newUserName
                    });
                }
                // setUserName(userResponse?.userDetails?.EmailId);
                setUserID(userResponse?.userId);
                localStorage.setItem("profileDetails", JSON.stringify({ userName: userName?.newUsername, otp: userResponse?.data?.otp, type: "email" }));
                // localStorage.setItem("profileDetails", JSON.stringify({ Email: values?.EmailId, Phone: values?.phoneNumber, otp: userResponse?.data?.otp, type: "email" }));
            } else if (userResponse?.type === "mobile" && userResponse?.status === 200) {
                // setMobileMsg(userResponse?.msg);
                setSuccessMsg(userResponse?.msg);
                setResponse(userResponse);
                setSubmitMsg("");
                setModal(true);
                setPhoneNumber("");
                setCurrentOTP("");
                resetForm("");
                setTimer(60);
                setType("mobile");
                setTimerDetails();
                if (userResponse?.stateType === "new") {
                    // Update newUserName while keeping userName the same
                    setUserName((prevState: any) => ({
                        ...prevState,
                        newUserName: userResponse?.userDetails?.PhoneNumber,
                        stateType: userResponse?.stateType
                    }));
                } else {
                    setUserName({
                        userName: userResponse?.userDetails?.PhoneNumber,
                        // newUserName: prevState?.newUserName
                    });
                }
                // setUserName(userResponse?.userDetails?.PhoneNumber);
                setUserID(userResponse?.userId);
                localStorage.setItem("profileDetails", JSON.stringify({ Email: values?.EmailId, Phone: values?.phoneNumber, otp: userResponse?.data?.otp, type: "mobile" }));
            } else if (userResponse?.status === 404) {

                setSubmitMsg("User is not registered with us, please provide proper email/phone details");
            } else {
                userResponse?.type === 'email' ? setEmailMsg(userResponse?.msg) : setMobileMsg(userResponse?.msg);
                setSubmitMsg(userResponse?.msg);
            }
        }
    };

    const ResendEmail = async () => {
        setCurrentOTP("");
        setOTPErr("");
        console.log(userName, "userName resend");
        if (userName?.stateType === "new") {
            setLoader(true);
            try {
                const getResponse = await forgotPasswordServices().SendEmail(userName?.newUserName, userName?.userName, userName?.stateType);
                console.log("getResponse", getResponse);
                if (getResponse?.status === 200) {
                    setResendMsg('Please check your email account for the mail.');
                    setTimer(60);
                    setTimerDetails();
                }
            } catch (error) {
                console.error('Error resending email:', error);
            } finally {
                setLoader(false);
            }
        } else {
            setLoader(true);
            try {
                const getResponse = await forgotPasswordServices().SendEmail(userName?.userName);
                console.log("getResponse", getResponse);
                if (getResponse?.status === 200) {
                    setResendMsg('Please check your email account for the mail.');
                    setTimer(60);
                    setTimerDetails();

                }
            } catch (error) {
                console.error('Error resending email:', error);
            } finally {
                setLoader(false);
            }

        }
    };

    //onchange function for values
    const onchangeInput: any = (e: any) => {
        const name = e?.target?.name;
        let value = e?.target?.value;

        const spaceVal = value.trim().replace(/( {1,})/g, ' ');
        const hasLeadingOrTrailingSpaces = value !== spaceVal;

        if (hasLeadingOrTrailingSpaces) {
            setPasswordErr("Multiple spaces are not allowed");
        } else {

            const isValid: any = name === 'Password' ? PasswordInput(value) : '';

            if (isValid) {
                console.log("isValid", isValid)
                //validate the particular fields and update the error message and state values of the InputDetails based on isValid state
                if (isValid?.status === false) {
                    setPasswordErr(isValid?.message);
                } else {
                    setPasswordErr("");
                }
                setPassword(e?.target?.value);
            }
        }
    };

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }

    const handleSubmit = async (values: any) => {
        let updatedUser: any;

        if (logUserObject?.Role === "Doctor") {
            updatedUser = {
                ...UserDetails,
                id: UserDetails?.id,
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Specialization: values?.Specialization,
                Gender: values?.Gender
            };
        } else if (logUserObject?.Role === "Patient") {
            updatedUser = {
                ...UserDetails,
                id: UserDetails?.id,
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender,
                Address: values?.Address
            };
        } else {
            updatedUser = {
                ...UserDetails,
                id: UserDetails?.id,
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender
            };
        }

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(updatedUser, fieldsToRemove);

        const updateDetails = await userService().updateDoctorByData(sanitizedUserInfo);
        if (updateDetails) {
            setProfileMsg("Profile details updated successfully");
            setTimeout(() => {
                setProfileMsg("");
            }, 5000);
            //  setUserDetails(updateDetails?.data?.updateUser);
            getUserDetails();
        }
    }

    const closeModal = () => {
        setModal(false);
        // setModalHeading("");
    };
    // console.log("setCurrentOTP", CurrentOTP);
    // console.log("location", location?.state?.fieldState);

    return (
        <>
            <Helmet>
                <title>My Account</title>
                <meta name="description" content="This is the My Account page description" />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>My Account</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>My Account</li>
                </ul>
            </div>

            <div className="container" style={{ marginBottom: '20px', boxShadow: '0 0 5px 0 grey', backgroundColor: '#f3f3f3', padding: '20px', height: '550px' }}>
                <ul className="nav nav-tabs mt-3" style={{ padding: '20px' }}>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${key === 'profile' ? 'active' : ''}`}
                            onClick={() => {
                                setKey('profile');
                                setShowFields(false);
                                setPhoneFields(false);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Profile Details
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${key === 'picture' ? 'active' : ''}`}
                            // onClick={() => setKey('picture')}
                            onClick={() => {
                                setKey('picture');
                                setShowFields(false);
                                setPhoneFields(false);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Profile Picture
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${key === 'password' ? 'active' : ''}`}
                            // onClick={() => setKey('password')}
                            onClick={() => {
                                setKey('password');
                                setShowFields(false);
                                setPhoneFields(false);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Change Password
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${key === 'PhoneNumber' ? 'active' : ''}`}
                            // onClick={() => setKey('PhoneNumber')}
                            onClick={() => {
                                setKey('PhoneNumber');
                                setShowFields(false);
                                setPhoneFields(false);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Change Phone Number
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${key === 'email' ? 'active' : ''}`}
                            // onClick={() => setKey('email')}
                            onClick={() => {
                                setKey('email');
                                setShowFields(false);
                                setPhoneFields(false);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Change Email
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${key === 'details' ? 'active' : ''}`}
                            // onClick={() => setKey('details')}
                            onClick={() => {
                                setKey('details');
                                setShowFields(false);
                                setPhoneFields(false);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Change Profile Details
                        </button>
                    </li>
                </ul>

                {/* <ul className="nav nav-tabs mt-3" style={{ padding: '20px' }}>
                <li className="nav-item">
                    <a className={`nav-link ${key === 'profile' ? 'active' : ''}`} onClick={() => setKey('profile')} style={{ cursor: 'pointer' }}>Profile Details</a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${key === 'picture' ? 'active' : ''}`} onClick={() => setKey('picture')} style={{ cursor: 'pointer' }}>Profile Picture</a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${key === 'password' ? 'active' : ''}`} onClick={() => setKey('password')} style={{ cursor: 'pointer' }}>Change Password</a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${key === 'PhoneNumber' ? 'active' : ''}`} onClick={() => setKey('PhoneNumber')} style={{ cursor: 'pointer' }}>Change PhoneNumber</a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${key === 'email' ? 'active' : ''}`} onClick={() => setKey('email')} style={{ cursor: 'pointer' }}>Change Email</a>
                </li>
            </ul> */}
                <div className="tab-content">
                    {key === 'profile' && (
                        <div className="tab-pane active">
                            <div className='mt-3' style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                <div style={{ textAlign: 'left', marginRight: '15px', }}>
                                    <h3>Profile Details</h3>
                                    <p>UserName: {UserDetails?.FirstName + " " + UserDetails?.LastName}</p>
                                    <p>Email: {UserDetails?.EmailId}</p>
                                    <p>Mobile: {UserDetails?.PhoneNumber}</p>
                                    <p>Gender: {UserDetails?.Gender}</p>
                                    {logUserObject?.Role === "Patient" && <p>Address: {UserDetails?.Address
                                    }</p>}
                                    {logUserObject?.Role === "Doctor" && <p>Specialization: {special}</p>}
                                </div>
                                <img
                                    className='pmtml'
                                    src={UserDetails?.userProfile ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${UserDetails?.userProfile}` : "/images/profile.jpg"}
                                    alt="Profile"
                                    style={{ maxWidth: '100%', height: '200px', marginTop: '4px' }}
                                />
                            </div>
                        </div>
                    )}
                    {key === 'picture' && (
                        <div className="container tab-pane  show active">
                            <div className='rownmt-3' style={{ textAlign: 'left' }}>
                                <div className="col-md-6 ">
                                    {UserDetails?.userProfile !== null ?
                                        <>
                                            <img src={UserDetails?.userProfile ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${UserDetails?.userProfile}` : "/images/profile.jpg"} alt="Profile" style={{ maxWidth: '100%', height: '200px', marginBottom: '15px', marginTop: '15px' }} />
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                {UserDetails?.userProfile ?
                                                    <><p style={{ margin: 0, lineHeight: '24px' }}><strong>FileName:</strong>{UserDetails?.userProfile}</p><span
                                                        style={{ cursor: 'pointer', color: 'blue', fontSize: '24px', marginLeft: '10px', lineHeight: '30px', }}
                                                        onClick={handleRemove}
                                                    >
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </span></>
                                                    : null}
                                            </div></> : null}
                                    <div className="form-group">
                                        <FileUpload label={"choose profile"} setDirectory={setDirectory} setFile={setFile} key={fileUploadKey} />
                                    </div>
                                    <button className="btn" onClick={handleupdate}>Update Profile Picture</button>
                                </div>

                            </div>
                        </div>
                    )}
                    {key === 'password' && (
                        <div className="container  tab-pane  show active">
                            <div className="row">
                                <div className="col-md-6">
                                    <form autoComplete='off'>
                                        <p className="text-danger">{ErrMsg}</p>
                                        <p className="text-success">{passwordMsg}</p>
                                        <div className="form-group">
                                            <label htmlFor="newPassword">New Password</label>
                                            <input type="password" className="form-control" placeholder="Enter new password" onChange={onchangeInput} name="Password" value={Password} onKeyPress={(e) => handleKeyPress(e, ChangePassword)} autoComplete='new-name' />
                                        </div>
                                        <p className="text-danger">{PasswordErr}</p>
                                        <div className="form-group">
                                            <label htmlFor="confirmPassword">Confirm New Password</label>
                                            <input type="password" className="form-control" placeholder="Confirm new password" onChange={(e) => setConfirmPassword(e?.target?.value)} value={ConfirmPassword} onKeyPress={(e) => handleKeyPress(e, ChangePassword)} />
                                        </div>
                                        <button type="button" onClick={ChangePassword} className="btn">Change Password</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {key === 'PhoneNumber' && (
                        <div className="container  tab-pane  show active">
                            <div className="row">
                                <div className="col-md-6">
                                    <form>
                                        <p className="text-danger" style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>{mobileMsg}</p>
                                        <p className='text-success text-center' style={{ fontSize: '15px' }} >{showMsg}</p>
                                        <div className="container-fluid">
                                            {Loader && (
                                                <Modal size="sm" centered show={Loader}>
                                                    <Modal.Body>
                                                        <div className="form-group">
                                                            <h5>
                                                                <SpinningCircles fill="#b3b3b3" style={{ marginLeft: '20%', marginRight: '5%' }} />Loading
                                                            </h5>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            )}

                                            {phoneFields ? (

                                                <Row className="mt-5" style={{ marginBottom: '80px' }}>
                                                    <Col md={4}></Col>

                                                    <Col md={8} style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                                        {/* {Show ? ( */}
                                                        <Formik
                                                            initialValues={initialValues}
                                                            validationSchema={ProfilePhoneValidationSchema}
                                                            onSubmit={handlePhoneSubmit}
                                                            enableReinitialize
                                                        >
                                                            {({ isSubmitting, setFieldValue, submitForm }) => (
                                                                <Form autoComplete='off'>
                                                                    <h4 style={{ color: '#007bff', marginBottom: '20px' }}>Update PhoneNumber</h4>
                                                                    <Field
                                                                        type="tel"
                                                                        name="PhoneNumber"
                                                                        value={phoneNumber}
                                                                        placeholder="Enter New PhoneNumber"
                                                                        onChange={(e: any) => {
                                                                            setFieldValue('PhoneNumber', e?.target?.value)
                                                                            setPhoneNumber(e?.target?.value)
                                                                        }
                                                                        }
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage name="PhoneNumber" component="div" className="errMsg" />
                                                                    <Field type="hidden" name="type" value="new" />
                                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <Button
                                                                            variant="primary"
                                                                            onClick={submitForm}
                                                                            type="button"
                                                                            className="btn mt-3"
                                                                            disabled={isSubmitting}
                                                                            style={{ marginRight: '30px', backgroundColor: '#007bff', borderColor: '#007bff', marginTop: '20px' }}
                                                                        >
                                                                            Update
                                                                        </Button>
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Formik
                                                    initialValues={{ PhoneNumber: UserDetails?.PhoneNumber || '' }} // Initialize with UserDetails.EmailId if available
                                                    validationSchema={ProfilePhoneValidationSchema}
                                                    onSubmit={handlePhoneSubmit}
                                                    enableReinitialize
                                                >
                                                    {({ isSubmitting, setFieldValue, submitForm }) => (
                                                        <Form autoComplete='off'>
                                                            <div className="form-group">
                                                                <label htmlFor="PhoneNumber">PhoneNumber</label>
                                                                <Field
                                                                    type="tel"
                                                                    name="PhoneNumber"
                                                                    placeholder="Enter new PhoneNumber"
                                                                    className="form-control"
                                                                    readOnly
                                                                />
                                                                <ErrorMessage name="PhoneNumber" component="div" className="errMsg" />
                                                            </div>
                                                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                <p className="text-center mt-3" style={{ fontSize: '14px', color: '#6c757d', fontWeight: '500', marginTop: '10px' }}>
                                                                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '8px' }} />
                                                                    Please click on submit to update PhoneNumber
                                                                </p>
                                                            </div>
                                                            <Button
                                                                variant="primary"
                                                                onClick={submitForm} // Trigger form submission programmatically
                                                                type="button"
                                                                className="btn"
                                                                disabled={isSubmitting}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {key === 'email' && (
                        <div className="container  tab-pane  show active">
                            <div className="row">
                                <div className="col-md-6">
                                    <form>
                                        <p className="text-danger" style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>{emailMsg}</p>
                                        <p className='text-success text-center' style={{ fontSize: '15px' }} >{showMsg}</p>
                                        <div className="container-fluid">
                                            {Loader && (
                                                <Modal size="sm" centered show={Loader}>
                                                    <Modal.Body>
                                                        <div className="form-group">
                                                            <h5>
                                                                <SpinningCircles fill="#b3b3b3" style={{ marginLeft: '20%', marginRight: '5%' }} />Loading
                                                            </h5>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            )}

                                            {showFields === true ? (

                                                <Row className="mt-5" style={{ marginBottom: '80px' }}>
                                                    <Col md={4}></Col>

                                                    <Col md={8} style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                                        {/* {Show ? ( */}
                                                        <Formik
                                                            initialValues={initialValues}
                                                            validationSchema={ProfileValidationSchema}
                                                            onSubmit={handlePhoneSubmit}
                                                            enableReinitialize
                                                        >
                                                            {({ isSubmitting, setFieldValue, submitForm, resetForm, values }) => (
                                                                <Form
                                                                    // onSubmit={(e) => { e.preventDefault(); submitForm(); }}
                                                                    autoComplete='off'>
                                                                    <h4 style={{ color: '#007bff', marginBottom: '20px' }}>Update Email</h4>

                                                                    <Field
                                                                        type="email"
                                                                        name="EmailId"
                                                                        placeholder="Enter New email"
                                                                        value={email}
                                                                        onChange={(e: any) => {
                                                                            setFieldValue('EmailId', e?.target?.value)
                                                                            setEmail(e?.target?.value)
                                                                        }}
                                                                        className="form-control"

                                                                    />
                                                                    <ErrorMessage name="EmailId" component="div" className="errMsg" />
                                                                    <Field type="hidden" name="type" value="new" />
                                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <Button
                                                                            variant="primary"
                                                                            onClick={submitForm}
                                                                            type="button"
                                                                            className="btn mt-3"
                                                                            disabled={isSubmitting}
                                                                            style={{ backgroundColor: '#007bff', borderColor: '#007bff', marginTop: '20px' }}
                                                                        >
                                                                            Update
                                                                        </Button>
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Formik
                                                    initialValues={{ EmailId: UserDetails?.EmailId || '' }} // Initialize with UserDetails.EmailId if available
                                                    validationSchema={ProfileValidationSchema}
                                                    onSubmit={handlePhoneSubmit}
                                                    enableReinitialize
                                                >
                                                    {({ isSubmitting, setFieldValue, submitForm, values }) => (
                                                        <Form onSubmit={(e) => { e.preventDefault(); submitForm(); }} autoComplete='off'>
                                                            <div className="form-group">
                                                                <label htmlFor="EmailId">Email</label>
                                                                <Field
                                                                    type="email"
                                                                    name="EmailId"
                                                                    placeholder="Enter email"
                                                                    className="form-control"
                                                                    readOnly
                                                                />
                                                                <ErrorMessage name="EmailId" component="div" className="errMsg" />
                                                            </div>
                                                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                <p className="text-center mt-3" style={{ fontSize: '14px', color: '#6c757d', fontWeight: '500', marginTop: '10px' }}>
                                                                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '8px' }} />
                                                                    Please click on submit to update email
                                                                </p>
                                                            </div>
                                                            {/* <Field type="hidden" name="type" value="new" /> */}
                                                            <Button
                                                                variant="primary"
                                                                onClick={submitForm} // Trigger form submission programmatically
                                                                type="button"
                                                                className="btn"
                                                                disabled={isSubmitting}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {key === 'details' && (
                        <div className=" container tab-pane  show active">
                            <div className=' row mt-3' style={{ textAlign: 'center' }}>
                                <p className="text-success" style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>{profileMsg}</p>
                                <div className="col-md-6">
                                    <Formik
                                        initialValues={UserDetails}
                                        validationSchema={ProfileDetailsValidationSchema}
                                        onSubmit={handleSubmit}
                                        enableReinitialize
                                        sx={{ width: "100%" }}
                                    >
                                        {({ isSubmitting, values, setFieldValue, touched, errors, submitForm }) => (
                                            <Form autoComplete='off'>
                                                <Box mb={3}>
                                                    <TextField
                                                        name="FirstName"
                                                        label="First Name*"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={values?.FirstName}
                                                        onChange={(e: any) =>
                                                            setFieldValue("FirstName", e?.target?.value)
                                                        }
                                                        error={touched?.FirstName && Boolean(errors?.FirstName)}
                                                        // helperText={touched?.FirstName && errors?.FirstName}
                                                        autoComplete='new-name' />
                                                </Box>

                                                <Box mb={3}>
                                                    <TextField
                                                        name="LastName"
                                                        label="Last Name*"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={values?.LastName}
                                                        onChange={(e: any) =>
                                                            setFieldValue("LastName", e?.target?.value)
                                                        }
                                                        error={touched?.LastName && Boolean(errors?.LastName)}
                                                    // helperText={touched?.LastName && errors?.LastName}
                                                    />
                                                </Box>
                                                {logUserObject?.Role == "Doctor" ? (
                                                    <Box mb={3}>
                                                        <DoctorSpecialization
                                                            value={
                                                                values?.Specialization
                                                                    ? {
                                                                        label: values?.Specialization,
                                                                        value: values?.Specialization,
                                                                    }
                                                                    : null
                                                            }
                                                            setselectedSpecialization={async (value: string) =>
                                                                setFieldValue("Specialization", value)
                                                            }
                                                            label="Doctor Specialization*"
                                                            error={
                                                                touched?.Specialization && Boolean(errors?.Specialization)
                                                            } // Pass error prop based on Formik's touched and errors
                                                        />

                                                        <ErrorMessage
                                                            name="Specialization"
                                                            component="div"
                                                            className="errMsg"
                                                        />
                                                    </Box>

                                                ) : null}

                                                <Box mb={3}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Gender</FormLabel>
                                                        <RadioGroup
                                                            aria-label="gender*"
                                                            name="Gender"
                                                            value={values?.Gender}
                                                            onChange={(e: any) =>
                                                                setFieldValue("Gender", e?.target?.value)
                                                            }
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value="Male"
                                                                control={<Radio />}
                                                                label="Male"
                                                            />
                                                            <FormControlLabel
                                                                value="Female"
                                                                control={<Radio />}
                                                                label="Female"
                                                            />
                                                        </RadioGroup>
                                                        <ErrorMessage
                                                            name="Gender"
                                                            component="div"
                                                            className="errMsg"
                                                        />
                                                    </FormControl>
                                                </Box>
                                                {logUserObject?.Role == "Patient" ? (
                                                    <Box mb={3}>
                                                        <TextField
                                                            name="Address"
                                                            label="Address"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={values?.Address}
                                                            onChange={(e: any) =>
                                                                setFieldValue("Address", e?.target?.value)
                                                            }
                                                            error={touched?.Address && Boolean(errors?.Address)}
                                                        // helperText={touched?.LastName && errors?.LastName}
                                                        />
                                                    </Box>
                                                ) : null}


                                                <Box textAlign="center">
                                                    <Button
                                                        type="button"
                                                        variant="primary"
                                                        color="secondary"
                                                        disabled={isSubmitting}
                                                        onClick={submitForm}
                                                        // fullWidth={true}
                                                        style={{ fontSize: "16px" }}
                                                    >
                                                        Update
                                                    </Button>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>


                            </div>
                        </div>
                    )}


                    {/* {getModal &&
                        <Modal size='sm' centered show={getModal}>
                            <Modal.Body>
                                <div className="form-group">
                                    <p className="text-success" style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>{SuccessMsg}</p>
                                    <h4 style={{ color: '#007bff', marginBottom: '20px' }}>OTP</h4>
                                    {timer > 0 ?
                                        <>
                                            <Form.Group controlId="password" style={{ textAlign: 'left' }}>
                                                <Form.Label style={{ fontWeight: 'bold' }}>Enter OTP</Form.Label>
                                                <p>Timer: {timer} seconds</p>
                                                <Form.Control type="text" name="otp" placeholder="Enter OTP" onChange={(e: any) => setCurrentOTP(e?.target?.value)} style={{ padding: '14px 20px' }} />
                                                <p className='errMsg'>{OTPErr}</p>
                                            </Form.Group>
                                            <Button variant="primary" type="submit" className="btn" style={{ marginRight: '30px', backgroundColor: '#007bff', borderColor: '#007bff' }} onClick={SubmitOTP}>
                                                Check
                                            </Button>
                                        </>
                                        :
                                        <p>The OTP has expired. Please click the link to resend the OTP.
                                            <br />
                                            <br />
                                            <button className='btn btn-sm text-center' onClick={(e: any) => { ResendEmail(); setTimer(60) }}>Click here to resend</button>
                                        </p>
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>
                    } */}

                    <div className={`modal ${getModal ? ('open') : ""}`}>
                        <div className="modal-content1" style={{ marginTop: '177px', height: '380px' }}>
                            <span className="close" onClick={closeModal}>
                                &times;
                            </span>
                            {/* <h6 className="text-center">Review By <strong>{modalHeading}</strong></h6> */}
                            <div className="form-group">
                                <p className="text-success" style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>{SuccessMsg}</p>
                                {/* <h4 style={{ color: '#007bff', marginBottom: '20px' }}>OTP</h4> */}
                                {timer > 0 ?
                                    <>
                                        <Form.Group controlId="password" style={{ textAlign: 'left' }}>
                                            <Form.Label style={{ fontWeight: 'bold' }}>Enter OTP</Form.Label>
                                            <p>Timer: {timer} seconds</p>
                                            <Form.Control type="text" name="otp" placeholder="Enter OTP" onChange={(e: any) => setCurrentOTP(e?.target?.value)} style={{ padding: '14px 20px' }} value={CurrentOTP} />
                                            <p className='errMsg'>{OTPErr}</p>
                                        </Form.Group>
                                        <Button variant="primary" type="submit" className="btn" style={{ marginRight: '30px', backgroundColor: '#007bff', borderColor: '#007bff' }} onClick={SubmitOTP}>
                                            Check
                                        </Button>
                                    </>
                                    :
                                    <p>The OTP has expired. Please click the link to resend the OTP.
                                        <br />
                                        <br />
                                        <button className='btn btn-sm text-center' onClick={(e: any) => { ResendEmail(); setTimer(60) }}>Click here to resend</button>
                                    </p>
                                }
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}

export default UserProfile;

