import { Amplify, API, graphqlOperation } from 'aws-amplify';
// import { graphqlOperation } from '@aws-amplify/api-graphql';
import { listUsers, listSpecializations, getUser, listPatientAppointments, listDoctorAvaiableDates, getDoctorAvaiableDate, getPatientAppointment } from '../../graphql/queries';
import { createDoctorAvaiableDate, createPatientAppointment, createUser, deleteDoctorAvaiableDate, deletePatientAppointment, deleteUser, updateDoctorAvaiableDate, updatePatientAppointment, updateUser } from '../../graphql/mutations';
import awsconfig from '../../aws-exports';
import { SHA256 } from 'crypto-js';
import { AnyPtrRecord } from 'dns';
import PrescriptionUtils from '../../utils/prescription';
import ReportUtils from '../../utils/reports';
import PrescriptionServices from '../prescription';
import ReportServices from '../reportservices';

Amplify.configure(awsconfig);

const userService = () => {

    const validateEmail = async (email: string) => {
        let nextToken: any = null;
        const limit = 100;
        try {
            const filterVar = {
                filter: {
                    EmailId: { eq: email }
                },
                limit: limit,
                nextToken: nextToken
            };

            const existingEmailUsers: any = await API.graphql(graphqlOperation(listUsers, filterVar));
            console.log("existingEmailUsers", existingEmailUsers);

            if (existingEmailUsers && existingEmailUsers?.data?.listUsers?.items?.length > 0) {
                return { msg: 'User already exists with this email', status: 1, existingEmailUsers };
            } else {
                // return { msg: '', status: 0 };
                return existingEmailUsers?.data?.listUsers?.items
            }
        } catch (error) {
            console.error('Error validating email:', error);
            return { msg: 'Error validating email', status: 1 };
        }
    };

    const validatePhonenumber = async (phonenumber: string) => {
        let nextToken: any = null;
        const limit = 100;
        try {
            const mobFilter = {
                filter: {
                    PhoneNumber: { eq: phonenumber }
                },
                limit: limit,
                nextToken: nextToken
            };
            console.log("phonenumber", phonenumber);

            const existingPhoneUser: any = await API.graphql(graphqlOperation(listUsers, mobFilter));
            console.log(existingPhoneUser, "existingPhoneUser")
            if (existingPhoneUser && existingPhoneUser?.data?.listUsers?.items?.length > 0) {
                return { msg: 'User already exists with this phone number', status: 1, existingPhoneUser };
            } else {
                // return { msg: '', status: 0 };
                return existingPhoneUser?.data?.listUsers?.items;
            }
        } catch (error) {
            console.error('Error validating phone number:', error);
        }
    };

    const validateSlug = async (slug: string) => {
        let nextToken: any = null;
        const limit = 100;
        try {
            const filterVar = {
                filter: {
                    Slug: { eq: slug }
                },
                limit: limit,
                nextToken: nextToken
            };

            const existingSlugs: any = await API.graphql(graphqlOperation(listUsers, filterVar));
            if (existingSlugs && existingSlugs?.data?.listUsers?.items?.length > 0) {
                return { msg: 'slug already exists with this slug', status: 1, data: existingSlugs?.data?.listUsers?.items };
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating slug:', error);
            return { msg: 'Error validating slug', status: 1 };
        }
    };



    const getcreateUser = async (userData: any) => {
        try {
            const getResponse: any = await API.graphql(graphqlOperation(createUser, { input: userData }));
            delete getResponse.data.createUser.Password;
            // delete getResponse.data.createDentalRegister.Otp;
            const userRespData = getResponse.data.createUser;
            if (userRespData?.EmailId) {
                let response;

                const dataSend = {
                    requestBody: {
                        email: userRespData?.EmailId,
                        userId: userRespData?.id,
                        type: "registration"
                    }
                };

                const options = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json, text/plain, */*"
                    },
                    body: JSON.stringify(dataSend)
                };

                try {
                    // response = await fetch(`${process.env.REACT_APP_SEND_EMAIL_API}`, options);
                    // const responseData = await response.json();
                    // console.log('Response registration:', responseData);

                    return { msg: 'User created successfully', data: getResponse.data, response, status: 200 };
                } catch (error) {
                    console.error('Error creating registration:', error);
                    return { msg: 'Error creating registration', status: 400 };

                }
            }

            console.log('Registration successful:', getResponse);
        } catch (error) {
            console.error('Error creating registration:', error);
        }
    };

    // const MasterAdmin = async () => {

    //     try {
    //         let nextToken = null;
    //         const limit = 100;

    //         const MasterAdmin: any = {
    //             email: process.env.REACT_APP_MA_USERNAME,
    //             password: process.env.REACT_APP_MA_PWD,
    //             FirstName: process.env.REACT_APP_MA_FIRST_NAME,
    //             LastName: process.env.REACT_APP_MA_LAST_NAME,
    //             PhoneNumber: process.env.REACT_APP_MA_MOBILE,
    //             countryCode: "N/A",
    //             Status: '1'
    //         };

    //         console.log("MasterAdmin", MasterAdmin);
    //         const hashPassword = await SHA256(MasterAdmin?.password).toString();
    //         // console.log("hashPassword", hashPassword)
    //         do {
    //             let filterForTutor = {
    //                 filter: {
    //                     EmailId: { eq: MasterAdmin?.email },
    //                     Password: { eq: hashPassword },
    //                     Role: { eq: "MasterAdmin" }
    //                 },
    //             }
    //             const existingUsers = await API.graphql(graphqlOperation(listUsers, {
    //                 ...filterForTutor,
    //                 limit: limit,
    //                 nextToken: nextToken
    //             })) as any;

    //             console.log(existingUsers, '...existingUsers')

    //             if (existingUsers?.data?.listUsers?.items?.length > 0) {
    //                 console.log('Master Email already exists');
    //                 return ({ msg: "Master Email already exists" })
    //                 // setCreateMA(false);
    //             } else {
    //                 const getDetails = {
    //                     input: {
    //                         EmailId: MasterAdmin.email,
    //                         Password: hashPassword,
    //                         Role: 'MasterAdmin',
    //                         FirstName: MasterAdmin.FirstName,
    //                         LastName: MasterAdmin.LastName,
    //                         PhoneNumber: MasterAdmin.PhoneNumber,
    //                         countryCode: MasterAdmin.countryCode,
    //                         Status: '1',
    //                         Specialization: 'N/A'
    //                     },

    //                 };
    //                 console.log("getDetails", getDetails)
    //                 const createMA = await API.graphql(
    //                     graphqlOperation(createUser, getDetails)
    //                 );
    //                 // console.log("createMA", createMA);
    //                 return createMA;
    //             }
    //             nextToken = existingUsers?.data?.listUsers?.nextToken;
    //             // Update nextToken to continue fetching more records
    //         } while (nextToken);

    //     } catch (err) {
    //         console.log(err);
    //         return err
    //     }
    // };

    const MasterAdmin = async () => {
        try {
            let nextToken = null;
            const limit = 100;
            // Define MasterAdmin details
            const MasterAdmin: any = {
                email: process.env.REACT_APP_MA_USERNAME,
                password: process.env.REACT_APP_MA_PWD,
                FirstName: process.env.REACT_APP_MA_FIRST_NAME,
                LastName: process.env.REACT_APP_MA_LAST_NAME,
                PhoneNumber: process.env.REACT_APP_MA_MOBILE,
                Slug: 'N/A',
                countryCode: "+91",
                Status: '1',
            };
            console.log("MasterAdmin", MasterAdmin);
            const hashPassword = SHA256(MasterAdmin?.password).toString();
            let userExists = false;
            // Pagination loop to fetch all records if necessary
            do {
                const filterForTutor = {
                    filter: {
                        EmailId: { eq: MasterAdmin?.email },
                        Role: { eq: "MasterAdmin" }
                    },
                };

                const existingUsers = await API.graphql(
                    graphqlOperation(listUsers, { ...filterForTutor, limit, nextToken })
                ) as any;

                const usersList = existingUsers?.data?.listUsers?.items || [];

                if (usersList.length > 0) {
                    userExists = true;
                    break; // Exit if a matching user is found
                }

                nextToken = existingUsers?.data?.listUsers?.nextToken;
            } while (nextToken);

            if (userExists) {
                console.log('Master Email already exists');
                return { msg: "Master Email already exists" };
            }

            // Use DynamoDB's conditional write to prevent duplicates
            const getDetails = {
                input: {
                    EmailId: MasterAdmin?.email,
                    Password: hashPassword,
                    Role: 'MasterAdmin',
                    FirstName: MasterAdmin?.FirstName,
                    LastName: MasterAdmin?.LastName,
                    PhoneNumber: MasterAdmin?.PhoneNumber,
                    countryCode: MasterAdmin?.countryCode,
                    Slug: 'N/A',
                    Status: '1',
                    Specialization: 'N/A',
                },
                // condition: {
                //     condition: {
                //         EmailId: { attribute_not_exists: true } // Prevent creating if EmailId exists
                //     }
                // }
            };
            console.log("getDetails", getDetails);
            // Attempt to create the MasterAdmin record with conditional write
            const createMA = await API.graphql(
                graphqlOperation(createUser, getDetails)
            );
            return createMA;
        } catch (err: any) {
            if (err.errors && err.errors[0]?.errorType === "ConditionalCheckFailedException") {
                console.log("Duplicate email detected during write");
                return { msg: "Master Email already exists" };
            }
            console.log(err);
            return err;
        }
    };

    const validateLogin = async (userName: string, password: string) => {
        // const userResps: any = await API.graphql(graphqlOperation(getGSCMUser, { userName: userName, password: password, type: "login"}));
        // console.log("userResp", userResps);
        // return userResps;
        try {
            // console.log("userName", userName, "password", password);

            const InputDetails = {
                "userName": userName,
                "password": password
            }
            // console.log("InputDetails", InputDetails)

            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text/plain, */*"
                },
                body: JSON.stringify(InputDetails)
            }

            // console.log("options", options);
            // console.log(`${process.env.REACT_APP_LAMBDA_LOGIN}`, options);

            const response = await fetch(`${process.env.REACT_APP_LOGIN_API}`, options);
            // debugger 

            console.log("response in login after login", response);

            if (response?.ok) {
                const responseData = await response.json();

                // delete responseData.details;

                if (responseData?.accessToken) {

                    // console.log('data', responseData);
                    // navigate("/Myaccount");
                    // setData(responseData);
                }
                return { msg: "user login successfully", data: responseData, userID: responseData };
            } else {
                // throw new Error("Failed to fetch data from the server");
                return response;
            }
        } catch (error) {
            console.error("An error occurred:", error);
            return new Error("Failed to fetch data from the server");
        }
    };

    const getlistSpecializations = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let allSpecializations: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };

                const result = await API.graphql(graphqlOperation(listSpecializations, filter)) as any;
                const specializations = result?.data?.listSpecializations?.items || [];
                allSpecializations.push(...specializations);
                nextToken = result?.data?.listSpecializations?.nextToken;
            } while (nextToken);
            allSpecializations.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allSpecializations;
        } catch (error) {
            console.error('Error fetching specializations:', error);
            return []; // Return an empty array in case of error
        }
    }

    const generateOTP = async (EmailId: any, userId: any) => {

        console.log(userId, '...userId');

        const dataSend = {
            requestBody: {
                email: EmailId,
                userId: userId,
                type: "registration"
            }
        }

        const options = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json, text/plain, */*"
            },
            body: JSON.stringify(dataSend)
        }
        console.log('options', options);
        // const userResp: any = await API.graphql(graphqlOperation(getGSCMUser, { id: userId }));

        const userResp = await fetch(`${process.env.REACT_APP_SEND_EMAIL_API}`, options);

        return userResp;
    }

    const getcreateAppointment = async (userData: any) => {
        console.log("userData", userData);
        try {
            const getResponse: any = await API.graphql(graphqlOperation(createPatientAppointment, { input: userData }));

            // delete getResponse.data.createPatientAppointment.Password;
            // delete getResponse.data.createDentalRegister.Otp;
            console.log("getResponse.data.createPatientAppointment", getResponse.data.createPatientAppointment);

            const userRespData = getResponse.data.createPatientAppointment;

            if (userRespData?.EmailId) {
                let response;

                const dataSend = {
                    requestBody: {
                        email: userRespData?.EmailId,
                        // userId: userRespData?.id,
                        patientId: userRespData?.patientId,
                        type: "Book Appointment"
                    }
                };

                const options = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json, text/plain, */*"
                    },
                    body: JSON.stringify(dataSend)
                };

                try {
                    // response = await fetch(`${process.env.REACT_APP_SEND_EMAIL_API}`, options);
                    // const responseData = await response.json();
                    // console.log('Response registration:', responseData);

                    return { msg: 'Appointment successfully', data: getResponse.data, response, status: 200 };
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            }
            console.log(' Appointment successful:', getResponse);
        } catch (error) {
            console.error('Error  booking Appointment:', error);
        }
    };

    const ListDoctor = async (Role: string) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    filter: {
                        Role: { eq: Role },
                        // Status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken,
                };

                // console.log("filter", filterVar);

                const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;

                console.log(result, "result");

                const users = result?.data?.listUsers?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listUsers?.nextToken;
            } while (nextToken);

            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }

    const PatientvalidateEmail = async (email: string) => {
        try {
            const filterVar = {
                filter: {
                    EmailId: { eq: email }
                }
            };
            console.log("email", email);

            const existingEmailUsers: any = await API.graphql(graphqlOperation(listPatientAppointments, filterVar));

            console.log("existingEmailUsers", existingEmailUsers)

            if (existingEmailUsers && existingEmailUsers.data.listPatientAppointments.items.length > 0) {
                return { msg: 'User already exists with this email', status: 1 };
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating email:', error);
            return { msg: 'Error validating email', status: 1 };
        }
    };

    const PatientvalidatePhonenumber = async (phonenumber: string) => {
        try {
            const mobFilter = {
                filter: {
                    PhoneNumber: { eq: phonenumber }
                }
            };
            console.log("phonenumber", phonenumber);

            const existingPhoneUser: any = await API.graphql(graphqlOperation(listPatientAppointments, mobFilter));
            if (existingPhoneUser && existingPhoneUser.data.listPatientAppointments.items.length > 0) {
                return { msg: 'User already exists with this phone number', status: 1 };
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating phone number:', error);
        }
    };

    const ListPatient = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    limit: limit,
                    nextToken: nextToken,
                };
                const result = await API.graphql(graphqlOperation(listPatientAppointments, filterVar)) as any;
                const users = result?.data?.listPatientAppointments?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listPatientAppointments?.nextToken;
            } while (nextToken);

            // fetch the list in descending order
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }

    const Deletepatient = async (id: string) => {
        console.log("id", id);

        const report = await ReportServices().reportsListBypatientID(id);
        console.log("report", report);
        const prescription = await PrescriptionServices().prescriptionListBypatientID(id);
        console.log("prescription", prescription);
        const hasReports = report?.length > 0;
        const hasPrescriptions = prescription?.length > 0;
        let confirmationMessage = "";


        if (hasReports && hasPrescriptions) {
            confirmationMessage = "This patient has both reports and prescriptions. Do you want to delete both and then delete the appointment?";
        } else if (hasReports) {
            confirmationMessage = "This patient has reports. Do you want to delete the reports and then delete the appointment?";
        } else if (hasPrescriptions) {
            confirmationMessage = "This patient has prescriptions. Do you want to delete the prescriptions and then delete the appointment?";
        } else {
            confirmationMessage = "Do you want to delete the appointment?";
        }

        try {
            if (window.confirm(confirmationMessage)) {
                let delResponse: any;
                if (hasReports) {
                    await Promise.all(report?.map(async (rep: any) => {
                        await ReportUtils().reportDelete(rep?.id);
                    }));
                }
                if (hasPrescriptions) {
                    await Promise.all(prescription?.map(async (pres: any) => {
                        await PrescriptionUtils().prescriptionDelete(pres?.id);
                    }));
                }
                const getResponse: any = await API.graphql(graphqlOperation(deletePatientAppointment, { input: { id: id } }));
                return getResponse;

            } else {
                console.log("Deletion canceled by the user.");
            }

        } catch (error) {
            console.log(error);
        }
    }


    const validateUser = async (email: string, password: String, otp: number, userId: String) => {

        try {

            const InputDetails = {
                "email": email,
                "password": password,
                "otp": otp,
                "userId": userId
            }
            console.log("InputDetails", InputDetails)

            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text/plain, */*"
                },
                body: JSON.stringify(InputDetails)
            }
            //   console.log(InputDetails, '...InputDetails');

            // const response = await fetch(`${process.env.REACT_APP_USER_LOGIN_API}`, options);
            const response = await fetch(`${process.env.REACT_APP_OTP_VALIDATION_API}`, options);

            if (response.ok) {

                const responseData = await response.json();
                delete responseData.details;

                if (responseData.accessToken) {
                    // console.log('data', responseData);
                }
                return { msg: "user login successfully", data: responseData };
                // return { msg: "user login successfully", data: response };
            } else {
                throw new Error("Failed to fetch data from the server");
            }
        } catch (error) {
            console.error("An error occurred:", error);
            return new Error("Failed to fetch data from the server");
        }
    };

    const CreateDoctorAvailableDate = async (input: any) => {
        console.log("response", input);

        try {
            const response = await API.graphql(graphqlOperation(createDoctorAvaiableDate, { input }));
            console.log("response", response);
            return response;
        } catch (error) {
            console.error("Error creating doctor available date:", error);
            throw error;
        }
    };

    const DeleteDoctorAvaiableDate = async (id: any) => {
        console.log("Deleteinput", id);


        // const input = {id}

        // console.log('deleteid', typeof(input))

        try {
            const response = await API.graphql(graphqlOperation(deleteDoctorAvaiableDate, { input: { id } }));
            console.log("response", response);
            return response;
        } catch (error) {
            console.error("Error deleting doctor available date:", error);
            throw error;
        }
    };

    const UpdateDoctorAvaiableDate = async (id: any) => {
        console.log("updateInput", id);


        try {
            const response = await API.graphql(graphqlOperation(updateDoctorAvaiableDate, { input: id }));
            console.log("response", response);
            return response;
        } catch (error) {
            console.error("Error updating doctor available date:", error);
            throw error;
        }
    };




    const getlistDoctorAvailableDates = async (doctorId: any) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {

                    filter: doctorId ? { DoctorID: { eq: doctorId } } : null,
                    limit: limit,
                    nextToken: nextToken
                };

                console.log("filter", filterVar);

                const result = await API.graphql(graphqlOperation(listDoctorAvaiableDates, filterVar)) as any;
                console.log("result", result)

                // console.log(result, "result");
                const users = result?.data?.listDoctorAvaiableDates?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listDoctorAvaiableDates?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    };


    const ListSpecializations = async (Role: string) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    filter: {
                        Role: { eq: Role },
                        Status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken
                };

                // console.log("filter", filterVar);

                const result = await API.graphql(graphqlOperation(listSpecializations, filterVar)) as any;

                console.log(result, "specresult");

                const users = result?.data?.listSpecializations?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listSpecializations?.nextToken;
            } while (nextToken);

            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }


    const ListPatientAppointments = async (doctorID: any) => {

        try {

            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {

                const filterVar = {
                    filter: {
                        ...(doctorID && {
                            DoctorID: {
                                eq: doctorID
                            }
                        }),
                    },
                    limit: limit,
                    nextToken: nextToken
                };

                const result = await API.graphql(graphqlOperation(listPatientAppointments, filterVar)) as any;

                console.log(result, "result");

                const users = result?.data?.listPatientAppointments?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listPatientAppointments?.nextToken;

            } while (nextToken)


            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }


    }

    const ListAllPatientAppointments = async () => {

        try {

            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {

                const filterVar = {

                    limit: limit,
                    nextToken: nextToken
                };

                const result = await API.graphql(graphqlOperation(listPatientAppointments, filterVar)) as any;

                console.log(result, "result");

                const users = result?.data?.listPatientAppointments?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listPatientAppointments?.nextToken;

            } while (nextToken)


            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }


    }

    const ListPatientAppointmentsById = async (id: any, slug: any) => {
        console.log("slugInfo", { id, slug })

        try {

            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {

                const filterVar = {
                    filter: {
                        ...(id && {
                            id: {
                                eq: id
                            }
                        }),
                        ...(slug && {
                            AppointmentID: {
                                eq: slug
                            }
                        }),
                    },
                    limit: limit,
                    nextToken: nextToken
                };

                const result = await API.graphql(graphqlOperation(listPatientAppointments, filterVar)) as any;

                console.log(result, "result");

                const users = result?.data?.listPatientAppointments?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listPatientAppointments?.nextToken;

            } while (nextToken)
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }


    }

    const updatePatientAppointmentByData = async (data: any) => {

        const updateData = (data: any) => {
            const updatedKYC = data?.KYC?.map((item: any) => {
                return removeFields(item, ['__typename']);
            });
            return {
                id: data?.id,
                ...data,
                KYC: updatedKYC,
            };
        };
        const newData = updateData(data);

        try {
            const getResponse: any = await API.graphql(graphqlOperation(updatePatientAppointment, { input: newData }));
            console.log(getResponse, "getResponse");
            return getResponse;
        } catch (error) {
            console.log(error);
        }
    }

    // const updateDoctorByData = async (data: any) => {
    //     try {
    //         console.log("data", data);
    //         // const filterVar = {
    //         //     filter:{
    //         //         input: data,
    //         //         condition: null
    //         //     }
    //         // }
    //         const getResponse: any = await API.graphql(graphqlOperation(updateUser, {input: data}));
    //         console.log(getResponse, "getResponse");
    //         return getResponse;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }


    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }
    const updateDoctorByData = async (data: any) => {
        console.log("data", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);
        try {
            // console.log("data", data);
            // const filterVar = {
            //     filter:{
            //         input: data,
            //         condition: null 
            //     }
            // }
            const getResponse: any = await API.graphql(graphqlOperation(updateUser, { input: sanitizedUserInfo }));
            console.log(getResponse, "getResponse");
            return getResponse;
        } catch (error) {
            console.log(error);
        }
    }
    const ListDoctors = async (id: string) => {
        console.log("id", id)
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    filter: {
                        id: { eq: id },
                        // Status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken,
                    sortDirection: "DESC"
                };

                // console.log("filter", filterVar);

                const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;

                console.log(result, "result");

                const users = result?.data?.listUsers?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listUsers?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }


    const ListDoctorsById = async (id: string) => {
        console.log("id", id)
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    filter: {
                        id: { eq: id },
                        Status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken,
                    sortDirection: "DESC"
                };

                // console.log("filter", filterVar);

                const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;

                console.log(result, "result");

                const users = result?.data?.listUsers?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listUsers?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }
    const deleteDoctor = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteUser, { input: { id } }));
            console.log(result);
            return result;
        } catch (error) {
            console.log(error);
        }
    }


    const getuserSingle = async (userId: any) => {
        const getuser = await API.graphql(graphqlOperation(getUser, { id: userId }));
        console.log('getuser', getuser);
        return getuser
    }
    const Updateuser = async (id: any, image: any) => {

        const data = {
            id: id,
            userProfile: image
        }
        const userResp: any = await API.graphql(graphqlOperation(updateUser, { input: data }));
        return { 'msg': 'user updated successfully', data: userResp };
    };

    const UpdatePassword = async (email: any, password: any) => {
        const hashPassword = (password: any) => {
            try {
                const hashedPassword = SHA256(password).toString();
                return hashedPassword;
            } catch (error) {
                console.error('Error hashing password:', error);
                return '';
            }
        };

        const existingUsers: any = await API.graphql(
            graphqlOperation(listUsers, {
                filter: {
                    EmailId: { eq: email },
                },
            })
        );
        if (existingUsers && existingUsers?.data?.listUsers?.items?.length > 0) {
            const data = {
                id: existingUsers?.data?.listUsers?.items[0].id,
                Password: hashPassword(password)
            }
            const userResp: any = await API.graphql(graphqlOperation(updateUser, { input: data }));
            return { 'msg': 'user updated successfully', data: userResp };
        }
    };


    const ActiveDoctors = async (Role: string) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    filter: {
                        Role: { eq: Role },
                        Status: { eq: "1" },
                        ShowInTeam: { eq: "1" }
                    },
                    limit: limit,
                    nextToken: nextToken
                };

                console.log("filter", filterVar);

                const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;

                console.log(result, "result");

                const users = result?.data?.listUsers?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listUsers?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }

    const getSingleAppointment = async (Id: any) => {
        const getuser = await API.graphql(graphqlOperation(getPatientAppointment, { id: Id }));
        // console.log('getuser', getuser);
        return getuser
    }


    const ListPatientAppointmentsBypatientID = async (patientid: any) => {

        try {

            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {

                const filterVar = {
                    filter: {
                        ...(patientid && {
                            patientID: {
                                eq: patientid
                            }
                        }),
                    },
                    limit: limit,
                    nextToken: nextToken
                };

                const result = await API.graphql(graphqlOperation(listPatientAppointments, filterVar)) as any;

                // console.log(result, "result");

                const users = result?.data?.listPatientAppointments?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listPatientAppointments?.nextToken;

            } while (nextToken)
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }


    }
    const ListPatientAppointmentsByphoneNumber = async (phonenumber: any) => {

        try {

            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;
            do {
                const filterVar = {
                    filter: {
                        ...(phonenumber && {
                            PhoneNumber: {
                                eq: phonenumber
                            }
                        }),
                    },
                    limit: limit,
                    nextToken: nextToken
                };

                const result = await API.graphql(graphqlOperation(listPatientAppointments, filterVar)) as any;

                // console.log(result, "result");

                const users = result?.data?.listPatientAppointments?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listPatientAppointments?.nextToken;

            } while (nextToken)
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }


    }

    const getExistingpatients = async (Role: string, phone: any, email: any) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterConditions: any[] = [];
            if (phone) {
                filterConditions.push({ PhoneNumber: { eq: phone } });
            }
            if (email) {
                filterConditions.push({ EmailId: { eq: email } });
            }

            do {
                const filterVar = {
                    filter: {
                        Role: { eq: Role },
                        // Status: { eq: "1" },
                        // userType: { eq: "G" },
                        // or: [
                        //     { userType: { eq: "E" } },
                        //     { userType: { eq: "G" } }
                        // ],
                        // ...(filterConditions?.length > 0 && {
                        //     or: filterConditions
                        // }),
                        or: [
                            { EmailId: { eq: email } },
                            { PhoneNumber: { eq: phone } }
                        ],
                        // resetPWD: "Yes"
                    },
                    limit: limit,
                    nextToken: nextToken
                };
                console.log("filterVar", filterVar);
                const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;
                console.log(result, "result");
                const users = result?.data?.listUsers?.items || [];
                allUsers.push(...users);
                nextToken = result?.data?.listUsers?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }
    const cancelAppointMent = async (id: string) => {
        try {
            const getResponse: any = await API.graphql(graphqlOperation(deletePatientAppointment, { input: { id: id } }));
            return getResponse;
        } catch (error) {
            console.log(error);
        }
    }
    return {
        validatePhonenumber,
        validateEmail,
        getcreateUser,
        getlistSpecializations,
        validateLogin,
        MasterAdmin,
        generateOTP,
        getcreateAppointment,
        PatientvalidatePhonenumber,
        PatientvalidateEmail,
        ListDoctor,
        ListPatient,
        Deletepatient,
        validateUser,
        CreateDoctorAvailableDate,
        DeleteDoctorAvaiableDate,
        getlistDoctorAvailableDates,
        ListSpecializations,
        ListPatientAppointments,
        ListPatientAppointmentsById,
        updatePatientAppointmentByData,
        deleteDoctor,
        ListDoctorsById,
        updateDoctorByData,
        UpdateDoctorAvaiableDate,
        getuserSingle,
        Updateuser,
        UpdatePassword,
        ActiveDoctors,
        getSingleAppointment,
        ListPatientAppointmentsBypatientID,
        ListPatientAppointmentsByphoneNumber,
        ListAllPatientAppointments,
        validateSlug,
        ListDoctors,
        getExistingpatients,
        cancelAppointMent

    };
};

export default userService;
