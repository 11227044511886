// import ReportsFilesmanagement from "../../../views/shared/downloadandprint";
import ReportsFilesmanagement from "../../../src/components/views/shared/downloadandprint";
import PrescriptionServices from "../prescription";
import ReportServices from "../reportservices";
import { saveAs } from 'file-saver';
import JSZip from "jszip";
import userService from "../users/user.service";
import PaymentServices from "../Appointment";
import Users from "../../utils/users";



const ViewAppointmentServices = () => {

    const getFileUrl = (fileName: any) => {
        const baseUrl = process.env.REACT_APP_S3_BUCKET_FETCH;
        const file = `${baseUrl}/images/${fileName}`;
        return file;
    };

    const handleReportDownload: any = async (doctor: any, type: any) => {
        console.log("doctor", doctor);
        const report = type === "reports"
            ? await ReportServices().reportsListBypatientID(doctor?.id)
            : await PrescriptionServices().prescriptionListBypatientID(doctor?.id);
        console.log("report", report);

        const downloadfile = report[0];
        const zip = new JSZip();

        const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
        const folderName = type === "reports" ? `reports${timestamp}` : `prescriptions${timestamp}`;
        const folder: any = zip.folder(folderName);
        if (downloadfile?.file?.length > 0) {
            try {
                for (const file of downloadfile?.file) {
                    // Handle file names and download
                    const fileNames = Array.isArray(file?.name) ? file?.name : [file?.name];
                    for (const fileName of fileNames) {
                        try {
                            const fileUrl = getFileUrl(fileName);
                            const response = await fetch(fileUrl);

                            if (!response?.ok) {
                                console.error(`Failed to fetch file: ${fileName}. Status: ${response.status}`);
                                continue;
                            }

                            const fileBlob = await response.blob();

                            if (!fileBlob.size) {
                                console.error(`Empty file received: ${fileName}`);
                                continue;
                            }
                            // Determine MIME type
                            let mimeType = fileBlob.type;
                            if (mimeType === "application/octet-stream") {
                                const extension = fileName.split('.').pop().toLowerCase();
                                switch (extension) {
                                    case 'jpg':
                                    case 'jpeg':
                                        mimeType = 'image/jpeg';
                                        break;
                                    case 'png':
                                        mimeType = 'image/png';
                                        break;
                                    default:
                                        mimeType = 'application/octet-stream';
                                }
                            }
                            // Create new Blob with the correct MIME type
                            const correctBlob = new Blob([fileBlob], { type: mimeType });
                            // Add file to zip
                            folder?.file(fileName, correctBlob, { binary: true });
                        } catch (fetchError) {
                            console.error(`Error processing file: ${fileName}`, fetchError);
                        }
                    }
                    // Handle description and generate PDF
                    if (file?.description) {
                        await ReportsFilesmanagement().imageCanvas(file?.description, folder, true, file?.fileType);
                    }
                }
                const content = await zip.generateAsync({ type: 'blob' });
                saveAs(content, `${folderName}.zip`);
            } catch (error) {
                console.error('Error while downloading files:', error);
            }
        } else {
            alert("you don't any files to download.")
        }
    };

    const StatusUpdate = async (status: any, appointment: any) => {
        if (status === "open") {
            const input = {
                id: appointment?.id,
                Status: "open"
            }
            const update = await userService().updatePatientAppointmentByData(input);
            console.log("update", update);
            return update;
        } else if (status === "close") {
            const input = {
                id: appointment?.id,
                Status: "close"
            }
            const update = await userService().updatePatientAppointmentByData(input);
            console.log("update", update);
            return update;
        }
    }

    const formatDate: any = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
            (day % 10 === 2 && day !== 12) ? 'nd' :
                (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

        return `${day}${suffix} ${month} ${year}`;
    };

    const handleAppoinmentDelete = async (appID: string, PaymentMode: string, key: any) => {
        console.log("appID", appID);
        console.log("key at function", key);
        let confirmBox: any;
        if (key === "cancel") {
            confirmBox = window.confirm("Are you sure you want to cancel the appointment?");
        } else if (key === "delete") {
            confirmBox = window.confirm("Are you sure you want to Delete the appointment?");
        } else {
            confirmBox = null;
        }
        if (!confirmBox) {
            alert('process aborted.');
            return; // Exit the function if the user does not confirm
        }
        // if (confirmBox) {
        try {
            if (PaymentMode === "online") {
                // List payments
                const paymentResp = await PaymentServices().ListPayments(appID);
                const paymentID = paymentResp[0]?.razorpay_payment_id;
                const payAmount = paymentResp[0]?.Amount;

                const response = await userService().cancelAppointMent(appID);
                if (response) {
                    const currentDate = new Date();
                    const formattedDate = currentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(',', ''); // Removing comma from the formatted date
                    const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                    if (response?.data?.deletePatientAppointment?.EmailId) {
                        try {
                            const EmailResponse: any = await Users().generateEmailSMS(
                                response?.data?.deletePatientAppointment?.EmailId,
                                `<html><body><div>Welcome to Gurram Dental!<br /><br />Thank you for contacting us.your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime}.<br/><br />Best Regards,<br /> Gurram Dental Hospital Team</div></body></html>`,
                                'Gurram Dental Hospitals,Patient Appointment',
                                'Your appointment cancelled successfully'
                            );
                        } catch (err) {
                            console.error("Error sending Email:", err);
                        }
                    }
                    try {
                        const MobileSMSResponse = await Users().generateMobileMsgBulk("+91" + response?.data?.deletePatientAppointment?.PhoneNumber, `Thank you! Your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime} - CLUSTERIT`, "Your appointment created successfully")
                    } catch (err) {
                        console.error("Error sending SMS:", err);
                    }
                    // const MobileSMSResponse = await Users().generateMobileMsg(
                    //     "+91" + response?.data?.deletePatientAppointment?.PhoneNumber,
                    //     `Thank you, your appointment  ${response?.data?.deletePatientAppointment?.AppointmentID} on ${appDate} at ${response?.data?.deletePatientAppointment?.AppointmentTime} cancelled succesfully.`,
                    //     'Your appointment cancelled successfully'
                    // )
                    if (response?.data) {
                        alert('Appointment canceled.');
                        return response;
                        // getPatients();
                    }
                } else {
                    console.error("Error in CancleAppointment:", response);
                    alert('An error occurred while canceling the appointment. Please try again.');
                }
                // console.log("paymentResp", paymentResp[0]);

                // const response = await fetch(`https://api.razorpay.com/v1/payments/${paymentID}/refund`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': `Basic ${btoa(`${process.env.REACT_APP_RAZORPAY_KEY}:${process.env.REACT_APP_RAZORPAY_SECRET_KEY}`)}`
                //     },
                //     body: JSON.stringify({
                //         amount: payAmount // Amount to be refunded (in paise)
                //     })
                // });

                // if (!response.ok) {
                //     const errorText = await response.text();
                //     throw new Error(`Refund request failed: ${response.status} - ${errorText}`);
                // }

                // const refundResult = await response.json();
                // console.log("Refund result:", refundResult);

                // if (refundResult.status === 'authorized') {
                //     alert('Refund initiated successfully!');
                // } else {
                //     alert('Refund failed. Please try again.');
                // }
            } else if (PaymentMode === "offline") {
                const response = await userService().cancelAppointMent(appID);
                console.log("response", response);
                const appDate = formatDate(response?.data?.deletePatientAppointment?.AppointmentDate)
                const appEncId = PaymentServices().encodeBase64(response?.data?.deletePatientAppointment?.AppointmentID)
                if (response) {
                    const currentDate = new Date();
                    const formattedDate = currentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(',', ''); // Removing comma from the formatted date
                    const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                    if (response?.data?.deletePatientAppointment?.EmailId) {
                        try {
                            const SMSresponse: any = await Users().generateEmailSMS(
                                response?.data?.deletePatientAppointment?.EmailId,
                                `<html><body><div>Welcome to Gurram Dental!<br /><br />Thank you for contacting us.your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime}.<br/><br />Best Regards,<br /> Gurram Dental Hospital Team</div></body></html>`,
                                'Gurram Dental Hospitals,Patient Appointment',
                                'Your appointment cancelled successfully'
                            );
                        } catch (err) {
                            console.error("Error sending Email:", err);
                        }
                    }
                    try {
                        const MobileSMSResponse = await Users().generateMobileMsgBulk("+91" + response?.data?.deletePatientAppointment?.PhoneNumber, `Thank you! Your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime} - CLUSTERIT`, "Your appointment created successfully")
                    } catch (err) {
                        console.error("Error sending SMS:", err);
                    }
                    // const MobileSMSResponse = await Users().generateMobileMsg(
                    //     "+91" + response?.data?.deletePatientAppointment?.PhoneNumber,
                    //     `Thank you, your appointment  ${response?.data?.deletePatientAppointment?.AppointmentID} on ${appDate} at ${response?.data?.deletePatientAppointment?.AppointmentTime} cancelled succesfully.`,
                    //     'Your appointment cancelled successfully'
                    // )
                    if (response?.data) {
                        alert('Appointment canceled.');
                        return response;
                        // naviagte("/ViewAppointment")
                        // await getPatients();
                    }
                } else {
                    console.error("Error in CancleAppointment:", response);
                    alert('An error occurred while canceling the appointment. Please try again.');
                }
            }
        } catch (error) {
            console.error('Error during cancellation:', error);
            alert('An error occurred while canceling the appointment. Please try again.');
        }
    }
    return {
        handleReportDownload,
        StatusUpdate,
        handleAppoinmentDelete,
        formatDate,
    }
}
export default ViewAppointmentServices;


