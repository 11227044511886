
import React, { useEffect, useState } from 'react';
import { createCareerCategory } from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { listCareerCategories } from '../../../graphql/queries';
import Multiselect from 'multiselect-react-dropdown';
import getCareer from '../../../services/Careers';

interface Carrer {
  label: string;
  value: string;
}

function CareerComponent(props: any) {
  const [getCareers, setCareer] = useState<string | undefined>();
  const [careerData, setCareerData] = useState<Carrer[]>([]);
  const [CareerRes, setCareerRes] = useState(true);

  const [selectedCats, setSelectedCats] = useState<string[]>([]);

  let transformedOptions: any, getObjects: any;

  useEffect(() => {
    async function fetchCareers() {
      try {
        // const AllCareers = await API.graphql(graphqlOperation(listCareerCategories)) as any;
        const AllCareers = await getCareer().careerCategoryList();
        const career = AllCareers.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setCareerData(career);
        setCareerRes(true);
      } catch (err) {
        console.log('error fetching career', err);
      }
    }
    fetchCareers();
  }, [CareerRes]);


  transformedOptions = careerData;
  const selectedCategories = selectedCats?.length > 0 ? selectedCats : props?.editValue

     console.log('selectedCategories', selectedCategories);

   getObjects = selectedCategories && typeof selectedCategories[0] === 'string' ? transformedOptions?.filter((option: any) => selectedCategories[0]?.split(',').some((value: string) => option?.value === value)) : [];

  


  

  const InsertBlog = async () => {
    if (getCareers) {
      try {
        const createdCareer = await API.graphql(
          graphqlOperation(createCareerCategory, { input: { name: getCareers } })
        ) as any;

        const newGroup = {
          label: createdCareer?.data?.createCareerCategory?.name,
          value: createdCareer?.data?.createCareerCategory?.id,
        };

        const currentCategories = props?.InputDetails?.category 
          ? Array.isArray(props?.InputDetails?.category) 
            ? props?.InputDetails?.category 
            : props?.InputDetails?.category.split(',')
          : [];

        const updatedCategory = [...currentCategories, newGroup.value].join(',');

        props.setInputDetails((prevState: any) => ({
          ...prevState,
          category: updatedCategory,
        }));
        setSelectedCats([updatedCategory])
        props.setFieldValue('category', updatedCategory);

        setCareerRes(false);
        setCareer(undefined);
      } catch (err) {
        console.log('error creating blog', err);
      }
    }
  };

  const handleSearch = (e: string) => {
    const blogExists = transformedOptions.some((option: any) => option.label === e);
    setCareer(blogExists ? undefined : e);
  };

  const handleSelect = (selectedList: any, selectedItem: any) => {
    const currentCategories = props?.InputDetails?.category 
      ? Array.isArray(props?.InputDetails?.category) 
        ? props?.InputDetails?.category 
        : props?.InputDetails?.category.split(',')
      : [];

      console.log('selectedOne', {'currentCategories':currentCategories, 'selectedItem':selectedItem?.value})

    const updatedCategory = [...currentCategories, selectedItem.value].join(',');

    console.log('updatedCategory', updatedCategory)

    props.setInputDetails((prevState: any) => ({
      ...prevState,
      category: [updatedCategory],
    }));

    setSelectedCats([updatedCategory])

    props.setFieldValue('category', updatedCategory);
  };

  const handleRemove = (selectedList: any, removedItem: any) => {
    const currentCategories = props?.InputDetails?.category ? props?.InputDetails?.category : [];
    // const catArr: any = currentCategories[0]?.split(',')
    const catArr: string[] = Array.isArray(currentCategories) && currentCategories[0]
    ? (currentCategories[0].includes(',') ? currentCategories[0].split(',') : [currentCategories[0]])
    : [];
   const updatedCategory = catArr?.filter((id: string) => id !== removedItem?.value).join(',');
   const finalCategory = updatedCategory ? [updatedCategory] : [];
    console.log({finalCategory})
    props.setInputDetails((prevState: any) => ({
      ...prevState,
      category: finalCategory,
    }));
    setSelectedCats(finalCategory)
    props.setFieldValue('category', updatedCategory);
  };

  return (
    <div className='form-group mt-3'>
      <label>Select Category*:</label>
      <div className='row col-md-12'>
        <div className='col-md-10' style={{ paddingRight: '0px' }}>
          <Multiselect
            style={{ borderRadius: '0px' }}
            isObject={true}
            onRemove={handleRemove}
            onSearch={handleSearch}
            onSelect={handleSelect}
            options={transformedOptions}
            displayValue='label'
            // showCheckbox
            selectedValues={getObjects}
          />
        </div>
        <div className='col-md-2 px-0'>
          {getCareers && (
            <button type='button' className='btn btn-dark btn-md' onClick={InsertBlog}>
              Add
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CareerComponent;
