import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import userService from '../../../services/users/user.service';
import { TextField, Button, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Helmet } from "react-helmet";
import { updDelFile } from '../shared/fileupload';
import './listDoctor.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HomeIcon from '@mui/icons-material/Home';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';


interface compProps {
  title: string;
  description: string;
}
interface Doctor {
  PhoneNumber: string;
  EmailId: string;
  Specialization: string;
  id: string;
  FirstName: string;
  LastName: string;
  TimeSlot: string;
  Status: string;
  ShowInTeam: string;
  Slug: string;
}

const ListDoctor: React.FC<compProps> = ({ title, description }) => {

  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [doctorList, setDoctorList] = useState<Doctor[]>([]);
  const [specializationtList, setspecializationtList] = useState<Doctor[]>([]);
  // const logUser: any = localStorage?.getItem('userData');
  // const logUserObject = JSON?.parse(logUser);
  // const logUserObject:any = useAuthRedirect();

  // useEffect(() => {
  //   if (!logUserObject?.accessToken) {
  //     navigate('/ListPatient');
  //   }
  // }, [navigate]);
  const getspec = async () => {
    const response = await userService().ListSpecializations('Doctor') as any;


    setspecializationtList(response);
    console.log("doctorsDetails", response)
  };


  const getDoctors = async () => {
    const response = await userService().ListDoctor('Doctor') as any;
    console.log("response", response)
    const specializationtList = await userService().getlistSpecializations() as any;
    setDoctorList(response);
    const updatedResponse = await Promise.all(response.map(async (item: any) => {
      const specName = specializationtList?.find((spec: any) => spec?.id === item?.Specialization);
      return {
        ...item,
        Specialization: specName?.name
      };
    }));
    setDoctorList(updatedResponse);
  };

  useEffect(() => {
    getDoctors();
    getspec();
  }, []);

  const handleDelete = async (id: string) => {
    const confirmBox = window.confirm("Are you sure you want to delete the selected doctor?");
    if (confirmBox) {
      const user: any = await userService().getuserSingle(id);
      const filedel = await updDelFile(user?.data?.getUser?.userProfile, "images");
      if (filedel?.status === 200) {
        await userService().deleteDoctor(id);
        getDoctors();
      }
    } else {
      alert('Deletion canceled.');
    }
  };

  const highlightText = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text?.split(regex);
    return parts.map((part, index) => (regex.test(part) ? <span key={index} className="highlighted-text">{part}</span> : part));
  };

  const filteredData = doctorList.filter((doctor: Doctor) =>
    Object.values(doctor)?.some((value: any) => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
  );

  const totalPages = Math?.ceil(filteredData?.length / pageSize);
  const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleSearch = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    setSearchTerm('');
  }, [currentPage]);

  const handleEdit = (event: any) => {
    console.log("event", event);
    navigate('/registerdoctor', { state: { id: event?.id } });
  }

  const handleSubmit = (setUpdate: boolean) => {
    navigate('/registerdoctor');
  }



  const handleStatusToggle = async (rowData: any) => {
    const updatedStatus = rowData?.Status === "1" ? "0" : "1";
    const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
    console.log("updatedRowData", updatedRowData);
    const update = await userService().updateDoctorByData(updatedRowData);

    setDoctorList((prevRows) =>
      prevRows?.map((doctor) =>
        doctor?.id === rowData?.id ? { ...doctor, Status: updatedStatus } : doctor
      )
    );
  };

  const handleShowteam = async (rowData: any) => {
    const updatedStatus = rowData?.ShowInTeam === "1" ? "0" : "1";
    const updatedRowData = { ...rowData, id: rowData?.id, ShowInTeam: updatedStatus };
    console.log("updatedRowData", updatedRowData);
    const update = await userService().updateDoctorByData(updatedRowData);

    setDoctorList((prevRows) =>
      prevRows?.map((doctor) =>
        doctor?.id === rowData?.id ? { ...doctor, ShowInTeam: updatedStatus } : doctor
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <section className="admin-section text-center"
      >
        <div className="container">
          <div className="bannerTitle">
            <h1>Doctors</h1>
          </div>
        </div>
      </section>
      <div>
        <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
          <li>
            <a className="aColor" href="/">Home  /&nbsp;</a>
          </li>
          <li>Doctors</li>
        </ul>
      </div>
      <div className="container">
        <div className='row'>

          <div className="col-md-12">
            <div className='tablefs'>
              <div className='mbtable' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='page-entry'>
                  <Select
                    value={pageSize}
                    onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                    style={{ marginRight: '10px' }}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                  entries per page
                </div>
                <div className='table-pagination' style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => handleSubmit(false)}
                    style={{ marginRight: '10px', backgroundColor: '#98B868', color: 'white', borderStyle: 'none', outline: 'none' }}
                    className='btn btn-primary'
                  >
                    Add
                  </Button>
                  <TextField
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: <SearchIcon />,
                    }}
                  />
                </div>
              </div>

              <div style={{ overflowX: 'auto' }}>
                <table className="table table-striped table-bordered table-hover table-responsive listDoctors">
                  <thead>
                    <tr>
                      <th className="sno">S.No</th>
                      <th >Doctor Name</th>
                      <th>Specialization</th>
                      {/* <th>Slug</th> */}
                      <th>Phone Number</th>
                      <th className="Doctoremail">Email Id</th>
                      <th>TimeSlot</th>
                      <th className="actions">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((doctor: Doctor, index: number) => (
                      console.log("doctor is", doctor),
                      <tr key={doctor.id} className="highlighted-row">
                        {/* <td>{index + 1}</td>
                       */}
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{highlightText(`${doctor?.FirstName} ${doctor?.LastName}`)}</td>
                        <td>{highlightText(doctor?.Specialization)}</td>
                        {/* <td>{highlightText(doctor?.Slug)}</td> */}
                        <td>{highlightText(doctor?.PhoneNumber)}</td>
                        <td>{highlightText(doctor?.EmailId)}</td>
                        <td>{highlightText(doctor?.TimeSlot)}</td>
                        <td className='actions'>

                          <Button title="Edit"
                            onClick={() => handleEdit(doctor)}
                            style={{ marginRight: '10px' }}
                            startIcon={<EditIcon style={{ color: 'green' }} />}
                          >

                          </Button>
                          <Button title="Delete"
                            onClick={() => handleDelete(doctor.id)}
                            startIcon={<DeleteIcon style={{ color: ' #af0e0e' }} />}
                          >

                          </Button>

                          <Button title="Status"
                            onClick={() => handleStatusToggle(doctor)}
                            className={doctor?.Status === '1' ? 'statusSuccess' : 'statusDanger'}
                            startIcon={<VisibilityIcon />}
                          >
                          </Button>
                          <Button title="Show in Home"
                            onClick={() => handleShowteam(doctor)}
                            className={doctor?.ShowInTeam === '1' ? 'statusSuccess' : 'statusDanger'}
                            startIcon={<HomeIcon />}
                          >
                          </Button>


                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
                <div className='Mmtpagination' >

                  <div className="paginationstyle">
                    <div
                      style={{
                        cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                        marginRight: '10px',
                        borderBottom: '1px solid red',
                        fontWeight: currentPage > 1 ? 'bold' : 'normal',
                      }}
                      onClick={() => {
                        if (currentPage > 1) {
                          handlePageChange(currentPage - 1);
                          window.scrollTo(0, 0); // Scroll to top
                        }
                      }}
                      className={currentPage === 1 ? 'disabled' : ''}
                    >
                      Previous
                    </div>
                    <div className="pageNumb">{currentPage}</div>
                    <div
                      style={{
                        cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                        marginLeft: '10px',
                        borderBottom: '1px solid red',
                        fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                      }}
                      onClick={() => {
                        if (currentPage < totalPages) {
                          handlePageChange(currentPage + 1);
                          window.scrollTo(0, 0);
                        }
                      }}
                      className={currentPage === totalPages ? 'disabled' : ''}
                    >
                      Next
                    </div>
                  </div>
                  <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

                  </div>

                </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListDoctor;
