import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { TextField, Button, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GalleryUtils from './../../../utils/galleryUploads';
import { Helmet } from "react-helmet";
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
    title: string;
    description: string;
}
interface gallery {
    PhoneNumber: string;
    EmailId: string;
    Specialization: string;
    id: string;
    FirstName: string;
    LastName: string;
}

const ListGallery: React.FC<compProps> = ({ title, description }) => {

    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [gallery, setGalleryList] = useState<gallery[]>([]);
    const [specializationtList, setspecializationtList] = useState<gallery[]>([]);
    const [listGallery, setlistGallery] = useState<gallery[]>([]);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // const logUserObject:any = useAuthRedirect();

    // useEffect(() => {
    //     if (!logUserObject?.accessToken) {
    //         navigate('/ListPatient');
    //     }
    // }, [navigate, logUserObject]);

    const galleryList = async () => {
        const response = await GalleryUtils().handleList();
        setGalleryList(response);
    };

    useEffect(() => {
        galleryList();
    }, []);

    const handleDelete = async (id: string) => {

        console.log("id", id)
        const confirmBox = window.confirm("Are you sure you want to delete the selected record?");
        if (confirmBox) {
            const delGallery = await GalleryUtils().galleryDelete(id);
            console.log("delGallery", delGallery);
            // if (delGallery) {
            galleryList()

            // }
            // const filedel = await updDelFile(delGallery?.data?.getUser?.Files, "images");

            // await userService().deletegallery(id);
            // getgallerys();
        } else {
            alert('Deletion canceled.');
        }
    };


    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts.map((part, index) => (regex.test(part) ? <span key={index} className="highlighted-text">{part}</span> : part));
    };

    const filteredData = gallery?.filter((gallery: any) =>
        Object.values(gallery).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setCurrentPage(1);
    };

    useEffect(() => {
        console.log(logUserObject?.Role)
        setSearchTerm('');
    }, [currentPage]);

    async function handleEdit(event: any) {
        console.log("event", event);
        navigate('/galleryUpload', { state: { id: event?.id } });
    }

    const handleSubmit = () => {
        navigate('/galleryUpload');
    }

    // const handleStatusToggle = (rowData: any) => async (event: any) => {
    //     const updatedStatus = event?.target?.checked === true ? "1" : "0";
    //     const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
    //     console.log("updatedRowData", updatedRowData);
    //     const update = await GalleryServices().galleryUpdate(updatedRowData);

    //     setlistGallery((prevRows : any) =>
    //       prevRows?.map((gallery : any) =>
    //         gallery?.id === rowData?.id ? { ...gallery, Status: updatedStatus } : gallery
    //       )
    //     );
    //   };
    const handleStatusToggle = (rowData: any) => async (event: any) => {
        const updatedStatus = event?.target?.checked === true ? "1" : "0";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        try {
            await GalleryUtils().galleryUpdateUtil(updatedRowData);
            setGalleryList((prevRows: any) =>
                prevRows?.map((gallery: any) =>
                    gallery.id === rowData.id ? { ...gallery, Status: updatedStatus } : gallery
                )
            );
        } catch (error) {
            console.error('Error updating gallery status:', error);
            // alert('Failed to update status.');
        }
    };



    console.log("listGallery", paginatedData)
    return (

        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Gallery</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Gallery</li>
                </ul>
            </div>

            <div className="container">
                <div className="row">
                    {/* <div className="col-md-2">
                        < Sidebar />
                    </div> */}
                    <div className="col-md-12 tablefs" >

                        <div className='mbtable' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <div className='page-entry'>
                                <Select
                                    value={pageSize}
                                    onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                    style={{ marginRight: '10px' }}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                </Select>
                                entries per page
                            </div>
                            <div className='table-pagination' style={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    onClick={() => handleSubmit()}
                                    style={{ marginRight: '10px', backgroundColor: '#98B868', color: 'white', borderStyle: 'none', outline: 'none' }}
                                    className='btn btn-primary'
                                >
                                    Add
                                </Button>
                                <TextField
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search..."
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        endAdornment: <SearchIcon />,
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <table className="table table-striped table-hover table-bordered listGallery">
                                <thead>
                                    <tr>
                                        <th className="sno">S.No</th>
                                        <th>Category</th>
                                        <th>File Type</th>
                                        <th className="actions">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {paginatedData?.map((gallery: any, index: any) => (
                                        <tr key={gallery?.id} className="highlighted-row">
                                            {/* <td>{index + 1}</td> */}
                                            <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                            <td>{highlightText(gallery?.Category)}</td>
                                            <td>{highlightText(gallery?.fileType)}</td>
                                            <td style={{ padding: '10px' }}  className='actions'>

                                                <Button title="Edit"
                                                    onClick={() => handleEdit(gallery)}
                                                    style={{ marginRight: '10px' }}
                                                    startIcon={<EditIcon style={{ color: 'green' }} />}
                                                >

                                                </Button>
                                                <Button title="Delete"
                                                    onClick={() => handleDelete(gallery.id)}
                                                    startIcon={<DeleteIcon style={{ color: ' #af0e0e' }} />}
                                                >
                                                </Button>

                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={gallery?.Status === "1"}
                                                            onChange={handleStatusToggle(gallery)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <span className={gallery?.Status === "1" ? 'Status-active' : 'Status-inactive'}>
                                                            {gallery?.Status === "1" ? 'Active' : 'Inactive'}
                                                        </span>
                                                    }
                                                />


                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='Mmtpagination' >

                            <div className="paginationstyle">
                                <div
                                    style={{
                                        cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                        marginRight: '10px',
                                        borderBottom: '1px solid red',
                                        fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                    }}
                                    onClick={() => {
                                        if (currentPage > 1) {
                                            handlePageChange(currentPage - 1);
                                            window.scrollTo(0, 0); // Scroll to top
                                        }
                                    }}
                                    className={currentPage === 1 ? 'disabled' : ''}
                                >
                                    Previous
                                </div>
                                <div className="pageNumb">{currentPage}</div>
                                <div
                                    style={{
                                        cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                        marginLeft: '10px',
                                        borderBottom: '1px solid red',
                                        fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                    }}
                                    onClick={() => {
                                        if (currentPage < totalPages) {
                                            handlePageChange(currentPage + 1);
                                            window.scrollTo(0, 0);
                                        }
                                    }}
                                    className={currentPage === totalPages ? 'disabled' : ''}
                                >
                                    Next
                                </div>
                            </div>
                            <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ListGallery;




