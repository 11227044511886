import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Homeservices from '../../../services/homeServices';
import './footer.css';

const Footer: React.FC = () => {
    const navigate = useNavigate();

    const [SeviceItems, setSeviceItems] = useState<any>([]);
    useEffect(() => {
        async function fetchGalleryItems() {
            const response = await Homeservices().ActiveservicesList();
            setSeviceItems(response);
            // console.log("response", response);
        }


        fetchGalleryItems();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    
    // const handleReadMore = (id: any) => {
    //     navigate('/singleService', { state: { id: id } });
    //   };

    // const handleReadMore = (slug: any,) => {
    //     navigate(`/services/${slug}`);
    //   };

    const handleReadMore = (slug: string) => {
        navigate(`/services/${slug}`, { replace: true });
        window.scrollTo(0, 0);
    };
    
    return (

        <footer className="footer-main">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-2"></div> */}

                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <h6>CONTACT INFO</h6>
                            <div className="about-widget">
                                <ul className="location-link mt-20">
                                    <li className="item">
                                        <i className="fa fa-map-marker"></i>
                                        <p>Trust Hospital, Khammam, India</p>
                                    </li>
                                    <li className="item">
                                        <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                        <a href="javascript:void(0)" >
                                            <p>help@trusthospital.com</p>
                                        </a>
                                    </li>
                                    <li className="item">
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        <p>+91 98480 22338</p>
                                    </li>
                                    <li className="item">
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <a href="javascript:void(0)" onClick={() => navigate('/reviews')}><p>Rate Us</p></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <h6>Useful Links</h6>
                            <ul className="menu-link mt-20">
                                <li>
                                    <a href="javascript:void(0)" onClick={() => navigate('/appointment')}>
                                        Appointment
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" onClick={() => navigate('/service#faq')}>
                                        FAQ
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" onClick={() => navigate('/Contact')}>
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" onClick={() => navigate('/blogs')}>
                                        Blogs
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" onClick={() => navigate('/termsConditions')}>
                                        Terms and Conditions
                                    </a>
                                </li>
                            </ul>

                        </div>


                        <div className="col-md-3 col-sm-6 col-xs-12 mediamtmb">
                            <h6>Services</h6>
                            {SeviceItems?.map((item:any) => (
                                <ul className="menu-link mt-20" key={item?.id || item?.Title}>
                                    <li>
                                        <a href="javascript:void(0)" onClick={() => handleReadMore(item?.Slug)}>
                                          {item?.Title}
                                        </a>
                                    </li>
                                   
                                </ul>
                            ))}
                        </div>


                        <div className="col-md-2 col-sm-6 col-xs-12">
                            <h6>Review Us</h6>
                            <ul className="list-inline social-icons  mt-20">
                                <li><a href="javascript:void(0)"><i className="bi bi-twitter"></i></a></li>
                                <li><a href="javascript:void(0)"><i className="bi bi-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-sm-6 col-xs-12" style={{ marginTop: '10px' }}>
                            <h6>Social Media</h6>
                            <ul className="list-inline social-icons ">
                                <li><a href="javascript:void(0)"><i className="bi bi-facebook"></i></a></li>
                                {/* <li><a href="/#"><i className="bi bi-instagram"></i></a></li> */}
                                <li><a href="javascript:void(0)"><i className="bi bi-linkedin"></i></a></li>
                                <li><a href="javascript:void(0)"><i className="bi bi-youtube"></i></a></li>
                            </ul>
                        </div>

                    </div>
                    <div className="copyright-text">
                        <p>&copy; Copyright 2024. All Rights Reserved by <a href="javascript:void(0)">Cluster IT Solutions</a></p>
                    </div>
                </div>
            </div>

        </footer>

    );
};

export default Footer;
