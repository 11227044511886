import { SendFile } from "../../components/views/shared/fileupload";
import userService from "../../services/users/user.service";
import { FormatFile } from "../Fileformat";
import Users from "../users";
import ReviewServices from "./../../services/Reviews/review";

const ReviewUtils = () => {

    const sendNotification = async (inputDetails: any) => {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json, text/plain, */*"
            },
            body: JSON.stringify(inputDetails)
        };
        // console.log("options", options);
        try {
            const response = await fetch(`${process.env.REACT_APP_REVIEWS_Function}`, options);
            if (response.ok) {
                const responseData = await response.json();
                console.log("Notification sent successfully", responseData);
                return { msg: "Notification sent successfully", data: responseData };
            } else {
                console.error("Failed to send notification");
                return { msg: "Failed to send notification", status: response.status };
            }
        } catch (error) {
            console.error("An error occurred while sending notification:", error);
            throw new Error("Failed to send notification");
        }
    };

    const admin: any = async () => {
        const getAdmin: any = await userService().ListDoctor("MasterAdmin");
        return getAdmin
    }

    const handleSubmit = async (values: any, resetForm: () => void, file: any) => {
        console.log("values", values);
        // let newFilename: any;
        // if (file?.name) {
        //     const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
        //     newFilename = replaceSpecialChars(filename);
        // }

        try {
            const adminData = await admin();
            const newFilename = file?.name ? await FormatFile(file) : null;

            const reviewData = {
                Name: values?.Name,
                EmailId: values?.EmailId,
                Rating: values?.Rating,
                Comment: values?.Comment,
                userProfile: newFilename,
                Status: 0
            };
            const reviewResponse = await ReviewServices().ReviewCreate(reviewData);

            if (reviewResponse) {
                if (reviewResponse?.userProfile != null) {
                    file?.name && await SendFile(file, newFilename, "images");
                }
                try {
                    await Users().generateEmailSMS(
                        adminData[0]?.EmailId,
                        `New review submitted by ${reviewResponse?.EmailId} with a rating of ${reviewResponse?.Rating}: ${reviewResponse?.Comment} - CLUSTERIT`,
                        'Review Form',
                        'success'
                    );
                } catch (error) {
                    console.log("error", error);
                }
                try {
                    await Users().generateMobileMsgBulk(adminData[0]?.countryCode + adminData[0]?.PhoneNumber, `New review submitted by ${reviewResponse?.EmailId} with a rating of ${reviewResponse?.Rating} - CLUSTERIT.`, "success"
                    )
                } catch (error) {
                    console.log("error", error);
                }
                if (reviewResponse) {
                    resetForm();
                    return { reviewResponse, status: 200 };
                }
            }
        } catch (error) {
            console.error('Error submitting review:', error);
            throw new Error('Failed to submit review');
        }
    };

    return {
        handleSubmit
    };
};

export default ReviewUtils;