import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import userService from '../../../services/users/user.service';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Sidebar from '../Dashboard/sidebar';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import './style.css';
import { Helmet } from "react-helmet";
import { updDelFile } from '../shared/fileupload';
import './listRegisterPatients.css';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
  title: string;
  description: string;
}
interface KYC {
  pastHealthConditions: string;
  foodAllergies: string;
  CurrentMedications: string;
  ChronicIllnesses: string;
  SmokingStatus: string;
  AlcoholConsumption: string;
}

interface Doctor {
  id: string;
  FirstName: string;
  LastName: string;
}

interface Patient {
  LastName: any;
  FirstName: any;
  id: string;
  PatientName: string;
  EmailId: string;
  PhoneNumber: string;
  Age: any;
  KYC: KYC;
  userType: any;
}

const ListAllPatients: React.FC<compProps> = ({ title, description }) => {
  const initialState: Patient = {
    id: '',
    PatientName: '',
    PhoneNumber: '',
    Age: '',
    EmailId: '',
    LastName: undefined,
    FirstName: undefined,
    KYC: {
      pastHealthConditions: '',
      foodAllergies: '',
      CurrentMedications: '',
      ChronicIllnesses: '',
      SmokingStatus: '',
      AlcoholConsumption: ''
    },
    userType: ''


  }

  const navigate = useNavigate();
  const [data, setData] = useState<Patient>(initialState);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [patientList, setPatientList] = useState<Patient[]>([]);
  const [doctorList, setDoctorList] = useState<Doctor[]>([]);
  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalHeading, setModalHeading] = useState('KYC Modal');
  const [singleRegister, setSingleRegister] = useState<any>(null);
  // const logUserObject:any = useAuthRedirect();


  const getPatients = async () => {
    try {
      //   let response: Patient[] = [];
      const response = await userService()?.ListDoctor('Patient');
      console.log("response", response);
      setPatientList(response || []);
    } catch (error) {
      console.error("Error fetching patients:", error);
      setPatientList([]);
    }
  };

  useEffect(() => {
    getPatients();
    // getDoctors();
  }, []);


  const handleDelete = async (id: any) => {
    const confirmBox = window.confirm("Are you sure you want to delete the selected patient?");
    if (confirmBox) {
      const user: any = await userService().getuserSingle(id);
      const filedel = await updDelFile(user?.data?.getUser?.userProfile, "images");
      console.log("filedel", filedel);
      if (filedel?.status === 200) {
        await userService().deleteDoctor(id);
        getPatients();
      }
    } else {
      alert('Deletion canceled.');
    }
  };

  const highlightText = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text?.split(regex);
    return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
  };


  // const formatKYC = (kyc: any) => {
  //   if (!kyc || kyc?.length === 0) return null;

  //   const kycInfo = kyc[0]; // Assuming there's only one KYC object in the array
  //   return (
  //     <div>
  //       <div>Past Health Conditions: {kycInfo.pastHealthConditions === "1" ? 'Yes' : 'No'}</div>
  //       <div>Food Allergies: {kycInfo.foodAllergies === "1" ? 'Yes' : 'No'}</div>
  //       <div>Current Medications: {kycInfo.CurrentMedications === "1" ? 'Yes' : 'No'}</div>
  //       <div>Chronic Illnesses: {kycInfo.ChronicIllnesses === "1" ? 'Yes' : 'No'}</div>
  //       <div>Smoking Status: {kycInfo.SmokingStatus === "1" ? 'Yes' : 'No'}</div>
  //       <div>Alcohol Consumption: {kycInfo.AlcoholConsumption === "1" ? 'Yes' : 'No'}</div>
  //     </div>
  //   );
  // }

  const filteredData = patientList?.filter((row: Patient) =>
    Object?.values(row)?.some((value: any) => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
  );

  const totalPages = Math?.ceil(filteredData?.length / pageSize);
  const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleSearch = () => {
    if (!searchTerm) {
      setData(initialState);
    } else {
      setData(filteredData[0] || initialState);
    }
    setCurrentPage(1);
  };

  const handleSubmit = () => {
    navigate('/register');
  };

  useEffect(() => {
    setSearchTerm('');
  }, [currentPage]);

  const handleEdit = (row: Patient) => {
    console.log("row", row);
    console.log("rowId", row.id);
    navigate("/register", { state: { patientId: row?.id } });
  };

  const openModal = async (patient: any) => {
    const singleReg = await userService().getuserSingle(patient?.id);
    // console.log("singleReg", singleReg);
    setSingleRegister(singleReg);
    // setModalHeading(`${singleReg.data.getUser.FirstName} ${singleReg.data.getUser.LastName}`);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalHeading("");
  };


  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <section className="admin-section text-center"
      >
        <div className="container">
          <div className="bannerTitle">
            <h1>Registered Patients</h1>
          </div>
        </div>
      </section>
      <div>
        <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
          <li>
            <a className="aColor" href="/">Home  /&nbsp;</a>
          </li>
          <li>Registered Patients</li>
        </ul>
      </div>


      <div className='container'>
        <div className='row'>
          {/* <div className="col-md-2">
          <Sidebar />
        </div> */}
          <div className="col-md-12">
            <div className='tablefs'>
              <div className='mbtable' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='page-entry'>
                  <Select
                    value={pageSize}
                    onChange={(e) => handlePageSizeChange(Number(e?.target?.value))}
                    style={{ marginRight: '10px' }}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                  entries per page
                </div>
                <div className='table-pagination' style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => handleSubmit()}
                    style={{ marginRight: '10px', backgroundColor: '#98B868', color: 'white', borderStyle: 'none', outline: 'none' }}
                    className='btn btn-primary'
                  >
                    Add
                  </Button>
                  <TextField
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: <SearchIcon />,
                    }}
                  />
                </div>
              </div>
              <div style={{ overflowX: 'auto' }}>
                <table className="table table-bordered table-hover table-striped listRegPatients">
                  <thead>
                    <tr>
                      <th className="sno" >S.No</th>
                      <th>Patient Name</th>
                      <th>EmailId</th>
                      <th>Phone Number</th>
                      <th className='mdl'>UserType</th>
                      <th className="age">Age</th>
                      <th className="actions">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((row: Patient, index: number) => (
                      <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{highlightText(`${row.FirstName} ${row?.LastName}`)}</td>
                        <td>{highlightText(row?.EmailId)}</td>
                        <td>{highlightText(row?.PhoneNumber)}</td>
                        <td>{highlightText(row?.userType)}</td>
                        <td>{highlightText(row?.Age)}</td>
                        <td className='actions'>

                          <Button title="view Patient Profile"
                            onClick={() => openModal(row)}
                            style={{ marginRight: '10px' }}
                            startIcon={<VisibilityIcon style={{ color: 'green' }} />}
                          >
                          </Button>
                          <Button title="Edit"
                            onClick={() => handleEdit(row)}
                            style={{ marginRight: '10px' }}
                            startIcon={<EditIcon style={{ color: 'green' }} />}
                          >
                          </Button>
                          <Button title="Delete"
                            onClick={() => handleDelete(row?.id)}
                            startIcon={<DeleteIcon style={{ color: ' #af0e0e' }} />}
                          >
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* 
              <div className='Mmtpagination' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

                </div>
                <div className="paginationstyle">
                  <div
                    style={{
                      cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                      marginRight: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage > 1 ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage > 1) {
                        handlePageChange(currentPage - 1);
                        window.scrollTo(0, 0); // Scroll to top
                      }
                    }}
                    className={currentPage === 1 ? 'disabled' : ''}
                  >
                    Previous
                  </div>
                  <div className="pageNumb">{currentPage}</div>
                  <div
                    style={{
                      cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                      marginLeft: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage < totalPages) {
                        handlePageChange(currentPage + 1);
                        window.scrollTo(0, 0);
                      }
                    }}
                    className={currentPage === totalPages ? 'disabled' : ''}
                  >
                    Next
                  </div>
                </div>

                <div className={`modal ${isModalOpen ? "open" : ""}`}>
                  <div className="modal-content1" style={{ marginTop: '177px', height: 'auto' }}>
                    <span className="close" onClick={closeModal}>
                      &times;
                    </span>
                    <h6 className="text-center">Patient Details <strong>{modalHeading}</strong></h6>
                    {singleRegister && (
                      <div style={{ padding: '40px' }}>
                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                            <strong>Name:</strong>
                          </div>
                          <div>
                            <span>{highlightText(singleRegister?.data?.getUser?.FirstName)}</span>
                          </div>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                            <strong>Email ID:</strong>
                          </div>
                          <div>
                            <span>{highlightText(singleRegister?.data?.getUser?.EmailId)}</span>
                          </div>
                        </div>
                      
                      </div>
                    )}
                  </div>
                </div>











              </div> */}
              <div className='Mmtpagination' >

                <div className="paginationstyle">
                  <div
                    style={{
                      cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                      marginRight: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage > 1 ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage > 1) {
                        handlePageChange(currentPage - 1);
                        window.scrollTo(0, 0); // Scroll to top
                      }
                    }}
                    className={currentPage === 1 ? 'disabled' : ''}
                  >
                    Previous
                  </div>
                  <div className="pageNumb">{currentPage}</div>
                  <div
                    style={{
                      cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                      marginLeft: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage < totalPages) {
                        handlePageChange(currentPage + 1);
                        window.scrollTo(0, 0);
                      }
                    }}
                    className={currentPage === totalPages ? 'disabled' : ''}
                  >
                    Next
                  </div>
                </div>
                <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListAllPatients;