import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, Button, Alert, Box, Container } from '@mui/material';
import AppointmentId from '../../../services/Appointment';
import './style.css';
import { Helmet } from "react-helmet"


interface compProps {
    title: string;
    description: string;
    keywords: string;
}


const validationSchema = Yup.object({
    appointmentId: Yup.string().required('Appointment ID is required'),
});

const AppointmentTrack: React.FC<compProps> = ({ title, description, keywords }) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();  // Initialize useNavigate

    const fieldRefs: any = useRef({
        PhoneNumber: null as HTMLDivElement | null,
    });

    const handleSearch = async (Appointmentid: any) => {

        try {
            const response = await AppointmentId().appointmentListByappointmentID(Appointmentid?.appointmentId);

            if (response) {
                setErrorMessage(null);
                navigate('/appointmentHistory', { state: { appointmentDetails: response } });
            } else {
                throw new Error('Appointment not found');
            }
        } catch (error) {
            setErrorMessage('Appointment not found');
        }
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
            </Helmet>
            <section className="page-title text-center" style={{ backgroundImage: 'url(images/img-5.jpg)' }}>
                <div className="container">
                    <div className="title-text">
                        <h1>Track Appointment</h1>
                        <h5 className="captionName" style={{ position: 'relative', fontWeight: '100' }}>Stay on top of your medical appointments with ease.</h5>
                    </div>
                </div>


            </section>
            <div>
                <ul style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li><a className="aColor" href="/">Home  /&nbsp;</a></li>
                    <li>Track Appointment</li>
                </ul>
            </div>

            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={8} md={7}>
                        <Formik
                            initialValues={{ appointmentId: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSearch}
                        >
                            {({ isSubmitting, errors }) => {

                                const errorCount = Object.keys(errors).length;
                                console.log({ errorCount })

                                if (isSubmitting && errorCount > 0) {

                                    for (const key in errors) {
                                        console.log({ key })
                                        if (fieldRefs.current[key]) {
                                            fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                            break; // Stop at the first error we find
                                        }
                                    }


                                }
                                return (
                                    <Form autoComplete="off">
                                        <Box sx={{ mb: 3 }} ref={(el) => (fieldRefs.current.appointmentId = el)}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                label="Appointment ID"
                                                name="appointmentId"
                                                variant="outlined"
                                                placeholder="Enter Appointment ID"
                                            />
                                            <ErrorMessage name="appointmentId" component="div" className="text-danger mt-1" />
                                        </Box>
                                        <Button type="submit" variant="contained" color="primary" style={{ marginBottom: '10px' }} disabled={isSubmitting}>
                                            Search
                                        </Button>
                                    </Form>
                                )
                            }}
                        </Formik>
                        {errorMessage && <Alert severity="error" sx={{ mt: 3 }}>{errorMessage}</Alert>}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default AppointmentTrack;
