import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import userSerivce from '../../../services/users/user.service'
import { SHA256 } from 'crypto-js';
import { registerValidationSchema } from './registerValidationSchema';
import { useIdleTimeout } from '../../views/shared/header/sessionExpire';
import '@fortawesome/fontawesome-free/css/all.min.css';
import medical from './../../users/registerImages/patientreg.jpg'
import userService from '../../../services/users/user.service';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from '../../views/shared/fileupload';
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, TextField } from '@mui/material';
import { Helmet } from "react-helmet";
import Users from '../../../utils/users';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatFile } from '../../../utils/Fileformat';
import AppointmentId from '../../../services/Appointment';
import Loader from '../../views/shared/loadercomponent';
import { Alert } from 'react-bootstrap';

interface compProps {
    title: string;
    description: string;
    keywords: string;
}

interface Kyc {
    pastHealthConditions: String;
    pastHealthConditionsDescription: String;
    foodAllergies: String;
    foodAllergiesDescription: String;
    CurrentMedications: String;
    CurrentMedicationsDescription: String;
    ChronicIllnesses: String;
    ChronicIllnessesDescription: String;
    SmokingStatus: String;
    AlcoholConsumption: String;
    heardFrom: String;
}

interface RegisterDetails {
    FirstName: string;
    LastName: string;
    Gender: string;
    Specialization: string;
    Age: string;
    Role: string;
    PhoneNumber: string;
    EmailId: string;
    Password: string;
    countryCode: string;
    confirmPassword: string;
    userProfile: string;
    Address: string;
    Slug: string;

    // [key: string]: string | number | undefined;
    KYC: any;
    City: String
    Street: String
    pinCode: String
    DOB: String
    HouseNumber: String
    MaritalStatus: String
    qualification: String
    resetPWD: String
    userType: String

}

const initialState: RegisterDetails = {
    FirstName: '',
    LastName: '',
    Gender: '',
    Age: '',
    Specialization: 'N/A',
    EmailId: '',
    PhoneNumber: '',
    Password: '',
    Role: 'Patient',
    confirmPassword: '',
    countryCode: '+91',
    userProfile: '',
    Address: '',
    Slug: 'N/A',
    // KYC: [
    //     { pastHealthConditions: '0', foodAllergies: '0', CurrentMedications: '0', ChronicIllnesses: '0', SmokingStatus: '0', AlcoholConsumption: '0', MaritalStatus: '0' }
    // ]

    KYC: [{
        pastHealthConditions: '0',
        pastHealthConditionsDescription: '',
        foodAllergies: '0',
        foodAllergiesDescription: '',
        CurrentMedications: '0',
        CurrentMedicationsDescription: '',
        ChronicIllnesses: '0',
        ChronicIllnessesDescription: '',
        SmokingStatus: '0',
        AlcoholConsumption: '0',
        heardFrom: '',
    }],
    City: '',
    Street: '',
    pinCode: '',
    DOB: '',
    HouseNumber: '',
    MaritalStatus: '',
    qualification: '',
    resetPWD: '',
    userType: ''

};


const Register: React.FC<compProps> = ({ title, description, keywords }) => {

    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const navigate = useNavigate();
    const location = useLocation();
    const [errMsg, setErrMsg] = useState(initialState);
    const isIdle = useIdleTimeout(1000 * 900);
    const isActive = useIdleTimeout(1000 * 900);
    const [errMsgMatchpwd, setErrMsgMatchpwd] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errMsgPwd, setErrMsgPwd] = useState("");
    const [errMsgMatch, setErrMsgMatch] = useState("");
    const [InputDetails, setInputDetails] = useState<RegisterDetails>(initialState);
    const [doctorDetails, setDoctorDetails] = useState<any>();
    let [updateDoctor, setUpdateDoctor] = useState<boolean>(false);
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [err, setErr] = useState('');
    const [errors, setErrors] = useState({});
    const [existingData, setExistingData] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [alertMsg, setAlertMsg] = useState<any>('');


    const fieldRefs: any = useRef({
        FirstName: null as HTMLDivElement | null,
        LastName: null as HTMLDivElement | null,
        Age: null as HTMLDivElement | null,
        Gender: null as HTMLDivElement | null,
        EmailId: null as HTMLDivElement | null,
        Specialization: null as HTMLDivElement | null,
        PhoneNumber: null as HTMLDivElement | null,
        Password: null as HTMLDivElement | null,
        confirmPassword: null as HTMLDivElement | null,
        countryCode: null as HTMLDivElement | null,
        userProfile: null as HTMLDivElement | null,
        qualification: null as HTMLDivElement | null,
        MaritalStatus: null as HTMLDivElement | null,
        Address: null as HTMLDivElement | null,
        City: null as HTMLDivElement | null,
        pinCode: null as HTMLDivElement | null,
    });



    const hashPassword = (password: string) => {
        try {
            const hashedPassword = SHA256(password).toString();
            return hashedPassword;
        } catch (error) {
            console.error("Error hashing password:", error);
            return "";
        }
    };

    useEffect(() => {
        getExistingUser();
    }, [location?.state]);

    const getExistingUser = async () => {
        const getDoctorDetails = await userService().ListDoctorsById(location?.state?.patientId);
        // console.log(getDoctorDetails[0]);
        if (getDoctorDetails?.length > 0) {
            setInputDetails(getDoctorDetails[0]);
            setUpdateDoctor(true);
        }
        setDoctorDetails(getDoctorDetails[0]);

    };
    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.userProfile, setInputDetails, 'userProfile', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };
    const handleUpdate = async (values: any, { setSubmitting }: any) => {
        window.scrollTo(0, 0);
        if (existingData) {
            values.id = existingData?.id;
            values.userType = existingData?.userType
            values.resetPWD = existingData?.resetPWD
        }
        const hashed = hashPassword(values?.Password);

        try {
            setLoading(true);
            const userEmail = await userService().validateEmail(values?.EmailId.toLowerCase());
            const email = userEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.id
            const userPhoneNumber = await userService().validatePhonenumber(values.PhoneNumber);
            const phn = userPhoneNumber?.existingPhoneUser?.data?.listUsers?.items[0]?.id
            // console.log('detailvalues', userPhoneNumber?.existingPhoneUser?.data?.listUsers?.items[0]?.id)

            if (userEmail?.status === 1 && userPhoneNumber?.status === 1 && email != values?.id && phn != values?.id) {
                setErrMsgMatch('Email address and phone number are already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userEmail?.status === 1 && email != values?.id) {
                setErrMsgMatch('Email address is already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userPhoneNumber?.status === 1 && phn != values?.id) {
                setErrMsgMatch('Phone number is already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else {
                setErrMsgMatch('')
            }

            // let newFilename: any;
            // if (file?.name) {
            //    const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            //     newFilename = replaceSpecialChars(filename);
            //  await updDelFile(values?.userProfile, "images");
            //  await SendFile(file, newFilename, "images");
            // }
            const newFilename = file?.name ? await FormatFile(file) : null;
            file?.name && await updDelFile(values?.userProfile, "images");
            file?.name && await SendFile(file, newFilename, "images");

            const formatEmail = values?.EmailId.toLowerCase();
            const GetInsertDataUpdate = {
                id: values?.id,
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender,
                Age: values?.Age,
                Specialization: values?.Specialization,
                EmailId: formatEmail,
                PhoneNumber: values?.PhoneNumber,
                // Password: values?.Password,
                Password: InputDetails?.Password === "NA" || InputDetails?.Password === "" ? hashed : InputDetails?.Password,
                Status: "1",
                Role: "Patient",
                countryCode: values?.countryCode,
                userProfile: file?.name ? newFilename : values?.userProfile,
                Address: values?.Address,
                KYC: values?.KYC,
                Slug: values?.Slug,
                City: values?.City,
                Street: values?.Street,
                pinCode: values?.pinCode,
                DOB: values?.DOB,
                HouseNumber: values?.HouseNumber,
                MaritalStatus: values?.MaritalStatus,
                qualification: values?.qualification,
                // resetPWD: values?.userType === "E" ? "Yes" : "No",
                resetPWD: values?.resetPWD,
                userType: values?.userType === "E" || values?.userType === "G" ? "R" : values?.userType
            };
            const userResponse = await userService().updateDoctorByData(GetInsertDataUpdate);
            const updateResponse = userResponse?.data?.updateUser;
            console.log("updateResponse", updateResponse);
            try {
                await AppointmentId().updateuserType(updateResponse?.id, updateResponse?.PhoneNumber, updateResponse?.userType);
            } catch (err) {
                console.log("err updating userType", err);
            }
            if (updateResponse?.EmailId && !location?.state?.patientId) {
                try {
                    await Users().generateEmailSMS(
                        updateResponse?.EmailId,
                        `<html><body><div>Welcome to Grurram Dental Hospitals!<br /><br />Thank you for registering with us.<br /><a href=${process.env.REACT_APP_HOST}login>Click here</a> to log your account using your Email: ${updateResponse?.EmailId} or Phone Number: ${updateResponse?.PhoneNumber}, and pwd: ${values?.newPassword}<br />Best Regards,<br />Team</div></body></html>`,
                        'Grurram Dental Hospitals',
                        'You are registered successfully'
                    );
                } catch (error) {
                    console.error("Error sending Email:", error);
                }
            }
            if (!location?.state?.patientId) {
                try {
                    const MobileSMSResponse = await Users().generateMobileMsgBulk(updateResponse?.countryCode + updateResponse?.PhoneNumber, `Thank you for registering with us. Log in using your Email: ${updateResponse?.EmailId} or Phone Number: ${updateResponse?.PhoneNumber}, and pwd: ${values?.newPassword} - CLUSTERIT.`, "success"
                    )
                } catch (error) {
                    console.error("Error sending SMS:", error);
                    setLoading(false);
                }
            }
            if (updateResponse && updateResponse?.id) {
                setLoading(false);
                setAlertMsg("Patient Updated successfully!");
                // navigate("/ListDoctor");
                setSubmitting(false);
            }
            // console.log("userResponse", userResponse);
            // navigate("/allRegisterpatients")
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
        setSubmitting(false);
    }
    const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
        console.log("existingData", existingData);
        window.scrollTo(0, 0);
        // if (existingData?.userType === "E") {
        //     values.id = existingData?.id;
        //     values.newPassword = values?.Password
        //     values.userType = existingData?.userType
        //     values.Password = hashPassword(values?.Password);
        //     values.resetPWD = existingData?.resetPWD
        //     await handleUpdate(values, setSubmitting);
        // } else {
        try {

            if (values.Password !== values.confirmPassword) {
                setErrMsgMatchpwd('Password does not match');
                setLoading(false);
                setSubmitting(false);
                return;
            }

            const userEmail = await userService().validateEmail(values?.EmailId);
            console.log({ userEmail, values })
            const userPhoneNumber = await userService().validatePhonenumber(values?.PhoneNumber);

            if (userEmail?.status === 1 && userPhoneNumber?.status === 1) {
                setErrMsgMatch('Email address and phone number are already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userEmail?.status === 1) {
                setErrMsgMatch('Email address is already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userPhoneNumber?.status === 1) {
                setErrMsgMatch('Phone number is already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else {
                setErrMsgMatch('');
            }
            const PassWord = values?.Password;
            // let newFilename: any;
            // if (file?.name) {
            //     const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            //     newFilename = replaceSpecialChars(filename);

            //     await SendFile(file, newFilename, directory);
            // }
            const newFilename = file?.name ? await FormatFile(file) : null;
            file?.name && await SendFile(file, newFilename, directory);
            const formatEmail = values?.EmailId.toLowerCase();
            const GetInsertDataUpdate = {
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender,
                Age: values?.Age,
                Specialization: 'N/A',
                EmailId: formatEmail,
                PhoneNumber: values.PhoneNumber,
                Password: hashPassword(values?.Password),
                Status: '1',
                Role: 'Patient',
                countryCode: "+91",
                userProfile: newFilename,
                Description: 'NoDescription',
                Slug: 'N/A',
                qualification: 'N/A',
                MaritalStatus: values?.MaritalStatus,
                userType: "R",
                resetPWD: logUserObject ? "Yes" : "No"
            };

            // console.log("GetInsertDataUpdate", GetInsertDataUpdate);

            const userResponse = await userService().getcreateUser(GetInsertDataUpdate);
            const userRegResponse = userResponse?.data?.createUser;
            console.log("userRegResponse", userRegResponse)
            // if (userResponse) {
            //     if (logUserObject?.Role === "MasterAdmin") {
            //         navigate("/allRegisterpatients")
            //     }
            // }
            if (userResponse?.status === 200) {
                try {
                    await Users().generateEmailSMS(
                        userRegResponse?.EmailId,
                        `<html><body><div>Welcome to Trust Hospitals!<br /><br />Thank you for registering with us.<br /><a href=${process.env.REACT_APP_HOST}login>Click here</a>to login your account using your Email: ${userRegResponse?.EmailId} or Phone Number: ${userRegResponse?.PhoneNumber}, and pwd: ${PassWord}<br />Best Regards,<br />Trust Hospitals Team</div></body></html>`,
                        'Trust Hospitals,User Registration Details',
                        'Your Registered successfully'
                    );
                } catch (error) {
                    console.error("Error sending SMS:", error);
                    setLoading(false);
                }
                try {
                    const MobileSMSResponse = await Users().generateMobileMsgBulk(userRegResponse?.countryCode + userRegResponse?.PhoneNumber, `Thank you for registering with us. Log in using your Email: ${userRegResponse?.EmailId} or Phone Number: ${userRegResponse?.PhoneNumber}, and pwd: ${PassWord} - CLUSTERIT.`, "success"
                    )
                } catch (error) {
                    console.error("Error sending SnS:", error);
                    setLoading(false);
                }

                // const MobileSMSResponse = await Users().generateMobileMsg(
                //     // userRegResponse?.PhoneNumber,
                //     userRegResponse?.countryCode + userRegResponse?.PhoneNumber,
                //     `Thank you, your appointment has been created successfully. Please login to your account ${process.env.REACT_APP_HOST}login`,
                //     'Your Registered successfully'

                // )

                if (userRegResponse) {
                    setLoading(false);
                    resetForm();
                    setLoading(false);
                    // console.log("Patient registered and logged in successfully");
                    setAlertMsg("Patient registered and logged in successfully!");

                    // if (userResponse) {
                    if (logUserObject?.Role === "MasterAdmin") {
                        setAlertMsg("Patient registered and logged in successfully!");
                        // navigate("/allRegisterpatients")
                    }
                    // }
                }

                if (!logUser) {

                    const loginResponse: any = await Users().handleLogin(
                        { userName: values?.EmailId, password: values?.Password },
                        setErrors, setErr, location?.state?.url, navigate
                    );

                    if (loginResponse?.data?.accessToken && !logUserObject) {
                        const { accessToken, details, userRole, PhoneNumber, userName } = loginResponse?.data;
                        const userData = { accessToken, userId: details, Role: userRole, PhoneNumber, userName };
                        localStorage.setItem('userData', JSON.stringify(userData));
                        navigate('/DashBoard');
                    }
                    // if (loginResponse?.data?.accessToken && !logUserObject) {
                    //     const { accessToken, details, userRole, PhoneNumber, userName } = loginResponse?.data;
                    //     const userData = { accessToken, userId: details, Role: userRole, PhoneNumber, userName };
                    //     localStorage.setItem('userData', JSON.stringify(userData));
                    //     navigate('/DashBoard');
                    // }

                    if (loginResponse?.status === 200) {

                        if (logUserObject?.Role === "MasterAdmin") {
                            navigate('/allRegisterpatients', {
                                state: {
                                    userEmail: userRegResponse?.EmailId,
                                    userPwd: GetInsertDataUpdate?.Password,
                                    userId: userRegResponse?.id
                                }
                            });
                        } else {
                            navigate('/dashboard');
                        }
                    }

                    // console.log("response", SMSresponse)
                } else {
                    console.error("Error in registration:", userResponse);
                }
                setSubmitting(false);
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            setSubmitting(false);
        } finally {
            setSubmitting(false);
        }
        // }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [alertMsg]);

    const fields = [
        { name: 'foodAllergies', label: 'Food Allergies' },
        { name: 'CurrentMedications', label: 'Current Medications' },
        { name: 'ChronicIllnesses', label: 'Chronic Illnesses' },
        { name: 'SmokingStatus', label: 'Smoking Status' },
        { name: 'AlcoholConsumption', label: 'Alcohol Consumption' },
        { name: 'pastHealthConditions', label: 'Past Health Conditions' },
        // { name: 'MaritalStatus', label: 'Marital Status' },y
    ];

    const handleEmailChange = async (event: any, setFieldValue: Function, values: any) => {
        setExistingData("");
        const email = event;
        if (existingData) {
            console.log("skip fetching");
        } else if (email) { // Only fetch if email is not empty
            const getData = await userService().getExistingpatients("Patient", "", email);
            setExistingData(getData[0]);
            if (getData?.length > 0 && getData[0]?.Role === "Patient" && (getData[0]?.userType === "G" || getData[0]?.userType === "E")) {
                setFieldValue("FirstName", getData[0]?.FirstName);
                setFieldValue("LastName", getData[0]?.LastName === "NA" ? "" : getData[0]?.LastName);
                setFieldValue("PhoneNumber", getData[0]?.PhoneNumber);
                setFieldValue("EmailId", getData[0]?.EmailId);
                setFieldValue("Password", getData[0]?.Password === "NA" ? "" : getData[0]?.Password);
            }
            // else {
            //     setFieldValue("FirstName", "");
            //     setFieldValue("LastName", "");
            //     setFieldValue("PhoneNumber", "");
            //     setFieldValue("EmailId", "");
            // }
        } else {
            console.log("Email is empty, skipping fetch.");
        }
    };

    const handlePhoneChange = async (event: any, setFieldValue: Function, values: any) => {
        setExistingData("");
        const phone = event;
        if (existingData) {
            console.log("skip fetching");
        } else if (phone) { // Only fetch if phone number is not empty
            const getData = await userService().getExistingpatients("Patient", phone, "");
            setExistingData(getData[0]);
            if (getData?.length > 0 && getData[0]?.Role === "Patient" && (getData[0]?.userType === "G" || getData[0]?.userType === "E")) {
                setFieldValue("FirstName", getData[0]?.FirstName);
                setFieldValue("LastName", getData[0]?.LastName === "NA" ? "" : getData[0]?.LastName);
                setFieldValue("PhoneNumber", getData[0]?.PhoneNumber);
                setFieldValue("EmailId", getData[0]?.EmailId);
                setFieldValue("Password", getData[0]?.Password === "NA" ? "" : getData[0]?.Password);
            }
            // else {
            //     setFieldValue("FirstName", "");
            //     setFieldValue("LastName", "");
            //     setFieldValue("PhoneNumber", "");
            //     setFieldValue("EmailId", "");
            // }
        } else {
            console.log("Phone number is empty, skipping fetch.");
        }
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>


                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description} />
                        <meta name="keywords" content={keywords} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={description} />
                    </Helmet>

                    <section className="page-title text-center" style={{ backgroundImage: 'url(images/background/banner.jpg)' }}>
                        <div className="container">
                            <div className="title-text">

                                <h1>Patient Registration</h1>
                            </div>
                        </div>
                    </section>
                    <div>
                        <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
                            <li>
                                <a href="/">Home &nbsp;/</a>
                            </li>
                            &nbsp;<li>Patient Registration</li>
                        </ul>
                    </div>
                    <div className="container">
                        <div className="row mt-5">

                            <div className="page-title-heading" style={{ textAlign: 'center' }}>
                                {/* <i className="fas fa-user fa-4x" style={{color:'#48BDC5'}}></i> */}
                                <h1 className="title" style={{ color: 'black', marginBottom: '15px', textAlign: 'left' }}>Patient Registration</h1>
                            </div>
                            {
                                alertMsg &&
                                <div className="alert alert-success alert-dismissible fade show alertMessage">
                                    <strong>Success!</strong> {alertMsg}
                                    <i className="alertClose fa fa-close" aria-hidden="true" onClick={() => { logUserObject?.userId ? navigate('/allRegisterpatients') : navigate('/') }}></i>
                                </div>
                            }
                            <div className="col-md-6">
                                <Formik
                                    initialValues={InputDetails}
                                    validationSchema={registerValidationSchema(location?.state?.patientId)}
                                    onSubmit={updateDoctor || existingData?.id ? handleUpdate : handleSubmit}
                                    enableReinitialize
                                >
                                    {({ isSubmitting, values, setFieldValue }) => (
                                        <Form autoComplete='off'>
                                            {successMessage && (
                                                <div className="alert alert-success" role="alert" style={{ color: 'green' }}>
                                                    {successMessage}
                                                </div>
                                            )}
                                            <p style={{ color: 'red' }}>{errMsgMatch ? errMsgMatch : ''}</p>
                                            {/* <h4 className="mb-4">Patient Registration</h4> */}
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="FirstName" className="form-label">First Name*</label>
                                                        {/* <Field name="FirstName" type="text" className="form-control" autoComplete='off'/> */}
                                                        <Field name="FirstName" type="text" className="form-control" value={values?.FirstName}
                                                            onChange={(e: any) => setFieldValue("FirstName", e?.target?.value)} autoComplete='new-name' />
                                                        <ErrorMessage name="FirstName" component="div" className="errMsg" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="LastName" className="form-label">Last Name*</label>
                                                        <Field name="LastName" type="text" className="form-control" value={values?.LastName}
                                                            onChange={(e: any) => setFieldValue("LastName", e?.target?.value)} autoComplete='new-name' />
                                                        <ErrorMessage name="LastName" component="div" className="errMsg" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="EmailId" className="form-label">Email Address*</label>
                                                        <Field name="EmailId" type="email" className="form-control" value={values?.EmailId}
                                                            onChange={(e: any) => {
                                                                setFieldValue("EmailId", e?.target?.value);
                                                                handleEmailChange(e?.target?.value, setFieldValue, values);
                                                                // if (logUserObject?.Role === "MasterAdmin") {
                                                                //     handleEmailChange(e?.target?.value, setFieldValue);
                                                                // }
                                                            }}
                                                        // onChange={(e: any) => setFieldValue("EmailId", e?.target?.value)} 
                                                        />
                                                        <ErrorMessage name="EmailId" component="div" className="errMsg" />
                                                        <p className='errMsg'>{errMsg?.EmailId}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="Age" className="form-label">Age</label>
                                                        <Field name="Age" type="tel" className="form-control" autoComplete='off' value={values?.Age}
                                                            onChange={(e: any) => setFieldValue("Age", e?.target?.value)} />
                                                        <ErrorMessage name="Age" component="div" className="errMsg" />
                                                        <p className='errMsg'>{errMsg?.Age}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="countryCode" className="form-label">Country Code*</label>
                                                        <Field name="countryCode" type="tel" min={0} placeholder="+" className="form-control" autoComplete='off' readOnly />
                                                        <ErrorMessage name="countryCode" component="div" className="errMsg" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="PhoneNumber" className="form-label">Phone Number*</label>
                                                        <Field name="PhoneNumber" type="tel" min={0} className="form-control" value={values?.PhoneNumber}
                                                            onChange={(e: any) => {
                                                                setFieldValue("PhoneNumber", e?.target?.value);
                                                                // if (logUserObject?.Role === "MasterAdmin") {
                                                                handlePhoneChange(e?.target?.value, setFieldValue, values);
                                                                // }
                                                            }}
                                                            // onChange={(e: any) => setFieldValue("PhoneNumber", e?.target?.value)} 
                                                            autoComplete='new-name' />
                                                        <ErrorMessage name="PhoneNumber" component="div" className="errMsg" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="Address" className="form-label">Address</label>
                                                        <Field name="Address" type="Address" className="form-control" value={values?.Address}
                                                            onChange={(e: any) => setFieldValue("Address", e?.target?.value)} autoComplete='new-address' />
                                                        <ErrorMessage name="Address" component="div" className="errMsg" />
                                                        <p className='errMsg'>{errMsg?.Address}</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="City" className="form-label">City</label>
                                                        <Field name="City" type="City" className="form-control" value={values?.City}
                                                            onChange={(e: any) => setFieldValue("City", e?.target?.value)} autoComplete='new-city' />
                                                        <ErrorMessage name="City" component="div" className="errMsg" />
                                                        <p className='errMsg'>{errMsg?.City}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="pinCode" className="form-label">PinCode</label>
                                                        <Field name="pinCode" type="pinCode" className="form-control" value={values?.pinCode}
                                                            onChange={(e: any) => setFieldValue("pinCode", e?.target?.value)} autoComplete='new-pincode' />
                                                        <ErrorMessage name="pinCode" component="div" className="errMsg" />
                                                        <p className='errMsg'>{errMsg?.pinCode}</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="DOB" className="form-label">DOB</label>
                                                        <Field name="" type="date" className="form-control" value={values?.DOB}
                                                            onChange={(e: any) => setFieldValue("DOB", e?.target?.value)} />
                                                        <ErrorMessage name="DOB" component="div" className="errMsg" />
                                                        <p className='errMsg'>{errMsg?.DOB}</p>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label" style={{ marginTop: '10px', display: 'block' }}>Gender</label>
                                                        <div>
                                                            <div className="form-check" style={{ display: 'flex' }}>
                                                                <Field name="Gender" type="radio" value="Male" className="form-check-input" id="genderMale" style={{ width: '25px', height: '15px' }} />
                                                                <span className="form-label" style={{ marginRight: '30px', marginTop: '0px' }}>Male</span>
                                                                <Field name="Gender" type="radio" value="Female" className="form-check-input" id="genderFemale" style={{ width: '25px', height: '15px' }} />
                                                                <span className="form-label" style={{ marginTop: '0px' }}>Female</span>

                                                            </div>

                                                        </div>
                                                        <ErrorMessage name="Gender" component="div" className="errMsg" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="MaritalStatus" className="form-label">Marital Status</label>
                                                        <Field as="select" name="MaritalStatus" className="form-control" value={values?.MaritalStatus}
                                                            onChange={(e: any) => setFieldValue("MaritalStatus", e?.target?.value)}>
                                                            <option value="" label="Select marital status" />
                                                            <option value="Single" label="Single" />
                                                            <option value="Married" label="Married" />
                                                            <option value="Divorced" label="Divorced" />
                                                            <option value="Widowed" label="Widowed" />
                                                        </Field>
                                                        <ErrorMessage name="MaritalStatus" component="div" className="errMsg" />
                                                        <p className='errMsg'>{errMsg?.MaritalStatus}</p>
                                                    </div>
                                                </div>

                                                {(InputDetails?.Password === "NA") ? (
                                                    <div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="Password" className="form-label">Password*</label>
                                                                <Field
                                                                    name="Password"
                                                                    type="password"
                                                                    className="form-control"
                                                                // value={InputDetails?.Password === "NA" ? "" : InputDetails?.Password}
                                                                />
                                                                <ErrorMessage name="Password" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="confirmPassword" className="form-label">Confirm Password*</label>
                                                                <Field
                                                                    name="confirmPassword"
                                                                    type="password"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage name="confirmPassword" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : !updateDoctor ? (
                                                    <div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="Password" className="form-label">Password*</label>
                                                                <Field
                                                                    name="Password"
                                                                    type="password"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage name="Password" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="confirmPassword" className="form-label">Confirm Password*</label>
                                                                <Field
                                                                    name="confirmPassword"
                                                                    type="password"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage name="confirmPassword" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {/* {!updateDoctor && (
                                            <div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="Password" className="form-label">Password*</label>
                                                        <Field
                                                            name="Password"
                                                            type="password"
                                                            className="form-control"
                                                            value={values?.Password}
                                                            onChange={(e: any) => setFieldValue("Password", e?.target?.value)}
                                                        />
                                                        <ErrorMessage name="Password" component="div" className="errMsg" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password*</label>
                                                        <Field
                                                            name="confirmPassword"
                                                            type="password"
                                                            className="form-control"
                                                            value={values?.confirmPassword}
                                                            onChange={(e: any) => setFieldValue("confirmPassword", e?.target?.value)}
                                                        />
                                                        <ErrorMessage name="confirmPassword" component="div" className="errMsg" />
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}
                                                <br />
                                                <div className="col-md-12">
                                                    {InputDetails?.userProfile !== null && updateDoctor ?
                                                        <><img src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.userProfile}`} alt="Profile" style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }} />
                                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                                <p style={{ margin: 0, lineHeight: '24px' }}><strong>FileName:</strong>{InputDetails?.userProfile}</p>
                                                                <span
                                                                    style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                                                    onClick={handleRemove}
                                                                >
                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                </span>
                                                            </div>
                                                        </>
                                                        : null}

                                                    <FileUpload label={updateDoctor ? "Update profile" : "Choose Profile"} setDirectory={setDirectory} setFile={setFile} />
                                                </div>


                                                {/* <FieldArray name="KYC">
            {({ push, remove }) => (
                <div>
                    {fields?.map((field: any, index: any) => {

                        type KYCField = 'pastHealthConditions' | 'foodAllergies' | 'CurrentMedications' | 'ChronicIllnesses' | 'SmokingStatus' | 'AlcoholConsumption'|'MaritalStatus' ;
                        // console.log("field", field),
                        // console.log("values", values),
                        return (
                            <div key={index}>
                                <label>
                                    <Field
                                        type="checkbox"
                                        // name={field?.name}
                                        name={`KYC[0].${field.name}`} // Ensure this matches the correct path in the KYC object
                                        checked={String(values?.KYC[0][field?.name as KYCField]) === '0' ? false : true}
                                        onChange={() => {
                                            // console.log('values', values?.KYC[0][field.name as KYCField]);
                                            const newValue = String(values?.KYC[0][field?.name as KYCField]) === '1' ? '0' : '1';
                                            setFieldValue(`KYC[0].${field?.name as KYCField}`, newValue);
                                        }}
                                    />
                                    {field?.label}
                                </label>
                            </div>
                        )
                    })}
                </div>
            )}
        </FieldArray> */}


                                                {/* <FieldArray name="KYC">
                {({ push, remove }) => (
                    <div>
                        {fields?.map((field: any, index: any) => {

                            const fieldName = field?.name;
                            const descriptionFieldName = `${fieldName}Description`;

                            type KYCField = 'pastHealthConditions' | 'foodAllergies' | 'CurrentMedications' | 'ChronicIllnesses' | 'SmokingStatus' | 'AlcoholConsumption';
                            // console.log("field", field),
                            // console.log("values", values),
                            return (
                                <div key={index}>
                                    <label>
                                        <Field
                                            type="checkbox" style={{ width: '15px', height: '15px', display: 'inline', marginRight: '10px', marginTop: '20px' }}
                                            // name={field?.name}
                                            name={`KYC[0].${field?.name}`} // Ensure this matches the correct path in the KYC object
                                            checked={String(values?.KYC[0][field?.name as KYCField]) === '0' ? false : true}
                                            onChange={() => {
                                                // console.log('values', values?.KYC[0][field.name as KYCField]);
                                                const newValue = String(values?.KYC[0][field?.name as KYCField]) === '1' ? '0' : '1';
                                                setFieldValue(`KYC[0].${field?.name as KYCField}`, newValue);
                                            }}
                                        />
                                        {field?.label}
                                    </label>
                                    {String(values?.KYC[0][field?.name as KYCField]) === '1' && !['SmokingStatus', 'AlcoholConsumption'].includes(field?.name) && (
                                        <div>
                                            <label htmlFor={`KYC[0]?.${descriptionFieldName}`}>Description for {field?.label}</label>
                                            <Field
                                                type="text"
                                                name={`KYC[0]?.${descriptionFieldName}`}
                                                placeholder={`Enter description for ${field?.label}`}
                                            />
                                        </div>
                                    )}
                                </div>
                            )
                        })}

                        <div>
                            <label htmlFor="KYC[0].heardFrom">Where have you heard about us?</label>
                            <Field as="select" name="KYC[0].heardFrom">
                                <option value="">Select an option</option>
                                <option value="Friends">Friends</option>
                                <option value="Social Media">Social Media</option>
                                <option value="Family members">Family members</option>
                                <option value="None of the above">None of the above</option>
                            </Field>
                        </div>
                    </div>
                )}
            </FieldArray> */}

                                                <div className="">
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ border: 'none' }}>{updateDoctor || existingData?.id ? "Update" : "Register"}</button>
                                                </div>
                                            </div>


                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <img src={medical} alt="signupImage" style={{ width: '100%', objectFit: 'cover', height: '643px' }} />
                            </div>
                        </div>
                    </div></>
            )}
        </>
    );
}

export default Register;