import React, { useState, useEffect } from "react";
import ListTermsAndConditions from "../../../services/TermsAndCondtions";
import { string } from "yup";
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from "react-router-dom";
import pages from "../../../utils/pages";
interface compProps {
    title: string;
    description: string;
  }
  

interface TermsAndConditions{
    Description:string
}

const TermsConditions: React.FC<compProps> =  ({ title, description }) => {
    const [TermsConditionsList, setListTermsConditions] = useState<any>();
    const [pageInfo, setPageInfo] = useState<any>();
    const [bannerName, setBannerName] = useState<any>();
    const [caption, setCaption] = useState<any>();
    const [loading, setLoading] = useState(true);

      // const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      console.log("result", result)

      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)

      })

    };

    fetchData();
  }, [])


    const listTermsConditions = async () => {
        try {
            const response = await ListTermsAndConditions().TermsAndConditionsList();
            console.log("response", response);
            response?.map((item) => {
                if (item?.Link === "TermsConditions") {
                    setListTermsConditions(item);
                }
            })

        } catch (error) {
            console.error('Error fetching terms and conditions:', error);
        }
    };

    console.log("TermsConditionsList",TermsConditionsList);
    useEffect(() => {
        listTermsConditions();
        window.scrollTo(0, 0);
    }, []);

    const removeHtmlTags = (str: string) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    // const truncateText = (text: string, length: number) => {
    //     if (text.length <= length) return text;
    //     return text.slice(0, length) + '...';
    // };

    return (
        <div className="page-wrapper">
             <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <section
          className="page-title text-center"
          // style={{ backgroundImage:"url(pageInfo? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})"}}
          style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/img-1.jpg' }}
        >
            <div className="container">
              <div className="title-text">
                {/* <ul className="title-menu clearfix" style={{ textAlign: "left" }}>
      <li>
        <a href="/">Home &nbsp;/</a>
      </li>
      <li>About Us</li>
    </ul> */}
                <h1>{bannerName}</h1>
                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
              </div>
            </div>
          </section>
          <div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
              <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                  Home  /&nbsp;
                </span>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div>
            <section className="story" style={{ marginBottom: "0px !important" }}>
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                        <span className="blog-content" dangerouslySetInnerHTML={{ __html: TermsConditionsList?.Description }} />
                           
                        </div>
                        
                    </div>
                </div>
            </section >
        </div >
    );
};

export default TermsConditions;

