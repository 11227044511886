import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
// import { graphqlOperation } from '@aws-amplify/api-graphql';
import Select from 'react-select';
import userService from '../../../../services/users/user.service';
import { createSpecialization } from '../../../../graphql/mutations';

interface Specialization {
    label: string;
    value: string;
  }
  
  interface DoctorSpecializationProps {
    value: Specialization | null;
    setselectedSpecialization: (value: string) => void;
    label: string;
    error?: boolean;
   
  }
  
  function DoctorSpecialization({
    value,
    setselectedSpecialization,
    label,
  }: DoctorSpecializationProps) {
    const [specialization, setSpecialization] = useState("");
    const [specializationStatus, setSpecializationStatus] = useState(false);
    const [specializationName, setSpecializationName] = useState<
      Specialization[]
    >([]);
    const [specializationInp, setSpecializationInp] = useState("");
    const [specializationInsert, setSpecializationInsert] = useState(false);
  
    useEffect(() => {
      async function fetchSpecializations() {
        try {
          const allSpecializations = await userService().getlistSpecializations();
          if (Array.isArray(allSpecializations)) {
            const specializationOptions = allSpecializations.map((type: any) => ({
              label: type?.name,
              value: type?.id,
            }));
            // console.log(allSpecializations, "allSpecializations");
            const allSpecializationsOptions = [
              { label: "All Specializations", value: "" },
              ...specializationOptions,
            ];
            setSpecializationName(allSpecializationsOptions);
          }
        } catch (error) {
          console.error("Error fetching specializations:", error);
          // Handle error gracefully (e.g., show a message to the user)
        }
      }
     
  
      fetchSpecializations();
    }, [specializationStatus]);
  
    const handleSpecialization = (option: any) => {
      setselectedSpecialization(option.value);
      setSpecializationInsert(false);
    };
  
    const filterSpecialization = (option: any, inputValue: string) => {
      if (inputValue === "" || inputValue === null) {
        setSpecializationInsert(false);
        return true;
      } else {
        const getValues = specializationName.some((specialization: any) =>
          specialization.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        const getValue = specializationName.some(
          (specialization: any) =>
            specialization.label.toLowerCase() === inputValue.toLowerCase()
        );
        if (getValues === true) {
          if (getValue === true) {
            setSpecializationInsert(false);
          } else {
            setSpecializationInp(inputValue);
            setSpecializationInsert(true);
          }
          return true;
        } else {
          setSpecializationInp(inputValue);
          setSpecializationInsert(true);
          return false;
        }
      }
    };
  
    const insertSpecialization = async () => {
      try {
        const getSpecialization: any = await API.graphql(
          graphqlOperation(createSpecialization, {
            input: { name: specialization },
          })
        );
        const newSpecialization = {
          label: getSpecialization?.data?.createSpecialization?.name,
          value: getSpecialization?.data?.createSpecialization?.id,
        };
        setSpecializationName((prev) => [...prev, newSpecialization]);
        setselectedSpecialization(newSpecialization.value);
        setSpecializationInsert(false);
        setSpecializationStatus(true);
      } catch (error) {
        console.error("Error creating specialization:", error);
      
      }
    };
  
    const insertSpecializationInput = async () => {
      try {
        const getSpecialization: any = await API.graphql(
          graphqlOperation(createSpecialization, {
            input: { name: specializationInp },
          })
        );
        const newSpecialization = {
          label: getSpecialization?.data?.createSpecialization?.name,
          value: getSpecialization?.data?.createSpecialization?.id,
        };
        setSpecializationName((prev) => [...prev, newSpecialization]);
        setselectedSpecialization(newSpecialization.value);
        setSpecializationInsert(false);
      } catch (error) {
        console.error("Error creating specialization:", error);
        // Handle error (e.g., show an error message to the user)
      }
    };
  
    return (
      <div>
        <div>
        <label style={{marginTop:'2px'}}>{label}:</label>
          {specializationName.length > 0 ? (
            <Select
              value={
                value
                  ? specializationName.find(
                      (spec) => spec.value === value.value
                    ) || null
                  : null
              }
              onChange={(e: any) => handleSpecialization(e)}
              options={specializationName}
              isSearchable={true}
              filterOption={(option: any, inputValue: any) =>
                filterSpecialization(option, inputValue)
              }
              noOptionsMessage={() => null} 
              placeholder="Doctor Specialization*"
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                  backgroundColor: "#fff",
                  display: state.selectProps.menuIsOpen ? "block" : "none",
                  fontFamily: "Poppins",
                }),
                control: (provided, state) => ({
                  ...provided,
                  borderColor: state.isFocused ? "black !important" : "#BFBFBF",
                  height: "55px",
                  color: "black !important",
                  fontFamily: "Helvetica",
                }),
              }}
            />
          ) : (
            <input
              type="text"
              className="form-control"
              placeholder="Type specialization name"
              value={specialization}
              onChange={(e) => setSpecialization(e?.target?.value)}
            />
          )}
        </div>
        <div>
          {specializationName.length === 0 ? (
            <button
              
              onClick={insertSpecialization}
              disabled={!specialization}
            >
              Add
            </button>
          ) : specializationInsert ? (
            <button
            style={{
              width: "100%",
              backgroundColor: "skyblue",
              borderColor: "none !important",
              padding: "10px 20px",
              cursor: "pointer",
              borderRadius: "4px",
              color:'white',
              fontWeight:'bold',
              marginTop:'10px',
              border:'none',
              fontSize:'15px'
            }}
             
              onClick={insertSpecializationInput}
              disabled={!specializationInp}
            >
              Add
            </button>
          ) : null}
        </div>
      </div>
    );
  }
  
  export default DoctorSpecialization;
  
