
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import TestimonialSection from "../TestimonialSection/TestimonialSection";
import ServiceCarousel from "../ServiceCarousel/ServiceCarousel";
import Heroslider from "../HeroSlider/Heroslider";
import Faq from "../FAQ/faq";
// import './views/ServiceCarousel/ServiceCarousel.css';
import './../ServiceCarousel/ServiceCarousel.css';
import Homeservices from "../../../services/homeServices";
import { Helmet } from "react-helmet";
import './style.css'
import Loader from "../shared/loadercomponent";

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

const Home: React.FC<compProps> = ({ title, description, keywords }) => {
  const navigate = useNavigate();
  const [services, setServiceList] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(services?.id || '');
  const [loading, setLoading] = useState(true);

  async function provInfo() {
    setLoading(true);
    // const reviews = await ReviewServices().ActivereviewList();
    // // console.log("reviews", reviews);
    // setReviewList(reviews);

    const response = await Homeservices().ActiveservicesList();

    // console.log("response", response)

    setServiceList(response);
    setLoading(false);
  }
  useEffect(() => {
    provInfo()
  }, []);



  useEffect(() => {
    if (services.length > 0) {
      setActiveTab(services[0].id);
    }
  }, [services]);
  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };
  // const handleReadMore = (id: any) => {
  //   navigate('/singleService', { state: { id: id } });
  // };
  const handleReadMore = (slug: any) => {
    navigate(`/services/${slug}`);
  };
  const removeHtmlTags = (str: any) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    setLoading(false);
  }, []);

  return (

    <div className="row">
      {loading ? (
        <Loader />
      ) : (
        <>

          <Helmet>
          <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
          </Helmet>

          <div className="medium-12 columns">
            <div className="page-wrapper">

              <div className="preloader"></div>
              <Heroslider />
              <section className="cta" style={{ width: '90%', margin: "0 auto" }}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="cta-block">
                        <div className="emmergency item">
                          <i className="fa fa-phone"></i>
                          <h2 className="contact-text">Critical Emergencies</h2>
                          <a href="/#">9343180000</a>
                          <p>
                            For emergencies, call the number listed above
                            immediately.
                          </p>
                        </div>
                        <div className="top-doctor item">
                          <i className="fa fa-stethoscope"></i>
                          <h2 className="contact-text">24 Hour Services</h2>
                          <p>
                            Our team is ready to assist you at any time, day or
                            night, all week long.
                          </p>
                          {/* <a href="/#" className="btn btn-main" style={{ backgroundColor: 'white', color: '#48bdc5' }}>
      Read more
    </a> */}
                        </div>
                        <div className="working-time item">
                          <i className="fa fa-hourglass-o"></i>
                          <h2 className="contact-text">Visiting Hours</h2>
                          <ul className="w-hours">
                            <li>
                              Mon - Fri - <span>9:00AM - 10:00PM</span>
                            </li>

                            <li>
                              Sat - Sun - <span>9:00AM - 12:00PM</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <ServiceCarousel />
              <section className="service-tab-section section">
                <div className="outer-box clearfix">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 provided-services">
                        <h2 style={{ textAlign: 'center', color: 'black', marginBottom: '34px', marginTop: '-48px' }}>Provided Services</h2>
                        <div className="tabs" style={{ marginBottom: '39px' }}>
                          <ul className="nav nav-tabs" role="tablist">
                            {services?.map((item: any) => (
                              //    <li role="presentation" className="active">
                              //    <a href="#orthopedic" data-toggle="tab" style={{ borderRadius: '10px', height: '43px', lineHeight: '20px' }}>
                              //      {item?.Title}
                              //    </a>
                              //  </li>
                              <li
                                key={item?.id}
                                role="presentation"
                                className={activeTab === item?.id ? 'active' : ''}
                              >
                                <a
                                  className="capitalTxt"
                                  href={`#${item.id}`}
                                  data-toggle="tab"
                                  style={{ borderRadius: '10px', height: '43px', lineHeight: '20px' }}
                                  onClick={() => setActiveTab(item.id)}
                                >
                                  {/* {item.Title}
                                     */}
                                  {item.Title.toLowerCase()}
                                </a>
                              </li>

                            ))}
                            {/* <li role="presentation">
      <a href="#general-medicine" data-toggle="tab" style={{ borderRadius: '10px', height: '43px', lineHeight: '20px' }}>
        General Medicine
      </a>
    </li>
    <li role="presentation">
      <a href="#laparoscopic-surgery" data-toggle="tab" style={{ borderRadius: '10px', height: '43px', lineHeight: '20px' }}>
        Laparoscopic Surgery
      </a>
    </li> */}
                          </ul>
                        </div>

                        <div className="tab-content">
                          {services?.map((item: any) => (
                            // <div className="service-box tab-pane fade in active row" id="orthopedic">
                            <div
                              key={item?.id}
                              className={`service-box tab-pane fade in ${activeTab === item?.id ? 'active' : ''} row`}
                              id={item?.id}
                            >
                              <div className="col-md-6">
                                <img className="img-responsive home-ps Mimgwidth" src={item?.Profile ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.Profile}` : 'images/service.jpg'}
                                  style={{ height: "330px", objectFit: 'cover' }} />
                              </div>
                              <div className="col-md-6">
                                <div className="contents">
                                  <div className="section-title capitalTxt">
                                    <h3>
                                      {/* {item.Title} */}
                                      {item.Title.toLowerCase()}

                                    </h3>
                                  </div>
                                  <div className="text">

                                    {/* <p>{truncateText(removeHtmlTags(item?.Description), 300)}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: truncateText(item?.Description, 300) }} style={{ marginTop: '-48px' }} />

                                    <div>
                                      <button
                                        className="btn btn-style-one"
                                        onClick={() => handleReadMore(item?.Slug)}
                                        style={{
                                          color: 'white',
                                          cursor: 'pointer',
                                          // background: '#2CBCBC',
                                          // border: 'none',
                                          // padding: '6px 20px',
                                          // borderRadius: '20px',
                                        }}
                                      >
                                        Read more
                                      </button>
                                    </div>


                                  </div>
                                  {/* <ul className="content-list">
            <li><i className="fa fa-dot-circle-o"></i> Surgical Services</li>
            <li><i className="fa fa-dot-circle-o"></i> Non-Surgical Treatments</li>
            <li><i className="fa fa-dot-circle-o"></i> Bone Density Testing</li>
          </ul>
          <a href="service" className="btn btn-style-one" style={{ color: "white" }}>Read more</a> */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <TestimonialSection />
            </div>
          </div></>
      )}
    </div>
  );
};

export default Home;
