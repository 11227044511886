
import React, { useEffect, useMemo, useRef, useState } from "react";
import userService from "../../../services/users/user.service";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import pages from "../../../utils/pages";
import './style.css';
import Loader from '../shared/loadercomponent';

interface comProps {
  title: string;
  description: string;
  keywords: string;
}

const Team: React.FC<comProps> = ({ title, description, keywords }) => {
  const navigate = useNavigate();
  const [pageInfo, setPageInfo] = useState<any>();
  const [bannerName, setBannerName] = useState<any>();
  const [caption, setCaption] = useState<any>();
  const [loading, setLoading] = useState(true);


  // const { slug } = useParams();
  const location = useLocation();
    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const SpecialityPerPage = 6;
    const SpecialityRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      console.log("result", result)

      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
        console.log('item', item)
      })

    };
    fetchData();
  }, [])


  const [doctorList, setDoctorList] = useState<any[]>([]);

  useMemo(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await userService().ActiveDoctors('Doctor');
        console.log("response", response)
        const specializationtList = await userService().getlistSpecializations() as any;
        response?.map(async (item: any, index: number) => {
          const specName = specializationtList?.find((spec: any) => spec?.id === item?.Specialization);
          item.Specialization = specName?.name
        })

        setDoctorList(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching doctors:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);


  // const [doctors, setDoctors] = useState<any>([]);
  // useEffect(() => {
  //   async function fetchGalleryItems() {
  //     const response = await Homeservices().servicesList();
  //     setGalleryItems(response);
  //     console.log("response", response);
  //   }


  //   fetchGalleryItems();
  // }, []);
  // const handleReadMore = (id: any) => {
  //   navigate('/singleDoctor', { state: { id: id } });
  // };
  const handleReadMore = (slug: any) => {
    navigate(`/team/${slug}`);
  };

  const removeHtmlTags = (str: any) => {
    if (!str) return "";
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };
  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };
  const generateSlug = (name: any) => name.replace(/\s+/g, '_').toLowerCase();


  // Pagination logic
  const indexOfLastSpeciality = currentPage * SpecialityPerPage;
  const indexOfFirstSpeciality = indexOfLastSpeciality - SpecialityPerPage;
  // const currentDoctors = blogsFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const currentDoctors = doctorList.slice(indexOfFirstSpeciality, indexOfLastSpeciality);
  const totalPages = Math.ceil(doctorList.length / SpecialityPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  useEffect(() => {
    if (SpecialityRef.current) {
      SpecialityRef.current.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);
  }, [currentPage]);
  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
        <Helmet>
          <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
          </Helmet>
        
        <section className="page-title text-center"
          //  style={{ backgroundImage: 'url(images/img-4.jpg)' }}
          style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/img-4.jpg' }}
        >

            <div className="container">
              <div className="title-text">

                {/* <ul className="title-menu clearfix" style={{ textAlign: 'left' }}>
      <li>
        <a href="/">home &nbsp;/</a>
      </li>
      <li>Team</li>
    </ul> */}
                {/* <h1>Team</h1>
     */}


                <h1>{bannerName}</h1>
                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
              <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                  Home  /&nbsp;
                </span>
              </li>
              <li>{bannerName}</li>

            </ul>
          </div><section className="team-section section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-title text-center">
                    <h3>Our Esteemed Medical Doctors</h3>
                    {/* <p>Meet Our Doctors</p> */}
                  </div>

                  <div className="tab-content ">
                    <div className="team-members MmarginBtm">
                      <div className="team-grid">
                        {currentDoctors?.map((member: any) => (

                          <div className="team-member" key={member?.id}>
                            <div className="team-person text-center staff">
                              {member?.userProfile ?

                                <img
                                  src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${member?.userProfile}`}
                                  className="img-responsive text-center team-profile"
                                  alt="team" /> :

                                <img
                                  src="/images/profile.jpg"
                                  className="img-responsive text-center team-def"
                                  alt="team" />}

                              {/* <img
              src={member?.userProfile !== null ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${member?.userProfile}` : "/images/profile.jpg"}
              className="img-responsive text-center"
              alt="team"
            /> */}
                              <div className="content-text">

                                <h6 className="teamname">Dr. {member?.FirstName}</h6>
                                <span style={{ fontWeight: 'bold' }}>{member?.Specialization}</span><br />
                                {/* <div className="htdesc">{truncateText(removeHtmlTags(member?.Description), 85)}</div>
             */}
                                <p className="htdesc" dangerouslySetInnerHTML={{ __html: truncateText(member?.Description, 85) }} />


                                {/* <ul className="ftco-social">
              {member?.SocialIcons?.map((social: any, i: any) => (
                console.log("socialicon", social),
                <li key={i} className="ftco-animate">
                  <a href={social?.icon}>
                    <span className={`icon-${social?.icon?.toLowerCase()}`}></span>
                  </a>
                </li>
              ))}
            </ul> */}

                                {/* {member?.Description?.length > 100 && ( */}
                                <div className="Mmargintop">
                                  <button
                                    className="btn"
                                    onClick={() => handleReadMore(member?.Slug)}
                                  >
                                    Know More
                                  </button>
                                </div>
                              </div>
                              {/* // )} */}
                            </div>

                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                 {/* Pagination Controls */}
                 {currentDoctors?.length > 0 ?
                <div className="custom-pagination">
                  <ul className="custom-pagination-list">
                    <li className={`custom-pagination-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button onClick={() => paginate(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`custom-pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
                : null}

            </div>
          </section>
        </>
      )}
    </div>
  );
}

export default Team;


