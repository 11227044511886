import { API, graphqlOperation } from "aws-amplify";
import { createBlog, deleteBlog, deleteBlogCategory, updateBlog, updateBlogCategory } from "../../graphql/mutations";
import { getBlog, getBlogCategory, listBlogCategories, listBlogs } from "../../graphql/queries";

const getblogs=()=>{
    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }

    const CreateBlog = async (userData:any) => {
        console.log("userData",userData);
        try {
            const result = await API.graphql(graphqlOperation(createBlog, { input: userData })) as any;
            return result?.data?.createBlog;
        } catch (error) {
            console.error('Error creating Blog:', error);
            return null;
        }
    }

    const validateSlug = async (slug: string) => {
        console.log("slug", slug);
        try {
            const filterVar = {
                filter: {
                    Slug: { eq: slug }
                }
            };
         console.log("filterVar", filterVar);

            const existingSlugs: any = await API.graphql(graphqlOperation(listBlogs, filterVar));

            // console.log("existingSlugs", existingSlugs)

            if (existingSlugs && existingSlugs?.data?.listBlogs?.items?.length > 0) {
                return { msg: 'slug already exists with this slug', status: 1, data: existingSlugs?.data?.listBlogs?.items};
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating slug:', error);
            return { msg: 'Error validating slug', status: 1 };
        }
    };





     
    const BlogUpdate = async (data: any) => {
        console.log("data", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt', 'categoryName'];
        const sanitizedUserInfo: any = removeFields(data, fieldsToRemove);

        console.log("sanitizedUserInfo", sanitizedUserInfo)

        try {
            const result = await API.graphql(graphqlOperation(updateBlog, { input: sanitizedUserInfo })) as any;
            console.log("sanitizedUserInforesult", result)
            return result?.data?.updateBlog;
        } catch (error) {
            console.error('Error updating Blog:', error);
            return null;
        }
    }

    const BlogDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteBlog, { input: { id: id } })) as any;
            return result?.data?.deleteBlog;
        } catch (error) {
            console.error('Error in deleteBlog:', error);
            return null;
        }
    }

    const getSingleBlog = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getBlog, { id: id })) as any;
            return result?.data?.getBlog;
        } catch (error) {
            console.error('Error in getBlog :', error);
            return null;
        }
    }
    const BlogList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listBlogs, filter)) as any;
                const specializations = result?.data?.listBlogs?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listBlogs?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listBlogs:', error);
            return [];
        }
    }

    const getcategoryname = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getBlogCategory, { id: id })) as any;
            return result?.data?.getBlogCategory;
        } catch (error) {
            console.error('Error in getBlogCategory :', error);
            return null;
        }
    }

    const CategoryList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                   
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listBlogCategories, filter)) as any;
                const specializations = result?.data?.listBlogCategories?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listBlogCategories?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listBlogs:', error);
            return [];
        }
    }

    const getcategory= async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let allCategories: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listBlogCategories, filter)) as any;
                // console.log("result",result)
                const Categories = result?.data?.listBlogCategories?.items || [];
                allCategories.push(...Categories);
                nextToken = result?.data?.listBlogCategories?.nextToken;
            } while (nextToken);
            return allCategories;
        } catch (error) {
            console.error('Error fetching Categories:', error);
            return []; // Return an empty array in case of error
        }
    }


    const ActiveBlogList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    filter: {
                        status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listBlogs, filter)) as any;
                const specializations = result?.data?.listBlogs?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listBlogs?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listBlogs:', error);
            return [];
        }
    }

    const BlogCategoryList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                limit: limit,
                nextToken: nextToken
            };

            do {
                const result = await API.graphql(graphqlOperation(listBlogCategories, filterVar)) as any;
                const users = result?.data?.listBlogCategories?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listBlogCategories?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching listBlogCategories:', error);
            return [];
        }
    }

    const BlogCategoryUpdate = async (data: any) => {
        console.log("careerData", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        console.log('sanitizedUserInfo', sanitizedUserInfo)

        try {
            const result = await API.graphql(graphqlOperation(updateBlogCategory, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateBlogCategory;
        } catch (error) {
            console.error('Error updatingCarrer:', error);
            return null;
        }
    }
    const BlogCategoryDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteBlogCategory, { input: { id: id } })) as any;
            return result?.data?.deleteBlogCategory;
        } catch (error) {
            console.error('Error in deleteCarrer:', error);
            return null;
        }
    }
return{
    CreateBlog,
    BlogUpdate,
    BlogDelete,
    getSingleBlog,
    BlogList,
    getcategoryname,
    CategoryList,
    getcategory,
    validateSlug,
    ActiveBlogList,
    BlogCategoryList,
    BlogCategoryUpdate,
    BlogCategoryDelete

}
}

export default getblogs