import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import userSerivce from '../../../services/users/user.service';
import { SHA256 } from 'crypto-js';
import { PharmacyValidationSchema } from './PharmacyValidationSchema';
import { useIdleTimeout } from './../../../components/views/shared/header/sessionExpire';
import medical from './../../users/registerImages/registrationdr.jpg'
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from './../../views/shared/fileupload';
import { Grid, TextField } from '@mui/material';
import userService from '../../../services/users/user.service';
import LabServices from "./../../../services/Labs"
import { Helmet } from "react-helmet";
import Users from '../../../utils/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormatFile } from '../../../utils/Fileformat';
import Loader from '../../views/shared/loadercomponent';
import { Alert } from 'react-bootstrap';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
    title: string;
    description: string;
}

interface SocialIcons {
    name: string;
    icon: string;
}
interface RegisterDetails {
    FirstName: string;
    LastName: string;
    Gender: string;
    Age: string;
    Role: string;
    PhoneNumber: string;
    EmailId: string;
    Password: string;
    countryCode: string;
    confirmPassword: string;
    userProfile: string;
    Specialization: string;

    // [key: string]: string | number | undefined;
}

const initialState: RegisterDetails = {
    FirstName: '',
    LastName: '',
    Age: 'N/A',
    Gender: '',
    EmailId: '',
    PhoneNumber: '',
    Password: '',
    Role: 'Lab',
    confirmPassword: '',
    countryCode: '+91',
    userProfile: "",
    Specialization: "N/A",
};


const LabRegister: React.FC<compProps> = ({ title, description }) => {
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const navigate = useNavigate();
    const location = useLocation();
    const [errMsg, setErrMsg] = useState(initialState);
    const isIdle = useIdleTimeout(1000 * 900);
    const isActive = useIdleTimeout(1000 * 900);
    const [errMsgMatchpwd, setErrMsgMatchpwd] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errMsgPwd, setErrMsgPwd] = useState("");
    const [errMsgMatch, setErrMsgMatch] = useState("");
    const [InputDetails, setInputDetails] = useState<RegisterDetails>(initialState);
    const [labDetails, setLabDetails] = useState<any>();
    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    let [updateLab, setUpdateLab] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [alertMsg, setAlertMsg] = useState<any>('');


    const fieldRefs: any = useRef({
        FirstName: null as HTMLDivElement | null,
        LastName: null as HTMLDivElement | null,
        Age: null as HTMLDivElement | null,
        Gender: null as HTMLDivElement | null,
        EmailId: null as HTMLDivElement | null,
        PhoneNumber: null as HTMLDivElement | null,
        Password: null as HTMLDivElement | null,
        confirmPassword: null as HTMLDivElement | null,
        countryCode: null as HTMLDivElement | null,
        userProfile: null as HTMLDivElement | null,
        Specialization: null as HTMLDivElement | null,
    });

    const hashPassword = (password: string) => {
        try {
            const hashedPassword = SHA256(password).toString();
            return hashedPassword;
        } catch (error) {
            console.error("Error hashing password:", error);
            return "";
        }
    };
    useEffect(() => {
        if (location?.state?.id) {
            getExistingUser();
        }
    }, [location.state]);
    const getExistingUser = async () => {
        const getLabDetails = await LabServices().ListLabById(location?.state?.id);
        if (getLabDetails?.length > 0) {
            setInputDetails(getLabDetails[0]);
            setUpdateLab(true);
        }
        setLabDetails(getLabDetails[0]);

    };
    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.userProfile, setInputDetails, 'userProfile', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };
    const handleUpdate = async (values: any, { setSubmitting }: any) => {
        window.scrollTo(0, 0);
        try {
            setLoading(true);
            // console.log('userEmail', values?.EmailId.toLowerCase());
            const userEmail = await userService().validateEmail(values?.EmailId.toLowerCase());
            const email = userEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.id
            const userPhoneNumber = await userService().validatePhonenumber(values?.PhoneNumber);
            const phn = userPhoneNumber?.existingPhoneUser?.data?.listUsers?.items[0]?.id
            if (userEmail?.status === 1 && userPhoneNumber?.status === 1 && email != values?.id && phn != values?.id) {
                setErrMsgMatch('Email address and phone number are already in use');
                setSubmitting(false);
                return;
            } else if (userEmail?.status === 1 && email != values?.id) {
                setErrMsgMatch('Email address is already in use');
                setSubmitting(false);
                return;
            } else if (userPhoneNumber?.status === 1 && phn != values?.id) {
                setErrMsgMatch('Phone number is already in use');
                setSubmitting(false);
                return;
            } else {
                setErrMsgMatch('')
            }
            // let newFilename: any;
            // if (file?.name) {
            //     const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            //     newFilename = replaceSpecialChars(filename);
            //     // console.log("filename",fileName)
            //     await updDelFile(values?.userProfile, "images");
            //     await SendFile(file, newFilename, "images");
            // }
            const newFilename = file?.name ? await FormatFile(file) : '';
            file?.name && await updDelFile(values?.userProfile, "images");
            file?.name && await SendFile(file, newFilename, "images");
            const formatEmail = values?.EmailId.toLowerCase();

            const GetInsertDataUpdate = {
                id: location?.state?.id,
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender,
                Age: values?.Age,
                EmailId: formatEmail,
                PhoneNumber: values?.PhoneNumber,
                Password: values?.Password,
                Status: "1",
                Role: "Lab",
                countryCode: values.countryCode,
                userProfile: file?.name ? newFilename : values?.userProfile,
                Specialization: values?.Specialization,
                Slug: "N/A"
            };
            const userResponse = await LabServices().updateLabByData(GetInsertDataUpdate)
            if (userResponse) {
                setLoading(false);
                setAlertMsg('Lab updated successfully');
                setSubmitting(false);
                // navigate("/listLab")
            }
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
        setSubmitting(false);
    }
    const handleSubmit = async (values: RegisterDetails, { setSubmitting, resetForm }: any) => {
        window.scrollTo(0, 0);
        const PassWord = values?.Password
        if (values.Password !== values.confirmPassword) {
            setErrMsgMatchpwd("Password does not match");
            return;
        }
        try {
            const userEmail = await userService().validateEmail(values?.EmailId.toLowerCase());
            const userPhoneNumber = await userService().validatePhonenumber(values?.PhoneNumber);

            if (userEmail?.status === 1 && userPhoneNumber?.status === 1) {
                setErrMsgMatch("Email address and phone number are already in use");
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userEmail?.status === 1) {
                setErrMsgMatch("Email address is already in use");
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userPhoneNumber?.status === 1) {
                setErrMsgMatch("Phone number is already in use");
                setSubmitting(false);
                setLoading(false);
                return;
            }else {
                setErrMsgMatch('')
            }
            // let newFilename: any;
            // if (file?.name) {
            //     const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            //     newFilename = replaceSpecialChars(filename);
            //     await SendFile(file, newFilename, directory);
            // }

            const newFilename = file?.name ? await FormatFile(file) : null;
            file?.name && await SendFile(file, newFilename, directory);
            const formatEmail = values?.EmailId.toLowerCase();

            const GetInsertDataUpdate = {
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender,
                Age: values?.Age,
                EmailId: formatEmail,
                PhoneNumber: values?.PhoneNumber,
                Password: hashPassword(values?.Password),
                Status: "1",
                Role: "Lab",
                countryCode: values?.countryCode,
                userProfile: newFilename,
                Specialization: values?.Specialization,
                Slug: "N/A"
            };
            // console.log("GetInsertDataUpdate", GetInsertDataUpdate);

            const userResponse = await LabServices().getcreateUser(GetInsertDataUpdate);
            const userRegResponse = userResponse?.data?.createUser;

            if (userResponse?.status === 200) {
                setLoading(false);
                resetForm();
                setAlertMsg('Lab Registered successfully')
                try {
                    await Users().generateEmailSMS(
                        userRegResponse?.EmailId,
                        `<html><body><div>Welcome to Trust Hospitals!<br /><br />Thank you for registering with us.<br />Your Credentials for login :<br/>Email Id :${userRegResponse?.EmailId} / Phone Number :${userRegResponse?.PhoneNumber} <br />Password : ${PassWord}<br/><a href=${process.env.REACT_APP_HOST}login>Click here</a>to login your account <br />Best Regards,<br />Trust Hospitals Team</div></body></html>`,
                        'Trust Hospitals,Lab Registration Details',
                        'Your Registered successfully'
                    );
                } catch (err) {
                    console.log("Error sending Email", err)
                }
                try {
                    await Users().generateMobileMsgBulk(userRegResponse?.countryCode + userRegResponse?.PhoneNumber, `Thank you for registering with us. Log in using your Email: ${userRegResponse?.EmailId} or Phone Number: ${userRegResponse?.PhoneNumber}, and pwd: ${PassWord} - CLUSTERIT.`, "success")
                } catch (err) {
                    console.log("Sending Error SMS", err)
                }
                // const MobileSMSResponse = await Users().generateMobileMsg(userRegResponse?.countryCode + userRegResponse?.PhoneNumber, `Thank you for registering with us. Log in using your Email: ${userRegResponse?.EmailId} or Phone Number: ${userRegResponse?.PhoneNumber}, and pwd: ${PassWord} - CLUSTERIT.`, "success"
                // )
                // if (userRegResponse) {
                //     resetForm();
                //     // console.log("Lab Registered successfully");
                //     alert("Lab Registered successfully!");
                //     navigate('/listdoctors')
                // }
                // setSuccessMessage("Patient Registered successfully!");
                resetForm();
            } else {
                setLoading(false);
                console.error("Error in registration:", userResponse);
            }
            setSubmitting(false);
            navigate("/listLab");
        } catch (error) {
            setLoading(false);
            console.error("Error during form submission:", error);
            setSubmitting(false);
        }
        setSubmitting(false);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [alertMsg]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description} />
                    </Helmet>
                    <section
                        className="page-title text-center"
                        style={{ backgroundImage: "url(images/background/banner.jpg)" }}
                    >
                        <div className="container">

                            <div className="title-text">
                                <h1>Lab Registration </h1>
                            </div>
                        </div>
                    </section>
                    <div>
                        <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
                            <li>
                                <a href="/" className="aColor">Home   /&nbsp;</a>
                            </li>
                            <li>Lab Registration</li>
                        </ul>
                    </div>

                    <div className="container-fluid" style={{ paddingLeft: '70px', paddingRight: '70px' }}>
                        <div className="row" style={{ padding: '50px' }}>
                            <div className="page-title-heading" style={{ textAlign: 'center', marginBottom: '40px' }}>
                                <h1 className="title" style={{ color: 'black', textAlign: 'left' }}>Lab Registration</h1>
                            </div>
                            {
                                alertMsg &&
                                <div className="alert alert-success alert-dismissible fade show alertMessage">
                                    <strong>Success!</strong> {alertMsg}
                                    <i className="alertClose fa fa-close" aria-hidden="true" onClick={() => { logUserObject?.userId ? navigate('/listLab') : navigate('/') }}></i>
                                </div>
                            }
                            <div className="col-md-6">
                                <Formik
                                    initialValues={InputDetails}
                                    validationSchema={PharmacyValidationSchema(location?.state?.id)}
                                    onSubmit={!updateLab ? handleSubmit : handleUpdate}
                                    enableReinitialize
                                >
                                    {({ isSubmitting, values, setFieldValue,errors }) => {

                                        const errorCount = Object.keys(errors).length;
                                        if (isSubmitting && errorCount > 0) {
                                            for (const key in errors) {
                                                if (fieldRefs.current[key]) {
                                                    fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                                    break; // Stop at the first error we find
                                                }
                                            }
                                        }

                                        return (
                                            <Form autoComplete='off'>
                                                {successMessage && (
                                                    <div className="alert alert-success" role="alert" style={{ color: 'green' }}>
                                                        {successMessage}
                                                    </div>
                                                )}
                                                <p style={{ color: 'red' }}>{errMsgMatch ? errMsgMatch : ''}</p>
                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <div className="mb-3"  ref={(el) => (fieldRefs.current.FirstName = el)}>
                                                            <label htmlFor="FirstName" className="form-label">First Name*</label>
                                                            <Field name="FirstName" type="text" className="form-control" value={values.FirstName}
                                                                onChange={(e: any) => setFieldValue("FirstName", e?.target?.value)} />
                                                            <ErrorMessage name="FirstName" component="div" className="errMsg" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3"  ref={(el) => (fieldRefs.current.LastName = el)}>
                                                            <label htmlFor="LastName" className="form-label">Last Name*</label>
                                                            <Field name="LastName" type="text" className="form-control" value={values.LastName}
                                                                onChange={(e: any) => setFieldValue("LastName", e.target.value)} />
                                                            <ErrorMessage name="LastName" component="div" className="errMsg" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3" ref={(el) => (fieldRefs.current.EmailId = el)}>
                                                            <label htmlFor="EmailId" className="form-label">Email Address*</label>
                                                            <Field name="EmailId" type="email" className="form-control" value={values.EmailId}
                                                                onChange={(e: any) => setFieldValue("EmailId", e?.target?.value)} />
                                                            <ErrorMessage name="EmailId" component="div" className="errMsg" />
                                                            <p className='errMsg'>{errMsg?.EmailId}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3" ref={(el) => (fieldRefs.current.Gender = el)}>
                                                            <label className="form-label" style={{ marginTop: '10px', display: 'block' }}>Gender</label>
                                                            <div className="form-check" style={{ display: 'flex' }}>
                                                                <Field name="Gender" type="radio" value="Male" className="form-check-input" id="genderMale" style={{ width: '25px', height: '15px' }} />
                                                                <span className="form-label" style={{ marginRight: '30px', marginTop: '0px' }}>Male</span>
                                                                <Field name="Gender" type="radio" value="Female" className="form-check-input" id="genderFemale" style={{ width: '25px', height: '15px' }} />
                                                                <span className="form-label" style={{ marginTop: '0px' }}>Female</span>
                                                            </div>
                                                            <ErrorMessage name="Gender" component="div" className="errMsg" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="row">
                                                        <div className="col-md-6" ref={(el) => (fieldRefs.current.countryCode = el)}>
                                                            <label htmlFor="countryCode" className="form-label">Country Code*</label>
                                                            <Field name="countryCode" type="tel" min={0} placeholder="+" className="form-control" readOnly />
                                                        </div>
                                                        <div className="col-md-6" ref={(el) => (fieldRefs.current.PhoneNumber = el)}>
                                                            <label htmlFor="PhoneNumber" className="form-label">Phone Number*</label>
                                                            <Field name="PhoneNumber" type="tel" min={0} className="form-control" value={values.PhoneNumber}
                                                                onChange={(e: any) => setFieldValue("PhoneNumber", e?.target?.value)} />
                                                            <ErrorMessage name="PhoneNumber" component="div" className="errMsg" />
                                                        </div>
                                                    </div>
                                                </div>

                                                {!updateLab && (
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="mb-3"  ref={(el) => (fieldRefs.current.Password = el)}>
                                                                <label htmlFor="Password" className="form-label">Password*</label>
                                                                <Field name="Password" type="password" className="form-control" value={values?.Password}
                                                                    onChange={(e: any) => setFieldValue("Password", e?.target?.value)} />
                                                                <ErrorMessage name="Password" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3"  ref={(el) => (fieldRefs.current.confirmPassword = el)}>
                                                                <label htmlFor="confirmPassword" className="form-label">Confirm Password*</label>
                                                                <Field name="confirmPassword" type="password" className="form-control" value={values?.confirmPassword}
                                                                    onChange={(e: any) => setFieldValue("confirmPassword", e?.target?.value)} />
                                                                <ErrorMessage name="confirmPassword" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {InputDetails?.userProfile &&
                                                    <>
                                                        <img
                                                            src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.userProfile}`}
                                                            alt="Profile"
                                                            style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }} />
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                            <p style={{ margin: 0, lineHeight: '24px' }}>
                                                                <strong>FileName:</strong> {InputDetails?.userProfile}
                                                            </p>
                                                            <span
                                                                style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                                                onClick={handleRemove}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </span>
                                                        </div>
                                                    </>
                                                }
                                                <FileUpload label={updateLab ? "Update profile" : "Choose Profile"} setDirectory={setDirectory} setFile={setFile} />

                                                <div className="mb-3" style={{ marginTop: '20px' }}>
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>{!updateLab ? "Register" : "Update"}</button>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                            <div className="col-md-6">
                                <img src={medical} alt="signupImage" style={{ width: '95%', height: '397px', objectFit: 'cover', marginTop: '42px', marginLeft: '73px' }} />
                            </div>
                        </div>
                    </div></>
            )}
        </>
    );
}

export default LabRegister;
