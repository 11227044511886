import { API, graphqlOperation } from "aws-amplify";
import {   createFAQ,deleteFAQ, updateFAQ } from "../../graphql/mutations";
import {  getFAQ, getSpecialityCategory,listFAQS, listSpecialityCategories } from "../../graphql/queries";

const getFAQs=()=>{
    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }

    const CreateFAQ = async (userData:any) => {
        console.log("userData",userData);
        try {
            const result = await API.graphql(graphqlOperation(createFAQ, { input: userData })) as any;
            return result?.data?.createFAQ;
        } catch (error) {
            console.error('Error createFAQ:', error);
            return null;
        }
    }



    const validateSlug = async (slug: string) => {
        console.log("slug", slug);
        try {
            const filterVar = {
                filter: {
                    Slug: { eq: slug }
                }
            };
         console.log("filterVar", filterVar);

            const existingSlugs: any = await API.graphql(graphqlOperation(listFAQS, filterVar));

            // console.log("existingSlugs", existingSlugs)

            if (existingSlugs && existingSlugs?.data?.listFAQS?.items?.length > 0) {
                return { msg: 'slug already exists with this slug', status: 1, data: existingSlugs?.data?.listFAQS?.items};
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating slug:', error);
            return { msg: 'Error validating slug', status: 1 };
        }
    };

     
    const UpdateFAQ = async (data: any) => {
        console.log("data", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updateFAQ, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateFAQ;
        } catch (error) {
            console.error('Error updateFAQ:', error);
            return null;
        }
    }

    const DeleteFAQ = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteFAQ, { input: { id: id } })) as any;
            return result?.data?.deleteFAQ;
        } catch (error) {
            console.error('Error in deleteFAQ:', error);
            return null;
        }
    }

    const getSingleFAQ = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getFAQ, { id: id })) as any;
            return result?.data?.getFAQ;
        } catch (error) {
            console.error('Error in getFAQ:', error);
            return null;
        }
    }
    const FAQlist = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listFAQS, filter)) as any;
                const specializations = result?.data?.listFAQS?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listFAQS?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listFAQS:', error);
            return [];
        }
    }

 

    
    const ActiveFAQlist = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    filter: {
                        Status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listFAQS, filter)) as any;
                const specializations = result?.data?.listFAQS?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listFAQS?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listFAQS:', error);
            return [];
        }
    }
return{
    CreateFAQ,
    UpdateFAQ,
    DeleteFAQ,
    getSingleFAQ,
    FAQlist,
    validateSlug,
    ActiveFAQlist 
    

}
}

export default getFAQs