import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { createGallery, deleteGallery, updateGallery } from '../../graphql/mutations';
import { SendFile, updDelFile } from './../../components/views/shared/fileupload';
import { getGallery, listGalleries } from '../../graphql/queries';
import AWS from 'aws-sdk';


const GalleryServices: any = () => {

    const galleryCreate = async (values: any) => {
        console.log("values at service", values);
        let fileNames: any;

        if (values?.Files?.length > 0 && values?.fileType === "image") {
            fileNames = await Promise.all(
                values?.Files?.map(async (file: any) => {
                    const fileName = Date.now() + "_" + file?.name;
                    await SendFile(file, fileName, "images");
                    return fileName;
                })
            );
        } else if (values?.Files?.length > 0 && values?.fileType === "video") {
            fileNames = await Promise.all(
                values?.Files?.map(async (file: any) => {
                    const fileName = Date.now() + "_" + file?.name;
                    await SendFile(file, fileName, "videos");
                    return fileName;
                })
            );
        } else {
            fileNames = [values?.Files];
        }
        console.log("fileNames", fileNames);

        const createGalleryInput = {
            input: {
                Category: values?.Category,
                fileType: values?.fileType,
                Files: fileNames,
                Status: "0"
            },
        };
        const response = await API.graphql(graphqlOperation(createGallery, createGalleryInput));
        console.log("response", response);
        return response
    }

    const galleryList = async (Role: string) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                limit: limit,
                nextToken: nextToken
            }

            do {
                const result = await API.graphql(graphqlOperation(listGalleries, filterVar)) as any;
                const users = result?.data?.listGalleries?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listGalleries?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return [];
        }
    }

    const getSingleGallery = async (id: string) => {
        try {
            const result = await API.graphql(graphqlOperation(getGallery, { id: id })) as any;
            const users = result?.data?.getGallery;
            return users;
        } catch (error) {
            console.error('Error fetching users:', error);
            return [];
        }
    }

    const galleryDelete = async (id: any) => {
        const getData = await getSingleGallery(id);
        if (getData?.fileType === "image" && getData?.Files?.length > 0) {
            await Promise.all(
                getData?.Files?.map(async (fileName: any) => {
                    const filedel = await updDelFile(fileName, "images");
                    if (filedel?.status === 200) {
                        const response = await API.graphql(graphqlOperation(deleteGallery, { input: { id } }));
                        console.log("response", response);
                        return response
                    }
                })
            )
        } else if (getData?.fileType === "video" && getData?.Files?.length > 0) {
            await Promise.all(
                getData?.Files?.map(async (fileName: any) => {
                    const filedel = await updDelFile(fileName, "videos");
                    if (filedel?.status === 200) {
                        const response = await API.graphql(graphqlOperation(deleteGallery, { input: { id } }));
                        console.log("response", response);
                        return response
                    }
                })
            )
        } else if (getData?.fileType === "url") {
            const response = await API.graphql(graphqlOperation(deleteGallery, { input: { id } }));
            return response
        }
    }

    const galleryUpdate = async (values: any) => {
        const response = await API.graphql(graphqlOperation(updateGallery, { input: values }));
        // console.log("response", response);
        return response
    }

    const ActivegalleryList = async (Role: string) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                filter: {
                    Status: { eq: "1" }
                },
                limit: limit,
                nextToken: nextToken
            }

            do {
                const result = await API.graphql(graphqlOperation(listGalleries, filterVar)) as any;
                const users = result?.data?.listGalleries?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listGalleries?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return [];
        }
    }

    return {
        galleryCreate,
        galleryList,
        getSingleGallery,
        galleryUpdate,
        galleryDelete,
        ActivegalleryList
    }
}
export default GalleryServices;