import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import getblogs from '../../../services/Blogs';
// import './BlogPage.css';
import { Helmet } from 'react-helmet';
import './blog.css';
import pages from "../../../utils/pages";
import Loader from "../shared/loadercomponent";



interface compProps {
  title: string;
  description: string;
  keywords: string;
}

const BlogPage: React.FC<compProps> = ({ title, description, keywords }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [blogsItems, setBlogItems] = useState<any>([]);

  const [filteredBlogs, setFilteredBlogs] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [blogsPerPage] = useState<number>(10);

  const [allCats, setAllCats] = useState<any>(false);

  const [categories, setCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>('');


  const [pageInfo, setPageInfo] = useState<any>()
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()

  // Pagination states
  const BlogsRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const BlogsPerPage = 6;

  // const { slug } = useParams();
  // const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      })

    };
    fetchData();
  }, [])



  const listBlogs = async () => {
    try {
      setLoading(true);
      const response = (await getblogs().ActiveBlogList()).filter((item: any) => item?.status === 1);
      // console.log("filteredBlogs", filteredBlogs);
      const categoryname = await getblogs().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.catergory.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });


      setBlogItems(updatedResponse);
      const categoryIds = updatedResponse.map((item: any) => item?.catergory);
      // console.log("categoryIds", categoryIds);

      // category List

      const catResponse = await getblogs().CategoryList();
      setCategories(catResponse);
      location?.state?.category && setSelectedCategory(location?.state?.category);
      setLoading(false);
      // console.log("catResponse", catResponse);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setLoading(false);
    }
  };

  const handleReadMore = (slug: any) => {
    navigate(`/blog/${slug}`);
  };

  useEffect(() => {
    listBlogs();
    window.scrollTo(0, 0);
  }, [location?.state?.category]);



  useEffect(() => {
    // const filterCat = selectedCategory ? selectedCategory : location?.state?.category
    const filterCat = selectedCategory
    const filterBlogs: any = filterCat && blogsItems?.filter((blog: any) => {
      const categoryIds = blog?.catergory[0]
      const splitString = categoryIds?.includes(',') ? categoryIds?.split(',') : [categoryIds];
      return splitString.includes(filterCat)
    });
    filterBlogs && setFilteredBlogs(filterBlogs);
  }, [selectedCategory, location?.state?.category, blogsItems]);

  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };

  // const blogsFiltered = filteredBlogs?.length > 0 ? filteredBlogs : blogsItems;
  const blogsFiltered: any = !selectedCategory ? blogsItems : filteredBlogs?.length > 0 ? filteredBlogs : [];

  const countByCategory = blogsItems && blogsItems?.reduce((acc: any, blog: any) => {

    const categoryIds = blog?.catergory[0]

    const splitString = categoryIds?.includes(',') ? categoryIds?.split(',') : [categoryIds];

    // console.log("blog", splitString)

    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });

    return acc;
  }, {});



  const indexOfLastBlogs = currentPage * BlogsPerPage;
  const indexOfFirstBlog = indexOfLastBlogs - BlogsPerPage;
  // const currentDoctors = blogsFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const currentBlogs = blogsFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const totalPages = Math.ceil(blogsFiltered.length / BlogsPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  useEffect(() => {
    if (BlogsRef.current) {
      BlogsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);
  }, [currentPage]);


  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
      (day % 10 === 2 && day !== 12) ? 'nd' :
        (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

    return `${day}${suffix} ${month} ${year}`;
  };
  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
        
        <Helmet>
          <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
          </Helmet>
        
        <section
          className="page-title text-center"
          // style={{ backgroundImage: "url(images/background/bannerr1.jpg)" }}
          style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/background/bannerr1.jpg' }}
        >
            <div className="container">
              <div className="title-text">

                <h1>{bannerName}</h1>
                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
              <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                  Home  /&nbsp;
                </span>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div><section className="blog-section style-four section">
            <div className="container">
              <div className="row">
                <div className="col-md-9">

                  {currentBlogs?.length > 0 ? (
                    currentBlogs?.map((item: any) => (
                      // console.log("item", item),
                      <div className="col-md-6">
                        <div className="left-side">
                          <div className="item-holder">
                            <div className="image-box">
                              <figure>
                                {/* <a href="#"> */}
                                {/* <img src="images/blog/blog-gen-med.jpg" alt="" /> */}

                                <img
                                  src={item?.bolgImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.bolgImage[0]}` : 'images/blog/blog2.jpg'}
                                  alt={item?.title}
                                  className="img-fluid imgcareer" />
                                {/* </a> */}
                              </figure>
                            </div>
                            <div className="content-text blogtext">
                              {/* <a href="#"> */}
                              <h6>
                                {item?.title}
                              </h6>
                              {/* </a> */}
                              {/* <span>By Neeraj / 08 January 2018</span> */}
                              <span>{formatDate(item?.createdAt)}</span>
                              {/* <p className="htdesc">{truncateText(removeHtmlTags(item?.description), 60)}</p> */}
                              <p className="htdesc" dangerouslySetInnerHTML={{ __html: truncateText(item?.description, 80) }} />

                            </div>
                            <div className="link-btn">
                              <a href='javascript:void(0)' className="btn MedMT"
                                // onClick={() => navigate(`/SingleBlog`, { state: { id: item?.Slug } })}
                                onClick={() => handleReadMore(item?.Slug)}
                              >
                                Read More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))) : (
                    <div className="col-12">
                      <p style={{ textAlign: 'center', fontSize: '30px' }}>No Blogs available</p>
                    </div>
                  )}
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6 className="h6ml">Categories</h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">

                          <li style={{ backgroundColor: selectedCategory === "" ? '#48BDC5' : 'transparent', color: selectedCategory === "" ? 'white' : 'black', padding: '8px', cursor: 'pointer' }}
                            onClick={() => setSelectedCategory("")}>All</li>

                          {categories.map((item: any) => {
                            // console.log("countByCategory", countByCategory);
                            const blogCount = countByCategory[item.id] || 0;
                            return (
                              <li key={item?.id}
                                style={{ backgroundColor: selectedCategory === item?.id ? '#48BDC5' : 'transparent', color: selectedCategory === item?.id ? 'white' : 'black', cursor: 'pointer', padding: '8px' }}
                                className="capitalTxt"
                                onClick={() => { setSelectedCategory(item?.id); setAllCats(false); window.scrollTo(0, 0); }} >

                                {item?.name.toLowerCase()} <span>({blogCount})</span>

                              </li>
                            );
                          }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Pagination Controls */}
              {currentBlogs?.length > 0 ?

                <div className="custom-pagination">
                  <ul className="custom-pagination-list">
                    <li className={`custom-pagination-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button onClick={() => paginate(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`custom-pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
                : null}

              <div className="styled-pagination">
                <ul>
                  <li>
                    <a className="prev" href="#">
                      <span className="fa fa-angle-left" aria-hidden="true"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a className="next" href="#">
                      <span className="fa fa-angle-right" aria-hidden="true"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section><div className="scroll-to-top scroll-to-target" data-target=".header-top">
            <span className="icon fa fa-angle-up"></span>
          </div></>
      )}
    </div>
  );
};

export default BlogPage;

