
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { validationSchema } from './validationSchema';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from '../shared/fileupload';
import { useLocation, useNavigate } from 'react-router-dom';
import getTermsAndConditions from '../../../services/TermsAndCondtions';
import './style.css';
import CKEditorComponent from '../../../utils/CKEditor';
import { Helmet } from "react-helmet";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatFile } from '../../../utils/Fileformat';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface compProps {
    title: string;
    description: string;
}

const CreateTermsAndCondtions: React.FC<compProps> = ({ title, description }) => {

    const initialValues = {
        Title: '',
        Link: '',
        Description: '',
        Status: '',
        Banner: ''

    };

    const location = useLocation();
    const navigate = useNavigate();
    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [InputDetails, setInputDetails] = useState(initialValues);
    const [updateTandC, setUpdateTandC] = useState<boolean>(false);
    // const logUser: any = localStorage.getItem('userData');
    // const logUserObject = JSON.parse(logUser);
    const [ErrMsgSlug, setErrMsgSlug] = useState("");
    // const logUserObject:any = useAuthRedirect();

    const fieldRefs: any = useRef({
        Title: null as HTMLDivElement | null,
        Link: null as HTMLDivElement | null,
        Description: null as HTMLDivElement | null,
        Banner: null as HTMLDivElement | null
    });

    async function provInfo() {
        const TermsAndConditions = await getTermsAndConditions().getSingleTermsAndConditions(location?.state?.id);
        setInputDetails(TermsAndConditions);
        setUpdateTandC(true);
    }


    useEffect(() => {
        provInfo();
    }, [location?.state]);

    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.Banner, setInputDetails, 'Banner', 'images'); // Pass the key and S3 directory
            setInputDetails({ ...InputDetails, Banner: '' });
            // setFile([]);
        } else {
            alert('Deletion canceled');
        }
    };

    // const handleSubmit = async (values: any, { resetForm }: any) => {
    //     console.log("values", values)
    //     try {
    //         const fileName = Date.now() + "_" + file?.name;
    //         await SendFile(file, fileName, directory);

    //         const BlogData = {
    //             Title: values?.Title,
    //             Link: values?.Link,
    //             Description: values?.Description,
    //             Banner:values?.Banner,
    //             Status: "1",
    //         };

    //         const TermsAndConditionsResponse = await getTermsAndConditions().CreateTermsAndConditions(BlogData);
    //         console.log(TermsAndConditionsResponse, "TermsAndConditionsResponse")
    //         if (TermsAndConditionsResponse) {
    //             navigate('/listTermsAndCondtions');
    //         }
    //     } catch (error) {
    //         console.error('Error submitting TermsAndCondtions:', error);
    //         throw new Error('Failed to submit TermsAndCondtions');
    //     }
    // };

    // const handleUpdate = async (values: any, { resetForm }: any) => {
    //     console.log("updatevalues", values?.catergory?.length)

    //     try {
    //         let fileName: any;
    //         if (file?.name) {
    //             fileName = Date.now() + "_" + file?.name;
    //             console.log("fileName", fileName);
    //             await updDelFile(values?.Banner, "images");
    //             await SendFile(file, fileName, "images");
    //         }

    //         const BlogData = {
    //             id: values?.id,
    //             Title: values?.Title,
    //             Link: values?.Link,
    //             Description: values?.Description,
    //             Banner:values?.Banner,
    //             Status: "1"
    //         };



    //         const TermsAndConditionsResponse = await getTermsAndConditions().UpdateTermsAndConditions(BlogData);

    //         console.log("TermsAndConditionsResponse", TermsAndConditionsResponse);

    //         if (TermsAndConditionsResponse) {
    //             navigate('/listTermsAndCondtions');
    //         }
    //     } catch (error) {
    //         console.error('Error submitting TermsAndCondtions:', error);
    //         throw new Error('Failed to submit TermsAndCondtions');
    //     }
    // };


    const handleSubmit = async (values: any, { resetForm }: any) => {
        try {
            let slugError = false;
            if (values?.id) {
                const SlugDetails = await getTermsAndConditions().validateSlug(values?.Link);
                if (SlugDetails?.msg) {
                    SlugDetails?.data?.map((item: any) => {
                        if (item?.id !== values?.id) {
                            slugError = true;
                        }
                    });
                    if (slugError) {
                        setErrMsgSlug("slug is already in use");
                        return;
                    } else {
                        setErrMsgSlug("");
                    }
                }
            } else {
                const SlugDetails = await getTermsAndConditions().validateSlug(values?.Link);
                if (SlugDetails?.msg) {
                    setErrMsgSlug("slug is already in use");
                    return;
                }
            }

            // let newFilename: any;
            // if (file?.name) {
            // const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            // newFilename = replaceSpecialChars(filename);
            const newFilename = await FormatFile(file);

            if (values?.id) {
                file?.name && await updDelFile(values?.Banner, "images");
            }

            file?.name && await SendFile(file, newFilename, "images");
            // }

            const PageData = {
                id: values?.id,
                Title: values?.Title,
                Description: values?.Description,
                Banner: file?.name ? newFilename : values?.Banner ? values?.Banner : [],
                Link: values?.Link,
                Status: 1,

            };

            const BlogResponse = values?.id ? await getTermsAndConditions().UpdateTermsAndConditions(PageData) : await getTermsAndConditions().CreateTermsAndConditions(PageData);

            if (BlogResponse) {
                navigate('/listTermsAndCondtions');
            }

        } catch (error) {
            console.error('Error submitting Service:', error);
            throw new Error('Failed to submit Service');
        }
    };

    console.log(InputDetails, "InputDetails")
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Create Pages</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Create Pages</li>
                </ul>
            </div>

            <div className="container mt-5">
                <div className="row">
                    {/* <div className="col-md-3"></div> */}
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <Formik
                            initialValues={location?.state?.id ? InputDetails : initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({ isSubmitting, setFieldValue, values,errors }) => {

                                const errorCount = Object.keys(errors).length;
                                if (isSubmitting && errorCount > 0) {
                                    for (const key in errors) {
                                        if (fieldRefs.current[key]) {
                                            fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                            break; // Stop at the first error we find
                                        }
                                    }
                                }

                                return (
                                    <Form className="blog-form">
                                        {/* <h3 className="section-Title" style={{ textAlign: 'center',marginBottom:'40px' }}>{!location?.state?.id ? "Create Pages" : "Update Pages"}</h3> */}
                                        <h3 className="section-Title" style={{ textAlign: 'center', marginBottom: '40px' }}>
                                            {!location?.state?.id ? "Create Pages" : `Update ${values?.Title}`}
                                        </h3>


                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.Title = el)}>
                                                <label htmlFor="Title"> Title:</label>
                                                <Field
                                                    type="text"
                                                    id="Title"
                                                    name="Title"
                                                    value={values.Title}
                                                    onChange={(e: any) => setFieldValue("Title", e.target.value)}
                                                />
                                                <ErrorMessage name="Title" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.Link = el)}>
                                                <label htmlFor="Link">Slug:</label>
                                                <Field
                                                    type="text"
                                                    id="Link"
                                                    name="Link"
                                                    value={values?.Link}
                                                    onChange={(e: any) => setFieldValue("Link", e.target.value)}
                                                    disabled={location?.state?.id ? true : false}
                                                />
                                                <ErrorMessage name="Link" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container"  ref={(el) => (fieldRefs.current.Description = el)}>
                                                <label htmlFor="Description">Pages Description:</label>
                                                <Field name="Description">
                                                    {({ field, form }: any) => (
                                                        <CKEditorComponent
                                                            data={field?.value}
                                                            onDataChange={(newContent: string) => setFieldValue('Description', newContent)}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="Description" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.Banner = el)} >
                                                {InputDetails?.Banner?.length > 0 &&
                                                    <>
                                                        <img
                                                            src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.Banner[0]}`}
                                                            alt="blogImage"
                                                            style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }}
                                                        />
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                            <p style={{ margin: 0, lineHeight: '24px' }}><strong>FileName:</strong> {InputDetails?.Banner}</p>
                                                            <span
                                                                style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                                                onClick={handleRemove}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </span>
                                                        </div>
                                                    </>

                                                }

                                                <FileUpload
                                                    label={location?.state?.id ? "Update image" : "Choose image"}
                                                    setDirectory={setDirectory}
                                                    setFile={setFile}
                                                />
                                                <ErrorMessage name="blogImage" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <button type="submit" disabled={isSubmitting}>
                                            {!location?.state?.id ? "Create Pages" : "Update Pages"}
                                        </button>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                    <div className="col-md-3"></div>
                    {/* <div className="col-md-3"></div> */}
                </div>
            </div>
        </>
    );
};

export default CreateTermsAndCondtions;






