import React, { useRef } from 'react';
// import medical from './users/registerImages/medical-2.jpg'
import medical from './../../users/registerImages/medical-2.jpg'

const Faq: React.FC = () => {
    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      alert('your appointment booked successfully!');
      if (formRef.current) {
        formRef.current.reset();
      }
    };
    return (
        <section className="appoinment-section section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="accordion-section">
                            <div className="section-title">
                                <h3>FAQ</h3>
                            </div>
                            <div className="accordion-holder">
                                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingOne">
                                            <h4 className="panel-title">
                                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Where is the hospital located?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                            <div className="panel-body">
                                            Our hospital is located at 5-99/99, Near prakash nagar Bridge, khammam, 507003.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingTwo">
                                            <h4 className="panel-title">
                                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                How do I schedule an appointment?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                            <div className="panel-body">
                                                You can schedule an appointment by calling our appointment line(+91 8125682929) or using our online appointment booking system on our website or app.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingThree">
                                            <h4 className="panel-title">
                                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What are the hospital's visiting hours?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                            <div className="panel-body">
                                            Visiting hours are from 9:00 AM to 10:00 PM daily. Special hours apply to intensive care units and maternity wards.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        {/* <div className="contact-area">
                            <div className="section-title">
                                <h3>Request Appointment</h3>
                            </div>
                            <form name="contact_form" className="default-form contact-form" action="sendmail.php" ref={formRef} onSubmit={handleSubmit} method="post">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <input type="text" name="Name" placeholder="Name" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" name="Email" placeholder="Email" required />
                                        </div>
                                        <div className="form-group">
                                            <select name="subject">
                                                <option>Departments</option>
                                                <option>Diagnostic</option>
                                                <option>Psychological</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <input type="text" name="Phone" placeholder="Phone" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="Date" placeholder="Date" required id="datepicker" />
                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                        </div>
                                        <div className="form-group">
                                            <select name="subject">
                                                <option>Doctor</option>
                                                <option>suresh</option>
                                                <option>hyndavi</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <textarea name="form_message" placeholder="Your Message" required></textarea>
                                        </div>
                                        <div className="form-group text-center">
                                            <button type="submit" className="btn-style-one">submit now</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div> */}
                        <img src={medical} alt="signupImage" style={{ width: '90%', height: '80%', objectFit: 'cover',marginTop:'100px',marginLeft:'73px'}} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;