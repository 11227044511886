/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://uc6kx7jhxzcytb6g4yekw7qtjq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-defjytag5ne6hdg6mqkg5cwpfi"
};


export default awsmobile;
