import { API, graphqlOperation } from "aws-amplify";
import { getServiceCategory, getServices, listServiceCategories, listServices } from "../../graphql/queries";
import { createServices, deleteServiceCategory, deleteServices, updateServiceCategory, updateServices } from "../../graphql/mutations";


const Homeservices = () => {

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }
    const CreateServices = async (userData: any) => {
        console.log("userData", userData);
        try {
            const result = await API.graphql(graphqlOperation(createServices, { input: userData })) as any;
            return result?.data?.createServices;
        } catch (error) {
            console.error('Error creating creating service:', error);
            return null;
        }
    }

    const validateSlug = async (slug: string) => {
        console.log("slug", slug);
        try {
            const filterVar = {
                filter: {
                    Slug: { eq: slug }
                }
            };
         console.log("filterVar", filterVar);

            const existingSlugs: any = await API.graphql(graphqlOperation(listServices, filterVar));

            // console.log("existingSlugs", existingSlugs)

            if (existingSlugs && existingSlugs?.data?.listServices?.items?.length > 0) {
                return { msg: 'slug already exists with this slug', status: 1, data: existingSlugs?.data?.listServices?.items};
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating slug:', error);
            return { msg: 'Error validating slug', status: 1 };
        }
    };


    // const validateSlug = async (slug: string, id?: string) => {
    //     console.log("slug", slug);
    //     try {
    //         const filterVar = {
    //             filter: {
    //                 Slug: { eq: slug },
    //                 ...(id && { id: { ne: id } })
    //             }
    //         };
    //         console.log("filterVar", filterVar);
    
    //         const existingSlugs: any = await API.graphql(graphqlOperation(listServices, filterVar));
    
    //         console.log("existingSlugs", existingSlugs);
    
    //         if (existingSlugs && existingSlugs?.data?.listServices?.items?.length > 0) {
    //             return { msg: 'slug already exists with this slug', status: 1 };
    //         } else {
    //             return { msg: '', status: 0 };
    //         }
    //     } catch (error) {
    //         console.error('Error validating slug:', error);
    //         return { msg: 'Error validating slug', status: 1 };
    //     }
    // };
    


    const servicesList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                limit: limit,
                nextToken: nextToken
            };

            do {
                const result = await API.graphql(graphqlOperation(listServices, filterVar)) as any;
                console.log("hiiresult", result);
                const users = result?.data?.listServices?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listServices?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }

    const ServiceUpdate = async (data: any) => {
        console.log("data", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updateServices, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateServices;
        } catch (error) {
            console.error('Error updating update service:', error);
            return null;
        }
    }

    const serviceDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteServices, { input: { id: id } })) as any;
            return result?.data?.deleteServices;
        } catch (error) {
            console.error('Error in delete Service:', error);
            return null;
        }
    }


    const getServiceSingle = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getServices, { id: id })) as any;
            return result?.data?.getServices;
        } catch (error) {
            console.error('Error in get Services :', error);
            return null;
        }
    }

    // const getServiceSlug = async (slug: any) => {
    //     console.log("slug", slug)
    //     console.log("slugtype", typeof(slug))
    //     try {
    //         const result = await API.graphql(graphqlOperation(getServices, { input : { Slug: slug } })) as any;
    //         return result?.data?.getServices;
    //     } catch (error) {
    //         console.error('Error in get Services :', error);
    //         return null;
    //     }
    // }

    const ActiveservicesList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                filter: {
                    ShowInHome: { eq: 1 },
                    Status: { eq: 1 }
                },
                limit: limit,
                nextToken: nextToken
            };


            do {
                const result = await API.graphql(graphqlOperation(listServices, filterVar)) as any;
                const users = result?.data?.listServices?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listServices?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }

    const FooterservicesList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                filter: {
                    Status: { eq: 1 }
                },
                limit: limit,
                nextToken: nextToken
            };
            do {
                const result = await API.graphql(graphqlOperation(listServices, filterVar)) as any;
                const users = result?.data?.listServices?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listServices?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }
    const getcategoryname = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getServiceCategory, { id: id })) as any;
            return result?.data?.getSpecialityCategory;
        } catch (error) {
            console.error('Error in getSpecialityCategory :', error);
            return null;
        }
    }

    const CategoryList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let Service: any[] = [];

            do {
                const filter = {
                   
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listServiceCategories, filter)) as any;
                const specializations = result?.data?.listServiceCategories?.items || [];
                Service.push(...specializations);
                nextToken = result?.data?.listServiceCategories?.nextToken;
            } while (nextToken);
            Service.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            console.log("Service",Service)
            return Service;
            
        } catch (error) {
            console.error('Error fetching listSpecialityCategories:', error);
            return [];
        }
    }

    const getcategory= async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let allCategories: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listServiceCategories, filter)) as any;
                // console.log("result",result)
                const Categories = result?.data?.listSpecialityCategories?.items || [];
                allCategories.push(...Categories);
                nextToken = result?.data?.listSpecialityCategories?.nextToken;
            } while (nextToken);
            allCategories.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allCategories;
        } catch (error) {
            console.error('Error fetching listSpecialityCategories:', error);
            return []; // Return an empty array in case of error
        }
    }

    const serviceCategoryList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                limit: limit,
                nextToken: nextToken
            };

            do {
                const result = await API.graphql(graphqlOperation(listServiceCategories, filterVar)) as any;
                const users = result?.data?.listServiceCategories?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listServiceCategories?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }


    const ServiceCategoryUpdate = async (data: any) => {
        console.log("careerData", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        console.log('sanitizedUserInfo', sanitizedUserInfo)

        try {
            const result = await API.graphql(graphqlOperation(updateServiceCategory, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateServiceCategory;
        } catch (error) {
            console.error('Error updateServiceCategory:', error);
            return null;
        }
    }
    const ServiceCategoryDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteServiceCategory, { input: { id: id } })) as any;
            return result?.data?.deleteServiceCategory;
        } catch (error) {
            console.error('Error in deleteServiceCategory:', error);
            return null;
        }
    }
    return {
        CreateServices,
        servicesList,
        ServiceUpdate,
        serviceDelete,
        getServiceSingle,
        ActiveservicesList,
        FooterservicesList,
        
        validateSlug,
        getcategoryname,
        CategoryList,
        getcategory,
        serviceCategoryList,
        ServiceCategoryUpdate,
        ServiceCategoryDelete


       
    }
}

export default Homeservices