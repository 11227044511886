
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Homeservices from "./../../../services/homeServices/index";
import userService from "../../../services/users/user.service";
// import './style.css';
import { SingleBed } from "@mui/icons-material";
import { Helmet } from 'react-helmet';
import Loader from "../shared/loadercomponent";

interface compProps {
  title: string;
  description: string;
}


const SingleDoctor: React.FC<compProps> = ({ title, description }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [singleDoctor, setSingleDoctor] = useState<any>();
  const [doctorList, setDoctorList] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const { slug } = useParams();


  console.log("location", slug);

  async function ProvInfo() {
    setLoading(true);
    // const singleDoctor: any = await userService().getuserSingle(location?.state?.id);
    const singleDoctor: any = (await userService().ListDoctor("Doctor")).find((doctor: any) => doctor?.Slug === slug);
    const specializationtList = await userService().getlistSpecializations() as any;
    const specName = specializationtList?.find((spec: any) => spec?.id === singleDoctor?.Specialization);
    console.log("specName", specName);

    console.log("singleDoctor", singleDoctor);
    if (specName?.name) {
      singleDoctor.Specialization = specName?.name;
    }
    // const userSingle: any = singleDoctor?.data?.getUser;
    setSingleDoctor(singleDoctor);
    setLoading(false);
    // const response: any = await Homeservices().FooterservicesList();
    // const sortedServices = response.sort((a: any, b: any) => {
    //   const dateA = new Date(a.updatedAt || a.createdAt).getTime();
    //   const dateB = new Date(b.updatedAt || b.createdAt).getTime();
    //   return dateB - dateA; // Sort in descending order
    // });
    // const latestServices = sortedServices.slice(0, 6);
    // setServiceItems(latestServices);
  }


  useEffect(() => {
    ProvInfo();
  }, [slug]);
  const removeHtmlTags = (str: any) => {
    return str && str.replace(/<\/?[^>]+(>|$)/g, "");
  };


  const [Doctors, setActiveDoctors] = useState<any>([]);

  useEffect(() => {
    async function fetchDoctors() {
      const response: any = await userService().ActiveDoctors('Doctor');
      setActiveDoctors(response);
      const specializationtList = await userService().getlistSpecializations() as any;
      response?.map(async (item: any, index: number) => {
        const specName = specializationtList?.find((spec: any) => spec?.id === item?.Specialization);
        item.Specialization = specName?.name
      })
      setDoctorList(response);
    }
    fetchDoctors();
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <><Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet><section className="page-title text-center" style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/img-4.jpg` }}>
            <div className="container">
              <div className="title-text">
                <h1>Dr. {singleDoctor?.FirstName}</h1>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
                <a onClick={() => navigate("/")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Home  /&nbsp;</a>
              </li>
              <li>
                <a onClick={() => navigate("/team")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Doctors  /&nbsp;</a>
              </li>
              <li>Dr. {singleDoctor?.FirstName}</li>
            </ul>
          </div><section className="ftco-section">
            <div className="container">
              <div className="row justify-content-center mb-5">
                {/* <div className="col-md-7 text-center heading-section ftco-animate">
      <h2 >{singleService?.Title}</h2>
  
    </div> */}
              </div>
              <div className="row">
                {/* {singleDoctor?.map((member: any) => (

      console.log("memberDetails", member),
      <div className="col-md-8">

        <div className="icon d-flex  align-items-center" >
          <img
            className="flaticon-tooth"
            src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${member?.userProfile}`}
            alt={member?.name}
            width="53%"
            style={{ marginBottom: '9px', width: '53%', height: '246px', marginRight: '50px' }}
          />
        </div>
        <div>
          <h3 className="serviceTitles heading-section ftco-animate">DR.{member?.FirstName}</h3>
        </div>
        <span>{member?.Specialization}</span>
        <div className="d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="media-body p-2 mt-3">
              <p className="media-body1" style={{ textAlign: 'justify' }}>
                {removeHtmlTags(member?.Description)}
              </p>
            </div>
          </div>
        </div>
      </div>
    ))} */}
                <div className="col-md-8">
                  <div>
                    <h3 className="serviceTitles heading-section ftco-animate capitalTxt ">{singleDoctor?.FirstName?.toLowerCase()}</h3>
                  </div>

                  <div className="icon d-flex  align-items-center">
                    <img
                      className="flaticon-tooth"
                      // src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleDoctor?.userProfile}`}
                      // alt={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleDoctor?.userProfile}`}
                      src={`${singleDoctor?.userProfile !== null ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleDoctor?.userProfile}` : `${process.env.REACT_APP_HOST}images/profile.jpg`}`}
                      alt={singleDoctor?.firstName}
                      width="53%"
                      style={{ marginBottom: '9px', width: '53%', height: '246px', marginRight: '50px' }} />

                  </div>
                  <h4 className="capitalTxt">{singleDoctor?.Specialization?.toLowerCase()}</h4>
                  <div className="d-flex align-self-stretch ftco-animate">
                    <div className="media block-6 services d-block text-center">
                      <div className="media-body p-2 mt-3">
                        {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
      {removeHtmlTags(singleDoctor?.Description)}
    </p> */}
                        <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleDoctor?.Description }} style={{ textAlign: 'justify' }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                </div>
                <div className="col-md-3 categories-container">
                  <h4 className='MmarginLeft' style={{ marginLeft: '40px' }}>Doctors</h4>
                  {doctorList?.map((item: any) => {
                    return (
                      <div className="d-flex align-self-stretch" key={item?.id || item?.firstName}>

                        <div className="media block-6 services d-block text-center">

                          <div className="media-body mt-3 categories">
                            <div className="categories-container">
                              <ul className="categorise-list">
                                <li className="listyle" onClick={() => setSingleDoctor(item)}><a href="javascript:void(0);" className="serviceTitle capitalTxt">{item?.FirstName?.toLowerCase()}- {item?.Specialization?.toLowerCase()}</a>
                                </li>
                              </ul>
                              {/* <span className="heading serviceTitle">{item?.Title}</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section></>
      )}
    </>
  )
}
export default SingleDoctor;


