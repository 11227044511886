
import React, { useEffect, useState } from 'react';
import { createSpecialityCategory } from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { listSpecialityCategories } from '../../../graphql/queries';
import Multiselect from 'multiselect-react-dropdown';
import './CustomMultiselect.css'; // Import your custom CSS file
import getspeciality from '../../../services/specialities';


interface Speciality {
  label: string;
  value: string;
}

function SpecialityComponent(props: any) {
  const [getSpeciality, setSpeciality] = useState<string | undefined>();
  const [specialityData, setSpecialityData] = useState<Speciality[]>([]);
  const [SpecialityRes, setSpecialityRes] = useState(true);

  const [selectedCats, setSelectedCats] = useState<string[]>([]);

  let transformedOptions: any, getObjects: any;

  useEffect(() => {
    async function fetchSpeciality() {
      try {
        // const AllSpeciality = await API.graphql(graphqlOperation(listSpecialityCategories)) as any;
        const AllSpeciality = await getspeciality().specialityCategoryList();
        const speciality = AllSpeciality?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        }));
        setSpecialityData(speciality);
        setSpecialityRes(true);
      } catch (err) {
        console.log('error fetching speciality', err);
      }
    }
    fetchSpeciality();
  }, [SpecialityRes]);


  transformedOptions = specialityData;
  const selectedCategories = selectedCats?.length > 0 ? selectedCats : props?.editValue

     console.log('selectedCategories', selectedCategories);

   getObjects = selectedCategories && typeof selectedCategories[0] === 'string' ? transformedOptions?.filter((option: any) => selectedCategories[0]?.split(',').some((value: string) => option?.value === value)) : [];

  


  

  const InsertSpeciality = async () => {
    if (getSpeciality) {
      try {
        const createdBlog = await API.graphql(
          graphqlOperation(createSpecialityCategory, { input: { name: getSpeciality } })
        ) as any;

        const newGroup = {
          label: createdBlog?.data?.createSpecialityCategory?.name,
          value: createdBlog?.data?.createSpecialityCategory?.id,
        };

        const currentCategories = props?.InputDetails?.category 
          ? Array.isArray(props?.InputDetails?.category) 
            ? props?.InputDetails?.category 
            : props?.InputDetails?.category.split(',')
          : [];

        const updatedCategory = [...currentCategories, newGroup.value].join(',');

        props.setInputDetails((prevState: any) => ({
          ...prevState,
          category: updatedCategory,
        }));
        setSelectedCats([updatedCategory])
        props.setFieldValue('category', updatedCategory);

        setSpecialityRes(false);
        setSpeciality(undefined);
      } catch (err) {
        console.log('error creating blog', err);
      }
    }
  };

  const handleSearch = (e: string) => {
    const specialityExists = transformedOptions.some((option: any) => option.label === e);
    setSpeciality(specialityExists ? undefined : e);
  };

  const handleSelect = (selectedList: any, selectedItem: any) => {
    const currentCategories = props?.InputDetails?.category 
      ? Array.isArray(props?.InputDetails?.category) 
        ? props?.InputDetails?.category 
        : props?.InputDetails?.category.split(',')
      : [];

      console.log('selectedOne', {'currentCategories':currentCategories, 'selectedItem':selectedItem?.value})

    const updatedCategory = [...currentCategories, selectedItem.value].join(',');

    console.log('updatedCategory', updatedCategory)

    props.setInputDetails((prevState: any) => ({
      ...prevState,
      category: [updatedCategory],
    }));

    setSelectedCats([updatedCategory])

    props.setFieldValue('category', updatedCategory);
  };

  const handleRemove = (selectedList: any, removedItem: any) => {
    const currentCategories = props?.InputDetails?.category ? props?.InputDetails?.category : [];
    // const catArr: any = currentCategories[0]?.split(',')
    const catArr: string[] = Array.isArray(currentCategories) && currentCategories[0]
    ? (currentCategories[0].includes(',') ? currentCategories[0].split(',') : [currentCategories[0]])
    : [];
   const updatedCategory = catArr?.filter((id: string) => id !== removedItem?.value).join(',');
   const finalCategory = updatedCategory ? [updatedCategory] : [];
    console.log({finalCategory})
    props.setInputDetails((prevState: any) => ({
      ...prevState,
      category: finalCategory,
    }));
    setSelectedCats(finalCategory)
    props.setFieldValue('category', updatedCategory);
  };

  return (
    <div className='form-group mt-3'>
      <label>Select Category*:</label>
      <div className='row col-md-12'>
        <div className='col-md-10' style={{ paddingRight: '0px' }}>
          <Multiselect
            // className="custom-multiselect-container"
            style={{ borderRadius: '0px' }}
            isObject={true}
            onRemove={handleRemove}
            onSearch={handleSearch}
            onSelect={handleSelect}
            options={transformedOptions}
            displayValue='label'
            // showCheckbox
            selectedValues={getObjects}
          />
        </div>
        <div className='col-md-2 px-0'>
          {getSpeciality && (
            <button type='button' className='btn btn-dark btn-md' onClick={InsertSpeciality}>
              Add
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SpecialityComponent;
