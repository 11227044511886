import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import getCareer from "../../../services/Careers";
// import getblogs from '../../../services/Blogs';
// import './BlogPage.css';
import { Helmet } from 'react-helmet';
import pages from "../../../utils/pages";
import Loader from "../shared/loadercomponent";


interface compProps {
  title: string;
  description: string;
  keywords: string;
}


const CareerPage: React.FC<compProps> = ({ title, description, keywords }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [careerItems, setCareerItems] = useState<any>([]);

  const [filteredCareer, setFilteredCareer] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [blogsPerPage] = useState<number>(10);

  const [allCats, setAllCats] = useState<any>(false);

  const [categories, setCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [pageInfo, setPageInfo] = useState<any>()
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()
  const [loading, setLoading] = useState(true);

  // const { slug } = useParams();
  // const location = useLocation();
  const CareerRef = useRef<HTMLDivElement>(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const CareerPerPage = 6;

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      console.log("result", result)

      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      })

    };
    fetchData();
  }, [])


  const listCareers = async () => {
    try {
      setLoading(true);
      const response = await getCareer().ActiveCarrerList();
      // console.log("filteredCareer", filteredCareer);
      const categoryname = await getCareer().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.category.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category?.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });

      setCareerItems(updatedResponse);
      const categoryIds = updatedResponse.map((item: any) => item?.category);
      // console.log("categoryIds", categoryIds);

      // category List

      const catResponse = await getCareer().CategoryList();
      setCategories(catResponse);
      location?.state?.category && setSelectedCategory(location?.state?.category);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching careers:', error);
      setLoading(false);
    }
  };

  useEffect(() => {

    listCareers();

    window.scrollTo(0, 0);
  }, [location?.state?.category]);

  useEffect(() => {
    // const filterCat = selectedCategory ? selectedCategory : location?.state?.category
    const filterCat = selectedCategory
    const filterCareers: any = filterCat && careerItems?.filter((careers: any) => {
      const categoryIds = careers?.category[0]
      const splitString = categoryIds?.includes(',') ? categoryIds?.split(',') : [categoryIds];
      // console.log("blogsInfo", splitString)
      // console.log('filterCat', filterCat)
      return splitString.includes(filterCat)
    });

    // console.log("filterCareers", filterCareers);
    // console.log("careerItems", careerItems);


    filterCareers && setFilteredCareer(filterCareers);
  }, [selectedCategory, location?.state?.category, careerItems]);

  const removeHtmlTags = (str: any) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };

  const handleReadMore = (slug: any) => {
    navigate(`/career/${slug}`);
  };

  // const CareersFiltered = filteredCareer?.length > 0 ? filteredCareer : careerItems;
  const CareersFiltered: any = !selectedCategory ? careerItems : filteredCareer?.length > 0 ? filteredCareer : [];

  const countByCategory = careerItems && careerItems?.reduce((acc: any, careers: any) => {

    const categoryIds = careers?.category[0]

    const splitString = categoryIds.includes(',') ? categoryIds?.split(',') : [categoryIds];

    // console.log("careers", splitString)

    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });

    return acc;
  }, {});


  // Pagination logic
  const indexOfLastCareer = currentPage * CareerPerPage;
  const indexOfFirstCareer = indexOfLastCareer - CareerPerPage;
  // const currentDoctors = blogsFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const currentCareers = CareersFiltered.slice(indexOfFirstCareer, indexOfLastCareer);
  const totalPages = Math.ceil(CareersFiltered.length / CareerPerPage);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  useEffect(() => {
    if (CareerRef.current) {
      CareerRef.current.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);
  }, [currentPage]);



  // console.log("careerItems", CareersFiltered);

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
        
        <Helmet>
          <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
          </Helmet>
        
        <section
          className="page-title text-center"
          // style={{ backgroundImage: "url(images/background/bannerr1.jpg)" }}
          style={{ backgroundImage: pageInfo?.length > 0 ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/background/bannerr1.jpg' }}

        >
            <div className="container">
              <div className="title-text">

                <h1>{bannerName}</h1>
                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
                <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                  Home  /&nbsp;
                </span>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div><section className="blog-section style-four section">
            <div className="container">
              <div className="row">
                <div className="col-md-9">

                  {currentCareers?.length > 0 ? (
                    currentCareers?.map((item: any) => (
                      // console.log("item", item),
                      <div className="col-md-6">
                        <div className="left-side">
                          <div className="item-holder">
                            <div className="image-box">
                              <figure>
                                {/* <a href="#"> */}
                                {/* <img src="images/blog/blog-gen-med.jpg" alt="" /> */}

                                <img
                                  src={item?.careerImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.careerImage[0]}` : 'images/blog/blog2.jpg'}
                                  alt={item?.title}
                                  className="img-fluid imgcareer" />
                                {/* </a> */}
                              </figure>
                            </div>
                            <div className="content-text">
                              {/* <a href="#"> */}
                              <h6>
                                {item?.title}
                              </h6>
                              {/* </a> */}
                              <p className="htdesc" dangerouslySetInnerHTML={{ __html: truncateText(item?.description, 80) }} />

                              <div className="link-btn">
                                <a href='javascript:void(0)' className="btn MedMT"
                                  // onClick={() => navigate(`/singlecareer`, { state: { id: item?.id } })}
                                  onClick={() => handleReadMore(item?.Slug)}
                                >
                                  View Details
                                </a>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    ))) : (
                    <div className="col-12">
                      <p style={{ textAlign: 'center', fontSize: '30px' }}>No Careers available</p>
                    </div>
                  )}
                </div>

                {/* <div className="col-md-1"></div> */}
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    {/* <div className="text-title">
            <h6>Search</h6>
          </div>
          <div className="search-box">
            <form method="post" action="/">
              <input type="search" name="search" placeholder="Enter to Search" required />
            </form>
          </div> */}
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6>Categories</h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">

                          <li style={{ backgroundColor: selectedCategory === "" ? '#48BDC5' : 'transparent', color: selectedCategory === "" ? 'white' : 'black', padding: '8px', cursor: 'pointer' }} onClick={() => setSelectedCategory("")}>All</li>

                          {categories?.map((item: any) => {
                            // console.log("countByCategory", countByCategory);



                            const blogCount = countByCategory[item?.id] || 0;

                            return (


                              <li key={item?.id} className="capitalTxt" onClick={() => { setSelectedCategory(item?.id); setAllCats(false); window.scrollTo(0, 0); }} style={{ backgroundColor: selectedCategory === item?.id ? '#48BDC5' : 'transparent', color: selectedCategory === item?.id ? 'white' : 'black', cursor: 'pointer', padding: '8px' }} >

                                {item?.name.toLowerCase()} <span>({blogCount})</span>

                              </li>
                            );
                          }
                          )}

                          {/* <li>
          <a href="#">
            General Medicine<span>(20)</span>
          </a>
        </li>
        <li>
          <a href="#">
            laparoscopic surgeon<span>(4)</span>
          </a>
        </li>
        <li>
          <a href="#">
            orthopedic <span>(9)</span>
          </a>
        </li>
        <li>
          <a href="#">
            oncologist<span>(2)</span>
          </a>
        </li> */}
                        </ul>
                      </div>
                    </div>

                    {/* <div className="tag-list">
            <div className="text-title">
              <h6>Tags</h6>
            </div>
            <a href="#">General Medicine</a>
            <a href="#">Laparoscopic surgeon</a>
            <a href="#">Orthopedic</a>
            <a href="#">Oncologist</a>
            <a href="#">Dormamu</a><a href="#">Medical</a>
          </div> */}

                  </div>
                </div>

              </div>
              {currentCareers?.length > 0 ?
                <div className="custom-pagination">
                  <ul className="custom-pagination-list">
                    <li className={`custom-pagination-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button onClick={() => paginate(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`custom-pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
                : null}

              <div className="styled-pagination">
                <ul>
                  <li>
                    <a className="prev" href="#">
                      <span className="fa fa-angle-left" aria-hidden="true"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a className="next" href="#">
                      <span className="fa fa-angle-right" aria-hidden="true"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section><div className="scroll-to-top scroll-to-target" data-target=".header-top">
            <span className="icon fa fa-angle-up"></span>
          </div></>
      )}
    </div >
  );
};

export default CareerPage;

