import * as Yup from 'yup';

const characterValidation = (value: any) => {
    return /^[A-Za-z ]+$/.test(value);
};

export const contactValidationSchema = Yup.object().shape({
    Name: Yup.string()
        .required('Name is required')
        .min(3, 'Message must be at least 3 characters')
        .test('character-validation', 'Name must contain only characters', characterValidation),
    emailID: Yup.string()
        .email('Invalid email format')
        .required('Email is required')
        .test(
            'valid-domain',
            'Invalid domain extension',
            (value) => {
                // Custom validation for domain extension
                if (value) {
                    const domainRegex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
                    return domainRegex.test(value);
                }
                return true; // Allow empty values, Yup's required handles that
            }
        ),
    phoneNumber: Yup.string()
        .required('Phone Number is required')
        .test(
            'phone-validation',
            'Phone number must have exactly 10 digits',
            (value) => {
                const inputValue = value.replace(/\D/g, '');
                const phoneRegex = /^\d{10}$/;
                return phoneRegex.test(inputValue);
            }
        ),
    Message: Yup.string().required('Message is required')
        .min(3, 'Message must be at least 3 characters')
        .max(150, 'Message must be at most 150 characters only'),


})