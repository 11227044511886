import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ServiceCarousel from "./../ServiceCarousel/ServiceCarousel";
import Homeservices from "../../../services/homeServices";
import getFAQs from "../../../services/FAQ";
import { profile } from "console";
import { Helmet } from 'react-helmet';
import pages from "../../../utils/pages";
import Loader from "../shared/loadercomponent";


interface compProps {
  title: string;
  description: string;
  keywords: string;
}


const ServicePage: React.FC<compProps> = ({ title, description, keywords }) => {
  const faqRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const navigate = useNavigate();
  // React.useEffect(() => {
  //   if (location.hash === "/faq") {
  //     faqRef.current?.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [location]);

  const BlogsRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ServicesPerPage = 6;
  const [pageInfo, setPageInfo] = useState<any>();
  const [bannerName, setBannerName] = useState<any>();
  const [caption, setCaption] = useState<any>();
  const [loading, setLoading] = useState(true);

  // const { slug } = useParams();
  // const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      console.log("result", result)

      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      })

    };
    fetchData();
  }, [])


  const [faqItems, setFAQItems] = useState<any>([]);
  useEffect(() => {
    async function fetchSliderItems() {
      const response = await getFAQs().ActiveFAQlist();
      setFAQItems(response);
      console.log("response", response);
    }

    fetchSliderItems();
  }, []);


  React.useEffect(() => {
    if (location.hash === "#faq") {
      setTimeout(() => {
        faqRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 0);
    }
  }, [location]);
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [galleryItems, setGalleryItems] = useState<any>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [allCats, setAllCats] = useState<any>(false);
  const [filiteredSpeciality, setFiliteredSpeciality] = useState<any>([]);
  useEffect(() => {
    async function fetchGalleryItems() {
      setLoading(true);
      // const response = await Homeservices().FooterservicesList();
      const response = await Homeservices().ActiveservicesList();
      setGalleryItems(response);

      const catResponse = await Homeservices().CategoryList();
      console.log("catResponse", catResponse)
      setCategories(catResponse);
      location?.state?.category && setSelectedCategory(location?.state?.category);
      setLoading(false);
    }
    fetchGalleryItems();
  }, []);
  // const SpecialitiesFiltered = filiteredSpeciality?.length > 0 ? filiteredSpeciality : galleryItems;
  const SpecialitiesFiltered: any = !selectedCategory ? galleryItems : filiteredSpeciality?.length > 0 ? filiteredSpeciality : [];
  const countByCategory = galleryItems && galleryItems.reduce((acc: any, blog: any) => {

    const categoryIds = blog?.category[0]

    const splitString = categoryIds?.includes(',') ? categoryIds.split(',') : [categoryIds];

    // console.log("blog", splitString)

    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });

    return acc;
  }, {});

  // const handleReadMore = (id: any) => {
  //   navigate('/singleService', { state: { id: id } });
  // };
  const handleReadMore = (slug: any) => {
    navigate(`/services/${slug}`);
  };

  const removeHtmlTags = (str: any) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };
  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };

  useEffect(() => {
    // const filterCat = selectedCategory ? selectedCategory : location?.state?.category
    const filterCat = selectedCategory


    const filterBlogs: any = filterCat && galleryItems?.filter((Service: any) => {

      const categoryIds = Service?.category[0]

      const splitString = categoryIds.includes(',') ? categoryIds.split(',') : [categoryIds];

      // console.log("blogsInfo", splitString)
      // console.log('filterCat', filterCat)

      return splitString.includes(filterCat)

    });

    // console.log("filterBlogs", filterBlogs);
    // console.log("blogsItems", blogsItems);


    filterBlogs && setFiliteredSpeciality(filterBlogs);
  }, [selectedCategory, location?.state?.category, galleryItems]);

  useEffect(() => {


    window.scrollTo(0, 0);
  }, []);

  console.log("categories", categories)
  // console.log("blogsItems", SpecialitiesFiltered);
  const indexOfLastBlogs = currentPage * ServicesPerPage;
  const indexOfFirstBlog = indexOfLastBlogs - ServicesPerPage;
  // const currentDoctors = SpecialitiesFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const currentServices = SpecialitiesFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const totalPages = Math.ceil(SpecialitiesFiltered.length / ServicesPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  useEffect(() => {
    if (BlogsRef.current) {
      BlogsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
        
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        
        <section
          className="page-title text-center"
          // style={{ backgroundImage: "url(images/background/meet-the-surgical-team.jpg)" }}
          style={{ backgroundImage: pageInfo?.length > 0 ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/background/meet-the-surgical-team.jpg' }}
        >
            <div className="container">
              <div className="title-text">

                {/* <ul className="title-menu clearfix" style={{textAlign: 'left'}}>
      <li>
        <a href="/">home &nbsp;/</a>
      </li>
      <li>services</li>
    </ul> */}
                <h1>{bannerName}</h1>
                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
                <a href="/" className="aColor">Home /&nbsp;</a>
              </li>
              <li>Services</li>
            </ul>
          </div><section className="feature-section mt-50">
            <div className="container">
              <div className="row">
                {/* <div className="col-sm-12 col-xs-12"> */}




                <div className="col-md-9">
                  <div className="image-content">
                    <div className="section-title text-center">
                      <h3>Leading-edge medical care.</h3>
                      {/* <p>Services We Provide.</p> */}
                    </div>
                    <div className="row">
                      {currentServices?.length > 0 ? (
                        currentServices?.map((item: any) => {

                          return (

                            <div className="col-sm-6" key={item?.Title}>
                              <div className="item">
                                {/* <div className="icon-box">
                      <figure>
                        <img src="images/resource/1.png" alt="" />
                      </figure>
                    </div> */}

                                <img src={item?.Profile?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.Profile}` : 'images/blog/blog2.jpg'}
                                  alt={item.name}
                                  //  className="servImg"
                                  className="img-fluid imgcareer servImg" />

                                <h6>{item?.Title}</h6>
                                {/* <p>{truncateText(removeHtmlTags(item?.Description), 80)}
                    </p> */}

                                <p className="htdesc" dangerouslySetInnerHTML={{ __html: truncateText(item?.Description, 80) }} />
                            
                                  <div className="MTBTn">
                                    <button
                                      className="btn"
                                      onClick={() => handleReadMore(item?.Slug)}
                                      style={{
                                        color: 'white',
                                        cursor: 'pointer',
                                        // background: '#2CBCBC',
                                        // border: 'none',
                                        // padding: '6px 20px',
                                        // borderRadius: '20px',
                                      }}    >
                                      Read more
                                    </button>
                                  </div>
                             
                              </div>
                            </div>
                          );

                        })) : (
                        <div className="col-12">
                          <p style={{ textAlign: 'center', fontSize: '30px' }}>No Services available</p>
                        </div>
                      )}


                    </div>

                    {/* <div className="col-md-1"></div> */}


                    <div className="styled-pagination">
                      <ul>
                        <li>
                          <a className="prev" href="#">
                            <span className="fa fa-angle-left" aria-hidden="true"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="active">
                            1
                          </a>
                        </li>
                        <li>
                          <a href="#">2</a>
                        </li>
                        <li>
                          <a href="#">3</a>
                        </li>
                        <li>
                          <a className="next" href="#">
                            <span className="fa fa-angle-right" aria-hidden="true"></span>
                          </a>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>

                {/* <div className="col-md-1"></div> */}

                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    {/* <div className="text-title">
            <h6>Search</h6>
          </div>
          <div className="search-box">
            <form method="post" action="/">
              <input type="search" name="search" placeholder="Enter to Search" required />
            </form>
          </div> */}
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6>Categories</h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">

                          <li 
                          style={{backgroundColor: selectedCategory === "" ? '#48BDC5' : 'transparent', color: selectedCategory === "" ? 'white' : 'black', padding: '8px', cursor: 'pointer'}} 
                           onClick={() => setSelectedCategory("")}>All</li>

                          {categories?.map((item: any) => {
                            // console.log("countByCategory", countByCategory);



                            const blogCount = countByCategory[item?.id] || 0;

                            return (


                              <li key={item?.id} 
                              className="capitalTxt" 
                              style={{backgroundColor: selectedCategory === item?.id ? '#48BDC5' : 'transparent', color: selectedCategory === item?.id ? 'white' : 'black',cursor: 'pointer',padding:'8px'}}  
                              onClick={() => { setSelectedCategory(item?.id); setAllCats(false); window.scrollTo(0, 0); }} >

                                {item?.name.toLowerCase()} <span>({blogCount})</span>

                              </li>
                            );
                          }
                          )}

                        </ul>
                      </div>
                    </div>



                  </div>
                </div>


              </div>
              {/* Pagination Controls */}
              {currentServices?.length > 0 ?
                <div className="custom-pagination">
                  <ul className="custom-pagination-list">
                    <li className={`custom-pagination-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button onClick={() => paginate(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`custom-pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
                : null}
            </div>
          </section><section ref={faqRef} id="faq" className="service-overview section bg-gray">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "40px" }}><h1>FAQs</h1></div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <img
                    className="img-responsive"
                    src="images/services/service6.jpg"
                    alt="service-image" />
                </div>



                <div className="col-md-6">
                  <div className="accordion-section">
                    <div className="accordion-holder">
                      <div
                        className="panel-group"
                        id="accordion"
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        {faqItems?.map((item: any, index: any) => (
                          <div className="panel panel-default" key={index}>
                            <div className="panel-heading" role="tab" id={`heading${index}`}>
                              <h4 className="panel-title">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href={`#collapse${index}`}
                                  aria-expanded={index === 0 ? "true" : "false"}
                                  aria-controls={`collapse${index}`}
                                >
                                  {item?.Title}
                                </a>
                              </h4>
                            </div>
                            <div
                              id={`collapse${index}`}
                              className={`panel-collapse collapse ${index === 0 ? "in" : ""}`}
                              role="tabpanel"
                              aria-labelledby={`heading${index}`}
                            >
                              <div className="panel-body">
                                {/* {item?.Description} */}
                                <span className="blog-content" dangerouslySetInnerHTML={{ __html: item?.Description }} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section></>

      )}

      {/* Provided Services Section */}
    </div>
  );
};


export default ServicePage;

