
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
// import axios from 'axios';
import StarRatingComponent from 'react-star-rating-component';
import { ReviewvalidationSchema } from './ReviewvalidationSchema';
import './style.css';
// import userService from '../Services/user/user.service';
import ReviewUtils from './../../../utils/Reviews';
import CameraComponent from './webCam';
import { FileUpload } from '../shared/fileupload';
import { useLocation, useNavigate } from 'react-router-dom';
import pages from '../../../utils/pages';
import { Helmet } from "react-helmet";
import Loader from '../shared/loadercomponent';
import { Alert } from 'react-bootstrap';

interface compProps {
    title: string;
    description: string;
}


interface Review {
    Name: string;
    EmailId: string;
    Rating: any;
    Comment: string;
    Upload: any;
}

const initialValues: Review = {
    Name: '',
    EmailId: '',
    Rating: '',
    Comment: '',
    Upload: ''
};

const ReviewForm: React.FC<compProps> = ({ title, description }) => {

    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [pageInfo, setPageInfo] = useState<any>()
    // const { slug } = useParams();
    const location = useLocation();
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const [loading, setLoading] = useState(true);
    const [alertMsg, setAlertMsg] = useState<any>('');

    const fieldRefs: any = useRef({
        Name: null as HTMLDivElement | null,
        EmailId: null as HTMLDivElement | null,
        Rating: null as HTMLDivElement | null,
        Comment: null as HTMLDivElement | null
    });


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            // Simulate fetching data
            const result = await pages(location?.pathname)
            result?.map((item: any) => {
                setPageInfo(item?.Banner);
            })

        };
        fetchData();
        setLoading(false)
    }, [])

    const handleSubmit = async (values: Review, { setSubmitting, resetForm }: any) => {

        try {
            setLoading(true);
            const submit: any = await ReviewUtils().handleSubmit(values, resetForm, file);
            if (submit) {
                setAlertMsg("Review submitted successfully");
                // navigate('/')
                resetForm();
                // setTimeout(() => {
                //     setSuccessMessage('');
                // }, 5000);
                // return { data: submit, status: 200 };
            }
        } catch (error) {
            setLoading(false);
            console.error("Error submitting form", error);
        } finally {
            setLoading(false);
            setSubmitting(false); // Ensure setSubmitting is called
        }
    };


    const getEmojiAndMessage = (rating: number) => {
        switch (rating) {
            case 1:
                return { emoji: '😞', message: 'Poor' };
            case 2:
                return { emoji: '😐', message: 'Fair' };
            case 3:
                return { emoji: '😊', message: 'Good' };
            case 4:
                return { emoji: '😃', message: 'Very Good' };
            case 5:
                return { emoji: '😍', message: 'Excellent' };
            default:
                return { emoji: '', message: '' };
        }
    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (


        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description} />
                    </Helmet>
                    <section
                        className="page-title text-center"
                        // style={{ backgroundImage:"url(pageInfo? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})"}}
                        style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/img-1.jpg' }}
                    >
                        <div className="container">
                            <div className="title-text">

                                <h1>Review Us</h1>
                            </div>
                        </div>
                    </section>
                    <div>
                        <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
                            <li >
                                <a href="/" className="aColor" >Home  /&nbsp;</a>
                            </li>
                            <li> Review Us</li>
                        </ul>
                    </div>

                    {
                        alertMsg && (
                            <div className="alert alert-success alert-dismissible fade show alertMessage">
                                <strong>Success!</strong> {alertMsg}
                                <i
                                    className="alertClose fa fa-close"
                                    aria-hidden="true"
                                    onClick={() => setAlertMsg('')}
                                ></i>
                            </div>
                        )
                    }
                    <Formik
                        initialValues={initialValues}
                        validationSchema={ReviewvalidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, setFieldValue, values, resetForm ,errors}: any) => {

                            const errorCount = Object.keys(errors).length;
                            console.log({ errorCount })

                            if (isSubmitting && errorCount > 0) {
                                for (const key in errors) {
                                    console.log({ key })
                                    if (fieldRefs.current[key]) {
                                        fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                        break; // Stop at the first error we find
                                    }
                                }

                            }
                            return(
                            <div className="container" >
                                {successMessage && <div className="text-success mt-2">{successMessage}</div>}
                                {/* <h2 className="text-center">Review Us</h2> */}
                                <div className="col-md-2"></div>
                               
                                <div className="col-md-8">
                                    <Form autoComplete='off'>

                                        <div className=" mb-3" ref={(el) => (fieldRefs.current.Name = el)}>
                                            <label htmlFor="Name" className="form-label">Name*:</label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                id="Name"
                                                name="Name"
                                                placeholder="Name"
                                            />
                                            <ErrorMessage name="Name" component="div" className="text-danger" />
                                        </div>
                                        <div className="mb-3"  ref={(el) => (fieldRefs.current.EmailId = el)}>
                                            <label htmlFor="EmailId" className="form-label">Email*:</label>
                                            <Field
                                                type="EmailId"
                                                className="form-control"
                                                id="EmailId"
                                                name="EmailId"
                                                placeholder="Email"
                                            />
                                            <ErrorMessage name="EmailId" component="div" className="text-danger" />
                                        </div>
                                        <div className="mb-3 mt-3"  ref={(el) => (fieldRefs.current.Rating = el)}>
                                            <div><label htmlFor="Rating" style={{ textAlign: 'left', marginBottom: '15px' }} className="form-label">How would you rate your experience*:</label></div>
                                            <div className="d-flex align-items-center justify-content-center" style={{ float: 'left' }}>
                                                <div className="star-rating" >
                                                    <StarRatingComponent
                                                        name="Rating"
                                                        starCount={5}
                                                        value={values?.Rating}
                                                        onStarClick={(nextValue: any) => setFieldValue('Rating', nextValue)}
                                                    />
                                                </div>
                                                {values?.Rating > 0 && (
                                                    <>
                                                        <span style={{ fontSize: '24px' }}>
                                                            {getEmojiAndMessage(values?.Rating).emoji}
                                                        </span>
                                                        <p>{getEmojiAndMessage(values?.Rating).message}</p>
                                                    </>
                                                )}
                                            </div>
                                            <ErrorMessage name="Rating" component="div" className="text-danger" />
                                        </div>
                                        <div className="mb-3 clrbth" ref={(el) => (fieldRefs.current.Comment = el)}>
                                            <label htmlFor="Comment">Comment*:</label>
                                            <Field
                                                style={{ resize: 'none', rows: '60', columns: '15' }}
                                                as="textarea"
                                                className="form-control"
                                                id="Comment"
                                                name="Comment"
                                                placeholder="Can you tell us more about your experience?"
                                                rows="5"
                                                cols="20"
                                            />
                                            <ErrorMessage name="Comment" component="div" className="text-danger" />
                                        </div>
                                        <div className=" mb-3 mt-2">
                                            <label htmlFor="Upload" className="form-label">Profile Upload:</label>
                                            <div className="d-flex radioLeft">
                                                <div className="form-check me-5 radioLeft radioleft">
                                                    <Field
                                                        type="radio"
                                                        name="Upload"
                                                        value="desktop"
                                                        className="form-check-input "
                                                        id="desktop"
                                                        onClick={(e: any) => setFieldValue('Upload', "desktop")}
                                                    />
                                                    <label className="form-check-label ml-10" htmlFor="desktop">
                                                        Upload
                                                    </label>
                                                </div>
                                                <div className="form-check me-3 radioLeft">
                                                    <Field
                                                        type="radio"
                                                        name="Upload"
                                                        value="webcam"
                                                        className="form-check-input"
                                                        id="webcam"
                                                        onClick={(e: any) => setFieldValue('Upload', "webcam")}
                                                    />
                                                    <label className="form-check-label ml-10" htmlFor="online">
                                                        Capture
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorMessage name="Upload" component="div" className="errMsg" />
                                        </div>
                                        {values?.Upload === "webcam" ? (
                                            <CameraComponent setFile={setFile} />
                                        ) : values?.Upload === "desktop" ? (
                                            <div className="col-md-8">
                                                <FileUpload label={"Upload profile"} setDirectory={setDirectory} setFile={setFile} />
                                            </div>
                                        ) : null}

                                        {/* </div> */}


                                        <div className="col-md-12 text-center">
                                            <button type="submit" className="btn btn-primary mt-3" disabled={isSubmitting} onReset={resetForm}>
                                                Submit Review
                                            </button>
                                        </div>

                                    </Form>
                                </div>
                                
                                <div className="col-md-2"></div>
                            </div>
                        )
}}
                    </Formik>
                </>
            )}
        </>
    );
};

export default ReviewForm;