/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTodo = /* GraphQL */ `query GetTodo($id: ID!) {
  getTodo(id: $id) {
    id
    name
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTodoQueryVariables, APITypes.GetTodoQuery>;
export const listTodos = /* GraphQL */ `query ListTodos(
  $filter: ModelTodoFilterInput
  $limit: Int
  $nextToken: String
) {
  listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTodosQueryVariables, APITypes.ListTodosQuery>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    FirstName
    LastName
    Specialization
    Gender
    Age
    Status
    Role
    PhoneNumber
    EmailId
    Password
    countryCode
    userProfile
    Otp
    ShowInTeam
    TimeSlot
    Address
    Description
    SocialIcons {
      name
      icon
      __typename
    }
    KYC {
      pastHealthConditions
      pastHealthConditionsDescription
      foodAllergies
      foodAllergiesDescription
      CurrentMedications
      CurrentMedicationsDescription
      ChronicIllnesses
      ChronicIllnessesDescription
      SmokingStatus
      AlcoholConsumption
      heardFrom
      Diabetes
      bloodPressure
      heartAttack
      pregnacy
      __typename
    }
    Slug
    City
    Street
    pinCode
    DOB
    HouseNumber
    MaritalStatus
    qualification
    userType
    resetPWD
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      FirstName
      LastName
      Specialization
      Gender
      Age
      Status
      Role
      PhoneNumber
      EmailId
      Password
      countryCode
      userProfile
      Otp
      ShowInTeam
      TimeSlot
      Address
      Description
      Slug
      City
      Street
      pinCode
      DOB
      HouseNumber
      MaritalStatus
      qualification
      userType
      resetPWD
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getPatientAppointment = /* GraphQL */ `query GetPatientAppointment($id: ID!) {
  getPatientAppointment(id: $id) {
    id
    PatientName
    patientID
    PhoneNumber
    DoctorID
    AppointmentID
    AppointmentDate
    AppointmentTime
    EmailId
    Message
    PaymentMode
    PaymentStatus
    Amount
    KYC {
      pastHealthConditions
      pastHealthConditionsDescription
      foodAllergies
      foodAllergiesDescription
      CurrentMedications
      CurrentMedicationsDescription
      ChronicIllnesses
      ChronicIllnessesDescription
      SmokingStatus
      AlcoholConsumption
      heardFrom
      Diabetes
      bloodPressure
      heartAttack
      pregnacy
      __typename
    }
    Address
    City
    Street
    pinCode
    DOB
    HouseNumber
    MaritalStatus
    userType
    Status
    Gender
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPatientAppointmentQueryVariables,
  APITypes.GetPatientAppointmentQuery
>;
export const listPatientAppointments = /* GraphQL */ `query ListPatientAppointments(
  $filter: ModelPatientAppointmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPatientAppointments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      PatientName
      patientID
      PhoneNumber
      DoctorID
      AppointmentID
      AppointmentDate
      AppointmentTime
      EmailId
      Message
      PaymentMode
      PaymentStatus
      Amount
      Address
      City
      Street
      pinCode
      DOB
      HouseNumber
      MaritalStatus
      userType
      Status
      Gender
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPatientAppointmentsQueryVariables,
  APITypes.ListPatientAppointmentsQuery
>;
export const getDoctorAvaiableDate = /* GraphQL */ `query GetDoctorAvaiableDate($id: ID!) {
  getDoctorAvaiableDate(id: $id) {
    id
    DoctorID
    Date
    Time
    Status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDoctorAvaiableDateQueryVariables,
  APITypes.GetDoctorAvaiableDateQuery
>;
export const listDoctorAvaiableDates = /* GraphQL */ `query ListDoctorAvaiableDates(
  $filter: ModelDoctorAvaiableDateFilterInput
  $limit: Int
  $nextToken: String
) {
  listDoctorAvaiableDates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      DoctorID
      Date
      Time
      Status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDoctorAvaiableDatesQueryVariables,
  APITypes.ListDoctorAvaiableDatesQuery
>;
export const getSpecialization = /* GraphQL */ `query GetSpecialization($id: ID!) {
  getSpecialization(id: $id) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSpecializationQueryVariables,
  APITypes.GetSpecializationQuery
>;
export const listSpecializations = /* GraphQL */ `query ListSpecializations(
  $filter: ModelSpecializationFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpecializations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSpecializationsQueryVariables,
  APITypes.ListSpecializationsQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    Name
    EmailId
    Rating
    Comment
    Status
    userProfile
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Name
      EmailId
      Rating
      Comment
      Status
      userProfile
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const getContact = /* GraphQL */ `query GetContact($id: ID!) {
  getContact(id: $id) {
    id
    Name
    emailID
    phoneNumber
    Message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactQueryVariables,
  APITypes.GetContactQuery
>;
export const listContacts = /* GraphQL */ `query ListContacts(
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Name
      emailID
      phoneNumber
      Message
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactsQueryVariables,
  APITypes.ListContactsQuery
>;
export const getGallery = /* GraphQL */ `query GetGallery($id: ID!) {
  getGallery(id: $id) {
    id
    Category
    Files
    fileType
    Status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGalleryQueryVariables,
  APITypes.GetGalleryQuery
>;
export const listGalleries = /* GraphQL */ `query ListGalleries(
  $filter: ModelGalleryFilterInput
  $limit: Int
  $nextToken: String
) {
  listGalleries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Category
      Files
      fileType
      Status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGalleriesQueryVariables,
  APITypes.ListGalleriesQuery
>;
export const getBlogCategory = /* GraphQL */ `query GetBlogCategory($id: ID!) {
  getBlogCategory(id: $id) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBlogCategoryQueryVariables,
  APITypes.GetBlogCategoryQuery
>;
export const listBlogCategories = /* GraphQL */ `query ListBlogCategories(
  $filter: ModelBlogCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBlogCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBlogCategoriesQueryVariables,
  APITypes.ListBlogCategoriesQuery
>;
export const getBlog = /* GraphQL */ `query GetBlog($id: ID!) {
  getBlog(id: $id) {
    id
    catergory
    title
    description
    status
    userID
    bolgImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBlogQueryVariables, APITypes.GetBlogQuery>;
export const listBlogs = /* GraphQL */ `query ListBlogs(
  $filter: ModelBlogFilterInput
  $limit: Int
  $nextToken: String
) {
  listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      catergory
      title
      description
      status
      userID
      bolgImage
      Slug
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBlogsQueryVariables, APITypes.ListBlogsQuery>;
export const getPages = /* GraphQL */ `query GetPages($id: ID!) {
  getPages(id: $id) {
    id
    Title
    Link
    Description
    Order
    ShowInHeader
    ShowInFooter
    Status
    Banner
    Caption
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPagesQueryVariables, APITypes.GetPagesQuery>;
export const listPages = /* GraphQL */ `query ListPages(
  $filter: ModelPagesFilterInput
  $limit: Int
  $nextToken: String
) {
  listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Title
      Link
      Description
      Order
      ShowInHeader
      ShowInFooter
      Status
      Banner
      Caption
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPagesQueryVariables, APITypes.ListPagesQuery>;
export const getServiceCategory = /* GraphQL */ `query GetServiceCategory($id: ID!) {
  getServiceCategory(id: $id) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceCategoryQueryVariables,
  APITypes.GetServiceCategoryQuery
>;
export const listServiceCategories = /* GraphQL */ `query ListServiceCategories(
  $filter: ModelServiceCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listServiceCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServiceCategoriesQueryVariables,
  APITypes.ListServiceCategoriesQuery
>;
export const getServices = /* GraphQL */ `query GetServices($id: ID!) {
  getServices(id: $id) {
    id
    Title
    Profile
    Description
    ShowInHome
    Status
    Slug
    category
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServicesQueryVariables,
  APITypes.GetServicesQuery
>;
export const listServices = /* GraphQL */ `query ListServices(
  $filter: ModelServicesFilterInput
  $limit: Int
  $nextToken: String
) {
  listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Title
      Profile
      Description
      ShowInHome
      Status
      Slug
      category
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesQueryVariables,
  APITypes.ListServicesQuery
>;
export const getReports = /* GraphQL */ `query GetReports($id: ID!) {
  getReports(id: $id) {
    id
    patientID
    appointmentID
    updatedBy
    patientName
    patientEmail
    patientPhoneNumber
    doctorID
    Status
    file {
      fileType
      name
      type
      description
      __typename
    }
    foodDiet
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportsQueryVariables,
  APITypes.GetReportsQuery
>;
export const listReports = /* GraphQL */ `query ListReports(
  $filter: ModelReportsFilterInput
  $limit: Int
  $nextToken: String
) {
  listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      patientID
      appointmentID
      updatedBy
      patientName
      patientEmail
      patientPhoneNumber
      doctorID
      Status
      foodDiet
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsQueryVariables,
  APITypes.ListReportsQuery
>;
export const getReportType = /* GraphQL */ `query GetReportType($id: ID!) {
  getReportType(id: $id) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportTypeQueryVariables,
  APITypes.GetReportTypeQuery
>;
export const listReportTypes = /* GraphQL */ `query ListReportTypes(
  $filter: ModelReportTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportTypesQueryVariables,
  APITypes.ListReportTypesQuery
>;
export const getCareerCategory = /* GraphQL */ `query GetCareerCategory($id: ID!) {
  getCareerCategory(id: $id) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCareerCategoryQueryVariables,
  APITypes.GetCareerCategoryQuery
>;
export const listCareerCategories = /* GraphQL */ `query ListCareerCategories(
  $filter: ModelCareerCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCareerCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCareerCategoriesQueryVariables,
  APITypes.ListCareerCategoriesQuery
>;
export const getCareeres = /* GraphQL */ `query GetCareeres($id: ID!) {
  getCareeres(id: $id) {
    id
    category
    title
    description
    status
    userID
    careerImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCareeresQueryVariables,
  APITypes.GetCareeresQuery
>;
export const listCareeres = /* GraphQL */ `query ListCareeres(
  $filter: ModelCareeresFilterInput
  $limit: Int
  $nextToken: String
) {
  listCareeres(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      category
      title
      description
      status
      userID
      careerImage
      Slug
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCareeresQueryVariables,
  APITypes.ListCareeresQuery
>;
export const getSpecialityCategory = /* GraphQL */ `query GetSpecialityCategory($id: ID!) {
  getSpecialityCategory(id: $id) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSpecialityCategoryQueryVariables,
  APITypes.GetSpecialityCategoryQuery
>;
export const listSpecialityCategories = /* GraphQL */ `query ListSpecialityCategories(
  $filter: ModelSpecialityCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpecialityCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSpecialityCategoriesQueryVariables,
  APITypes.ListSpecialityCategoriesQuery
>;
export const getSpeciality = /* GraphQL */ `query GetSpeciality($id: ID!) {
  getSpeciality(id: $id) {
    id
    category
    title
    description
    status
    userID
    specialityImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSpecialityQueryVariables,
  APITypes.GetSpecialityQuery
>;
export const listSpecialities = /* GraphQL */ `query ListSpecialities(
  $filter: ModelSpecialityFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpecialities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      category
      title
      description
      status
      userID
      specialityImage
      Slug
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSpecialitiesQueryVariables,
  APITypes.ListSpecialitiesQuery
>;
export const getBanner = /* GraphQL */ `query GetBanner($id: ID!) {
  getBanner(id: $id) {
    Title
    Caption
    Files
    Status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBannerQueryVariables, APITypes.GetBannerQuery>;
export const listBanners = /* GraphQL */ `query ListBanners(
  $filter: ModelBannerFilterInput
  $limit: Int
  $nextToken: String
) {
  listBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      Title
      Caption
      Files
      Status
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBannersQueryVariables,
  APITypes.ListBannersQuery
>;
export const getFAQ = /* GraphQL */ `query GetFAQ($id: ID!) {
  getFAQ(id: $id) {
    Title
    Description
    Status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFAQQueryVariables, APITypes.GetFAQQuery>;
export const listFAQS = /* GraphQL */ `query ListFAQS($filter: ModelFAQFilterInput, $limit: Int, $nextToken: String) {
  listFAQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      Title
      Description
      Status
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFAQSQueryVariables, APITypes.ListFAQSQuery>;
export const getPayment = /* GraphQL */ `query GetPayment($id: ID!) {
  getPayment(id: $id) {
    id
    appointmentId
    razorpay_payment_id
    Amount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentQueryVariables,
  APITypes.GetPaymentQuery
>;
export const listPayments = /* GraphQL */ `query ListPayments(
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appointmentId
      razorpay_payment_id
      Amount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentsQueryVariables,
  APITypes.ListPaymentsQuery
>;
