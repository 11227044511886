import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './TestimonialSection.css';
import ReviewServices from '../../../services/Reviews/review';


interface Testimonial {
    name: string;
    image: string;
    feedback: string;
}

const CustomPrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "-80px", zIndex: 1 }}
            onClick={onClick}
        >
            <i className="fas fa-chevron-left" style={{ fontSize: "24px", color: "#fff" }}></i>
        </div>
    );
  };
  
  const CustomNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "-80px", zIndex: 1 }}
            onClick={onClick}
        >
            <i className="fas fa-chevron-right" style={{ fontSize: "24px", color: "#fff" }}></i>
        </div>
    );
  };

// const testimonials: Testimonial[] = [
//     {
//         name: 'Suresh',
//         image: 'images/testimonials/testmonial2.jpg',
//         feedback: "The orthopedic team at the hospital provided exceptional treatment for my knee injury. From diagnosis to recovery, they were thorough and supportive. I'm grateful for their expertise and dedication.Thank you for your immense care.",
//     },
//     {
//         name: 'Karthik',
//         image: 'images/testimonials/testmonial3.jpg',
//         feedback: "I am grateful to the hospital's emergency department for their prompt response and effective treatment during my recent medical emergency. Their professionalism and quick action saved valuable time and eased my anxiety.",
//     },
//     {
//         name: 'Sharnya',
//         image: 'images/testimonials/testmonial4.jpg',
//         feedback: "I underwent laparoscopic surgery at the hospital, and I couldn't be happier with the outcome. The surgical team was skilled and caring, and the recovery was quicker than I expected. Thank you for your excellent care.",
//     },
// ];



const TestimonialSection: React.FC = () => {

    const [reviews, setReviewList] = useState<any>([]);

async function provInfo() {
    const reviews = await ReviewServices().ActivereviewList();
    console.log("reviews", reviews);
    setReviewList(reviews);
  
}
useEffect(() => {
    provInfo()
}, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section
            className="testimonial-section"
            style={{ backgroundImage: 'url(images/background/1.jpg)' }}
        >
            <div className="container">
                <div className="section-title text-center">
                    <h3>
                        What Our Patients Say
                         {/* <span>Patients Say</span> */}
                    </h3>
                </div>
                < div className="row">
                    
                    <Slider {...settings} className="testimonial-carousel">
                    {reviews.map((testimonial:any, index:any) => (
                        <div className="col-md-6">
                        <div key={index} className="slide-item">
                            <div className="inner-box text-center" style={{height: 'auto'}}>
                                <div className="image-box">
                                    <figure>
                                        <img src={testimonial?.userProfile ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${testimonial?.userProfile}` : "/images/profile.jpg"}alt={testimonial.name} />
                                    </figure>
                                </div>
                                <h6>{testimonial?.Name}</h6>
                                
                                <p className="categories-containerNew">{testimonial?.Comment}</p>
                            </div>
                        </div>
                        </div>
                    ))}
                </Slider>

                    
                </div>
              
            </div>
        </section>
    );
};

export default TestimonialSection;
