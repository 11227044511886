import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { getDoctorAvaiableDate } from '../../graphql/queries';
import { SendFile, updDelFile } from './../../components/views/shared/fileupload/index';
import userService from './../../services/users/user.service'
import { SHA256 } from 'crypto-js';
import { FormatFile } from '../Fileformat';



const Users = () => {

  const handleUpdateProfilePicture = async (file: any, UserDetails: any, LogUser: any, directory: any, setNewProfile: any) => {
    // const fileName = Date.now() + "_" + file?.name;
    // Implement the logic to upload the selected file
    const fileName = await FormatFile(file)
    if (file) {
      const getResponse: any = await SendFile(file, fileName, directory);
      // Perform the upload operation
      if (getResponse?.status === 200) {

        // const userDetails: any = await userService().getuser(LogUser.userId);
        if (UserDetails) {
          const delRes: any = await updDelFile(UserDetails?.userProfile, "images");
          if (delRes?.status === 200) {
            await userService().Updateuser(LogUser?.userId, fileName);
            setNewProfile(true);
            return { msg: "Profile picture updated successfully" }
          }
        }
      }
    } else {
      // Handle the case where no file is selected
      console.error('No file selected');
    }
  };

  const handleLogin = async (
    values: { userName: any; password: any },
    setErrors: any, setErr: any, url: any, navigate: any) => {

    try {
      const { userName, password } = values;
      const hashedPassword = SHA256(password).toString();
      const userResps: any = await userService().validateLogin(userName, hashedPassword);

      if (userResps && userResps?.status === 400) {
        setErr("User not registerd with us / Invalid Credentials");
      } else if (userResps?.data?.resetPWD === "Yes") {
        const userget: any = await userService().getuserSingle(userResps?.data?.details);
        navigate("/updatepassword", { state: { email: userget?.data?.getUser?.EmailId, restPWD: userResps?.data?.resetPWD,userget: userget } });
      } else if (userResps?.data?.userRole !== 'MasterAdmin' && userResps?.data?.userRole !== 'Patient') {
        const { accessToken, details, userRole, PhoneNumber, userName, resetPWD } = userResps.data;

        const userData = {
          accessToken,
          userId: details,
          Role: userRole,
          PhoneNumber: PhoneNumber,
          userName: userName,
          resetPWD: resetPWD
        };
        localStorage.setItem("userData", JSON.stringify(userData));
        navigate("/DashBoard");
      }
      else {
        return { ...userResps, userName: userName }
      }

    } catch (error) {
      // Handle other errors (network error, etc.)
      console.error("Error during login:", error);
      setErrors({ userName: "An error occurred during login" });
    }
  };


  const validateTwoStepOTP = async (userName: string, Otp: string) => {
    // const userResps: any = await API.graphql(graphqlOperation(getGSCMUser, { userName: userName, password: password, type: "login"}));
    // console.log("userResp", userResps);
    // return userResps;
    try {
      const InputDetails = {
        "userName": userName,
        "otp": Otp
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json, text/plain, */*"
        },
        body: JSON.stringify(InputDetails)
      }

      const response = await fetch(`${process.env.REACT_APP_TWO_STEP_AUTHENTICATION}`, options);
      // debugger 

      if (response?.ok) {
        const responseData = await response.json();

        // delete responseData.details;

        if (responseData.accessToken) {

          // console.log('data', responseData);
          // navigate("/Myaccount");
          // setData(responseData);
        }
        return { msg: "user login successfully", data: responseData, userID: responseData?.details };
      } else {
        // throw new Error("Failed to fetch data from the server");
        return response;
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return new Error("Failed to fetch data from the server");
    }
  };


  const handleTwoStepOTP = async (values: { userName: any; otp: any },
    setErrors: any, setErr: any, url: any, navigate: any) => {
    try {
      const { userName, otp } = values;
      // const hashedPassword = SHA256(password).toString();
      const userResps: any = await Users().validateTwoStepOTP(userName, otp);

      if (userResps?.status === 400) {
        // setErrors({ userName : userResps.msg || 'User not registerd with us / Invalid Credentials' });
        setErr("User not registerd with us / Invalid Credentials");
      } else if (userResps?.data?.resetPWD === "Yes" && (userResps?.data?.userRole === 'MasterAdmin' || userResps?.data?.userRole === 'Patient')) {
        const userget: any = await userService().getuserSingle(userResps?.data?.details);
        navigate("/updatepassword", { state: { phoneNumber: userget?.data?.getUser?.EmailId, restPWD: userResps?.data?.resetPWD } });
      } else if (userResps?.data?.accessToken) {
        // If 'data' property is present and truthy, it's a successful response
        const { accessToken, details, userRole, PhoneNumber, userName } = userResps?.data;
        // const userData = { accessToken, userId: details, Role: userRole };
        const userData = {
          accessToken,
          userId: details,
          Role: userRole,
          PhoneNumber: PhoneNumber,
          userName: userName,
        };
        localStorage.setItem("userData", JSON.stringify(userData));
        if (url) {
          navigate(url);
        } else {
          navigate("/DashBoard");
        }
      }
      // if ("data" in userResps && userResps.data) {
      // }
      if ("msg" in userResps) {
        // Access errMsg property if 'msg' property is present
        setErrors({
          userName:
            userResps.msg || "User not registerd with us / Invalid Credentials",
        });
        // console.log("userResps", userResps)
      }
    } catch (error) {
      // Handle other errors (network error, etc.)
      console.error("Error during login:", error);
      setErrors({ userName: "An error occurred during login" });
    }
  };


  const generateEmailSMS = async (EmailId: string, message: string, subject: string, responseMsg: string) => {
    const dataSend = {

      email: EmailId,
      message: message,
      subject: subject,
      responseMsg: responseMsg,

    };

    const options = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json, text/plain, */*",
      },
      body: JSON.stringify(dataSend),
    };

    try {
      const userResp = await fetch(`${process.env.REACT_APP_SEND_EMAIL_FUNCTION}`, options);

      if (!userResp.ok) {
        throw new Error(`Error: ${userResp.statusText}`);
      }

      const result = await userResp.json();
      return result;
    } catch (error) {
      console.error('Error sending email/SMS:', error);
      throw error;
    }
  };

  const generateMobileMsg = async (phone: string, message: string, responseMsg: string) => {
    const dataSend = {
      phone: phone,
      message: message,
      responseMsg: responseMsg,
    };
    const options = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json, text/plain, */*",
      },
      body: JSON.stringify(dataSend),
    };

    try {
      const userResp = await fetch(`${process.env.REACT_APP_SEND_MOBILE_FUNCTION}`, options);

      if (!userResp.ok) {
        throw new Error(`Error: ${userResp.statusText}`);
      }

      const result = await userResp.json();
      return result;
    } catch (error) {
      console.error('Error sending SMS:', error);
      throw error;
    }
  };

  const generateMobileMsgBulk = async (phone: string, message: string, responseMsg: string) => {

    const dataSend = {
      phone: phone,
      message: message,
      responseMsg: responseMsg,
    };

    const options = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json, text/plain, */*",
      },
      body: JSON.stringify(dataSend),
    };

    try {
      const userResp = await fetch(`${process.env.REACT_APP_SEND_MOBILE_FUNCTION_BULK}`, options);

      if (!userResp.ok) {
        throw new Error(`Error: ${userResp.statusText}`);
      }

      const result = await userResp.json();
      return result;
    } catch (error) {
      console.error('Error sending SMS:', error);
      throw error;
    }
  };

  const encodeBase64 = async (input: any) => {
    return btoa(input);
  }

  const decodeBase64 = async (encoded: any) => {
    return atob(encoded);
  };


  const userSessionData = async () => {
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    return logUserObject;
  }

  return {
    handleUpdateProfilePicture,
    handleLogin,
    handleTwoStepOTP,
    validateTwoStepOTP,
    generateEmailSMS,
    generateMobileMsg,
    encodeBase64,
    decodeBase64,
    generateMobileMsgBulk,
    userSessionData
  }



}
export default Users;