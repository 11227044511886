import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  // userName: Yup.string().required('Email/Phone is required').email('Invalid email format'),
  userName: Yup.string()
    .required('Email/Phone is required')
    .test(
      'valid-username',
      'Invalid email or phone number',
      (value) => {
        // Check if the value is either an email or a phone number
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const phoneRegex = /^[0-9]{10}$/;


        if (emailRegex.test(value) || phoneRegex.test(value)) {
          return true;
        }
        return false;
      }
    ),

  password: Yup.string().required('Password is required').min(3, 'Password is too short'),

});
