import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Tooltip, IconButton, Avatar, Menu, MenuItem, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { useIdleTimeout } from './../shared/header/sessionExpire'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './header.css';

interface User {
  userName: string;
  photoURL: string;
  email: string;
  Role: string;
}

const MasterAdminMenu = ({ handleCloseUserMenu }: { handleCloseUserMenu: () => void }) => (
  <div>

    <MenuItem component={Link} to="/dashboard" onClick={handleCloseUserMenu}>Dashboard</MenuItem>
    <MenuItem component={Link} to="/UpdateProfile" onClick={handleCloseUserMenu}>Profile</MenuItem>
    <MenuItem component={Link} to="/BulkUpload" onClick={handleCloseUserMenu}>Manage BulkUpload</MenuItem>
    <MenuItem component={Link} to="/listpatients" onClick={handleCloseUserMenu}>Manage Appointments</MenuItem>
    <MenuItem component={Link} to="/listdoctors" onClick={handleCloseUserMenu}>Manage Doctors</MenuItem>
    <MenuItem component={Link} to="/allRegisterpatients" onClick={handleCloseUserMenu}>List Patients</MenuItem>
    <MenuItem component={Link} to="/listReviews" onClick={handleCloseUserMenu}>Manage Reviews</MenuItem>
    <MenuItem component={Link} to="/listGallery" onClick={handleCloseUserMenu}>Manage Gallery</MenuItem>
    <MenuItem component={Link} to="/listServices" onClick={handleCloseUserMenu}>Manage Services</MenuItem>
    <MenuItem component={Link} to="/serviceCategoryList" onClick={handleCloseUserMenu}>service Category List</MenuItem>

    <MenuItem component={Link} to="/listblogs" onClick={handleCloseUserMenu}>Manage blogs</MenuItem>
    <MenuItem component={Link} to="/BlogCategoryList" onClick={handleCloseUserMenu}>Blog Category List</MenuItem>
    {/* <MenuItem component={Link} to="/reports" onClick={handleCloseUserMenu}>Manage Reports</MenuItem> */}
    <MenuItem component={Link} to="/listCareer" onClick={handleCloseUserMenu}>Manage careeres</MenuItem>
    <MenuItem component={Link} to="/careerCategoryList" onClick={handleCloseUserMenu}>Career Category List</MenuItem>
    <MenuItem component={Link} to="/listSpeciality" onClick={handleCloseUserMenu}>Manage Specialities</MenuItem>
    <MenuItem component={Link} to="/specialityCategoryList" onClick={handleCloseUserMenu}>speciality Category List</MenuItem>
    <MenuItem component={Link} to="/listPharmacy" onClick={handleCloseUserMenu}>Manage Pharmacy</MenuItem>
    <MenuItem component={Link} to="/listLab" onClick={handleCloseUserMenu}>Manage Labs</MenuItem>
    <MenuItem component={Link} to="/listSliders" onClick={handleCloseUserMenu}>Manage Sliders</MenuItem>
    <MenuItem component={Link} to="/listFAQs" onClick={handleCloseUserMenu}>Manage FAQs</MenuItem>
    <MenuItem component={Link} to="/listTermsAndCondtions" onClick={handleCloseUserMenu}>Manage Pages</MenuItem>
    <MenuItem component={Link} to="/AvailabilityList" onClick={handleCloseUserMenu}>Doctor's Availibilty Dates</MenuItem>


  </div>
);

const DoctorMenu = ({ handleCloseUserMenu }: { handleCloseUserMenu: () => void }) => (
  <div>
    <MenuItem component={Link} to="/dashboard" onClick={handleCloseUserMenu}>Dashboard</MenuItem>
    <MenuItem component={Link} to="/UpdateProfile" onClick={handleCloseUserMenu}>Profile</MenuItem>
    {/* <MenuItem component={Link} to="/report" onClick={handleCloseUserMenu}>Manage Reports</MenuItem> */}
    <MenuItem component={Link} to="/listpatients" onClick={handleCloseUserMenu}>Manage Appointments</MenuItem>
    <MenuItem component={Link} to="/doctorAvailability" onClick={handleCloseUserMenu}>Availability Dates</MenuItem>
  </div>
);

const PatientMenu = ({ handleCloseUserMenu }: { handleCloseUserMenu: () => void }) => (
  <div>
    <MenuItem component={Link} to="/dashboard" onClick={handleCloseUserMenu}>Dashboard</MenuItem>
    <MenuItem component={Link} to="/UpdateProfile" onClick={handleCloseUserMenu}>Profile</MenuItem>
    <MenuItem component={Link} to="/listpatients" onClick={handleCloseUserMenu}>Appointments</MenuItem>
  </div>
);
const LabMenu = ({ handleCloseUserMenu }: { handleCloseUserMenu: () => void }) => (
  <div>
    <MenuItem component={Link} to="/dashboard" onClick={handleCloseUserMenu}>Dashboard</MenuItem>
    <MenuItem component={Link} to="/UpdateProfile" onClick={handleCloseUserMenu}>Profile</MenuItem>
    <MenuItem component={Link} to="/listpatients" onClick={handleCloseUserMenu}>Appointments</MenuItem>
    {/* <MenuItem component={Link} to="/reports" onClick={handleCloseUserMenu}>Manage Reports</MenuItem> */}
  </div>
);
const PharmacyMenu = ({ handleCloseUserMenu }: { handleCloseUserMenu: () => void }) => (
  <div>
    <MenuItem component={Link} to="/dashboard" onClick={handleCloseUserMenu}>Dashboard</MenuItem>
    <MenuItem component={Link} to="/UpdateProfile" onClick={handleCloseUserMenu}>Profile</MenuItem>
    <MenuItem component={Link} to="/listpatients" onClick={handleCloseUserMenu}>Appointments</MenuItem>
    {/* <MenuItem component={Link} to="/prescriptions" onClick={handleCloseUserMenu}>Manage prescriptions</MenuItem> */}
  </div>
);

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isDropdownOpenMyAccount, setIsDropdownOpenMyAccount] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState<HTMLButtonElement | null>(null);
  const [anchorElUser, setAnchorElUser] = useState<HTMLButtonElement | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<any>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleDropdownToggleMyAccount = () => {
    setIsDropdownOpenMyAccount(!isDropdownOpenMyAccount);
  };

  const handleDropdownCloseMyAccount = () => {
    setIsDropdownOpenMyAccount(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    navigate('/login');
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const logout = () => {
    localStorage.removeItem('userData');
    navigate("/login");
  };

  const handleUserControl = (event: React.MouseEvent<HTMLElement>) => {
    const userEvent = event.currentTarget.innerText;
    if (userEvent === "Logout") {
      logout();
    } else if (userEvent === "Profile") {
      navigate("/profile");
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUser = JSON.parse(userData);
      setUser(parsedUser);
      setToken(parsedUser.token);
    }
  }, []);

  const logUser: any = localStorage?.getItem('userData');
  const logUserObject: any = JSON.parse(logUser);
  const isIdle = useIdleTimeout(1000 * 900);
  const isActive = useIdleTimeout(1000 * 900);

  //   useEffect(() => {
  //     // if (!isActive && logUserObject) {
  //     if(!isIdle && logUserObject) {   
  //         localStorage?.removeItem("userData");
  //             // console.log("in masteradmin")
  //             alert("Your session has expired. Please login again.");
  //             navigate('/')
  //     } 
  // }, [logUserObject, isActive, navigate, isIdle]);
  useEffect(() => {
    if (!isIdle && logUserObject && logUserObject?.accessToken) {
      // Use a flag to ensure the alert only shows once
      const isAlertShown = localStorage.getItem('isAlertShown');

      if (!isAlertShown) {
        alert("Your session has expired. Please login again.");
        localStorage.setItem('isAlertShown', 'true');
        localStorage.removeItem("userData");
        navigate('/');

        // Optionally, remove the flag after navigating to prevent it from being set indefinitely
        setTimeout(() => {
          localStorage.removeItem('isAlertShown');
        }, 0);  // You can adjust this timeout as needed
      }
    }
  }, [isIdle, logUserObject, navigate]);

  useEffect(() => {
    const userToken: any = localStorage.getItem('userData');
    if (userToken) {
      setToken(userToken);
    } else {
      console.error("userData is null in localStorage");
      setToken('');
    }
  }, [localStorage.getItem('userData')]);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScroll = (hash: string) => {
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const roleBasedMenu = (role: any) => {
    switch (role) {
      case 'MasterAdmin':
        return <MasterAdminMenu handleCloseUserMenu={handleCloseUserMenu} />;
      case 'Doctor':
        return <DoctorMenu handleCloseUserMenu={handleCloseUserMenu} />;
      case 'Patient':
        return <PatientMenu handleCloseUserMenu={handleCloseUserMenu} />;
      case 'Lab':
        return <LabMenu handleCloseUserMenu={handleCloseUserMenu} />;
      case 'Pharmacy':
        return <PharmacyMenu handleCloseUserMenu={handleCloseUserMenu} />;
      default:
        return null;
    }
  };

  const getActiveStyle = ({ isActive }: { isActive: boolean }) => ({
    color: isActive ? '#98B868' : '#48bdc5',
    fontWeight: isActive ? 'bold' : '600',
  });

  return (
    <>
      <nav className="navbar navbar-default sticky-header">
        <div className="container-fluid" style={{ backgroundColor: 'white', marginTop: '20px' }}>
          <div className="row">
            <div className="col-md-2 head-nav">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  aria-expanded={isMenuOpen}
                  onClick={toggleMenu}
                >
                  <span className="sr-only">Toggle navigation</span>
                  {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </button>
                <a className="navbar-brand" href="javascript:void(0)" onClick={() => { navigate('/'); closeMenu(); }}>
                  {/* <img src="images/NEWLOGO.png" alt="Logo" style={{ height: '85px', marginTop: '-43px', padding: '10px' }} /> */}
                  {/* <img src="images/orginallogotrust.jpg" alt="Logo"style={{ height: '60px', marginTop: '-30px',marginLeft:'0px', padding: '5px' }} /> */}
                  <img className="logoimg" src={`${process.env.REACT_APP_HOST}images/thlogoBlue.png`} alt="Logo" />
                </a>
                <span className="logo-text logoText" >TRUST HOSPITAL</span>
              </div>
            </div>
            <div className={`col-md-8 navbar-mdl-sec ${isMenuOpen ? 'show' : ''}`}>
              <div className="navbar-collapse" id="navbar-menu">
                <ul className="nav navbar-nav navbar-center" style={{ color: '#48bdc5' }}>
                  <li className="nav-item">
                    <NavLink to="/" style={getActiveStyle} onClick={closeMenu}>Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/about" style={getActiveStyle} onClick={closeMenu}>About us</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/service" style={getActiveStyle} onClick={closeMenu}>Services</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/gallery" style={getActiveStyle} onClick={closeMenu}>Gallery</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/team" style={getActiveStyle} onClick={closeMenu}>Doctors</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/blogs" style={getActiveStyle} onClick={closeMenu}>Blogs</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/career" style={getActiveStyle} onClick={closeMenu}>Careers</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/speciality" style={getActiveStyle} onClick={closeMenu}>Specialities</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/Contact" style={getActiveStyle} onClick={closeMenu}>Contact us</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/TrackAppointment" style={getActiveStyle} onClick={closeMenu}>Track Appointment</NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-2 head-log navbar-rght-sec">
              <li className="nav-item">
                <NavLink to="/Appointment" onClick={() => { navigate('/Appointment'); closeMenu(); }} className="appointmentButton">APPOINTMENT</NavLink>
              </li>
              {/* <button className="navbar-btn" style={{ marginRight: '10px' }} onClick={() => { navigate('/Appointment'); closeMenu(); }}>Appointment</button> */}
              {logUserObject?.accessToken ? (
                <Box sx={{ flexGrow: 0 }}>

                  <IconButton style={{ fontSize: '12px' }} onClick={handleOpenUserMenu} sx={{ p: 0 }}>

                    Hi, {logUserObject?.userName}
                    <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                  </IconButton>

                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <Typography sx={{ p: "5px" }} color="primary" textAlign="center" style={{ fontSize: '13px', color: '#98B868' }}>
                      Hi, {logUserObject?.userName}
                    </Typography>
                    <Divider />
                    {roleBasedMenu(logUserObject?.Role)}
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography onClick={handleUserControl} textAlign="center" style={{ fontSize: '13px', color: '#98B868' }}>Logout</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <li className="nav-item">
                  <NavLink to="/login" style={{ color: '#0000008A', fontSize: '12px' }} onClick={() => { navigate('/login'); closeMenu(); }}>Hello, Sign In</NavLink>
                </li>
                // <span className="" onClick={() => { navigate('/login'); closeMenu(); }}>Login</span>
              )}
            </div>
          </div>
        </div>
      </nav>


      <nav className="mob-header">
        <div className="container-fluid" style={{ backgroundColor: 'white' }}>
          <div className="row">
            <div className="col-md-12 head-log">
              {/* <button className="navbar-btn" style={{ marginRight: '5px' }} onClick={() => { navigate('/Appointment'); closeMenu(); }}>Appointment</button> */}

              <li className="nav-item">
                <NavLink to="/Appointment" onClick={() => { navigate('/Appointment'); closeMenu(); }} className="appointmentButton">APPOINTMENT</NavLink>
              </li>

              {logUserObject?.accessToken ? (
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt={logUserObject?.userName} src={logUserObject?.photoURL} style={{ backgroundColor: '#98B868', marginTop: '-4px' }} />
                    </IconButton> */}
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>

                      Hi, {logUserObject?.userName}
                      <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <Typography sx={{ p: "5px" }} color="primary" textAlign="center" style={{ fontSize: '13px', color: '#98B868' }}>
                      Hi, {logUserObject?.userName}
                    </Typography>
                    <Divider />
                    {roleBasedMenu(logUserObject?.Role)}
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography onClick={handleUserControl} textAlign="center" style={{ fontSize: '13px', color: '#98B868' }}>Logout</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                // <button className="" onClick={() => { navigate('/login'); closeMenu(); }}>Login</button>
                // <li className="nav-item" style={{ marginTop: '10px' }}>
                //   <NavLink to="/login" style={{ marginRight: '14px', color: '#98B868', fontWeight: 'bold', fontSize: '17px' }} onClick={() => { navigate('/login'); closeMenu(); }}>LOGIN</NavLink>
                // </li>
                <li className="nav-item">
                  <NavLink to="/login" style={{ color: '#0000008A', fontSize: '12px' }} onClick={() => { navigate('/login'); closeMenu(); }}>Hello, Sign In</NavLink>
                </li>
              )}
            </div>
          </div>

          <div className="row head-nav navbar-header">

            <div className='col-md-4'>
              <a className="navbar-brand" href="javascript:void(0)" onClick={() => { navigate('/'); closeMenu(); }}>
                <img src="images/thlogoBlue.png" alt="Logo" />
              </a>
              <span className="logo-text" style={{ color: '#48BDC5', fontSize: '17px', marginLeft: '-12px', fontWeight: 'bold' }}>TRUST HOSPITAL</span>
            </div>





            <div className='col-md-8'>

              <button
                type="button"
                className="navbar-toggle collapsed"
                aria-expanded={isMenuOpen}
                onClick={toggleMenu}
              >
                <span className="sr-only">Toggle navigation</span>
                {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </button>

            </div>


          </div>


        </div>

        <div className={`navbar-mdl-sec ${isMenuOpen ? 'show' : ''}`}>
          <div className="navbar-collapse" id="navbar-menu">
            <ul className="nav navbar-nav navbar-center">
              <li className="nav-item">
                <NavLink to="/" style={getActiveStyle} onClick={closeMenu}>Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about" style={getActiveStyle} onClick={closeMenu}>About us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/service" style={getActiveStyle} onClick={closeMenu}>Services</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/gallery" style={getActiveStyle} onClick={closeMenu}>Gallery</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/team" style={getActiveStyle} onClick={closeMenu}>Doctors</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/blogs" style={getActiveStyle} onClick={closeMenu}>Blogs</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/career" style={getActiveStyle} onClick={closeMenu}>Careers</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/speciality" style={getActiveStyle} onClick={closeMenu}>Specialities</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/Contact" style={getActiveStyle} onClick={closeMenu}>Contact us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/TrackAppointment" style={getActiveStyle} onClick={closeMenu}>Track Appointment</NavLink>
              </li>
            </ul>
          </div>
        </div>

      </nav>
    </>
  );
};

export default Header;

