import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SendFile, updDelFile } from '../../components/views/shared/fileupload/uploadFile';

interface CKEditorComponentProps {
  data: string;
  onDataChange: (newContent: string) => void;
  setFieldValue: (field: string, value: any) => void;
  disableLink?: boolean;

}

function CKEditorComponent({ data, onDataChange, setFieldValue, disableLink }: CKEditorComponentProps) {
  const [content, setContent] = useState(data);

  const handleEditorChange = async (event: any, editor: any) => {
    editor.keystrokes.set('Delete', (data: any, cancel: any) => {
      const selectedElement = editor.model.document.selection.getSelectedElement();
      if (selectedElement) {
        const imgID = selectedElement.getAttribute('src');
        if (imgID) {
          let fileName = imgID.split('/').pop();
          updDelFile(fileName, "images");
        }
      }
    });

    editor.keystrokes.set('Backspace', (data: any, cancel: any) => {
      const selectedElement = editor.model.document.selection.getSelectedElement();
      if (selectedElement) {
        const imgID = selectedElement.getAttribute('src');
        if (imgID) {
          let fileName = imgID.split('/').pop();
          updDelFile(fileName, "images");
        }
      }
    });

    const newContent = editor.getData();
    setContent(newContent);
    onDataChange(newContent);
    setFieldValue('description', newContent);
  };

  const MyUploadAdapterPlugin = (editor: any) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  };

  function uploadAdapter(loader: any) {
    return {
      upload: async () => {
        return await loader.file.then((file: any) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("uploadImg", file);
            const fileName = Date.now() + file.name;

            uploadImage(formData, file, fileName).then(async (res) => {
              const imageUrl = `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${fileName}`;
              const newContent = `${content}<img src="${imageUrl}" alt="Uploaded Image"/>`;
              setContent(newContent);
              resolve({ default: imageUrl });
            })
              .catch((err: any) => console.log(err));
          });
        });
      }
    };
  }

  const uploadImage = async (formData: FormData, file: any, fileName: any) => {
    await SendFile(file, fileName, "images");
  };

  const toolbarItems = [
    'bold', 'italic', 'underline', 'bulletedList', 'numberedList', 'undo', 'redo', 'ImageUpload',
  ];
  if (disableLink) {
    toolbarItems.push('link'); // Add link only if not disabled
  }
  return (
    <CKEditor
      editor={ClassicEditor as any}
      data={data}
      onReady={(editor: any) => {
        MyUploadAdapterPlugin(editor);
      }}
      onChange={handleEditorChange}
      config={{
        toolbar: toolbarItems,
        removePlugins: disableLink ? ['Link', 'AutoLink'] : [] // Remove Link and AutoLink plugins if disableLink is true
      }}
    />
  );
}

export default CKEditorComponent;


