import React, { useEffect, useRef, useState } from "react";
// import userService from "../services/user/user.service";
import userService from "./../../../services/users/user.service";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import Renderfiletype from "./renderfiletype";
import FileInput from "./renderfiletype";
import ReportUtils from "./../../../utils/reports";
import ReportServices from "./../../../services/reportservices";
import { validationSchema } from "./validationSchema";

interface Reports {
    patientID: string;
    updatedBy: string;
    patientName: string;
    patientEmail: string;
    patientPhoneNumber: string;
    doctorID: string;
    Status: string;
    file: any[];
    appointmentID: string;
    type: string;
}

const initialState: Reports = {
    patientID: "",
    updatedBy: "",
    patientName: "",
    patientEmail: "",
    patientPhoneNumber: "",
    doctorID: "",
    Status: "",
    file: [{ fileType: "", name: [], type: "", description: "" }],
    appointmentID: "",
    type: "reports"
}

const AddReports = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // const [formData, setFormData] = useState(initialState);
    const [formData, setFormData] = useState(initialState);

    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);

    async function ProvInfo() {
        const patients: any = await userService().ListDoctor("Patient");

        // const selectedUser = patients?.find((user: any) => user?.id === userID?.id);
        // setSingleuser(selectedUser);
        // const singleReport = await ReportServices().getReportSingle(location?.state?.id);

        const reportsbasedonpatirntId: any = await ReportServices().reportsListBypatientID(location?.state?.appointmentID);
        // console.log("reportsbasedonpatirntId", reportsbasedonpatirntId);
        const combinedFiles: any = {};
        reportsbasedonpatirntId.forEach((report: any) => {
            report?.file?.forEach((file: any) => {
                const { fileType, name } = file;

                if (!combinedFiles[fileType]) {
                    combinedFiles[fileType] = {
                        fileType,
                        names: [],
                        type: file?.type,
                        description: file?.description
                    };
                }
                combinedFiles[fileType].names.push(...name);
            });
        });
        const combinedFileList: any = Object.values(combinedFiles)?.map((fileGroup: any) => ({
            fileType: fileGroup?.fileType,
            name: fileGroup?.names,
            type: fileGroup?.type,
            description: fileGroup?.description,
            data: "existed"
        }));
        // console.log("combinedFileList", combinedFileList);
        const combinedReport: any = {
            id: reportsbasedonpatirntId[0]?.id,
            reports: reportsbasedonpatirntId,
            file: combinedFileList,
            patientID: reportsbasedonpatirntId[0]?.patientID,
            appointmentID: reportsbasedonpatirntId[0]?.appointmentID,
            updatedBy: reportsbasedonpatirntId[0]?.updatedBy,
            type: "reports",
        }
        setFormData(combinedReport);
    }

    useEffect(() => {
        ProvInfo();
    }, [location?.state?.appointmentID]);

    const handleSubmit = async (values: Reports, { setSubmitting }: any) => {
        // console.log("values", values);
        let result: any;
        if (location?.state?.appointmentID) {
            const s3Check = await ReportUtils().check(values);
            // console.log("s3Check", s3Check);
            // console.log("values updatre", values);
            values.type = "reports"
            result = await ReportUtils().reportUpdateUtil(values);
            // console.log("result update", result);
        } else {
            values.appointmentID = location?.state?.appointmentId
            values.patientID = location?.state?.patientID
            result = await ReportUtils().handleSubmit(values);
        }
        if (result) {
            // navigate('/appointmentHistory');
            navigate('/ViewAppointment', { state: { patientID: location?.state?.patientID, PhoneNumber: location?.state?.PhoneNumber } });
        }
    };




    // console.log("userID", userID);
    return (
        <>

            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Reports</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Reports</li>
                </ul>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <div className="mb-3">
                            <Formik
                                initialValues={location?.state?.appointmentID ? formData : initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                                enableReinitialize
                            >
                                {({ setFieldValue, values, errors, touched }) => (
                                    <Form autoComplete="off">
                                        <h2 style={{ marginTop: "100px" }}>Upload Reports</h2>
                                        <div>
                                            {/* {formData?.file?.map((file: any, index: any) => ( */}
                                            <FileInput
                                                setFieldValue={setFieldValue}
                                                values={values}
                                            />
                                            {/* ))} */}
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Upload
                                            </button>
                                        </div >

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddReports;