import { API, graphqlOperation } from "aws-amplify";
import { getReports, getServices, listReports, listReportTypes, listServices } from "../../graphql/queries";
import { createReports, createServices, deleteReports, deleteServices, updateReports, updateServices } from "../../graphql/mutations";


const ReportServices = () => {

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }
    const CreateReport = async (userData: any) => {
        try {
            const result = await API.graphql(graphqlOperation(createReports, { input: userData })) as any;
            return result?.data?.createReports;
        } catch (error) {
            console.error('Error creating creating Reports:', error);
            return null;
        }
    }

    const reportTypeList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                limit: limit,
                nextToken: nextToken
            };

            do {
                const result = await API.graphql(graphqlOperation(listReportTypes, filterVar)) as any;
                const users = result?.data?.listReportTypes?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listReportTypes?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching ReportTypes:', error);
            return [];
        }
    }

    const ReportUpdate = async (data: any) => {
        console.log("data", data);

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updateReports, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateReports;
        } catch (error) {
            console.error('Error updating update service:', error);
            return null;
        }
    }

    const ReportDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteReports, { input: { id: id } })) as any;
            return result?.data?.deleteReports;
        } catch (error) {
            console.error('Error in delete Service:', error);
            return null;
        }
    }


    const getReportSingle = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getReports, { id: id })) as any;
            return result?.data?.getReports;
        } catch (error) {
            console.error('Error in get Reports :', error);
            return null;
        }
    }

    const reportsList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                filter: {
                    type: { eq: "reports" }
                },
                limit: limit,
                nextToken: nextToken
            };

            do {
                const result = await API.graphql(graphqlOperation(listReports, filterVar)) as any;
                const users = result?.data?.listReports?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listReports?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching listReports:', error);
            return [];
        }
    }

    const reportsListBypatientID = async (patientID: any) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                filter: {
                    appointmentID: { eq: patientID },
                    type: { eq: "reports" }
                },
                limit: limit,
                nextToken: nextToken
            };
            do {
                const result = await API.graphql(graphqlOperation(listReports, filterVar)) as any;
                const users = result?.data?.listReports?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listReports?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }

    const reportsByappIDFileType = async (appointmentID: string, fileType: any) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    filter: {
                        appointmentID: { eq: appointmentID },
                        type: { eq: "reports" },
                        // 'M.fileType': { eq: fileType }
                    },
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listReports, filterVar)) as any;

                const users = result?.data?.listReports?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listReports?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            const filteredReports = allUsers?.filter((report: any) =>
                report?.file?.some((file: any) => file?.fileType === fileType)
            );

            return filteredReports;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }

    const UpdateReportFileType = async (data: any) => {

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updateReports, { input: data })) as any;
            return result?.data?.updateReports;
        } catch (error) {
            console.error('Error updating update service:', error);
            return null;
        }
    }


    return {
        CreateReport,
        reportTypeList,
        ReportUpdate,
        ReportDelete,
        getReportSingle,
        reportsList,
        reportsListBypatientID,
        reportsByappIDFileType,
        UpdateReportFileType
    }
}

export default ReportServices;

