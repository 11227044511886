import { faArrowLeft, faArrowRight, faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import ReportsFilesmanagement from "../shared/downloadandprint"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportServices from "../../../services/reportservices";
import { Document, Page, pdfjs } from 'react-pdf';
import { useEffect, useState } from "react";


const ViewappointmentModel = (props: any) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

    const [numPages, setNumPages] = useState(null);
    const [fileTypes, setFileTypes] = useState<any>(null);

    useEffect(() => {
        const fetchFileTypes = async () => {
            const fileTypeList = await ReportServices().reportTypeList();
            setFileTypes(fileTypeList);
        };
        fetchFileTypes();
    }, []);

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    const { isModalOpen, closeModal, currentIndex, viewFiles, setCurrentIndex }: any = props;

    const renderFile: any = (file: any) => {
        if (!fileTypes) {
            return <div>Loading file types...</div>;
        }

        const { name, description, fileType, patientName, AppointmentID } = file;
        const formattedDate = ReportsFilesmanagement().formatDate(file?.date);
        const fileType1: any = fileTypes?.find((type: any) => type?.id === fileType);


        if (name) {
            const fileExtension = file?.name?.split('.').pop().toLowerCase();

            return (
                <div>
                    {fileExtension === 'pdf' ? (
                        //  return (
                        <>
                            <div style={{ float: "left" }}>
                                <strong>Patient Name: </strong>{patientName}<br />
                                <strong>Appointment ID: </strong>{AppointmentID}
                            </div>
                            <div className='center'>
                                <strong>ReportType: </strong> {fileType1?.name}<br />
                                <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
                            </div>
                            <div className='clrbth' >
                                < Document
                                    file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`
                                    }
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    className="pdf-viewer"
                                >
                                    {
                                        Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <Page key={`page_${index + 1}`} pageNumber={index + 1
                                                } />
                                            )
                                        )}
                                </Document>
                                {/* <div>{viewFiles[currentIndex]}</div> */}
                            </div>
                            < div className='clrbth' >
                                <button className="nav-button reportbtn" onClick={handlePrev} >
                                    <FontAwesomeIcon style={{ color: "white" }} icon={faArrowLeft} />
                                </button>

                                < button className="nav-button reportbtn" onClick={handleNext} >
                                    <FontAwesomeIcon style={{ color: "white" }} icon={faArrowRight} />
                                </button>
                            </div>
                        </>
                        // );
                    ) : (
                        <>
                            <div style={{ float: "left" }}>
                                <strong>Patient Name: </strong>{patientName}<br />
                                <strong>Appointment ID: </strong>{AppointmentID}
                            </div>
                            <div className='center'>
                                <strong>ReportType: </strong> {fileType1?.name}<br />
                                <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
                            </div>
                            <div className='clrbth' >
                                <img
                                    key={file}
                                    src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`
                                    }
                                    alt={file}
                                    className='img-viewer'
                                />
                            </div>

                            < div className='clrbth' >

                                <button className="reportbtn" onClick={handlePrev} >
                                    <FontAwesomeIcon style={{ color: "white" }} icon={faArrowLeft} />
                                </button>
                                < button className="reportbtn" onClick={handleNext} >
                                    <FontAwesomeIcon style={{ color: "white" }} icon={faArrowRight} />
                                </button>

                            </div>
                        </>
                    )}
                </div>
            )
        } else if (description) {
            return (
                <>
                    <div style={{ float: "left" }}>
                        <strong>Patient Name: </strong>{patientName}<br />
                        <strong>Appointment ID: </strong>{AppointmentID}
                    </div>
                    <div className='center'>
                        <strong>ReportType: </strong> {fileType1?.name}<br />
                        <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
                    </div>
                    <div className='clrbth  mt-5 textDesc'>
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                    </div>


                    < div className='clrbth' >

                        <button className="nav-button reportbtn" onClick={handlePrev} >
                            <FontAwesomeIcon style={{ color: "white" }} icon={faArrowLeft} />
                        </button>
                        < button className="nav-button reportbtn" onClick={handleNext} >
                            <FontAwesomeIcon style={{ color: "white" }} icon={faArrowRight} />
                        </button>

                    </div>

                </>
            );
        } else {
            return <div>No content available</div>;
        }
    }

    const getFileUrl = (fileName: any) => {
        const baseUrl = process.env.REACT_APP_S3_BUCKET_FETCH;
        const file = `${baseUrl}/images/${fileName}`;
        return file;
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex: any) => (prevIndex + 1) % viewFiles?.length);
    };
    const handlePrev = () => {
        setCurrentIndex((prevIndex: any) => (prevIndex - 1 + viewFiles?.length) % viewFiles?.length);
    };


    return (
        <div className={`modal ${isModalOpen ? "open" : ""}`} style={{ display: isModalOpen ? "block" : "none" }}>
            <div className="modal-content2 modalmob" style={{ marginTop: '100px', height: '600px', width: '1000px' }}>
                <span className="close" onClick={closeModal}>
                    &times;
                </span>
                <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary downloadicon" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "download")}><FontAwesomeIcon style={{ color: 'white' }} icon={faDownload} /></button>
                <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary downloadicon" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "print")}><FontAwesomeIcon className="downloadicon" style={{ color: 'white' }} icon={faPrint} /></button>
                <div style={{ textAlign: 'center' }}>
                    {Array.isArray(viewFiles) && viewFiles?.length > 0 ?
                        renderFile(viewFiles[currentIndex])
                        : "No file to View"
                    }

                    <div className="thumbnail-container">
                        {viewFiles?.map((file: any, index: number) => {
                            const extension = file?.name?.split('.').pop().toLowerCase()
                            // console.log('extension', extension),
                            return (
                                <img
                                    key={index}
                                    className={`thumb-view thumbnail ${currentIndex === index ? 'active' : ''}`}
                                    src={(extension === "pdf" || !file?.name) ? 'images/pdf-thumbnail.png' : getFileUrl(file?.name)}
                                    alt={`Thumbnail ${index + 1}`}
                                    onClick={() => setCurrentIndex(index)}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
// export default ViewappointmentModel;

// const ViewappointmentModel = (props: any) => {
//     console.log("props", props);

//     const [numPages, setNumPages] = useState(null);
//     const [fileTypes, setFileTypes] = useState<any>(null);

//     useEffect(() => {
//         const fetchFileTypes = async () => {
//             try {
//                 const fileTypeList = await ReportServices().reportTypeList();
//                 setFileTypes(fileTypeList);
//             } catch (error) {
//                 console.error("Error fetching file types", error);
//             }
//         };
//         fetchFileTypes();
//     }, []);

//     const onDocumentLoadSuccess = ({ numPages }: any) => {
//         setNumPages(numPages);
//     };

//     const { isModalOpen, closeModal, currentIndex, viewFiles, setCurrentIndex }: any = props;

//     const handleNext = () => {
//         setCurrentIndex((prevIndex: any) => (prevIndex + 1) % viewFiles?.length);
//     };
//     const handlePrev = () => {
//         setCurrentIndex((prevIndex: any) => (prevIndex - 1 + viewFiles?.length) % viewFiles?.length);
//     };
//     const getFileUrl = (fileName: any) => {
//         const baseUrl = process.env.REACT_APP_S3_BUCKET_FETCH;
//         const file = `${baseUrl}/images/${fileName}`;
//         return file;
//     };
//     const renderFile = (file: any) => {
//         if (!fileTypes) {
//             return <div>Loading file types...</div>;
//         }

//         const { name, description, fileType, patientName, AppointmentID } = file;
//         const formattedDate = ReportsFilesmanagement().formatDate(file?.date);
//         const fileType1 = fileTypes?.find((type: any) => type?.id === fileType);

//         if (name) {
//             const fileExtension = file?.name?.split('.').pop().toLowerCase();

//             return (
//                 <div>
//                     {fileExtension === 'pdf' ? (
//                         <>
//                             <div style={{ float: "left" }}>
//                                 <strong>Patient Name: </strong>{patientName}<br />
//                                 <strong>Appointment ID: </strong>{AppointmentID}
//                             </div>
//                             <div className='center'>
//                                 <strong>ReportType: </strong> {fileType1?.name}<br />
//                                 <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
//                             </div>
//                             <div className='clrbth'>
//                                 <Document
//                                     file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`}
//                                     onLoadSuccess={onDocumentLoadSuccess}
//                                     className="pdf-viewer"
//                                 >
//                                     {Array.from(new Array(numPages), (el, index) => (
//                                         <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                                     ))}
//                                 </Document>
//                             </div>
//                             <div className='clrbth'>
//                                 <button className="nav-button reportbtn" onClick={handlePrev}>
//                                     <FontAwesomeIcon style={{ color: "white" }} icon={faArrowLeft} />
//                                 </button>
//                                 <button className="nav-button reportbtn" onClick={handleNext}>
//                                     <FontAwesomeIcon style={{ color: "white" }} icon={faArrowRight} />
//                                 </button>
//                             </div>
//                         </>
//                     ) : (
//                         <>
//                             <div style={{ float: "left" }}>
//                                 <strong>Patient Name: </strong>{patientName}<br />
//                                 <strong>Appointment ID: </strong>{AppointmentID}
//                             </div>
//                             <div className='center'>
//                                 <strong>ReportType: </strong> {fileType1?.name}<br />
//                                 <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
//                             </div>
//                             <div className='clrbth'>
//                                 <img
//                                     key={file}
//                                     src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`}
//                                     alt={file}
//                                     className='img-viewer'
//                                 />
//                             </div>
//                             <div className='clrbth'>
//                                 <button className="reportbtn" onClick={handlePrev}>
//                                     <FontAwesomeIcon style={{ color: "white" }} icon={faArrowLeft} />
//                                 </button>
//                                 <button className="reportbtn" onClick={handleNext}>
//                                     <FontAwesomeIcon style={{ color: "white" }} icon={faArrowRight} />
//                                 </button>
//                             </div>
//                         </>
//                     )}
//                 </div>
//             );
//         } else if (description) {
//             return (
//                 <>
//                     <div style={{ float: "left" }}>
//                         <strong>Patient Name: </strong>{patientName}<br />
//                         <strong>Appointment ID: </strong>{AppointmentID}
//                     </div>
//                     <div className='center'>
//                         <strong>ReportType: </strong> {fileType1?.name}<br />
//                         <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
//                     </div>
//                     <div className='clrbth mt-5 textDesc'>
//                         <p dangerouslySetInnerHTML={{ __html: description }} />
//                     </div>
//                     <div className='clrbth'>
//                         <button className="nav-button reportbtn" onClick={handlePrev}>
//                             <FontAwesomeIcon style={{ color: "white" }} icon={faArrowLeft} />
//                         </button>
//                         <button className="nav-button reportbtn" onClick={handleNext}>
//                             <FontAwesomeIcon style={{ color: "white" }} icon={faArrowRight} />
//                         </button>
//                     </div>
//                 </>
//             );
//         } else {
//             return <div>No content available</div>;
//         }
//     };

//     return (
//         <div className={`modal ${isModalOpen ? "open" : ""}`} style={{ display: isModalOpen ? "block" : "none" }}>
//             <div className="modal-content2 modalmob" style={{ marginTop: '100px', height: '600px', width: '1000px' }}>
//                 <span className="close" onClick={closeModal}>
//                     &times;
//                 </span>
//                 <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary downloadicon" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "download")}><FontAwesomeIcon style={{ color: 'white' }} icon={faDownload} /></button>
//                 <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary downloadicon" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "print")}><FontAwesomeIcon className="downloadicon" style={{ color: 'white' }} icon={faPrint} /></button>
//                 <div style={{ textAlign: 'center' }}>
//                     {Array.isArray(viewFiles) && viewFiles?.length > 0 ?
//                         renderFile(viewFiles[currentIndex])
//                         : "No file to View"
//                     }
//                     <div className="thumbnail-container">
//                         {viewFiles?.map((file: any, index: number) => {
//                             const extension = file?.name?.split('.').pop().toLowerCase();
//                             return (
//                                 <img
//                                     key={index}
//                                     className={`thumb-view thumbnail ${currentIndex === index ? 'active' : ''}`}
//                                     src={(extension === "pdf" || !file?.name) ? 'images/pdf-thumbnail.png' : getFileUrl(file?.name)}
//                                     alt={`Thumbnail ${index + 1}`}
//                                     onClick={() => setCurrentIndex(index)}
//                                 />
//                             );
//                         })}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

export default ViewappointmentModel;
