import React, { useEffect } from 'react';
import Sidebar from './sidebar';
import MainContent from './mainContent';
import { Helmet } from 'react-helmet';
//import useAuthRedirect from '../../../utils/Redirect/useAuthRedirect';

interface User {
    userName: string;
    photoURL: string;
    email: string;
    Role: string;
    token: string;
}

export default function Dashboard() {
    const [token, setToken] = React.useState<any>();
    const [user, setUser] = React.useState<User | null>(null);
    // const logUser: any = localStorage.getItem('userData');
    // const logUserObject = JSON.parse(logUser);
   // const logUserObject:any = useAuthRedirect();

    React.useEffect(() => {
        const userData = localStorage.getItem('userData');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
            setToken(parsedUser.token);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (

        <>
            <Helmet>
                <title>Dashboard</title>
                <meta name="description" content="This is the My Dashboard page description" />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Dashboard</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Dashboard</li>
                </ul>
            </div>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-4">
                <Sidebar />
                </div> */}
                    <div className="col-md-12" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>


                        <MainContent user={user} />

                    </div>
                    {/* <div className="col-md-4"></div> */}
                </div>
            </div>

            <div style={styles.dashboardContainer}>
                {token ? (
                    <>

                    </>
                ) :
                    (
                        // <div style={styles.linkBtn}>hello</div>
                        null
                    )
                }
            </div>
        </>
    );
}

const styles = {
    dashboardContainer: {
        display: 'flex',
        height: '10vh',
    },
    linkBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '24px',
        color: '#333',
    },
};
