import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import ContactServices from '../../../services/contact/contact';
import Sidebar from '../Dashboard/sidebar';


interface Review {
    Name: string;
    EmailId: string;
    phoneNumber: any;
    Message: string;
    // Status: any;
}

const ListContacts = () => {
    const initialState: Review = {
        Name: '',
        EmailId: '',
        phoneNumber: '',
        Message: '',
        // Status: ''
    };

    const navigate = useNavigate();
    const [data, setData] = useState<Review>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [listContact, setListContact] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('PDF Viewer Modal');
    const [singleReview, setSingleReview] = useState<any>({});
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);

    const listContacts = async () => {
        const response = await ContactServices().contactList();
        setListContact(response);
    };

    useEffect(() => {
        listContacts();
    }, []);

    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected Review?");
        if (confirmBox) {
            await ContactServices().contactdelete(id);
            listContacts();
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const filteredData: any = listContact?.filter((row: Review) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

    const openModal = async (files: any) => {
        const singleReview = await ContactServices().getcontactSingle(files?.id);
        setSingleReview(singleReview);
        setIsModalOpen(true);
        setModalHeading(files?.Name);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalHeading("");
    };


    const handleStatusToggle = (rowData: any) => async (event: any) => {
        const updatedStatus = event?.target?.checked === true ? "1" : "0";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        const update = await ContactServices().contactUpdate(updatedRowData);

        setListContact((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, Status: updatedStatus } : row
            )
        );
    };

    const truncateText = (text: any) => {
        if (!text) return '';
        const truncatedText = text?.length > 10 ? text?.substring(0, 10) + '...' : text;
        return truncatedText;
    };

    return (
        <>
         <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Enquiry List</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Enquiry List</li>
                </ul>
            </div>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-2">
                        < Sidebar />
                    </div> */}
                    <div className="col-md-12">
                        <div style={{ margin: '50px auto' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <h1 style={{ marginTop: '100px' }}>Enquiry List</h1>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        value={searchTerm}
                                        onChange={(e: any) => setSearchTerm(e?.target?.value)}
                                        placeholder="Search..."
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <SearchIcon />,
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                            <th style={{ padding: '10px', textAlign: 'center' }}>S.No</th>
                                            <th style={{ padding: '10px', textAlign: 'center' }}>Name</th>
                                            <th style={{ padding: '10px', textAlign: 'center' }}>EmailId </th>
                                            <th style={{ padding: '10px', textAlign: 'center' }}>PhoneNumber</th>
                                            <th style={{ padding: '10px', textAlign: 'center' }}>Message</th>
                                            <th style={{ padding: '10px', textAlign: 'center' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((row: any, index: number) => (
                                            <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                <td style={{ padding: '10px' }}>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td style={{ padding: '10px' }}>{highlightText(row?.Name)}</td>
                                                <td style={{ padding: '10px' }}>{highlightText(row?.emailID)}</td>
                                                <td style={{ padding: '10px' }}>{highlightText(row?.phoneNumber)}</td>
                                                <td style={{ padding: '10px' }}>{highlightText(truncateText(row?.Message))}</td>
                                                <td style={{ padding: '10px' }}>
                                                    <Button
                                                        onClick={() => openModal(row)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<VisibilityIcon style={{ color: 'green' }} />}
                                                    >
                                                        View
                                                    </Button>
                                                    <Button
                                                        onClick={() => handleDelete(row?.id)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<DeleteIcon style={{ color: ' #af0e0e'  }} />}
                                                    >
                                                        Delete
                                                    </Button>

                                                    {/* <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={row.Status === "1"}
                                                            onChange={handleStatusToggle(row)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <span className={row.Status === "1" ? 'status-active' : 'status-inactive'}>
                                                            {row.Status === "1" ? 'Active' : 'Inactive'}
                                                        </span>
                                                    }
                                                /> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='Mmtpagination' style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {/* <div>
                                    Showing {((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                </div> */}
                                <div>
                                    Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                </div>

                                <div>
                                    <Select
                                        value={pageSize}
                                        onChange={(e: any) => handlePageSizeChange(Number(e.target.value))}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                    entries per page
                                </div>
                            </div>

                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    variant="contained"
                                    style={{ marginRight: '10px' }}
                                >
                                    Previous
                                </Button>
                                <div>{currentPage}</div>
                                <Button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    variant="contained"
                                    style={{ marginLeft: '10px' }}
                                >
                                    Next
                                </Button>
                            </div>

                            <div className={`modal ${isModalOpen ? "open" : ""}`}>
                                <div className="modal-content1" style={{ marginTop: '177px', height: '380px' }}>
                                    <span className="close" onClick={closeModal}>
                                        &times;
                                    </span>
                                    <h6 className="text-center">Message By <strong>{modalHeading}</strong></h6>
                                    {singleReview && (
                                        <div style={{ padding: '40px' }}>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Name:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.Name)}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Email ID:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.emailID)}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>PhoneNumber:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.phoneNumber)}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Message:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.Message)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >

        </>

    );
};

export default ListContacts;
