
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./heroslider.css"; // Make sure to import your CSS file
import getSliders from "../../../services/sliders";
import Loader from "../shared/loadercomponent";

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "30px", zIndex: 1 }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-left" style={{ fontSize: "24px", color: '#48bdc5' }}></i>
    </div>
  );
};


const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "30px", zIndex: 1 }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-right" style={{ fontSize: "24px", color: '#48bdc5' }}></i>
    </div>
  );
};






const HeroSlider: React.FC = () => {
  const [sliderItems, setSliderItems] = useState<any>([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    async function fetchSliderItems() {
      setLoading(true);
      const response = await getSliders().ActiveSliderList();
      setSliderItems(response);
      setLoading(false);
    }
    fetchSliderItems();
  }, []);




  const sliderRef = useRef<Slider>(null);


  const settings = {
    // dots: true,
    // infinite: true,
    infinite: sliderItems?.length > 1,
    speed: 500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplay: sliderItems?.length > 1,
    autoplaySpeed: 3000,
    // arrows: true,
    arrows: sliderItems?.length > 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <div>
      {loading ? (
        <Loader /> // Display loader while loading
      ) : (
        <Slider ref={sliderRef} {...settings}>
          {sliderItems?.map((item: any, index: any) => (
            <div className="slider-item">

              <div
                key={index}
                className="hero-slider"
                style={{
                  // backgroundImage: `url(${item.Files})`,
                  backgroundImage: item?.Files
                    ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.Files})`
                    : 'url(images/background/meet-the-surgical-team.jpg)',
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  height: "70vh",
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="content style text-center"
                        style={{ textAlign: "center" }}                  >
                        <h2 className="text-white text-bold mb-2 highlight sliderText">
                          {item?.Title}
                        </h2>
                        {/* Uncomment the following lines if you want to include a tagline or a button */}
                        {/* <p className="tag-text mb-5 highlight" style={{ color:'#48bdc5'}}>
                    {item.tagline || "your health is our top priority."}
                  </p>
                  <a href={item.link || "/#"} className="btn btn-main btn-white">Explore</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};


export default HeroSlider;

