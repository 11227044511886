import React from 'react';
import { Oval, Rings } from 'react-loading-icons'; // or use any other loader

const Loader = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        {/* <Oval stroke="#4fa94d" width={50} height={50} /> */}
        <Rings stroke="#4fa94d" width={50} height={50} />
    </div>
);

export default Loader;
