import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import userService from '../../../services/users/user.service';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReviewServices from '../../../services/Reviews/review';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import './listCareer.css';
import Sidebar from '../Dashboard/sidebar';
import Homeservices from '../../../services/homeServices';
import getblogs from '../../../services/Blogs';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import getCareer from '../../../services/Careers';

import { Helmet } from "react-helmet";
import { updDelFile } from '../shared/fileupload';

interface compProps {
    title: string;
    description: string;
}

interface Career {
    catergory: any,
    title: string,
    description: string,
    bolgImage: string
}

const ListCareer: React.FC<compProps> = ({ title, description }) => {
    const initialState: Career = {
        catergory: '',
        title: '',
        description: '',
        bolgImage: '',
    };

    const navigate = useNavigate();
    const [data, setData] = useState<Career>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [careerlist, setCareerlist] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('PDF Viewer Modal');
    const [singleReview, setSingleReview] = useState<any>({});
    const [categories, setCategories] = useState([]);

    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);

    const listCareers = async () => {
        const response = await getCareer().CareerList();
        const categoryname = await getCareer().getcategory();
        console.log("response", response)
        console.log("categoryname", categoryname)

        const categoryIds = response?.map((item: any) => item?.category)
        // console.log("categoryIds", categoryIds)


        const updatedResponse = response?.map((item: any) => {



            const categoryItems = item?.category[0]?.split(',');

            // console.log("categoryItems", item?.catergory[0])



            const categoryNames = categoryItems?.map((cateId: string) => {
                const category = categoryname?.find((cate: any) => cate?.id === cateId);
                return category ? category?.name : cateId;
            });

            console.log("categoryNames", categoryNames)

            return {
                ...item,
                categoryName: categoryNames
            };
        });


        setCareerlist(updatedResponse);
    };

    const catergoryname = async () => {
        const response: any = await getCareer().CategoryList();
        console.log("catergoryname", response);
        setCategories(response)
        const ids = response.map((category: any) => category.id);
        console.log("ids", ids)
    };



    useEffect(() => {
        listCareers();
        catergoryname();
    }, []);

    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected Career?");
        if (confirmBox) {
            const career: any = await getCareer().getSingleCareer(id);
            const filedel = await updDelFile(career?.careerImage[0], "images");
            if (filedel?.status === 200) {
                await getCareer().CareerDelete(id);
                listCareers();
            }
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    console.log("careerlist", careerlist)

    const filteredData: any = careerlist?.filter((row: any) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);


    const closeModal = () => {
        setIsModalOpen(false);
        setModalHeading("");
    };


    const handleStatusToggle = (rowData: any) => async (event: any) => {
        const updatedStatus = event?.target?.checked === true ? 1 : 0;
        const updatedRowData = { ...rowData, id: rowData?.id, status: updatedStatus };
        const { categoryName, ...newObject } = updatedRowData;
        const update = await getCareer().CareerUpdate(newObject);
        setCareerlist((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, status: updatedStatus } : row
            )
        );
    };
    async function handleEdit(event: any) {
        console.log("event", event);
        navigate('/addCareer', { state: { id: event?.id } });
    }

    const handleSubmit = (setUpdateService: boolean) => {
        navigate('/addCareer', { state: { setUpdateService: false } });
    };

    const removeHtmlTags = (str: any) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    const truncateText = (text: any) => {
        if (!text) return '';
        const truncatedText = text?.length > 10 ? text?.substring(0, 100) + '...' : text;
        return truncatedText;
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <section className="admin-section text-center"
            >
                <div className="container">
                    <div className="bannerTitle">
                        <h1>Career List</h1>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Career List</li>
                </ul>
            </div>
            <div className="container">
                <div className="row">
                    {/* <div className='col-md-2'>
 < Sidebar />
 </div> */}
                    <div className="col-md-12">
                        <div className='tablefs'>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <div className='page-entry'>
                                    <Select
                                        value={pageSize}
                                        onChange={(e) => handlePageSizeChange(Number(e?.target?.value))}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                    entries per page
                                </div>
                                <div className='table-pagination' style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        onClick={() => handleSubmit(false)}
                                        style={{ marginRight: '10px', backgroundColor: '#98B868', color: 'white', borderStyle: 'none', outline: 'none' }}
                                        className='btn btn-primary'
                                    >
                                        Add
                                    </Button>
                                    <TextField
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search..."
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <SearchIcon />,
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-bordered table-striped table-hover table-responsive listCareers">
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                            <th className="sno">S.No</th>
                                            <th className='mdl'>Title</th>
                                            <th className='mdl'>Description</th>
                                            <th className='mdl'>Category</th>
                                            <th className="actions">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((row: any, index: number) => (
                                            console.log("row", row),
                                            <tr key={row.id}>
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>{highlightText(row?.title)}</td>

                                                {/* <td dangerouslySetInnerHTML={{ __html: truncateText(row?.description) }} /> */}
                                                <td
                                                    dangerouslySetInnerHTML={{
                                                        __html: row?.description?.substring(0, 30) + (row?.description?.length > 30 ? "..." : "")
                                                    }}
                                                />
                                                <td>
                                                    {row?.categoryName?.map((catName: any, index: number) => (
                                                        <div key={index} style={{ marginRight: '5px' }}>
                                                            {catName}{index < row.categoryName.length - 1 ? ',' : ''}
                                                        </div>
                                                    ))}
                                                </td>

                                                <td className='actions'>
                                                    <Button title="Edit"
                                                        onClick={() => handleEdit(row)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<EditIcon style={{ color: 'green' }} />}
                                                    >

                                                    </Button>
                                                    <Button title="Delete"
                                                        onClick={() => handleDelete(row?.id)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<DeleteIcon style={{ color: ' #af0e0e' }} />}
                                                    >

                                                    </Button><FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={row.status === 1}
                                                                onChange={handleStatusToggle(row)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={
                                                            <span className={row?.status === 1 ? 'status-active' : 'status-inactive'}>
                                                                {row?.status === 1 ? 'Active' : 'Inactive'}
                                                            </span>
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className='Mmtpagination' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                <div>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

                                </div>
                                <div className="paginationstyle">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                handlePageChange(currentPage - 1);
                                                window.scrollTo(0, 0); // Scroll to top
                                            }
                                        }}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage < totalPages) {
                                                handlePageChange(currentPage + 1);
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div >

        </>

    );
};

export default ListCareer;