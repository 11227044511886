
import React from "react";
import { useState, useEffect } from "react";
import "./about.css";
import { Helmet } from 'react-helmet';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import pages from "../../../utils/pages";
import ListTermsAndConditions from "../../../services/TermsAndCondtions";
import Loader from "../shared/loadercomponent";

interface compProps {
  title: string;
  description: string;
  keywords: string;
}
const AboutUs: React.FC<compProps> = ({ title, description, keywords }) => {

  const [pageInfo, setPageInfo] = useState<any>();
  const [bannerName, setBannerName] = useState<any>();
  const [caption, setCaption] = useState<any>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // const { slug } = useParams();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      console.log("result", result)

      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)

      })

    };

    fetchData();
  }, [])


  // const pathname = location?.pathname;

  const [about, setAbout] = useState<any>();

  const Listabout = async () => {
    try {
      setLoading(true);
      const response = await ListTermsAndConditions().TermsAndConditionsList();
      console.log("response", response);
      response?.map((item) => {
        if (item?.Link === "aboutus") {
          setAbout(item);
        }
      })
      setLoading(false);
    } catch (error) {
      console.error('Error fetching terms and conditions:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Listabout();
    window.scrollTo(0, 0);
  }, []);
  console.log("pageInfo", pageInfo)

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    department: "Departments",
    doctor: "Doctor",
    message: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Implement form submission logic here
  };

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
        
        <Helmet>
          <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
          </Helmet>


          <section
            className="page-title text-center"
            // style={{ backgroundImage:"url(pageInfo? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})"}}
            style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo[0]})` : 'images/img-1.jpg' }}
          >
            <div className="container">
              <div className="title-text">
                {/* <ul className="title-menu clearfix" style={{ textAlign: "left" }}>
      <li>
        <a href="/">Home &nbsp;/</a>
      </li>
      <li>About Us</li>
    </ul> */}
                <h1>{bannerName}</h1>
                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
              </div>
            </div>
          </section>
          <div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
                <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                  Home  /&nbsp;
                </span>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div>
          <section className="story" style={{ marginBottom: "0px !important" }}>
            <div className="container">
              <div className="row">

                <div className="col-md-12">
                  <span className="blog-content" dangerouslySetInnerHTML={{ __html: about?.Description }} style={{ textAlign: 'justify' }} />

                </div>

              </div>
            </div>
          </section><div className="scroll-to-top scroll-to-target" data-target=".header-top">
            <span className="icon fa fa-angle-up"></span>
          </div></>
      )}
    </div>
  );
};

export default AboutUs;