
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import getspeciality from '../../../services/specialities';
// import getspeciality from '../../../services/specialitys';
import { Helmet } from 'react-helmet';
import Loader from '../shared/loadercomponent';


interface compProps {
  title: string;
  description: string;
}




const SpecialityDetails: React.FC<compProps> = ({ title, description }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [singleSpeciality, setSingleSpeciality] = useState<any>();
  const [specialityItems, setSpecialityItems] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const { slug } = useParams();
  const listspecialitys = async () => {
    try {

      const response = await getspeciality().ActiveSpecialityList();
      // console.log("filteredspeciality", filteredspeciality);
      const categoryname = await getspeciality().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.category.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category?.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });
      setSpecialityItems(updatedResponse);
      setCategories(await getspeciality().CategoryList());
    } catch (error) {
      console.error('Error fetching specialitys:', error);
    }
  };

  async function ProvInfo() {
    setLoading(true);
    // const Singlespeciality = await getspeciality().getSinglespeciality(location?.state?.id);
    const Singlespeciality: any = (await getspeciality().specialityList()).find((speciality: any) => speciality?.Slug === slug);
    setSingleSpeciality(Singlespeciality);
    setLoading(false);
  }

  useEffect(() => {
    ProvInfo();
    listspecialitys();
  }, [slug]);

  const removeHtmlTags = (str: any) => {
    return str && str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const countByCategory = specialityItems?.reduce((acc: any, speciality: any) => {
    const categoryIds = speciality?.category[0] || [];
    const splitString = Array.isArray(categoryIds) ? categoryIds : categoryIds.split(',');
    splitString.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });
    return acc;
  }, {});
  const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/blog/blog2.jpg`;
  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <><Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet><section className="page-title text-center" style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/background/banner.jpg` }}>
            <div className="container">
              <div className="title-text">
                <h1>{singleSpeciality?.title}</h1>
              </div>
            </div>
          </section><div>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
                <a onClick={() => navigate("/")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Home  /&nbsp;</a>
              </li>
              <li>
                <a onClick={() => navigate("/speciality")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Specialities  /&nbsp;</a>
              </li>
              <li>{singleSpeciality?.title}</li>
            </ul>
          </div><section className="blog-section section style-four style-five">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-sm-12 col-xs-12">
                  <div className="left-side">
                    <div className="item-holder">
                      <div className="image-box">
                        <figure>
                          {/* <a href="Singleblog"> */}
                          <img
                            className="flaticon-tooth"
                            src={singleSpeciality?.specialityImage.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleSpeciality?.specialityImage[0]}` : bgImageStylesingle}
                            alt={singleSpeciality?.title}
                            width="57%"
                            style={{ marginBottom: '9px', width: '50%' }} />
                          {/* </a> */}
                        </figure>
                      </div>
                      <div className="content-text">
                        {/* <a href="Singleblog"> */}
                        <h5>{singleSpeciality?.title}</h5>
                        {/* </a> */}
                        {/* <span>{singleSpeciality?.createdAt}</span> */}
                        {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
      {removeHtmlTags(singleSpeciality?.description)}
    </p> */}
                        <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleSpeciality?.description }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6>Categories</h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">
                          <li style={{ cursor: 'pointer' }} onClick={() => navigate('/speciality')}>All</li>
                          {categories?.map((item: any) => {
                            const blogCount = countByCategory[item?.id] || 0;

                            //   console.log("blogCount",countByCategory[item?.id] )
                            return (
                              <li key={item?.id} className="capitalTxt" onClick={() => { navigate(`/speciality`, { state: { category: item?.id } }); }} style={{ cursor: 'pointer' }}>
                                {item?.name.toLowerCase()} <span>({blogCount})</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section><div className="scroll-to-top scroll-to-target" data-target=".header-top">
            <span className="icon fa fa-angle-up"></span>
          </div></>
      )}
    </div>
  );
};

export default SpecialityDetails;
