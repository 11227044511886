
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Homeservices from "./../../../services/homeServices/index";
// import getspeciality from '../../../services/specialitys';
import { Helmet } from 'react-helmet';
import pages from '../../../utils/pages';
import Loader from '../shared/loadercomponent';


interface compProps {
  title: string;
  description: string;
}

const SingleService: React.FC<compProps> = ({ title, description }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [singleService, setSingleService] = useState<any>();
  const [specialityItems, setSpecialityItems] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  const [loading, setLoading] = useState(true);

  const { slug } = useParams();


  const [pageInfo, setPageInfo] = useState<any>()

  // const { slug } = useParams();
  // const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      console.log("result", result)

      result?.map((item: any) => {
        setPageInfo(item?.Banner);
      })

    };
    fetchData();
  }, [])


  const listspecialitys = async () => {
    try {

      const response = await Homeservices().ActiveservicesList();
      // console.log("filteredspeciality", filteredspeciality);
      const categoryname = await Homeservices().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.category.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category?.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });
      console.log("updatedResponse", response)
      setSpecialityItems(updatedResponse);
      setCategories(await Homeservices().CategoryList());
    } catch (error) {
      console.error('Error fetching specialitys:', error);
    }
  };

  async function ProvInfo() {
    setLoading(true);
    // const SingleService = await getspeciality().getSingleService(location?.state?.id);
    const SingleService: any = (await Homeservices().servicesList()).find((speciality: any) => speciality?.Slug === slug);

    setSingleService(SingleService);
    setLoading(false);
  }

  useEffect(() => {
    ProvInfo();
    listspecialitys();
  }, [slug]);

  const removeHtmlTags = (str: any) => {
    return str && str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const countByCategory = specialityItems && specialityItems.reduce((acc: any, blog: any) => {

    const categoryIds = blog?.category[0]

    const splitString = categoryIds?.includes(',') ? categoryIds.split(',') : [categoryIds];

    // console.log("blog", splitString)

    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });

    return acc;
  }, {});

  console.log("singleService", singleService)
  const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/blog/blog2.jpg`;
  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <><Helmet>
          <title>{singleService?.Title}</title>
          <meta name="description" content={description} />
        </Helmet><section className="page-title text-center" style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/background/meet-the-surgical-team.jpg` }}>
            <div className="container">
              <div className="title-text">
                <h1>{singleService?.Title}</h1>
              </div>
            </div>
          </section>
          <div className='MfontSize'>
            <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
              <li>
                <a onClick={() => navigate("/")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Home  /&nbsp;</a>
              </li>
              <li>
                <a onClick={() => navigate("/service")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Services  /&nbsp;</a>
              </li>
              <li>{singleService?.Title}</li>
            </ul>
          </div><section className="blog-section section style-four style-five">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-sm-12 col-xs-12">
                  <div className="left-side">
                    <div className="item-holder">
                      <div className="image-box">
                        <figure>
                          <a href="Singleblog">
                            <img
                              className="flaticon-tooth"
                              src={singleService?.Profile?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleService?.Profile}` : bgImageStylesingle}
                              alt={singleService?.name}
                              width="57%"
                              style={{ marginBottom: '9px', width: '50%' }} />
                          </a>
                        </figure>
                      </div>
                      <div className="content-text">
                        <a href="Singleblog">
                          <h5>{singleService?.Title}</h5>
                        </a>
                        {/* <span>{singleService?.createdAt}</span> */}
                        {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
      {removeHtmlTags(singleService?.description)}
    </p> */}
                        <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleService?.Description }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6>Categories</h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">
                          <li style={{ cursor: 'pointer' }} onClick={() => navigate('/service')}>All</li>
                          {categories?.map((item: any) => {
                            const blogCount = countByCategory[item?.id] || 0;

                            //   console.log("blogCount",countByCategory[item?.id] )
                            return (
                              <li key={item?.id} className="capitalTxt" onClick={() => { navigate(`/service`, { state: { category: item?.id } }); }} style={{ cursor: 'pointer' }}>
                                {item?.name.toLowerCase()} <span>({blogCount})</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section><div className="scroll-to-top scroll-to-target" data-target=".header-top">
            <span className="icon fa fa-angle-up"></span>
          </div></>
      )}
    </div>
  );
};

export default SingleService;

